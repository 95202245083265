.social-links {

  &__item {
    display: inline;
  }

  &__icon {
    @include transition(color, background-color);
    @include ico-icon();

    vertical-align: middle;
  }

  &__icon-text {
      font-size: 0;
      line-height: 0;
      color: transparent;
  }

  &__label {
    @include font($family:$font-family__copy, $weight: 'regular');
  }
}
