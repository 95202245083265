.featured-spotlight {
    &.section-accordion {
        .video-card {
            @include respond-to('desktop') {
                margin-top: 0;
            }
            margin-top: 1em;
        }

        .video-card__accordion {
            display: none;
        }

        .video-card__iframe-container {
            display: none;
        }
    }
}