.related-services {
  .listing-services {
    margin-top: px-to-em($spacing__sm);

    &__heading {
      color: $color__black; 
    }

    &__link {
      color: $color__brand-blue;
      font-size: px-to-em($font-size__small);
    }
  }
  
  &__body {
    .section-accordion & {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  
  @include respond-to('between') {
    // This applies to Insights detail template
    &:not(.section--gray) {

      .wrapper {
        position: relative;
        
        &:after {
          content: '';
          position: absolute;
          bottom:0;
          left: px-to-em($wrapper-padding--tablet);
          right: px-to-em($wrapper-padding--tablet);
          display: block;
          border-top: 1px solid $color__border-gray;
          margin-bottom: px-to-em(($spacing__lg + 1px) * -1 );
        }
      }
    }
    // & + .related-insights {
    // }

    .listing-services {
      margin-top: 0;

      & + .listing-services {
        margin-top: $spacing__sm;
      }

      &__link {
        @include transition-bezier(color);

        font-size: px-to-em($font-size__h4--tablet);

        &:hover {
          color: $color__black;
        }
      }
    }
  }
  @include respond-to('desktop') {

    &__body {
      .section-accordion & {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .listing-services {
      &__heading {
        width: px-to-em(130px, $font-size__h4--tablet);
        float: left;
      }

      &__items {
        display: table;
      }
    }

  }
}
