$searchbox__border-color: $color__brand-blue;
$searchbox__fontsize--tablet: 20px;
$searchbox__padding: px-to-em(18px);
$searchbox__padding--tablet: px-to-em(24px, 20px);
$searchbox__icon-size: 20px;

.searchbox {
  position: relative;

  &__input {
  	line-height: 1.1;
		width: 100%;
		@include transition-bezier(border-color, box-shadow);
		border: 1px solid $searchbox__border-color;

		color: $color__gray-charcoal;
		padding: $searchbox__padding;
		padding-right: $searchbox__padding + px-to-em($searchbox__icon-size);
		font-weight: map-get($font-weights, 'light');
		outline: none;//browser default styling

		@include placeholder {
			color: $color__gray-charcoal;
			opacity: 1;
		}

		&:focus {
			border-color: $color__gray-hover--for-gray-background;
			box-shadow: $button__box-shadow;
		}
  }

  &__button {
  	position: absolute;
		right: 0;
		padding-right: 1.25em;
		top: 0;
		height: 100%;
		text-align: right;
		background-color: transparent;
		border-color: transparent;
		color: $searchbox__border-color;
		font-size: px-to-em($searchbox__icon-size);

		&:hover {
			color: $color__gray-hover--for-gray-background;
		}
  }


	@include respond-to('between') {
		&__input {
			font-size: px-to-em($searchbox__fontsize--tablet);
			padding: $searchbox__padding--tablet/1.25 $searchbox__padding--tablet;
		}
	}
}

