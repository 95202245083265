// Link Variants
@mixin overlay-gradient__blue() {
  background-image: linear-gradient(to bottom, $color__gradient--start, $color__gradient--end );
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: $transition__card-gradient;
}


@mixin overlay-gradient__coral() {
  @include transition(opacity);
  
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgba(255, 113, 91, 1) );
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: $transition__card-gradient;
}