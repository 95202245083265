//site search landing page
.site-search-segments{


    &__item {
        font-size: px-to-em($font-size__subheading);
        text-transform: uppercase;
        color: $color__brand-blue;
        @include links__blue-on-light();
        margin-right: 1em;
        
        &:last-child{
            margin-right: 0;
        }

        &--active {
            color: $color__gray-charcoal;
            cursor: auto; 
        }

        display: block;
        margin-top: 10px;
    }


    @include respond-to('landscape') {
        display: flex;
        justify-content: space-between;

        &__item {
            // margin-top: 0;
            display: inline;
        }
    }
}