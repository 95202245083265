.articles-heading {
  position: relative;
  padding-top: px-to-em($site-header-height + $spacing__sm);
  background-color: $color__black;

  &__background {
    @include bg-cover;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: -2;
    opacity: 0.15;
    
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35) );
      // z-index: -1;
    }
  }

  &__body {
    padding-bottom: px-to-em($spacing__md);
    text-align: center;
    color: $color__white;
    position: relative;
    z-index: 2;
  }

  &__title {
    @include titles-banner();
    margin-bottom: px-to-em($spacing__sm, $font-size__banner);
  }

  &__meta {
    color: $color__gray-text;
    padding-bottom: px-to-em($spacing__sm);
  }

  &__meta-item {
    font-size: px-to-em($font-size-base);
    text-transform: uppercase;

    & + & {
      &:before {
        content: '—';
        margin-left: 0.5em;
        margin-right: 0.75em;
      }
    }
  }

  &__action-link {
    @include links__blue-on-black;
    display: block;
    i {
      margin-left: 0.5em;
    }
  }

  &__utility {
    margin-top: px-to-em($spacing__gutter);
  }

  .social-links {
    $icon-height: 32px;

    margin-top: px-to-em($spacing__gutter);

    .icon-envelope {
      &:before {
        font-size: 1.3em;
      }
    }

    &__item {
      margin:0 0.5em;
    }

    &__icon {
      height: px-to-em($icon-height);
      min-width: px-to-em(32px);
      padding: 0 px-to-em(8px);
      background-color: $color__black;
      color: $color__white;
      border: 1px solid $color__white;
      display: inline-flex;
      align-items: center;
    }
  }

  @include respond-to('landscape') {
    &__options {
      display: flex;
      flex-wrap: nowrap;
      margin-top: px-to-em($spacing__gutter);
    }

    .social-links {
      margin-top: 0;
      flex-shrink: 0;
    }

    &__utility {
      margin-left: auto;
      width: px-to-em(200px);
      //match with social links
      padding-right: .5em;
    }

    &__actions-wrapper {
      display: block;
    }

    &__actions {
      display: inline-flex;
      flex-wrap: nowrap;
      flex-grow: 1;
      text-align: left;
      position: relative;
      align-items: center;

      &:after {
        content: '';
        
        border-top: 1px solid $color__gray-text;
        display: inline-block;
        width: 2em;
        flex-grow: 1;
        min-width: px-to-em(100px);
        margin-right: 1em;
      }

    }

    &__action-link {
      margin-right: 1.5em;
      & + & {
        margin-left: 0
      }
    }
  }

  @include respond-to('between') {
    &__body {
      padding-bottom:px-to-em($spacing__md) ;
      padding-top:px-to-em($spacing__md);
      min-height: px-to-em(350px);
      text-align: left; 
    }

    &__action-link {
      display: inline-block;

      & + & {
          margin-left: 1.5em;
        }
    }
    &__title {
      max-width: px-to-em(775px, $font-size__banner--tablet)
    }

    &__options {
      margin-top: px-to-em($spacing__md);
    }

    .social-links {

      &__icon {
        &:hover {
          color: $color__black;
          background-color: $color__white;
        }
      }
    }
  }

  // @include respond-to('desktop') {

  // }
}