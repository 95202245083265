.showcase-quote {
  $font-size__blockquote: 22px;
  $font-size__blockquote--desktop: 28px;

  padding-top: px-to-em($spacing__lg) ;
  padding-bottom: px-to-em($spacing__lg) ;
  position: relative;
  z-index: 20;
  margin-bottom: 0;

  
  //special markup just for mobile
  &--mobile {
    .showcase-quote__body {
      flex-direction: column;
    }
    .showcase-quote__cite {
      max-width: none;
      width: 100%;
      text-align: right;
    }

    .showcase-quote__author {
      font-size: 30px;
      &::after {
        right: 0;
        // width: 40%;
      }
    }

    .showcase-quote__blockquote {
      margin-bottom: 1.5em;
      max-width: none;
      width: 100%;
      background-color: transparent;
      color: $color__white;
    }
  }





  &--desktop {
    display: none;
  }
  
  &--dark {
    background-color: $color__black;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    z-index: 1;
  }

  &__cite {
    color: $color__white;
    font-style: normal;
    display: block;
    max-width: 50%;
    padding-right: px-to-em($wrapper-padding);

    max-width: 40%;
  }

  &__author {
    @include font($font-family__titles, $font-size__h1--tablet );

    line-height: 1.1;
    display: block;
    padding-bottom: px-to-em($spacing__sm * 2, $font-size__h1--tablet);

    &:after {
      content: '';
      display: block;
      width: 60%;
      height: px-to-em($spacing__sm, $font-size__h1--tablet);
      border-bottom: 1px solid $color__gray-text;
      position: absolute;
      z-index: -1;
    }
  }

  &__blockquote  {
    @include font( $size: $font-size__blockquote, $weight: 'light');

    width: 40%;
    background-color: $color__brand-gray;
    padding-left: px-to-em($wrapper-padding--tablet,$font-size__blockquote);

    &:before {
      @include font($family: $font-family__titles, $size: 65px, $weight: 'normal', $size-base: $font-size__blockquote);

      content: '“';
      line-height: 1em;
      position: absolute;
      margin-left: -0.5em;
      display: inline-block;
    }

  }


    @include respond-to('showcase-desktop') {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
      }
  }
}
