.keyword-search {
  $input-size--tablet: 24px;
  $input-padding--tablet: 30px;

  &--typeahead {
    //bring typeahead list up over fields below 
    position: relative;
    z-index: 3;
  }

  &--site-search-landing {
    position: relative;
  }

  .searchbox {

    &__input {
      @include transition-bezier(border-color, box-shadow);

      border:1px solid $color__brand-blue;

      &:focus {
        border-color: $color__gray-hover--for-white-background;
        box-shadow: $button__box-shadow;
      }
    }
    &__button {
      color: $color__brand-blue;
      padding-right: px-to-em(15px, $searchbox__icon-size)
    }

    & + .searchbox {
      margin-top: px-to-em($spacing__sm);
    }
  }



  @include respond-to('between') {
    .searchbox {
      &__input {
        font-size: px-to-em($input-size--tablet);
        padding: px-to-em($input-padding--tablet/1.2,$input-size--tablet) px-to-em($input-padding--tablet,$input-size--tablet) ;
        padding-right: px-to-em($input-padding--tablet*2,$input-size--tablet); 
      }

      &__button {
        $icon-size: 26px;

        font-size: px-to-em($icon-size);
        padding-right: px-to-em($input-padding--tablet, $icon-size);

        &:hover,
        &:focus {
          color: $color__gray-hover--for-white-background;
        }
      }

      & + .searchbox {
        margin-top: 0;
      }
    } 

    &--grid {
      @include clearfix;
      margin: 0 px-to-em($spacing__unit/-2);
      
      .searchbox {
        float: left;
        width: 50%;
        padding:0 px-to-em($spacing__unit/2);

        &__input {
          font-size: px-to-em(22px);
        }
      }
    }
  }

  @include respond-to('desktop') {

    &--grid {

      .searchbox {
        
        &__input {
          font-size: px-to-em($input-size--tablet);
        }
      }
    }
  }
}