.dropdown-custom {
  position: relative;

  &__toggle {
    width: 100%;
  }

  .button-utility__label{
    @include ico-icon-after('chevron-thin-down') {
      @include transition(transform);
      @include center-align(vertical);

      right: 0;
    }
  }

  &__link .dropdown-custom__list-item {
    color: $color__white; //overwrite default link styles
  }

  &__list {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    text-align: left;
    border: 1px solid $color__gray-charcoal-alt;
    border-top-width: 0;
  }

  &__list-item {
    padding: px-to-em($button__padding) px-to-em($spacing__sm);
    background-color: $color__gray-charcoal;
    cursor: pointer;

    &:hover {
      color: $color__gray-charcoal;
      background-color: $color__white;
    }

    & + & {
      border-top: 1px solid $color__gray-charcoal-alt;
    }
  }

  &--show-dropdown & {
    &__list {
      visibility: visible;
      opacity: 1;
    }
  }

  &--show-dropdown {
    .button-utility__label {
      &:after {
        transform: rotateX(180deg) translateY(50%)
      }
    }
  }

	@include respond-to('between') {
    &__list-item {
      padding: px-to-em($button__padding) px-to-em($spacing__sm);
    }
	}
}