.video-grid {
    
    &__grid {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 -1em;

        @include respond-to('between') {
            margin: -1em;
        }
    }

    &__grid-item {
        display: none;
        margin: 1em;
        flex: 1 1 100%;

        &.active {
            display: block;
        }

        @include respond-to('between') {
            flex: 0 1 calc(50% - 2em);
        }
    }

    &__load-more {
        margin-top: 1em;
        margin-bottom: 1em;
        @include respond-to('desktop') {
            margin-top: 3em;
            margin-bottom: 0;
        }
    }

    &__load-more-button {
        .button-primary__label {
            &::before {
                content: "+";
                display: inline-block;
                width: 1.25em;
            }
        }
    }

    // default video card state (used on general content pages
    .video-card__accordion {
        display: none;
    }

    // .video-card__iframe-container {
    //     display: none;
    // }

    // .video-card__caption {
    //     display: none;
    // }

    // .video-card__cta {
    //     display: none;
    // }

    .video-card__details {
        border: 0;
        padding-bottom: 0;
    }

    .video-card__desc {
        padding-top: .5em;
        padding-bottom: 0;
    }

    // for single videos e.g. featured spotlight
    &--single {
        .video-card__cta,
        .video-card__caption {
            display: block;
        }

        .video-grid__grid-item {
            flex: 1 1 100%;
            @include respond-to('between') {
                flex: 1 1 100%;
            }
        }
    }

    .titles__subheading-title {
        button {
            text-align: left;
        }
    }

}

// for video grids/cards in home page marquee 
.hasVideoInHeader {
    .showcase-copy {
        &.hasVideoInHeader {
            height: auto;
        }
    }
    .video-grid {
        &.section {
            background-color:transparent;
            padding: 0;
            min-height: 75vh;
            display: flex;
            align-items: center;
        }

        .titles__subheading {
            display: none;
        }

        .wrapper {
            padding: 0;
            flex: 1 1 100%;
        }

        // .video-card__caption {
        //     display:none;
        // }
    }
}

// video grids in insight detail pages
.articles-body {
    .video-grid {
        header.titles__subheading {
            display: none;
        }

        .wrapper {
            padding: 0;
        }

        &.section {
            background-color: $color__white;
        }
    }
}