.listing-insights {
  $padding-sides: px-to-em(25px);
  $padding-vertical: px-to-em(20px);


  &__meta {
    font-size: px-to-em($font-size__small);
    display: block;
    margin-bottom: 1em;
  }

  &__date {
    &:after {
      content: '-';
      margin-left: 0.5em;
      margin-right: 0.25em;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  &__link {
    @include font($font-family__titles,$font-size__prominent-card, 'light' );
    @include links__blue-on-light;

    display: block;
    line-height: 1.2;

    &--external {
      @include links__external();
      &::after {
        font-size: .5em;
        display: inline; //seemed unique to this case that the icon was line-breaking independently of the last word
      }
    }
  }

  &__item--prominent & {
    &__link {
      font-size: px-to-em($font-size__banner)
    }
  }


  &__item {
    & + & {
      border-top: 2px solid $color__border-gray;
      padding-top: px-to-em($spacing__gutter);
      margin-top: px-to-em($spacing__gutter);
    }
  }

  &__figure {
    position: relative;
    display: inline-block;
    width: 70%;
    margin-right: 1em;
    margin-bottom: 1em;
    flex-shrink: 0;

    // &:before {
    //   @include overlay-gradient__blue;
    //   content: '';
    // }
  }

  &__img {
    max-width: 100%;
    display: block;
  }

  @include respond-to('landscape') {

    &__meta {
      margin-top: -.25em; //align the text to the top of the container (noticeable with an image)
    }

    &__link {
      &--top-align {
        margin-top: -.25em;
      }
    }

    &__item {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
    }

    &__figure {
      width: 35%;
      margin-bottom: 0;
      max-width: px-to-em(235px);
    }

    &__item--prominent & {
      &__figure {
        width: 40%;
        max-width: px-to-em(400px);
      }
    }
  }
  
  @include respond-to('between') {
    &__link {
      font-size: px-to-em($font-size__prominent-card--tablet);
    }

    &__item {
      & + & {
        padding-top: px-to-em($spacing__md);
        margin-top: px-to-em($spacing__md);
      }
    }

    &__figure {
      margin-right: px-to-em($spacing__gutter);
    }

    &__item--prominent & {
      &__link {
        font-size: px-to-em($font-size__banner--tablet)
      }
    }
  }
}