$showcase__zindex-gradient: 10;
$showcase__zindex-headline: 5;
$showcase__zindex-photo: 1;
$showcase__zindex-bg: $showcase__zindex-headline - 1;
$showcase__animation: all 1s $transition__bezier;


.showcase {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: $showcase__animation;

  &.hasVideoInHeader {
    overflow: visible;
  }

  &--init {
    opacity: 1;
  }

  &.showcase--marquee-story {
    display: none; // don't show the fancy thing on marquee story mobile, not showing the image
  }

  //overlay
  &:before {
    content: '';
    direction: block;
    background-image: linear-gradient(to bottom, rgba(28,28,28, 0.8) 8%,  rgba(28,28,28, 0));
    position: absolute;
    top:0;
    left: 0;
    height: 25%;
    width: 100%;
    z-index: $showcase__zindex-gradient;
  }

  &__nav {
    @include center-align(horizontal);
    
    bottom: px-to-em($wrapper-padding*4);
    z-index: $showcase__zindex-headline;
    width: 100%;
    text-align: center;
  }

  &__nav-option {
    @include transition(color, background-color, opacity);
    
    width: 20%;
    height: px-to-em(6px);
    background-color: $color__brand-gray;
    vertical-align: middle;

    & + & {
      margin-left: 1%;
    }

    &--active {
      background-color: $color__gray-charcoal-alt;
    }
  }

  &__arrow {
    vertical-align: middle;
    color: $color__brand-teal;
    font-size: px-to-em(20px);
  }

  &__design-wedge {
    position: absolute;
    top: $showcase__img-frame-height;
    bottom: 0;
    width:100%;
    background-color: $color__black;
    z-index: $showcase__zindex-bg;

    //left wedge;
    &:before,
    &:after {
      content: '';
      position: absolute;
    }

    &:before {
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 10vw solid $color__black;
      border-right: 50vw solid transparent;
      bottom: 100%;
      left: 0;
    }

    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 10vw solid $color__black;
      border-left: 50vw solid transparent;
      bottom: 100%;
      right: 0;
    }
  }

  &__updates {
    $border-height: 20px;
    
    @include center-align(horizontal);
    
    font-size: px-to-em($font-size__smallest);
    text-align: center;
    text-transform: uppercase;
    color: $color__brand-teal;
    bottom: px-to-em($border-height + $spacing__unit/2, $font-size__smallest);
    z-index: $showcase__zindex-headline;

    &:before {
      content: '';
      @include center-align(horizontal);
      position: absolute;
      top: 100%;
      margin-top: px-to-em($spacing__unit/2, $font-size__smallest);
      height: px-to-em($border-height + $spacing__unit/2);
      border-left: 2px solid $color__brand-teal;
      z-index: $showcase__zindex-headline;
    }

    &-default {
      &:before {
        content: "+";
      }
    }
  } 

  &__back-wrapper {
    display: none;
  }

  //don't cut off heads! 
  @include respond-to($showcase-tablet-fix) {
    &__design-wedge {
      top: $showcase__img-frame-height--tablet;
    }
  }

  @include respond-to('showcase-desktop') {
    transition: $showcase__animation;
    position: fixed;
    z-index: 18;
    overflow: hidden;

    &.showcase--marquee-story {
      display: block; // go back to the fancy thing on marquee story desktop
    }

    &:before {
      background-image: none;
      background-color: rgba(28,28,28, 0.75);
      opacity: 0;
      visibility: hidden;
      height: 100%;
      transition: opacity 1s $transition__bezier,
          visibility 1s $transition__bezier 1s;

      .showcase-layout--show-performance &{
        opacity: 1;
        visibility: visible;
        transition: opacity 1s $transition__bezier,
      }
    }

    &__frame-offset {
      margin-left: 50%;
      padding-left: px-to-em($wrapper-padding--tablet);
    }
  
    &__updates {
      font-size: px-to-em($font-size__small);
      bottom: px-to-em($wrapper-padding--desktop, $font-size__small);
      left: auto;
      transform: none;
      right: 0;
      z-index: $showcase__zindex-gradient;
      color: $color__brand-teal;
      transition: right 1s $transition__bezier;

      &:before {
        content: none;
      }

      &:after {
        content: '';
        display: inline-block;
        border-top: 2px solid $color__brand-teal ;
        width: px-to-em(50px, $font-size__small);
        vertical-align: middle;
        margin-left: 0.5em;
        transition: border-color 1s $transition__bezier;
      }

      .icon-close {
        font-size: px-to-em(35px);
        line-height: 1;
        vertical-align: middle;
      }
    } 

    &__updates-count {
      color: $color__gray-text;
      font-weight: map-get($font-weights, 'light' );
    }

    &__nav {
      text-align: left;
      bottom: px-to-em($wrapper-padding--desktop);
    }

    
    &__nav-option {
      width: 18%;
      height: px-to-em(10px);

      &:hover {
        background-color: $color__brand-teal;
      }

      & + & {
        margin-left: 1%;
      }

      &--active {
        
        &:hover {
          background-color: $color__gray-charcoal-alt;
          cursor: default;
        }
      }
    }

    &__arrow {
      font-size: px-to-em(26px);
    }
    

    //design wedge
    &__design-wedge {
      right: 0em;
      top: 0;
      bottom: auto;
      width:52vw;
      height: 100%;
      // transition: right 1s cubic-bezier(0.86, 0, 0.07, 1), transform .5s cubic-bezier(0.86, 0, 0.07, 1) ;
      // transition: right 1s cubic-bezier(0.86, 0, 0.07, 1);
      transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), right 1s cubic-bezier(0.86, 0, 0.07, 1);

      // top: $showcase__img-frame-height;
      .showcase--marquee-story & {
        top: 0
      }

      &:before,
      &:after {
        display: none;
      }
    }
    
  
    &__design-wedge-left-col {
      position: absolute;
      height: 100%;
      right: 100%;
      margin-right: -1px;
      
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        backface-visibility: hidden;
        // transform: translateX(100%);
        right: 0;
      } 
  
      &:before {
        border-top: 51vh solid $color__black; //50.1 fixes rounding calculation issue in safari
        border-left: px-to-em(75px) solid transparent;
        top: 0;
      }
      &:after {
        border-bottom: 51vh solid $color__black;
        border-left: px-to-em(75px) solid transparent;
        bottom: 0;
      }
    }
  
    &__design-wedge-right-col {
      position: absolute;
      height: 100%;
      left: 100%;
      margin-left: -1px; //stops stupid line flickering in chrome
      
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        backface-visibility: hidden;
        left: 0;
      } 
  
      &:before {
        border-bottom: 51vh solid $color__black; //50.1 fixes rounding calculation issue in safari
        border-right: px-to-em(75.3333px) solid transparent;
        bottom: 50%;
      }
      &:after {
        border-top: 51vh solid $color__black;
        border-right: px-to-em(75.333px) solid transparent;
        top: 50%;
      }
    }

    $arrow-size: 32px;
    $arrow-diamond-size: 45px;

    &__back-wrapper {
      width: 100%;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      z-index: $zindex__site-header;
      padding-left: 1.25em;
    }

    &__back {
      font-size: px-to-em($font-size__small);
      color: $color__gray-text;
      text-transform: uppercase;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.25s ease-in,
      visibility 0.25s ease-in $transition__timing;
      position: absolute;
      top: px-to-em(45px);

      &--show-detail {
        opacity: 1;
        overflow: visible; //for IE
        visibility: visible;
        transition: opacity $animation__overlay-expanded ease-in;
        
      }

      &:hover {
        .showcase__back-diamond {
          &:after {
            top: -25%;
            left: -25%;
          }
        }
      }
    }

    &__back-icon {
      font-size: px-to-em($arrow-size);
      position: relative;
      z-index: 1;
      width: px-to-em($arrow-diamond-size, $arrow-size);
      height: px-to-em($arrow-diamond-size, $arrow-size);
      padding-top: px-to-em($arrow-diamond-size/6, $arrow-size);
      
      display: block;
      margin-bottom: 0.75em;
      overflow: visible;

      &:before {
        margin-left: -3px;
      }
        
      // &:after {
      //   @include center-align(both);
        
      //   content: '';
      //   display: block;
      //   border:1px solid $color__gray-text;
      //   width: 100%;
      //   height: 100%;
      //   transform: translate(-50%, -50%) rotate(45deg);
      //   z-index: -1;
      // }
    }

    &__back-diamond {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $color__gray-text;
      transform: rotate(45deg);
      overflow: hidden;
      z-index: -1;

      &:after {
        @include transition(top,left);
        content: '';
        display: block;
        position: absolute;
        width: 150%;
        height: 150%;
        transform: rotate(-45deg);
        background-color: $color__white;
        top: 85%;
        left: 85%;
        z-index: -2;
      }
    }
  }

  @include respond-to('desktop-large') {
    &__updates {
      font-size: 1em;
      bottom: px-to-em($wrapper-padding--desktop);
      &:after {
        width: px-to-em(50px);
      }
    }

    &__nav-option {
      width: px-to-em(100px);
    }

    &__design-wedge {
      width: 55vw;
    }
  }
}