.listing-services {
  margin-bottom: px-to-em($spacing__sm);

  & + & {
    margin-top: px-to-em($spacing__sm);
  }
  &__heading {
    @include titles-subentity;

    margin-bottom: 0.5em;
  }

  &__item {
    display: inline;

    &:after {
      content: '/';
      margin-left: 0.5em;
      margin-right: 0.25em;
      color: $color__gray-text;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  @include respond-to('between') {
    margin-bottom: 0;
    
    & + & {
      margin-top: px-to-em($spacing__gutter);
    }

    &__link {
      font-size: px-to-em($font-size__h4--tablet);
    }


  }
}
