html {
	font-size: 100%;
	box-sizing: border-box;
	height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
	background-color: $color__black;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
  font: map-get($font-weights, regular) 100% $font-family__copy;
	line-height: $font__line-height;
	color: $color__gray-charcoal;
}

main {
	display: block;
	background-color: $color__white;

	&#homepage {
		background-color: transparent;	
	}
}

article {
	background-color: $color__white;
}

a {
	color: inherit;
	text-decoration: none;
	&:focus {
		outline: none;
	}
}

ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

li {
	margin-bottom: 0;
}

pre {
	margin: 0;
}

p {
  margin:0 0 px-to-em($spacing__normalize);

  &:last-child {
  	margin-bottom: 0;
  }
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
	
	@include font($family: $font-family__titles, $weight: 'regular');
}

fieldset {
	border: 0 none;
	padding: 0;
	margin: 0;
}
