.page-heading {
  @include heading--base();

  &__background {
    @include heading__background();
  }

  &__body {
    @include heading__body();
  }

  &__cta {
    @include heading__cta();
  }

  &__title {
    @include titles-page-title();
  }

  &__options {
      margin-top: px-to-em($spacing__gutter);
  }

  &--search {
    padding-bottom: px-to-em($spacing__gutter);
  }

  &__summary {
    font-size: px-to-em($font-size__small);
    margin-top: px-to-em($spacing__sm, $font-size__small);
  }

  .jumpto-nav {
    margin: px-to-em($spacing__gutter) auto 0;
    max-width: px-to-em(300px);
  }
  
  &--summary-layout{
    z-index: 10;
  }

  @include respond-to('landscape') {
    &__header {
      display: flex;
      flex-wrap: nowrap;
      text-align: left;
      width: 100%;
    }

    &__title {
      max-width: 72%;
    }

    &__related-cta {
      @include heading__related-cta();
    }

    &__cta {
      flex-shrink: 0;
      margin-top: 0;

      &:hover {
        &:after {
          transform: translateX( px-to-em($spacing__unit));
        }
      }
    }

    &__body {
      text-align: left;
    }

    .jumpto-nav {
      margin-left: 0;
      margin-right: 0;
      max-width: px-to-em(600px);
    }
  }

  @include respond-to('between') {

    &__body {
      padding-bottom:px-to-em($spacing__md) ;
      padding-top:px-to-em($spacing__md);
      min-height: px-to-em(350px);
      height: 100px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    &__details {
      width: 100%;
    }

    &__summary {
      font-size: 1em;
      line-height: 1.75;
      margin-top: px-to-em($spacing__gutter);
      max-width: px-to-em(650px);
    }

    &--summary-layout &{
      &__body {
        flex-wrap: wrap;
        align-content: center;
      }
    }
  }

  @include respond-to('desktop') {
    &--summary-layout & {
      &__header {
        width: auto;
      }
      &__body {
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    }

    &__utility {
      margin-top: 0;
      width: px-to-em(200px);
      flex-shrink: 0;
    }
    &__options {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      align-items: center;
      margin-top: px-to-em($spacing__gutter);
    }
    &__details {
      width: 50%;
      max-width: px-to-em(650px);
    }

    &__summary {
      margin-top:  0;
    }
  }
}