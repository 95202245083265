$font-size__headline:   20px;
$font-size__headline--tablet:   30px;

//just the marquee story detail on mobile 
.showcase-marquee-story-mobile-headline {
    margin-top: $site-header-height; //for header
    background-color: $color__black;
    color: $color__white;
    padding: $spacing__lg 0;

    &__frame {
        text-align: center;
    }

    &__category {
        text-transform: uppercase;
        color: $color__gray-text;
        font-size: px-to-em($font-size__smallest);
        margin-bottom: 0.5em;
      }

    &__title {
        @include font($font-family__titles, $font-size__headline);
        line-height: 1.4;
    }

    &__date {
        font-size: px-to-em($font-size__smallest);
        color: $color__gray-text;
        margin-top: 1em;
      }

    @include respond-to('between') {
        margin-top: px-to-em($site-header-height--tablet - 45px); //the actual header takes up less space than this idk why 

        &__title {
            font-size: px-to-em($font-size__headline--tablet);
        }
    }

    @include respond-to('showcase-desktop') {
        display: none;
    }
}