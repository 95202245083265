.video-modal {

    // MODAL STYLES
  .modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(28,28,28,0.95);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    z-index: 9999;
  }
  
  .modal__container {
    // margin-top: 10em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    margin: auto;
    padding: 30px;
    max-width: 870px;
    box-sizing: border-box;
}
  
  .modal__close {
    background: transparent;
    border: 0;
    position: relative;
    right: 0;
    left: 100%;
    display: block;

    svg {
        display: block;
        height: 30px;
        width: 30px;
    }
  }
    
  .modal__content {
    .video-card__title {
      cursor: auto;
    }
  }
  
    // MODAL ANIMATIONS
  @keyframes mmfadeIn {
      from { opacity: 0; }
        to { opacity: 1; }
  }
  
  @keyframes mmfadeOut {
      from { opacity: 1; }
        to { opacity: 0; }
  }
  
  @keyframes mmslideIn {
    from { transform: translateY(15%); }
      to { transform: translateY(0); }
  }
  
  @keyframes mmslideOut {
      from { transform: translateY(0); }
      to { transform: translateY(-10%); }
  }
  
  .micromodal-slide {
    display: none;
  }
  
  .micromodal-slide.is-open {
    display: block;
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
  }
  
  .micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
  }
  
  .micromodal-slide .modal__container,
  .micromodal-slide .modal__overlay {
    will-change: transform;
  }

    // VIDEO MODAL STYLES
    .video-card,
    .video-card__details,
    .video-card__eyebrow,
    .video-card__title,
    .video-card__share-btn,
    .video-card__caption,
    .video-card__desc,
    .video-card__cta {
        color: $color__white;
    }

    .video-card__cover,
    .video-card__icon {
        display: none!important;
    }

    .video-card__accordion,
    .video-card__iframe-container,
    .video-card__desc {
        display: block!important;
    }

}