$img-width: 200px;

.testimonial-result {
    margin-bottom: px-to-em($spacing__gutter);
    padding-bottom: px-to-em($spacing__gutter);
    border-bottom: 1px solid $color__brand-gray;


    &__grid-item {
        display: block;
        align-items: flex-start;
        flex-wrap: nowrap;
        width: 100%;

        & + & {
            margin-top: px-to-em($spacing__gutter);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        @include titles-banner;
        font-size: px-to-em($font-size__testimonial-result-title);
    }

    &__quote {
        margin-top: px-to-em(15px);
    }

    &__more-detail {
        margin-top: px-to-em(25px);

        .button-primary {
            min-width: px-to-em(100px);
            padding: px-to-em(10px) px-to-em($button__padding-sides);
        }
    }

    &__figure {
        position: relative;
        width: px-to-em($img-width);
        flex-shrink: 0;
        margin-right: 1em;
        margin-bottom: px-to-em(10px);
        margin-top: 0;
        > img {
            max-width: 100%;
            display: block;
        }

        &:after {
          @include overlay-gradient__coral();

          content: '';
        }
    }

    &__experience {
        @include font($size: $font-size__h3, $weight: 'light');
        font-style: italic;
        margin-top: 0.5em;

        &:before {
            content: '-';
            display: inline-block;
            margin-right: 0.25em;
        }
    }

    &__meta {
        &:not(:last-child) {
            &:after {
                content: '/';
                margin-right: 0.2em;
                padding-left: 0.5em;
            }
        }
    }

    .rte {
        blockquote {
            font-size: $font-size-base;
            padding-left: 0;
        }
    }

    @include respond-to('landscape') {
        $credentials-grid-spacing: px-to-em($spacing__gutter);

        columns: 2 auto;
        column-gap: $credentials-grid-spacing;
        
        &__grid-item {
            display: flex;
        }

        &__figure {
            margin-bottom: 0;
            margin-top: 10px;
        }
    }

    @include respond-to('between') {
        margin-bottom: px-to-em($spacing__gutter * 1.5);
        padding-bottom: px-to-em($spacing__gutter * 1.5);
    }

    @include respond-to('desktop') {
        @include clearfix;

        &__name {
            font-size: $font-size__testimonial-result-title--tablet;
        }

        &__figure {
            margin-right: px-to-em($spacing__gutter);
        }

        .rte {
            blockquote {
                font-size: $font-size__h4--tablet;
            }
        }
    }

}
