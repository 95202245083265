.prominent-services {
  $border: 1px solid $color__gray-shady-lady;

  border-top: 1px solid $color__gray-shady-lady;
  border-bottom: 1px solid $color__gray-shady-lady;
  color: $color__white;
  display: none;

  &__list {
    padding-left: 0;
    padding-right: 0;
  }

  &__option {
    & +  & {
      border-top:  $border;
    }
  }

  &__link {
    padding:1em px-to-em($wrapper-padding);
    display: block;
  } 

  @include respond-to('between') {
    display: block;
    position: relative;

    &__list {
      display: flex;
      flex-wrap: nowrap;
    }

    &__option {
      flex-basis: 20%;
      flex-grow:1;
      position: relative;

      & + & {
        border-top: 0;
        border-left: $border;
      }
    }

    &__link {
      @include transition-bezier(color);

      text-align: center;
      padding: 1em;
      position: relative;
      z-index: 2;
      overflow: hidden;
      

      &:after {
        @include transition-bezier(transform);

        content: '';
        background-color: $color__white;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        transform: translateY(100%);
      }

      &:hover {
        color: $color__black;

        &:after {
          transform: translateY(0);
        }
      }
    }
  }

  @include respond-to('desktop') {
    $font-size: 18px;

    &__option {
      

      & + & {
        border-top: 0;
      }

      &:first-child {

        .prominent-services__link {
          padding-left: px-to-em($wrapper-padding--tablet, $font-size);
        }
      }

      &:last-child {
        
        .prominent-services__link {
          padding-right: px-to-em($wrapper-padding--tablet, $font-size);
        }
      }
    }

    &__link {
      font-size: px-to-em($font-size);
    }
  }

  @include respond-to('desktop') {
    &__option {
      &:first-child {
        border-left: $border;
      }

      &:last-child {
        border-right: $border;
      }
    }
  }
}
