.articles-body {
  .podcast {
    margin-bottom: px-to-em($spacing__gutter);
  }

  &__lead-image {
    padding-bottom: px-to-em($spacing__gutter);
    margin-bottom: px-to-em($spacing__gutter);
    border-bottom: 1px solid $color__border-gray;
  }

  &__lead-image-wrapper {
    position: relative;
    // display: inline-block; //IE
    display: block;

    // &:before {
    //   @include overlay-gradient__blue;
    //   content: '';
    // }

    > img {
      max-width: 100%;
      display: block;
    }
  }

  &__lead-image-caption {
    font-weight: map-get($font-weights, 'light');
    font-style: italic;
    margin-top: px-to-em($spacing__sm + 7px); //removed inline-block in wrapper which took away some extra space
  }

  
	@include respond-to('between') {
    .podcast {
      margin-bottom: px-to-em($spacing__md);
    }
  
    &__lead-image {
      padding-bottom: px-to-em($spacing__md);
      margin-bottom: px-to-em($spacing__md);
    }
	}
}