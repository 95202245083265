.site-footer {
  $border: 1px solid $color__gray-shady-lady;
  background-color: $color__black;
  color: $color__gray-text;
  z-index: #{$zindex__back-to-top - 1};
  position:relative;

  &__main {
    padding-top: px-to-em($spacing__md/2);
    padding-bottom: px-to-em($spacing__md/2);
  }

  &__regions {
    font-size: px-to-em($font-size__small);
    line-height: 1.6;
  }

  &__closing {
    display: block;
  }

  &__row {
    margin-top: px-to-em($spacing__md);
  }

  &__legal-link, &__menu-link {
    display: inline-block;
    font-size: px-to-em($font-size__small);
    margin-bottom: px-to-em($spacing__unit, $font-size__small);

    &--external {
      @include links__external();
    }
  }

  .social-links {
    $icon-size: 20px;
    $icon-box-size: 40px;
    float: right;
    margin-right: 50px;

    &__item {
      display: block;
      margin-bottom: -1px;
    }

    &__icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: px-to-em($icon-size);
      width: px-to-em($icon-box-size,$icon-size);
      height: px-to-em($icon-box-size,$icon-size);
      text-align: center;
      border: $border;
    }
  }

  &__logo {
    display: none;
  }

  &__locations {
    display: none;
  }

  &__copyright {
    font-size: px-to-em($font-size__small);
    display: inline-block;
    color: $color__white;
    margin-top: px-to-em($spacing__sm, $font-size__small);
  }

  &__closing-options {
    margin-top: px-to-em($spacing__sm, $font-size__small);
  }

  &__regions-label, &__menu-label {
    font-weight: map-get($font-weights, bold);
    color: $color__white;
    margin-bottom: 1em;
    display: inline-block;
  }

  &__menu-label {
      [class^="icon-"] {
          padding-right: 0.5em;
      }
  }

  @include respond-to('between') {
    $slash-spacing: 0.4em;
    &__main {
      padding-top: px-to-em($spacing__md);
      padding-bottom: px-to-em($spacing__md);
    }

    &__locations {
      display: table;

    }
    &__regions {
      display: flex;

      & + & {
        margin-top: 1em;
      }
    }

    &__regions-label, &__menu-label {
      font-weight: map-get($font-weights, bold);
      color: $color__white;
      margin-right: 0.75em;
      margin-bottom: 0;
      flex-shrink: 0;
      display: inline-block;
  
      &:after {
        content: " -";
        margin-left: 0.5em;
      }
    }

    &__locations-list, &__legal, &__menu {
      flex-grow: 1;
      display: inline;
    }

    &__locations-item, &__legal-item, &__menu-item {
      display: inline-block;

      &:after {
        content: "/";

        margin-right: $slash-spacing;
      }

      &:last-child  {
        &:after {
          content: close-quote;
          margin-right: 0;
        }
      }
    }

    &__locations-city, &__legal-link, &__menu-link {
      margin-right: $slash-spacing;

      display: inline;
      margin-bottom: 0;

      &:hover {
        color: $color__white;
      }
    }

    &__closing {
      display: flex;
      align-items: flex-end;
      margin-top: px-to-em($spacing__md);
      text-align: right;
    }

    &__closing-options {
      padding-left: 2em;
      flex-grow: 1;
      margin-top: 1em;
    }

    .social-links {
      $icon-size: 30px;
      $icon-box-size: 60px;

      flex-shrink: 0;

      float: none;
      
      &__item {
        float: left;
        margin-right: -1px;
        margin-bottom: 0;
      }

      &__icon {
        font-size: px-to-em($icon-size);
        width: px-to-em($icon-box-size,$icon-size);
        height: px-to-em($icon-box-size,$icon-size);

        &:hover {
          background-color: $color__white;
          color: $color__black;
        }
      }
    }

    &__copyright {
      margin-top: 0;
    }
  }

  @include respond-to('desktop') {

    &__logo {
      float: right;
      max-width: px-to-em(270px);
      display: block;

      > img {
        width: 100%;
      }
    }

    &__legal-link,
    &__menu-link,
    &__copyright {
      font-size: 1em;
    }
  }
}
