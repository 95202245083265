.practice-area-filters {
  $grid-spacing: 10px;

  @include transition(max-height);
  
  overflow: hidden;
  max-height: px-to-em(320px);

  &--disabled {
    max-height: 0;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .practice-area-filters__nav {
      opacity: 0.2;
    }
  }

  &__heading {
    @include font($family: $font-family__copy, $size: $font-size__h4, $weight: 'light');

    line-height: 1.3;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: px-to-em($spacing__gutter);

    &:after {
      content: '';
      display: block;
      border-top: 1px solid $color__gray-shady-lady;
      flex-grow: 1;
      margin-left: 1em;
    }
  }

  &__heading-label {
    display: block;
    flex-shrink: 0;
  }

  &__nav {
    @include transition(opacity);

    display: flex;
    flex-wrap: wrap;
    margin: 0 $grid-spacing / -2;
    padding-bottom: px-to-em($spacing__gutter);
  }

  &__filter {
    line-height: 1.35;
    width: calc(50% - #{$grid-spacing});
    margin: 0 $grid-spacing/2;
    margin-bottom: $grid-spacing;
    height: px-to-em(60px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: px-to-em($spacing__unit) px-to-em($spacing__unit/1.5);
    border-color: transparent;

    &:first-child {
      width: calc(100% - #{$grid-spacing});
    }

    &--selected {
      background-color: $color__white;
      color: $color__black;
      border-color: transparent;
      box-shadow:  none;
      
      &:hover {
        cursor: default;
        box-shadow:  none;
      }
    }
  }

  
  @include respond-to('between') {

    &__heading {
      font-size: px-to-em($font-size__h4--tablet);
      line-height: 1.3;
    }
  }

  @include respond-to('between') {
    max-height: none;

    &--disabled {
      max-height: none;
    }
  }
}
