.featured-banner {
  $window-image-view: 40vh; 
  background-color: $color__black;
  position: relative;
  overflow: hidden;
  padding-top: $window-image-view;

  &__date {
    color: $color__gray-text;
    display: block;
    margin-bottom: px-to-em($spacing__unit);
  }

  &__title {
    @include transition(color);
    @include titles-banner;

    color: $color__white;
  }
  &__button {
      margin-top: px-to-em(20px);

      .button{
          padding: 1em;
      }
  }

  &__body {
    padding-top: px-to-em($spacing__gutter);
    padding-bottom: px-to-em($spacing__md);
    text-align: center;
  }

  &__figure {
    height: $window-image-view;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    overflow: hidden;

    &:after {
      content: "";
      @include overlay-gradient__blue;
    }
  }

  &__img {
    min-height: 100%;
    width: 100%;
    transition: transform $transition__timing * 3 ease-out;

    &--has-mobile-image {
      display: none;
    }
  }

  &__mobile-image {
    //using background image instead because mobile image was occasionally stretching 
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
  }


  &__triangle {
    $wedge-angle: 10vw;
    $wedge-width: 50vw;

    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 100%;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      backface-visibility: hidden;
      bottom: 0;
    } 

    &:before {
      border-right: $wedge-width solid transparent;
      border-bottom: $wedge-angle solid  $color__black;
      left: 0;
    }

    &:after {
      border-left: $wedge-width solid transparent;
      border-bottom: $wedge-angle solid  $color__black;
      right: 0;
    }
  }
  



  @include respond-to('between') {
    padding: px-to-em($spacing__md * 1.25) 0;

    &__figure {
      @include transition-bezier(height);
  
      width: 33vw;
      height: 100%;
      position: absolute;
      right: auto;
    }

    &__img {
      width: auto;
      height: 115.5%;

      &--has-mobile-image {
        display: block;
      }
    }

    &__mobile-image {
      display: none;
    }

    &__triangle {
      $wedge-angle: px-to-em(125px);
      $wedge-height: px-to-em(600px);

      position: static;

      &:before,
      &:after {
        z-index: 5;
      }
      
  
      &:before {
        border-left:   $wedge-angle solid transparent;
        border-top:  $wedge-height solid  $color__black;
        border-right-width: 0;
        border-bottom-width: 0;
        bottom: 50%;
        left: auto;
        right: 0;
      }

      &:after {
        top: 50%;
        border-left-width: $wedge-angle;
        border-bottom-width: $wedge-height ;
        
      }
      
    }

    &__body {
      padding-bottom:px-to-em($spacing__md) ;
      padding-top:px-to-em($spacing__md);
      min-height: px-to-em(350px);
      text-align: left;
    }

    &__date,
    &__title,
    &__button {
      margin-left: 40%;
    }

    &__title {
      display: inline-block;

      &:hover {
        color: $color__blue-light;

        & + .featured-banner__figure {
          &:after {
            top: 0;
          }

          .featured-banner__img {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  @include respond-to('desktop') {
    &__date,
    &__title,
    &__button  {
      margin-left: 35%;
    }
  }
  
  @include respond-to('desktop-largest') {
    &__img {
      width: 115.5%;
      height: auto;
    }  
  }
}