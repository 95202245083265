.media-module {
    &__lead-media {
        padding-bottom: px-to-em($spacing__gutter);
        border-bottom: 1px solid $color__border-gray;
    }

    &__lead-media-wrapper {
        position: relative;
        display: inline-block;
        max-width: 100%;
        // &--has-overlay {

        //     &:before {
        //         @include overlay-gradient__blue;
        //         content: '';
        //         pointer-events: none;

        //         @include transition(opacity);
        //     }
        // }
        
        // &--fade-overlay:before{
        //     opacity: 0;
        // }

        > img, video, iframe, embed {
            max-width: 100%;
            display: block;
        }

        &--video {
            display: block;
        }
    }

    &__lead-media-caption {
        font-weight: map-get($font-weights, 'light');
        font-style: italic;
        margin-top: px-to-em($spacing__sm);
    }

    &.section {
        &:nth-child(2n+1) {
			.media-module__lead-media {
                border-bottom: 1px solid $color__gray-shady-lady;
            }
		}
    }
}
