.photo-gallery{
  @include bg-cover;
  $slide-width: 895;
  $slide-height: 504;
  $slide-ratio: $slide-height / $slide-width;

  $arrow-size: 20px;
  $arrow-diamond-size: 32px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 70%;
  position: relative;
  background-position: center;

  &--show-slides & {
    &__intro {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s $transition__ease, visibility 0.5s ease-out 0.5s;
    }
    &__arrows {
      opacity: 1;
    }

    &__slides {
      opacity: 1;
      visibility: visible;
    }

    //opacity issue in IE needed to make the active slide transparent as well 
    &__slide--active {
      opacity: 1;
    }
  }


  &__intro {
    @include transition(background-color);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 15;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    
    padding-left: $wrapper-padding;
    padding-right: $wrapper-padding;

    &--hovered {
      background-color: rgba(0,0,0,0.25);


    }
  }

  &__cta {
    min-width: px-to-em(260px);

    .button-utility__label {
      padding-right: 0;
    }
  }

  &__slides {
    @include transition(opacity 1s);

    opacity: 0;
    visibility: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $color__black;
    }
  }

  &__slide,
  &__slide-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__slide-image {
    @include bg-cover;

    background-position: 50%;
  }

  &__slide {
    z-index: 5;
    opacity: 0; //needed more for IE

    &--active {
      visibility: visible;
      z-index: 7;
    }
  }

  &__copy-wrapper,
  &__slide-image  {
    @include transition(opacity);

    opacity: 0;
  }

  &__slide--active &{
    &__copy-wrapper,
    &__slide-image  {
      opacity: 1
    }
  }

  &__copy {
    font-weight: map-get($map: $font-weights, $key: 'light');
    font-style: italic;
  }

  &__copy-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.65);
    color: $color__white;
    z-index: 5;
    padding: px-to-em($spacing__sm);
  }

  &__arrows {
    @include transition(opacity 1s);

    @include center-align(horizontal);

    top: px-to-em($spacing__gutter * 2);
    width: 100%;
    opacity: 0;
    z-index: 10;
  }

  &__arrow {
    float: left;
    overflow: visible;
    color: $color__white;
    width: px-to-em($arrow-diamond-size);
    height: px-to-em($arrow-diamond-size);

    &--right {
      float: right;
    }
  }

  &__arrow-icon {
    font-size: px-to-em($arrow-size);
    z-index: 1;
    // padding-top: px-to-em($arrow-diamond-size/6, $arrow-size);
    display: inline-block;
  }

  &__arrow-diamond {
    @include transition(box-shadow);

    transition-delay: $transition__timing;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
    overflow: hidden;
    z-index: -1;
    background-color: $color__black;

    &:after {
      @include transition(top,left);
      content: '';
      display: block;
      position: absolute;
      width: 150%;
      height: 150%;
      transform: rotate(-45deg);
      background-color: $color__white;
      top: 85%;
      left: 85%;
      z-index: -2;
    }
  }

  &--careers-theme &{
    &__intro {
      background-color: transparent;

      &:before {
        @include overlay-gradient__coral;

        content: ''
      }

      &--hovered {
        &:before {
          opacity: 0.7;
        }
      }
    }

    &__copy {
      a {
        @include links__coral-on-black;
      }
    }
  }

  @include respond-to('landscape') {
    padding-bottom: 60%;
    height: auto;

  }

	@include respond-to('between') {
    $arrow-size: 28px;
    $arrow-diamond-size: 48px;

    &__cta {
      min-width: px-to-em(275px);
      padding: px-to-em($spacing__sm) px-to-em($spacing__gutter);
    }

    &__arrows {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__arrow {
      width: px-to-em($arrow-diamond-size);
      height: px-to-em($arrow-diamond-size);
    }

    &__arrow-icon {
      font-size: px-to-em($arrow-size);
    }

    &__arrow:hover {
      color: $color__black;
    }

    &__arrow:hover & {
      
      &__arrow-diamond {
        box-shadow: $color__box-shadow; //$button__box-shadow;
        &:after {
          top: -25%;
          left: -25%;
        }
      }
    }
	}
}