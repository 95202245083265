.search-results {
  $grid-spacing: 1em;

  &__load-more {
    display: block;
    margin: px-to-em($spacing__gutter)  auto 0;
    
    .button-primary__label {
      &:before {
        content: '+';
        width: 1.1em;
        display: inline-block;
        //when it was centered, it looked wonky
        text-align: left;
        margin-left: -5px;
      }
    }
  }

  &__expanded-search-result-list{
      .person-result{
          &:last-child{
              margin-bottom: 0;
          }
      }
  }

  &__grid {
    width: px-to-em($insight-card-width-ideal);
    margin: 0 auto $grid-spacing;
  }

  .insight-card {
    & + .insight-card {
      margin-top: $grid-spacing;
    }
  }

  @include respond-to('landscape') {
    &__grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0 $grid-spacing / -2;
      width: 100%;
    }

    .insight-card {
      width: calc(50% - #{$grid-spacing});
      margin: 0 $grid-spacing/2 $grid-spacing;
    }

    .insight-card {
      & + .insight-card {
        margin-top: 0
      }
    }
  }

  @include respond-to('desktop-large') {
    .insight-card {
      width: calc(33.33333% - #{$grid-spacing});
    }
  }

   &__list-custom {
        max-width: 1003px;
   }

  @include respond-to('between') {
    &__list-custom { 
        max-width: px-to-em($spacing__custom-wrapper);
    }
  } 
}


