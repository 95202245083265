// Link Variants
@mixin heading--base() {
  position: relative;
  padding-top: px-to-em($site-header-height + $spacing__sm);
  background-color: $color__black;

}

@mixin heading__background() {
  @include bg-cover;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.15;
    
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35) );
    }
}


@mixin heading__body() {
  padding-bottom: px-to-em($spacing__md);
  text-align: center;
  color: $color__white;
  position: relative;
  z-index: 2;
}


@mixin heading__cta() {
  @include links__blue-on-black;

  display: inline-block;    
  margin-top: px-to-em($spacing__sm);

  @include ico-icon-after('chevron-thin-right') {
    @include transition-bezier(transform);

    transform: translateX( px-to-em($spacing__unit/2) );
    vertical-align: middle;
  }
}

@mixin heading__related-cta {
  display: inline-flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  align-items: center;
  padding-top: 0.5em;

  &:before {
    content: '';
    
    border-top: 1px solid $color__gray-text;
    display: block;
    width: 2em;
    flex-grow: 1;
    min-width: px-to-em(100px);
    margin-left: 1em;
    margin-right: 1em;
  }
}