@mixin section {
	margin-bottom: px-to-em($spacing__md);

	@include respond-to('between') {
		margin-bottom: px-to-em($spacing__lg);
	}
}

@mixin section--margin {
	margin-bottom: px-to-em($spacing__md);

	@include respond-to('between') {
		margin-bottom: px-to-em($spacing__lg);
	}
}

@mixin section--padded {
	padding-top: px-to-em($spacing__md);
	padding-bottom: px-to-em($spacing__md);

	@include respond-to('between') {
		padding-top: px-to-em($spacing__lg);
		padding-bottom: px-to-em($spacing__lg);
	}
}

.section-layout {
	background-color: $color__white;
	padding-top: px-to-em($wrapper-padding);
	padding-bottom: px-to-em($wrapper-padding);
	
	.section {
		padding-top: 0;
		padding-bottom: 0;
	}

	@include respond-to('between') {
		background-color: transparent;
		padding-top: 0;
		padding-bottom: 0;

		.section {
			padding-top: px-to-em($spacing__lg);
			padding-bottom: px-to-em($spacing__lg);    
		}
	}
}

//take padding off in mobile only
//so that the siblings slide up to the first one
.section--combined-mobile-accordion + .section--combined-mobile-accordion {
	@media (max-width: px-to-em($screen-sm - 1)){
		padding-top: 0;
	}
}

$section-title-width: 270px;
.section {
	@include section--padded;

	background-color: $color__white;

    // & > & {
    //     padding-top: 0;
    //     padding-bottom: 0;
	// }

	&.section-layout {
		padding-top: 0;
        padding-bottom: 0;
	}

	&--alternating-bg & {
		
		&.section--gray {
			background-color: inherit;
		}

		&:nth-child(2n+1) {
			background-color: $color__brand-gray;
            .person-result {
                 border-bottom: 1px solid $color__gray-shady-lady;
            }
            .listing-insights__item+.listing-insights__item{
                border-top: 2px solid $color__gray-shady-lady;
            }
            .read-more__window {
			    &:after {
				    background-image: linear-gradient(to bottom, $color__gradient-gray--start, $color__gradient-gray--end );
			    }
			}
			
			//this handles COLOR for the alternating stuff
			//for spacing on the crazy mobile collapsing accordions see section-accordion

			//make the entire collapsed accordion section one color, based off the last one in the alternating thing 
 			 @media (max-width: px-to-em($screen-sm - 1)){
				//the first one will be styled correctly by the alternation 
				//second, third, and fourth adjacent siblings get the same color
				//then anything that happens after that ... no guarantees
				& + .section--combined-mobile-accordion + .section--combined-mobile-accordion, 
				& + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion, 
				& + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion, {
					background-color: $color__white;

                    .read-more__window {
			            &:after {
				            background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 100) );
			            }
			        }
				}
			}
		}
        &:nth-child(even){
        //    .section {
        //         &:nth-child(odd) {
		// 	        background-color: $color__white;
		//         }
        //         &:nth-child(even) {
		// 	        background-color: $color__brand-gray;
        //             .read-more__window {
		// 	            &:after {
		// 		            background-image: linear-gradient(to bottom, $color__gradient-gray--start, $color__gradient-gray--end );
		// 	            }
		//             }
		//         }
		// 	}
			
			//make the entire collapsed accordion section one color, based off the last one in the alternating thing 
			@media (max-width: px-to-em($screen-sm - 1)){
				//the first one will be styled correctly by the alternation 
				//second, third, and fourth adjacent siblings get the same color as the first sibling
				//then anything that happens after that ... no guarantees
				& + .section--combined-mobile-accordion + .section--combined-mobile-accordion, 
				& + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion,
				& + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion, {
					background-color: $color__brand-gray;
				}
			}
        }
	}

	&--gray {
		background-color: $color__brand-gray;
	}

	&--no-top-padding {
		padding-top: 0;
	}

	@include respond-to('between') {

		&.section { //increases specificity to overwrite section-accordion class
			background-color: $color__white;
		}
	
		&.section--gray {
			background-color: $color__brand-gray;
		}
	}

	@include respond-to('desktop') {

		&__multi-column {
			display: flex;
			align-items: flex-start;
		}

		&--multi-column {
			display: flex;
			flex-wrap:nowrap;
		}

		&__column {
			width: px-to-em($section-title-width);
			flex-shrink: 0;
			margin-right: px-to-em($spacing__gutter);
		}

		&__column-body {
			flex-grow: 1;
			width: 100%; //needed to fix ie bug
		}
	}
}

