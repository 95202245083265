//Over arching layout of homepage, handles states and Detail styles

@keyframes slideinview {
  from {
    transform: translateX(100%)
  }
  to {
    transform: translateX(0)
  }
}

@keyframes slideoutview {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(100%)
  }
}


@keyframes slideinviewMobile {
  from {
    transform: translateY(100%)
  }
  to {
    transform: translateY(0)
  }
}

@keyframes slideoutviewMobile {
  from {
    transform: translateY(0)
  }
  to {
    transform: translateY(100%)
  }
}



.showcase-layout {
  overflow: hidden;
  height: 100%;

  $wedge-offset: px-to-em($spacing__unit *7);
  $showcase-viewable-window: 200px;

  #homepage {
    height: 100%;
  }
  
  &--show-detail {
    height: auto;
    overflow: visible;
    // transition: all 0.1s linear 1s;

    &.showcase-layout--remove-detail-scroll {
        height:100%;
        overflow: hidden;
    }
    .showcase {
      position: absolute;
      
      &__nav,
      &__updates {
        opacity: 0;
      }
    }

    .showcase-panel {
      &__headline-frame {
        margin-left: 0;
      }
      &__date {
        opacity: 1;
      }
      &__figures {

        //gray bar that appears to hide background image
        &:before {
          opacity: 1;
        }
      }
    }
  }

  &--force-no-scroll {
      height: 100%;
      overflow: hidden;
  }

  &--show-detail &{
    &__dark-column {
      opacity: 1;
      transition: opacity 0.5s ease-in 1.25s;
    }

    &__frame {
      opacity: 1;
      transition: none;
    }
    &__insight-detail {
      z-index: 20;
      transition: z-index 0.5s ease-out 1s;
      opacity: 1;
    }
  }

  &--allow-scrolling {
    // height: auto; taking out for mobile
    overflow: visible;
  }

  &--show-performance {
    
    .showcase {
      //click to close gutter for performance grid
      @include respond-to('showcase-desktop') {
        cursor: pointer;
      }

      &__updates {
        color: $color__white;

        &:after {
          border-color: $color__white;
        }
        
        .icon-close {
          color: $color__brand-teal;
          
        }
      }
    }

    .site-footer {
      padding-bottom: px-to-em($spacing__md);
    }
  }


  &--show-performance & {
    &__frame {
      opacity: 1;
      background-color: $color__white;
      z-index: 20;
      position: relative;
      transition: none;
      animation: slideinviewMobile 1s $transition__bezier forwards;
    }
  
    &__performance {
      display: block;
      height: auto;
      visibility: visible;
      transition: none;
    }

    &__insight-detail {
      display: none;
    }

    &__close-performance {
      @include transition-bezier(opacity);
      transition-delay: 1s;
      opacity: 1;
      visibility: visible;
    }
  }

  &--hide-performance-state & {
    
    &__frame {
      opacity: 1;
      background-color: $color__white;
      z-index: 20;
      position: relative;
      transition: none;
      animation: slideoutviewMobile 1s $transition__bezier forwards;
    }
  
    &__performance {
      display: block;
      height: auto;
      visibility: visible;
      transition: none;
    }

    &__insight-detail {
      display: none;
    }

    &__close-performance {
      transition: opacity $transition__timing $transition__ease,
      visibility 0.1s linear $transition__timing;
      opacity: 0;
      visibility: hidden;
    }
  }

  // Detail 
  
  &__frame {
    background-color: $color__brand-gray;
    opacity: 0;
    transition: opacity 0.1s linear 1s;
  }

  &__insight-detail {
    position: relative;
    z-index: 10;
    transition: none;

    // .wrapper {
    //   padding-left: px-to-em($wrapper-padding--tablet + 45px); //hardcoded number is arrow size 
    // }
  }

  &__performance {
    height: 0;
    visibility: hidden;
    overflow: hidden;
    transition: height 0.1s linear 1s,
                visibility 0.1s linear 1s;
  }


  .showcase-insights,
  .performance-grid {
    position: relative;
    z-index: 5;
  }

  .showcase-insights {
    padding: px-to-em($spacing__lg) 0 px-to-em($spacing__lg);
  }

  .performance-grid {
    padding: px-to-em($spacing__md + $site-header-height) 0 px-to-em($spacing__lg);
  }

  .site-footer {
    position: relative;
  }

  &__dark-column {
    transition: opacity 0.5s ease-out;
    opacity: 0;
    position: relative;
    background-color: $color__brand-gray;

    &:before {
      content: '';
      display: block;
      //!
      // width: 52vw;
      // right: calc(52% + #{$wedge-offset} - 5px);
      bottom: 0;
      position: absolute;
      top: 0;
      
      background-color: $color__black;
      transform: translateZ(0);
    }
  }

  &__white-section {
    background-color: $color__white;
  }

  &__close-performance {
    position: fixed;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    background-color: $color__black;
    border-top: 1px solid $color__border-gray;
    color: $color__brand-teal;
    z-index: 25;
    padding: $button__padding;
    text-transform: uppercase;
    opacity: 0;
    visibility: hidden;

    .icon-close {
      font-size: px-to-em(24px);
      line-height: 1;
      vertical-align: middle;
      color: $color__white;
      margin-right: 0.25em;
    }
  }

  @include respond-to ('between') {
    .performance-grid {
      padding: px-to-em($site-header-height--tablet) 0 px-to-em($spacing__lg);
    }
  }

  //1000px
  @include respond-to('showcase-desktop') {
    &__frame {
      opacity: 0;
      transition: opacity 0.1s linear 1s;
    }

    &--show-performance {
      .site-footer {
        padding-bottom: 0;
      }
    }

    &--show-performance & {
      &__frame {
      
        margin-left: 0;
        background-color: $color__white;
        animation: slideinview 1s $transition__bezier forwards;
      }

      &__close-performance {
        opacity: 0;
        visibility: hidden;
      }
    }

    &--hide-performance-state & {
      &__frame {
        animation: slideoutview 1s $transition__bezier forwards;
        margin-left: px-to-em($showcase-viewable-window);
      }
    }

    &__dark-column {
      &:before {
        width: 52vw;
        right: calc(52% + #{$wedge-offset} - 5px);
      }

      &--bottom-padding {
        padding-bottom: px-to-em($spacing__lg); //add some spacing when the quote component is not there
      }
    }
  }

  //was 1200px but merged the two because stuff was too weird
  // @include respond-to('desktop-large') {
  @include respond-to('showcase-desktop') {

    &__insight-detail {
  
      .wrapper {
        padding-left: px-to-em($wrapper-padding--tablet + 65px); //hardcoded number is arrow size 
      }
    }

    &--show-detail {
      height: auto;
      overflow: visible;
      // transition: all 0.1s linear 1s;
  
      .showcase {
        position: absolute;
        
        &__design-wedge {
          // right: calc(52% + #{$wedge-offset});
          //transition + calc doesn't work in IE
          right: 52%;
          transform: translateX(-#{$wedge-offset});
          }
        }


      .team-carousel,
      .showcase-quote {
        opacity: 1;
        transition: opacity 0.5s ease-in 1.25s;
      }
    }

    &--show-performance {
      
      .showcase {
        &__updates {
          right: calc(100% - #{$showcase-viewable-window});
        }
      }
    }

    &--show-performance & {
      &__frame {
        margin-left: px-to-em($showcase-viewable-window);
      }
    }
  }
}