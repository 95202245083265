.typeahead {
    display: none;
    position: absolute;
    width: 100%;



    background-color: $color__white;

    &--show {
        display: block;
        z-index: 10;
    }

    &__list{
        border: 1px solid $color__gray-charcoal-alt;
        border-top: none;
        
    }



    &__list-item {
        padding: px-to-em($button__padding) px-to-em($spacing__sm);
        cursor: pointer;

        &:hover {
            color: $color__brand-blue;
        }
    }


    &__name {
        word-break: break-word;
    }



  @include respond-to('between') {
    
    //size properly to parent element 
    padding-right: 10px;

    &__list-item {
        padding-left: 30px;
    }
  }
}