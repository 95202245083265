.person-result {
  border-bottom: 1px solid $color__brand-gray;
  margin-bottom: px-to-em($spacing__gutter-custom);
  padding-bottom: px-to-em($spacing__gutter-custom);
  &_transition-in{
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    
    visibility: hidden;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  &__name {
    @include titles-banner;

    > a {
      @include links__blue-on-light;
    }
  }

  &__level {
    @include font($size: $font-size__h3, $weight: 'light');
    white-space: pre-line;
    margin-top: px-to-em($spacing__unit, $font-size__h3);
  }

  &__email {
    display: inline-block;
    margin-top: px-to-em($spacing__unit, $font-size__h3);
  }

  &__office,
  &__email {
    @include font($size: $font-size__h3, $weight: 'light');
    margin-top: px-to-em($spacing__unit, $font-size__h3);
  }

  &__office-title {
    font-family: $font-family__copy;
    font-weight: inherit;
  }

  &__office-title,
  &__email {
    @include links__blue-on-light;
  }

  &__level,
  &__email,
  &__office {
    font-size: px-to-em($font-size__h3--tablet-custom);
    line-height: 16px;
  }

  &__level {
    line-height: 100%;
  }

  @include respond-to('between') {
    margin-bottom: px-to-em($spacing__gutter-custom * 1.5);
    padding-bottom: px-to-em($spacing__gutter-custom * 1.5);
    display: flex;
    justify-content: space-between;
    
    &__level,
    &__office,
    &__email {
        font-size: px-to-em($font-size__h3--tablet);
        line-height: 30px;
    }

    &__email {
        line-height: 30px;
        padding-bottom: px-to-em($spacing__gutter-custom, 20px);
    }

    &__main {
      flex-grow: 1;
      padding-right: px-to-em($spacing__sm);
    }

    &__offices {
      flex-shrink: 0;
      max-width: 35%;
      //need to vertically align the name and the first office name which are different fonts 
      //do this with padding 
      padding-top: 6px; 
    }

    &__office {
      margin-top: 0;

      & + & {
        margin-top: px-to-em($spacing__gutter, $font-size__h3--tablet);
      }
    }
  }

  @include respond-to('desktop') {
    @include clearfix;

    &__offices {      
      width: 28%;
    }
  }
}