$team-card-width: 250px;
$team-card-width--medium: 285px;

.team-card {
  display: block;
  margin: 0 1px;
  
  &__figure {
    max-width: px-to-em($team-card-width);
    position: relative;
  }

  &__img-placeholder {
    width: px-to-em($team-card-width);
    height: 0;
    padding-top: 100%;
    background-color: $color__white; 
    
    border-right: 1px solid $color__brand-gray;
    border-left: 1px solid $color__brand-gray;
    border-bottom: 1px solid $color__brand-gray;
  }
  
  &__img {
    display: block;
    width: 100%;
    max-width: 100%;
  }

  &__info {
    background-color: $color__white;
    padding: 1em;
    border: 1px solid $color__brand-gray;  
    border-top: 0;
    max-width: $team-card-width; //prevent long names from making the whole card wider
    height: 100%; //if one card breaks to create multiple lines, bring the height of the others up to match
  }

  &__name {
    font-size: px-to-em($font-size__small);
    font-weight: map-get($font-weights, 'bold' );
    color: $color__brand-blue;
    transition:$transition__card-text;
    word-wrap: break-word;
  }

  &__service {
    font-size: px-to-em($font-size__smallest);
    display: block;
    transition:$transition__card-text;
  }

  @include respond-to('landscape') {
    & + & {
      border-right: 1px solid $color__brand-gray; 

      .team-card__name {
        border-left: 0 none;
      }
    }
  }
  @include respond-to('between') {
    //background layer
    &__info {
      position: relative;

      &:after {
        @include transition(top);

        content: '';
        display: block;
        background-color: $color__brand-blue;
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        bottom: 0;
      }
    }

    &__name,
    &__service {
      position: relative;
      z-index: 1;
    }

    &:hover {
      .team-card__info {
        color: $color__white;

        &:after {
          //Background Grow
          top: 0;
        }
      }

      .team-card__name {
        color: $color__white;
      }
    }
  }

  @include respond-to('desktop') {
    border-right: 1px solid $color__brand-gray;  

    &__figure {
      max-width: px-to-em($team-card-width--medium);
    }

    &__img-placeholder {
      width: px-to-em($team-card-width--medium);
    }

    &__name {
      font-size: 1em;
    }

    &__service {
      font-size: px-to-em($font-size__small);
    }

    &__info {
      max-width: $team-card-width--medium;
    }
  }
}
