.general-listing {
  // $img-width: 85px;
  // $img-width--desktop: 150px;

  & + & {
    border-top: 1px solid $color__gray-shady-lady;
    padding-top: px-to-em($spacing__gutter);
    margin-top: px-to-em($spacing__gutter);
  }

  &__grid-item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    & + & {
      margin-top: 1.2em;
    }
  }

  &__meta {
    @include font($size: $font-size__h3, $weight: 'light');

    &:not(:last-child) {
      &:after {
        content: '/';
        margin: 0 0.5em;
      }
    }
  }

  // &__figure {
  //   position: relative;
  //   width: px-to-em($img-width);
  //   flex-shrink: 0;
  //   margin-right: 1em;

  //   &:before {
  //     @include overlay-gradient__blue;

  //     content: '';
  //   }

  //   > img {
  //     max-width: 100%;
  //     display: block;
  //   }
  // }

  &__details,
  &__name {
    flex-grow: 1;
  }

  &__name {
    // @include font($font-family__titles, $font-size__subheading);
    @include font($font-family__copy, 22px);
    font-weight: 300;
  }

  &__name-link {
    @include links__blue-on-light;
  }

  @include respond-to('landscape') {
    $credentials-grid-spacing: px-to-em($spacing__gutter);

    columns: 2 auto;
    column-gap: $credentials-grid-spacing;

    &__grid-item {
      position: relative;
      break-inside: avoid;
      overflow: hidden;

      &:first-child:last-child {
        width: 150%;
      }

      // & + & {
      //   margin-top: 0;
      // }
    }

    // &__details {
      // padding-bottom: px-to-em($spacing__gutter);
    // }
  }
  
	@include respond-to('desktop') {

    & + & {
      // padding-top: px-to-em($spacing__sm);
      // margin-top: px-to-em($spacing__sm);
      margin-top: 1em;
    }
    &__meta {
      font-size: px-to-em($font-size__h3--tablet);
    }

    &__name {
      // // font-size: px-to-em($font-size__banner--tablet);
      // font-size: px-to-em(24p)x;
    }

    // &__figure {
    //   width: px-to-em($img-width--desktop);
    //   margin-right: px-to-em($spacing__gutter);
    // }
	}
}