.site-search {
  $font-size--tablet: 30px;
  $input-padding--tablet: px-to-em(24px, $font-size--tablet);
  $icon-size--tablet: 30px;

  $offset-position: 45%;

  $transition-timing: 0.4s;



  @include center-align(both);
  max-width: px-to-em(1000px);
  width: 100%;
  top: 50%;    
  opacity: 0;
  transition: opacity $transition__timing $transition__ease,
    top $transition__timing/2 $transition__ease; 

  &.site-search--expanded{
    opacity: 1;
    top: $offset-position;
    transition-delay: $animation__overlay-expanded;
  }

  &__body {
      position: relative;
  }
  &__results {
    position: absolute;
    padding-top: px-to-em(20px);
    padding-bottom: px-to-em(20px);
    display: none;
    left:0;

    //typeahead for site search page
    &--landing {
      // position: relative;
      padding-bottom: 1em;
      // margin-top: -24px;
      top: 50%;

      z-index: 200; //needs to be above footer
      background-color: $color__white;
      position: absolute;
      border: 1px solid $color__brand-blue;
      border-top: none;
      
      width: 100%;
      padding-left: 1.25em;
      padding-right: 1.25em;
      .site-search__result-option {
        font-size: $font-size__h4;
      }
    }

    .site-search__result-option {
        &:first-child{
            padding-top: 0;
          }
      }

    &--show {
      display: block;
      width: 100%;
      border: 1px solid $color__brand-blue;
      border-top: none;
      background-color: $color__white;
    }
  }

  &__result-option {
    line-height: 1.2;
    padding-top: 1em;
  }


  @include respond-to('between') {
    .searchbox {
      &__input {
        font-size: px-to-em($font-size--tablet);
        padding: $input-padding--tablet $input-padding--tablet*1.25;
        padding-right: $input-padding--tablet*1.25 + px-to-em($icon-size--tablet);
      }

      &__button {
        font-size: px-to-em($icon-size--tablet);
        padding-right: $input-padding--tablet*1.25;
      }
    }

    &__result-option {
      font-size: px-to-em($font-size--tablet);
      font-weight: map-get($font-weights, 'light');
      a {
        @include links_blue-hover();
      }
    }

    &__results {
        padding: px-to-em(30px) px-to-em(30px) px-to-em(30px) px-to-em(30px);
        //typeahead for site search page
        &--landing{
        .site-search__result-option{
            padding-left: 0;
          font-size: $font-size__subheading;
        }
      }
    }
  }
}
