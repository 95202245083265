.performance-grid {
  $grid-spacing: 1em;

  background-color: $color__white;
  padding-bottom: px-to-em($spacing__md);
  
  &__item {
    & + & {
      margin-top: $grid-spacing;
    }

    &--ajax {
      order: 5 !important;
    }
  }
  
  @include respond-to('desktop') {
    padding-bottom: px-to-em($spacing__lg);

    &__insights {
      display: flex;
      flex-wrap: wrap;
      margin: 0 $grid-spacing * -1
    }

    
  }

  @include respond-to('desktop') {
    &__item {
      width: calc(50% - #{$grid-spacing});
      margin: 0 0.5em;
      margin-bottom: $grid-spacing;
      order: 1;

      & + & {
        margin-top: 0;
      }

      //on desktop view, show this in the bottom left corner
      &--prominent {
        width: calc(100% - #{$grid-spacing});
      }

      &--2, &--3, &--4{
        order: 1
      }

      //prominent position on desktop - move to 4th
      //aka first on left on the second line
      &--1 {
        order: 2;
      }

      &--5 {
        order: 3;
      }

      &--ajax {
        order: 10
      }

    }
    
  }

  @include respond-to('desktop') {
    &__item {
      width: calc(33.33333% - #{$grid-spacing});

      &--prominent {
        width: calc(66.66667% - #{$grid-spacing});
      }
    }
  }
}
