.search-controls {
  @include section--padded;
  background-color: $color__white;
  padding-top: 0;

  .keyword-search {
    .searchbox {
      transform: translateY(-50%);
      //since its transformed up, half of it was unclickable
      z-index: 3;
    }
  }

  &--insights {
    padding-bottom: 0;

    .wrapper {
      &:after {
        content: '';
        display: block;
        border-bottom: 1px solid $color__border-gray;
        padding-bottom: px-to-em($spacing__md);
      }
    }
  }

  &__no-results {
    padding-top: px-to-em($spacing__md);
    font-size: px-to-em($font-size__subheading);
  }

  @include respond-to('between') {
    padding-top: 0;
    &--insights {
      .keyword-search {
        .searchbox {
          transform: none;
        }
      }
      .advanced-search {
        margin-top: 0;
        
      }

      .keyword-search--grid {
        margin-top:-2.24em;
        margin-bottom:2.24em;
      }
      .wrapper {
        &:after {
          padding-bottom: px-to-em($spacing__lg);
        }
      }
    }
  }
  @include respond-to('desktop') {
    .keyword-search--grid {
      margin-top:-2.42em;
      margin-bottom:2.42em;
    }
  }
}