.back-to-top {
    &__container {
        position: fixed;
        right: 0.8em;
        bottom: 1.5em;
        z-index: $zindex__back-to-top--homepage;
        opacity: 0;
        @include transition(opacity 0.3s ease-in-out);
        
        &--active {
            opacity: 100;
        }
    }

    &__label {
        text-transform: uppercase;
        font-size: px-to-em($font-size__smallest);
        color: $color__gray-text;
        display: none;
        margin-top: 0.4em;
    }

    &__button {
        text-align: center;

        .back-to-top__svg {
            rect {
                @include transition(fill, stroke 0.3s ease-in-out);
            }
            path {
                @include transition(stroke 0.3s ease-in-out);
            }
        }
    }

    &__svg {
        width: px-to-em(40px);
        height: px-to-em(40px);
        display: block;;
    }

    &__svg-background {
        fill: $color__black;
        stroke: $color__white;
    }

    &__svg-arrow {
        stroke: white;
    }

   

     @include respond-to('between') {
        &__label {   
            display: block;
        }
        &__button:hover &{
            &__svg-background {
                fill: $color__brand-blue;
            }
        }   
    }
}