@mixin titles-subheading() {
  @include font($font-family__titles, $font-size__subheading);

	line-height: 1.3;

	@include respond-to('between') {
		font-size: px-to-em($font-size__subheading--tablet);
	}
}

@mixin titles-page-heading() {
	@include font($size: $font-size__h1);
	
	line-height: 1.1;
	
	@include respond-to('between') {
		font-size: px-to-em($font-size__h1--tablet);
	}
}

@mixin titles-tertiary-heading() {
	@include font($font-family__titles, $font-size__h2);
	
	line-height: 1.2;
	
	@include respond-to('between') {
		font-size: px-to-em($font-size__h2--tablet);
	}
}

@mixin titles-subentity() {
	@include font($family: $font-family__copy, $size: 16px, $weight: 'medium');
	line-height: 1.3;

	@include respond-to('between') {
		font-size: px-to-em($font-size__h4--tablet);
	}
}

@mixin titles-page-title() {
	@include font($size: $font-size__page-title);
	
	line-height: 1.1;
	
	@include respond-to('between') {
		font-size: px-to-em($font-size__page-title--tablet);
	}
}

@mixin titles-banner() {
	@include font($font-family__titles, $font-size__banner );
	
	line-height: 1.3;
	
	@include respond-to('between') {
		font-size: px-to-em($font-size__banner--tablet);
	}
}

@mixin titles-banner-custom() {
	@include font($font-family__titles, $font-size__banner--custom);
    line-height: 100%;

	@include respond-to('between') {
		font-size: px-to-em($font-size__banner--custom-tablet);
        line-height: 46px;
	}
}

.titles__subheading {
	&:after {
		content: '';
		display: block;
		width: px-to-em(30px);
		height: 1px;
		background-color: $color__gray-text;
		margin-top: px-to-em(24px);
		margin-bottom: px-to-em(24px);
	}

    @include respond-to('desktop') {
        &:after {
            margin-bottom: 0;
        }

        &--top {
            &:after {
                margin-bottom: px-to-em(24px);
            }
        }
	}

	&-title {
		@include titles-subheading();
	}
}

.titles__page-heading {
	@include titles-page-title();
}

.titles__tertiary {
	@include titles-tertiary-heading;
}

.titles__banner {
	@include titles-banner;
}