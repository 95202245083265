.infographic-card {
  $padding-sides: px-to-em(20px);

  // display:block;
  padding:px-to-em($spacing__gutter) $padding-sides;
  border: 1px solid $color__gray-shady-lady; 
  background-color: $color__white;
  text-align: center;
  width: 100%;

  //they want the card height to be consistent with each other in mobile view (before it was dynamic) 
  //using min height then when theres a really long one it may surpass it, but it won't cut off 
  //similar to insight cards, but not quite the same 

  min-height: px-to-em(280px);

  display: inline-flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;

  &__heading {
    width: 100%;
  }

  &__img {
    display:block;
    margin: 0 auto;
    max-width: 100%;
    margin-bottom: px-to-em($spacing__unit * 2 );
  }

  &__info {
    $font-size: 18px;
    @include font($size: $font-size, $weight: 'light');

    max-width: px-to-em(350px, $font-size);
    margin: 0 auto;
  }

  @at-root a#{&}{
    border-color: $color__brand-blue;
  }

  &--intro {
    border: 0 none;
    text-align: left;
    min-height: 0;
  }

  &--intro &{
    border: 0 none;
    text-align: left;

    &__info {
      max-width: none;
    }
  }

  &__heading {
    font-size: px-to-em($font-size__banner);
    line-height: 1.2;
    color: $color__brand-blue;

    &--external-link {
      @include links__external();
      &::after {
        font-size: .5em;
      }
    }

    & + .infographic-card__info {
      margin-top: px-to-em($spacing__sm);
    }
  }

  @include respond-to('between') {
    padding:px-to-em($spacing__sm * 2) $padding-sides;
    transition: $transition__card;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;

    min-height: px-to-em(350px);


    &__heading {
      font-size: px-to-em(32px);
      // width: 100%;
    }

    &__figure {
      width: 100%;
    }

    &__img {
      margin-bottom: px-to-em($spacing__md);
    }

    &__info {
      $font-size: 20px;
      font-size: px-to-em($font-size);
      margin: 0 auto;
      width: 100%;
    }

    @at-root a#{&}{

      &:hover,
      &:focus {
        box-shadow: 0 10px 30px 5px rgba(0,0,0,0.15);
        color: $color__brand-blue;
        border-color: transparent;
      }
    }
  }
}
