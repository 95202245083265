//IE hated the transparency when repeating
$kinetic-bg-url: "/assets/public/images/kinetic-pattern-grey.png";


.site-overlay {
  // background: $color__brand-gray url($kinetic-bg-url)  center top;
  background: url($kinetic-bg-url) center top;
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex__site-menu-overlay;
  padding-top: px-to-em($site-header-height); 
  overflow: hidden;
  transition: top  $animation__overlay-expanded cubic-bezier(0.86, 0, 0.07, 1);

  &--expanded {
    top: 0;
    transition-delay: 0s;
  }

  @include respond-to('between') {
    padding-top: px-to-em($site-header-height--tablet);  
  }  
}