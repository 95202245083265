@charset "UTF-8";
/* Variables */
@font-face {
  font-family: "kirkland";
  src: url("/assets/public/icons/kirkland.ttf") format("truetype"), url("/assets/public/icons/kirkland.woff") format("woff"), url("/assets/public/icons/kirkland.svg?z3e1ry#/assets/public/icons/") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "kirkland" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google-plus:before {
  content: "\e900";
}

.icon-facebook:before {
  content: "\e901";
}

.icon-twitter:before {
  content: "\e902";
}

.icon-x:before {
  content: "\e90e";
}

.icon-linkedin:before {
  content: "\e903";
}

.icon-youtube:before {
  content: "\ea9d";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-chevron-right:before {
  content: "\e90f";
}

.icon-chevron-left:before {
  content: "\e910";
}

.icon-chevron-thin-up:before {
  content: "\e905";
}

.icon-chevron-thin-right:before {
  content: "\e906";
}

.icon-chevron-thin-left:before {
  content: "\e907";
}

.icon-chevron-thin-down:before {
  content: "\e908";
}

.icon-search:before {
  content: "\e909";
}

.icon-close:before {
  content: "\e904";
}

.icon-globe:before {
  content: "\e90d";
}

.icon-marker:before {
  content: "\f0ca";
}

.icon-external-link:before {
  content: "\e90b";
}

.icon-download:before {
  content: "\e90a";
}

.icon-envelope:before {
  content: "\e90c";
}

.icon-play-arrow:before {
  content: "\e037";
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button, input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

html {
  font-size: 100%;
  box-sizing: border-box;
  height: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background-color: #1C1C1C;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 400 100% "Rubik", sans-serif;
  line-height: 1.5;
  color: #3F3F3F;
}

main {
  display: block;
  background-color: #fff;
}
main#homepage {
  background-color: transparent;
}

article {
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}
a:focus {
  outline: none;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin-bottom: 0;
}

pre {
  margin: 0;
}

p {
  margin: 0 0 1.5em;
}
p:last-child {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

fieldset {
  border: 0 none;
  padding: 0;
  margin: 0;
}

.wrapper {
  padding-left: 1.25em;
  padding-right: 1.25em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 48em) {
  .wrapper {
    max-width: 80em;
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
  .wrapper--wide {
    max-width: 92.5em;
  }
  .wrapper--narrow {
    max-width: 67.5em;
  }
}

button {
  transition: color 0.35s ease-out;
  line-height: 1.1;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  transition: color 0.4s;
  position: relative;
  border-radius: 0;
  padding: 0;
  border-width: 1px;
}

.button {
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  border-radius: 0;
  border-width: 1px;
  text-align: center;
  padding: 0.9375em 1.625em;
  min-width: 7.8125em;
}
@media screen and (min-width: 48em) {
  .button {
    min-width: 10.9375em;
    padding: 1.40625em 1.95em;
  }
}
.button--disabled {
  background-color: #fff !important;
}
.button--disabled::before {
  display: none;
}
.button--disabled:hover {
  box-shadow: none !important;
  color: inherit !important;
}

.button-primary {
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  background-color: #fff;
  border: 1px solid #2354E8;
  color: #2354E8;
  overflow: hidden;
}
.button-primary__label {
  position: relative;
}
.button-primary:before {
  transition: transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2354E8;
  transform: translateY(100%);
}
.button-primary--play:hover::after {
  transition: border-left-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.button-primary--play::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fff;
  margin-left: 1em;
  display: inline-block;
  transition: border-left-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  position: relative;
  z-index: 1;
}
.button-primary--dark {
  border: 0 none;
  background-color: #1C1C1C;
  color: #fff;
}
.button-primary--dark:before {
  background-color: #fff;
}
.button-primary--dark:hover::after {
  border-left-color: #2354E8;
}
.button-primary--coral {
  border-color: #FF715B;
  background-color: #FF715B;
  color: #fff;
}
.button-primary--coral:before {
  background-color: #fff;
}
.button-primary--coral:hover::after {
  border-left-color: #FF715B;
}
.button-primary--blue {
  background-color: #2354E8;
  color: #fff;
}
.button-primary--blue:before {
  background-color: #fff;
}
.button-primary--blue:hover::after {
  border-left-color: #2354E8;
}
.button-primary--ghost {
  border-color: #7C7C7C;
  background-color: transparent;
  color: #fff;
}
.button-primary--ghost:before {
  background-color: #fff;
}
.button-primary--ghost:hover::after {
  border-left-color: #7C7C7C;
}
.button-primary--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .button-primary:hover {
    color: #fff;
    box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.15);
  }
  .button-primary:hover:before {
    transform: translateY(0);
  }
  .button-primary:focus {
    outline: none;
    box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.15);
  }
  .button-primary--dark:hover {
    color: #1C1C1C;
  }
  .button-primary--blue:hover {
    color: #2354E8;
  }
  .button-primary--ghost:hover {
    color: #1C1C1C;
  }
  .button-primary--coral:hover {
    color: #FF715B;
  }
}

.button-utility {
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  border: 1px solid #fff;
  background-color: #1C1C1C;
  color: #fff;
  text-align: left;
  text-transform: uppercase;
  overflow: hidden;
  padding: 0.9375em 1.375em;
}
.button-utility__label {
  position: relative;
  width: 100%;
  line-height: 1.5;
  display: inline-block;
  padding-right: 1.5em;
}
.button-utility__label [class^=icon-] {
  font-size: 1.2em;
  padding-right: 0.5em;
  vertical-align: text-bottom;
}
.button-utility--cta {
  max-width: 16.25em;
  width: 100%;
}
.button-utility--cta .button-utility__label {
  padding-right: 0;
}
.button-utility--external .button-utility__label:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e906";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.button-utility--centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.button-utility--smaller {
  padding: 0.5em 1em;
}
@media screen and (min-width: 48em) {
  .button-utility--smaller {
    padding: 0.75em 1.2em;
  }
}
.button-utility:before {
  transition: top 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
@media screen and (min-width: 48em) {
  .button-utility:hover, .button-utility:focus {
    color: #1C1C1C;
    box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.15);
  }
  .button-utility:hover:before, .button-utility:focus:before {
    top: 0;
  }
}

.titles__subheading:after {
  content: "";
  display: block;
  width: 1.875em;
  height: 1px;
  background-color: #7C7C7C;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
@media screen and (min-width: 62em) {
  .titles__subheading:after {
    margin-bottom: 0;
  }
  .titles__subheading--top:after {
    margin-bottom: 1.5em;
  }
}
.titles__subheading-title {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  line-height: 1.3;
}
@media screen and (min-width: 48em) {
  .titles__subheading-title {
    font-size: 1.5em;
  }
}

.titles__page-heading {
  font-size: 2em;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .titles__page-heading {
    font-size: 4em;
  }
}

.titles__tertiary {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  line-height: 1.2;
}
@media screen and (min-width: 48em) {
  .titles__tertiary {
    font-size: 1.5em;
  }
}

.titles__banner {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
}
@media screen and (min-width: 48em) {
  .titles__banner {
    font-size: 2.375em;
  }
}

.section-layout {
  background-color: #fff;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
}
.section-layout .section {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 48em) {
  .section-layout {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
  }
  .section-layout .section {
    padding-top: 6.25em;
    padding-bottom: 6.25em;
  }
}

@media (max-width: 47.9375em) {
  .section--combined-mobile-accordion + .section--combined-mobile-accordion {
    padding-top: 0;
  }
}

.section {
  padding-top: 4.0625em;
  padding-bottom: 4.0625em;
  background-color: #fff;
}
@media screen and (min-width: 48em) {
  .section {
    padding-top: 6.25em;
    padding-bottom: 6.25em;
  }
}
.section.section-layout {
  padding-top: 0;
  padding-bottom: 0;
}
.section--alternating-bg .section.section--gray {
  background-color: inherit;
}
.section--alternating-bg .section:nth-child(2n+1) {
  background-color: #E3E3E3;
}
.section--alternating-bg .section:nth-child(2n+1) .person-result {
  border-bottom: 1px solid #979797;
}
.section--alternating-bg .section:nth-child(2n+1) .listing-insights__item + .listing-insights__item {
  border-top: 2px solid #979797;
}
.section--alternating-bg .section:nth-child(2n+1) .read-more__window:after {
  background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0), rgb(227, 227, 227));
}
@media (max-width: 47.9375em) {
  .section--alternating-bg .section:nth-child(2n+1) + .section--combined-mobile-accordion + .section--combined-mobile-accordion, .section--alternating-bg .section:nth-child(2n+1) + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion, .section--alternating-bg .section:nth-child(2n+1) + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion {
    background-color: #fff;
  }
  .section--alternating-bg .section:nth-child(2n+1) + .section--combined-mobile-accordion + .section--combined-mobile-accordion .read-more__window:after, .section--alternating-bg .section:nth-child(2n+1) + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion .read-more__window:after, .section--alternating-bg .section:nth-child(2n+1) + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion .read-more__window:after {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  }
}
@media (max-width: 47.9375em) {
  .section--alternating-bg .section:nth-child(even) + .section--combined-mobile-accordion + .section--combined-mobile-accordion, .section--alternating-bg .section:nth-child(even) + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion, .section--alternating-bg .section:nth-child(even) + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion + .section--combined-mobile-accordion {
    background-color: #E3E3E3;
  }
}
.section--gray {
  background-color: #E3E3E3;
}
.section--no-top-padding {
  padding-top: 0;
}
@media screen and (min-width: 48em) {
  .section.section {
    background-color: #fff;
  }
  .section.section--gray {
    background-color: #E3E3E3;
  }
}
@media screen and (min-width: 62em) {
  .section__multi-column {
    display: flex;
    align-items: flex-start;
  }
  .section--multi-column {
    display: flex;
    flex-wrap: nowrap;
  }
  .section__column {
    width: 16.875em;
    flex-shrink: 0;
    margin-right: 2em;
  }
  .section__column-body {
    flex-grow: 1;
    width: 100%;
  }
}

@keyframes headroom-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes headroom-fadein-reverse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.site-header {
  height: 3.75em;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 90;
  padding: 1.25em 0;
}
.site-header__h1-tag {
  font-size: 1em;
}
.site-header__hidden-label {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  right: 100vw;
}
.site-header.headroom--not-top {
  position: fixed;
  transform: translateY(-100%);
  background-color: #1C1C1C;
  animation: headroom-fadein 0.5s ease-in forwards;
}
.site-header.headroom--not-top .site-header__body {
  max-width: none;
}
.site-header.headroom--unpinned {
  transform: translateY(-100%);
  transition: transform 0.2s ease-out, background-color 0.2s ease-out;
}
.site-header.headroom--pinned {
  transform: translateY(0);
  transition: transform 0.2s ease-out, background-color 0.2s ease-out;
}
.site-header.headroom--top {
  transition: none;
}
.site-header__body {
  text-align: right;
  position: relative;
}
.site-header__body:after {
  content: " ";
  display: table;
}
.site-header__body:after {
  clear: both;
}
.site-header__brand {
  display: block;
  width: 11.875em;
  float: left;
  position: relative;
}
.site-header__logo {
  display: block;
  width: 100%;
  transition: opacity 0.35s ease-in;
}
.site-header__logo--black {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.site-header.site-header--overlay-expanded {
  background-color: transparent;
}
.site-header--overlay-expanded .site-header__logo {
  opacity: 0;
}
.site-header--overlay-expanded .site-header__logo--black {
  opacity: 1;
  transition-delay: 0.5s;
}
.site-header__search {
  color: #fff;
  font-size: 1.375em;
  margin-right: 0.5em;
  vertical-align: top;
  line-height: 1;
  overflow: visible;
}
.site-header__search:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e904";
  transition: opacity 0.35s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.3em;
  line-height: 0.75;
  opacity: 0;
}
.site-header__search:before {
  transition: opacity 0.35s ease-out;
}
.site-header__search--expanded:before {
  opacity: 0;
}
.site-header__search--expanded:after {
  opacity: 1;
}
.site-header--showcase {
  transition: 0.2s opacity ease-out;
}
.site-header--showcase.site-header--modal-open {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: none;
  height: 0;
  overflow: hidden;
}
.site-header--showcase-expanded-logo .site-header__logo {
  opacity: 0;
  transition-delay: 0s;
}
.site-header--showcase-expanded-logo .site-header__logo--black {
  opacity: 1;
  transition-delay: 0.5s;
}
.site-header--showcase-expanded-logo .site-header__search {
  color: #7C7C7C;
}
.site-header--showcase-marquee-story.site-header--overlay-expanded .site-header__logo {
  opacity: 0 !important;
}
.site-header--showcase-marquee-story.site-header--overlay-expanded .site-header__logo--black {
  opacity: 1 !important;
}
.site-header--showcase-marquee-story:not(.headroom--pinned) .breadcrumbs {
  display: none;
}
@media screen and (min-width: 48em) {
  .site-header {
    padding: 2.8125em 0;
    height: 6.25em;
  }
  .site-header--showcase:not(.headroom--pinned) .site-header__body {
    display: block;
    text-align: right;
  }
  .site-header--showcase:not(.headroom--pinned) .site-header__brand {
    float: left;
  }
  .site-header--showcase-expanded .site-header__logo {
    opacity: 0;
  }
  .site-header--showcase-expanded .site-header__logo--black {
    opacity: 1;
    transition-delay: 0.5s;
  }
  .site-header.headroom--not-top {
    height: 6.25em;
    padding-top: 2.1875em;
  }
  .site-header.headroom--not-top .site-header__brand {
    width: 13.75em;
  }
  .site-header.headroom--not-top:not(.site-header--overlay-expanded) .breadcrumbs {
    visibility: visible;
  }
  .site-header__body {
    text-align: left;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .site-header .breadcrumbs {
    flex-grow: 1;
    padding-left: 5%;
    visibility: hidden;
  }
  .site-header__brand {
    width: 16.875em;
    flex-shrink: 0;
  }
  .site-header__search {
    font-size: 1.75em;
    margin-right: 0.75em;
    color: #7C7C7C;
  }
  .site-header__search:hover {
    color: #fff;
  }
  .site-header--auxillary {
    opacity: 1;
  }
  .site-header--auxillary .site-header__body {
    padding-left: calc(50% + 40px);
  }
}
@media screen and (min-width: 62em) {
  .site-header__brand {
    width: 17.875em;
  }
}
@media screen and (min-width: 62.5em) {
  .site-header--showcase:not(.headroom--pinned):not(.site-header--showcase-marquee-story) .site-header__brand {
    margin-left: calc(50% + 2.5em);
  }
}

.site-overlay {
  background: url("/assets/public/images/kinetic-pattern-grey.png") center top;
  position: fixed;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 85;
  padding-top: 3.75em;
  overflow: hidden;
  transition: top 1s cubic-bezier(0.86, 0, 0.07, 1);
}
.site-overlay--expanded {
  top: 0;
  transition-delay: 0s;
}
@media screen and (min-width: 48em) {
  .site-overlay {
    padding-top: 9.375em;
  }
}

.site-nav {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.site-nav--expanded {
  z-index: 4;
}
.site-nav:not(.site-nav--expanded) .site-nav__link,
.site-nav:not(.site-nav--expanded) .site-nav__auxillary-link,
.site-nav:not(.site-nav--expanded) .social-links__item a {
  pointer-events: none;
}
.site-nav:not(.site-nav--expanded) .site-nav__link,
.site-nav:not(.site-nav--expanded) .site-nav__auxillary-link {
  display: inline-block;
}
.site-nav__primary {
  width: 100%;
}
.site-nav__auxillary {
  align-items: flex-end;
  flex-shrink: 0;
}
.site-nav__item {
  margin-bottom: 2vh;
}
.site-nav__link {
  font-size: 1.125em;
}
.site-nav__list--primary .site-nav__link {
  font-size: 1.375em;
  color: #2354E8;
  transition: color 0.35s ease-out;
}
.site-nav__list--primary .site-nav__link:hover {
  color: #3F3F3F;
}
.site-nav__list--secondary .site-nav__link {
  transition: color 0.35s ease-out;
}
.site-nav__list--secondary .site-nav__link:hover {
  color: #7C7C7C;
}
.site-nav .social-links {
  overflow: hidden;
  margin-bottom: 1em;
  padding-right: 1px;
}
.site-nav .social-links__item {
  float: left;
  margin-right: -1px;
}
.site-nav .social-links__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  width: 1.6666666667em;
  height: 1.6666666667em;
  text-align: center;
  border: 1px solid #979797;
}
.site-nav__auxillary-info {
  font-size: 0.75em;
  line-height: 1.6;
}
.site-nav__auxillary-option {
  margin-bottom: 1em;
}
.site-nav__auxillary-link {
  transition: color 0.35s ease-out;
  color: #2354E8;
}
.site-nav__auxillary-link:hover {
  color: #3F3F3F;
}
@media screen and (min-width: 48em) {
  .site-nav__item {
    margin-bottom: 2.5vh;
    text-align: right;
  }
  .site-nav__link {
    font-size: 2em;
  }
  .site-nav__list--primary .site-nav__link {
    font-size: 2.5em;
  }
  .site-nav__auxillary {
    padding-bottom: 2.75em;
    text-align: right;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }
  .site-nav__auxillary-info {
    padding-left: 2em;
    flex-grow: 1;
  }
  .site-nav__auxillary-nav {
    display: inline;
    margin-right: 1em;
  }
  .site-nav__auxillary-option {
    display: inline;
    margin-bottom: 0;
  }
  .site-nav__auxillary-option:after {
    content: "/";
    margin: 0 0.4em;
  }
  .site-nav__auxillary-option:last-child:after {
    content: close-quote;
    margin-right: 0;
  }
  .site-nav .social-links {
    flex-shrink: 0;
    margin-bottom: 0;
    overflow: visible;
  }
  .site-nav .social-links__icon {
    font-size: 1.875em;
    width: 2em;
    height: 2em;
  }
  .site-nav .social-links__icon:hover {
    background-color: #fff;
    color: #1C1C1C;
  }
}

.site-search {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 62.5em;
  width: 100%;
  top: 50%;
  opacity: 0;
  transition: opacity 0.35s ease-out, top 0.175s ease-out;
}
.site-search.site-search--expanded {
  opacity: 1;
  top: 45%;
  transition-delay: 1s;
}
.site-search__body {
  position: relative;
}
.site-search__results {
  position: absolute;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  display: none;
  left: 0;
}
.site-search__results--landing {
  padding-bottom: 1em;
  top: 50%;
  z-index: 200;
  background-color: #fff;
  position: absolute;
  border: 1px solid #2354E8;
  border-top: none;
  width: 100%;
  padding-left: 1.25em;
  padding-right: 1.25em;
}
.site-search__results--landing .site-search__result-option {
  font-size: 16px;
}
.site-search__results .site-search__result-option:first-child {
  padding-top: 0;
}
.site-search__results--show {
  display: block;
  width: 100%;
  border: 1px solid #2354E8;
  border-top: none;
  background-color: #fff;
}
.site-search__result-option {
  line-height: 1.2;
  padding-top: 1em;
}
@media screen and (min-width: 48em) {
  .site-search .searchbox__input {
    font-size: 1.875em;
    padding: 0.8em 1em;
    padding-right: 2.875em;
  }
  .site-search .searchbox__button {
    font-size: 1.875em;
    padding-right: 1em;
  }
  .site-search__result-option {
    font-size: 1.875em;
    font-weight: 300;
  }
  .site-search__result-option a {
    transition: color 0.35s ease-out;
  }
}
@media screen and (min-width: 48em) and (min-width: 48em) {
  .site-search__result-option a:hover {
    color: #2354E8;
  }
}
@media screen and (min-width: 48em) {
  .site-search__results {
    padding: 1.875em 1.875em 1.875em 1.875em;
  }
  .site-search__results--landing .site-search__result-option {
    padding-left: 0;
    font-size: 20px;
  }
}

.site-footer {
  background-color: #1C1C1C;
  color: #7C7C7C;
  z-index: 19;
  position: relative;
}
.site-footer__main {
  padding-top: 2.03125em;
  padding-bottom: 2.03125em;
}
.site-footer__regions {
  font-size: 0.875em;
  line-height: 1.6;
}
.site-footer__closing {
  display: block;
}
.site-footer__row {
  margin-top: 4.0625em;
}
.site-footer__legal-link, .site-footer__menu-link {
  display: inline-block;
  font-size: 0.875em;
  margin-bottom: 0.7142857143em;
}
.site-footer__legal-link--external:after, .site-footer__menu-link--external:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90b";
  font-size: 1em;
  padding-left: 0.4em;
}
.site-footer .social-links {
  float: right;
  margin-right: 50px;
}
.site-footer .social-links__item {
  display: block;
  margin-bottom: -1px;
}
.site-footer .social-links__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25em;
  width: 2em;
  height: 2em;
  text-align: center;
  border: 1px solid #979797;
}
.site-footer__logo {
  display: none;
}
.site-footer__locations {
  display: none;
}
.site-footer__copyright {
  font-size: 0.875em;
  display: inline-block;
  color: #fff;
  margin-top: 1.5714285714em;
}
.site-footer__closing-options {
  margin-top: 1.5714285714em;
}
.site-footer__regions-label, .site-footer__menu-label {
  font-weight: 700;
  color: #fff;
  margin-bottom: 1em;
  display: inline-block;
}
.site-footer__menu-label [class^=icon-] {
  padding-right: 0.5em;
}
@media screen and (min-width: 48em) {
  .site-footer__main {
    padding-top: 4.0625em;
    padding-bottom: 4.0625em;
  }
  .site-footer__locations {
    display: table;
  }
  .site-footer__regions {
    display: flex;
  }
  .site-footer__regions + .site-footer__regions {
    margin-top: 1em;
  }
  .site-footer__regions-label, .site-footer__menu-label {
    font-weight: 700;
    color: #fff;
    margin-right: 0.75em;
    margin-bottom: 0;
    flex-shrink: 0;
    display: inline-block;
  }
  .site-footer__regions-label:after, .site-footer__menu-label:after {
    content: " -";
    margin-left: 0.5em;
  }
  .site-footer__locations-list, .site-footer__legal, .site-footer__menu {
    flex-grow: 1;
    display: inline;
  }
  .site-footer__locations-item, .site-footer__legal-item, .site-footer__menu-item {
    display: inline-block;
  }
  .site-footer__locations-item:after, .site-footer__legal-item:after, .site-footer__menu-item:after {
    content: "/";
    margin-right: 0.4em;
  }
  .site-footer__locations-item:last-child:after, .site-footer__legal-item:last-child:after, .site-footer__menu-item:last-child:after {
    content: close-quote;
    margin-right: 0;
  }
  .site-footer__locations-city, .site-footer__legal-link, .site-footer__menu-link {
    margin-right: 0.4em;
    display: inline;
    margin-bottom: 0;
  }
  .site-footer__locations-city:hover, .site-footer__legal-link:hover, .site-footer__menu-link:hover {
    color: #fff;
  }
  .site-footer__closing {
    display: flex;
    align-items: flex-end;
    margin-top: 4.0625em;
    text-align: right;
  }
  .site-footer__closing-options {
    padding-left: 2em;
    flex-grow: 1;
    margin-top: 1em;
  }
  .site-footer .social-links {
    flex-shrink: 0;
    float: none;
  }
  .site-footer .social-links__item {
    float: left;
    margin-right: -1px;
    margin-bottom: 0;
  }
  .site-footer .social-links__icon {
    font-size: 1.875em;
    width: 2em;
    height: 2em;
  }
  .site-footer .social-links__icon:hover {
    background-color: #fff;
    color: #1C1C1C;
  }
  .site-footer__copyright {
    margin-top: 0;
  }
}
@media screen and (min-width: 62em) {
  .site-footer__logo {
    float: right;
    max-width: 16.875em;
    display: block;
  }
  .site-footer__logo > img {
    width: 100%;
  }
  .site-footer__legal-link, .site-footer__menu-link, .site-footer__copyright {
    font-size: 1em;
  }
}

.mobile-toggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 1.875em;
  height: 1.875em;
  padding-top: 0.5em;
  color: #7C7C7C;
  vertical-align: top;
  overflow: visible;
}
.mobile-toggle__label {
  display: none;
}
.mobile-toggle__bars {
  transition: background-color 0.35s ease-out;
  width: 100%;
  display: inline-block;
  height: 3px;
  background-color: #FF715B;
  position: absolute;
  left: 50%;
  top: 0.6875em;
  transform: translate(-50%, -50%);
}
.mobile-toggle__bars:before, .mobile-toggle__bars:after {
  transition: background-color 0.35s ease-out;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.5em;
  background-color: #FF715B;
  display: block;
  transition: all 0.25s;
}
.mobile-toggle__bars:after {
  top: auto;
  bottom: -0.5em;
}
.mobile-toggle--expanded .mobile-toggle__bars {
  background-color: transparent;
}
.mobile-toggle--expanded .mobile-toggle__bars:hover {
  background-color: transparent;
}
.mobile-toggle--expanded .mobile-toggle__bars:before {
  transform: rotate(-45deg);
  top: 0;
}
.mobile-toggle--expanded .mobile-toggle__bars:after {
  transform: rotate(45deg);
  bottom: 0;
}
@media screen and (min-width: 48em) {
  .mobile-toggle {
    padding-top: 1.5em;
    width: auto;
  }
  .mobile-toggle:hover .mobile-toggle__bars {
    background-color: #fff;
  }
  .mobile-toggle:hover .mobile-toggle__bars:before, .mobile-toggle:hover .mobile-toggle__bars:after {
    background-color: #fff;
  }
  .mobile-toggle--expanded:hover .mobile-toggle__bars {
    transition: none;
    background-color: transparent;
  }
  .mobile-toggle__bars {
    position: absolute;
    top: 0.625em;
    width: 100%;
  }
  .mobile-toggle__bars:before {
    top: -0.625em;
  }
  .mobile-toggle__bars:after {
    bottom: -0.625em;
  }
  .mobile-toggle__label {
    display: inline-block;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.75em;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-invisible-offset::before {
  display: block;
  content: " ";
  margin-top: -150px;
  height: 150px;
  visibility: hidden;
}

.breadcrumbs {
  display: none;
}
.breadcrumbs__options {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.breadcrumbs__item {
  color: #7C7C7C;
  display: inline;
}
.breadcrumbs__item:after {
  content: "-";
  margin-left: 0.45em;
  margin-right: 0.15em;
}
.breadcrumbs__item:last-child:after {
  content: "";
  margin: 0;
}
.breadcrumbs__title {
  font-size: 0.875em;
}
a.breadcrumbs__title {
  color: #fff;
  transition: color 0.35s ease-out;
}

@media screen and (min-width: 48em) {
  .breadcrumbs {
    display: block;
    overflow: hidden;
  }
  .breadcrumbs a.breadcrumbs__title:hover {
    color: #7C7C7C;
  }
}

.searchbox {
  position: relative;
}
.searchbox__input {
  line-height: 1.1;
  width: 100%;
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  border: 1px solid #2354E8;
  color: #3F3F3F;
  padding: 1.125em;
  padding-right: 2.375em;
  font-weight: 300;
  outline: none;
}
.searchbox__input::-webkit-input-placeholder {
  color: #3F3F3F;
  opacity: 1;
}
.searchbox__input::-moz-placeholder {
  color: #3F3F3F;
  opacity: 1;
}
.searchbox__input:-moz-placeholder {
  color: #3F3F3F;
  opacity: 1;
}
.searchbox__input:-ms-input-placeholder {
  color: #3F3F3F;
  opacity: 1;
}
.searchbox__input:focus {
  border-color: #3F3F3F;
  box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.15);
}
.searchbox__button {
  position: absolute;
  right: 0;
  padding-right: 1.25em;
  top: 0;
  height: 100%;
  text-align: right;
  background-color: transparent;
  border-color: transparent;
  color: #2354E8;
  font-size: 1.25em;
}
.searchbox__button:hover {
  color: #3F3F3F;
}
@media screen and (min-width: 48em) {
  .searchbox__input {
    font-size: 1.25em;
    padding: 0.96em 1.2em;
  }
}

.social-links__item {
  display: inline;
}
.social-links__icon {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}
.social-links__icon-text {
  font-size: 0;
  line-height: 0;
  color: transparent;
}
.social-links__label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
}

.rte {
  line-height: 1.5;
  font-weight: 300;
  /* Class added within rich text content by editors*/
}
.rte.rte--intro-paragraph > p:first-child {
  font-size: 1.32em;
}
.rte .rte--intro-paragraph-custom {
  font-size: 1.32em;
}
.rte--disclaimer {
  font-size: 0.875em;
  font-style: italic;
}
.rte > *:last-child {
  margin-bottom: 0;
}
.rte > *:first-child {
  margin-top: 0;
}
.rte a {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
  word-wrap: break-word;
}
@media screen and (min-width: 48em) {
  .rte a:hover {
    color: #505050;
  }
}
.rte ul {
  list-style-type: disc;
}
.rte ul ul {
  list-style-type: circle;
  margin-top: 0.75em;
}
.rte li {
  margin-bottom: 0.75em;
}
.rte ol {
  list-style-type: decimal;
}
.rte ol ol {
  margin-top: 0.75em;
  list-style-type: lower-alpha;
}
.rte ol ol ol {
  list-style-type: lower-roman;
}
.rte ul, .rte ol {
  padding-left: 1em;
  margin-left: 1em;
}
.rte .blockquote--no-quote:before {
  content: "" !important;
  display: none !important;
}
.rte blockquote,
.rte .blockquote,
.rte .blockquote--no-quote {
  font-size: 1.125em;
  font-weight: 700;
  border: 1px solid #E3E3E3;
  border-width: 1px 0;
  margin: 1.7777777778em 0;
  padding: 1.7777777778em 2em;
}
.rte blockquote:before,
.rte .blockquote:before,
.rte .blockquote--no-quote:before {
  content: "“";
  display: inline-block;
  font-family: "Playfair Display", serif;
  font-weight: 400;
  padding-right: 0.25em;
  font-size: 1.5em;
  line-height: 0.5;
  vertical-align: middle;
}
.rte blockquote p:first-child,
.rte .blockquote p:first-child,
.rte .blockquote--no-quote p:first-child {
  display: inline;
}
.rte blockquote:first-child,
.rte .blockquote:first-child,
.rte .blockquote--no-quote:first-child {
  margin-top: 0;
  border: 0 none;
  padding-top: 0;
  padding-bottom: 0;
}
.rte cite,
.rte .cite {
  font-weight: 300;
}
.rte img {
  max-width: 100%;
  height: auto !important;
}
.rte ul li p {
  margin-bottom: 0;
}
.rte ul {
  margin-bottom: 1.5em;
}
.rte h3,
.rte .h3-size {
  margin-bottom: 1.2em;
}
.rte h4,
.rte .h4-size {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1em;
  margin-bottom: 0;
  margin-top: 0;
}
@media screen and (min-width: 48em) {
  .rte {
    font-size: 1.25em;
  }
  .rte--disclaimer {
    font-size: 0.875em;
  }
  .rte a {
    color: #2354E8;
  }
  .rte a:hover {
    color: #1AC6BA;
  }
  .rte blockquote,
.rte .blockquote {
    font-size: 1.4em;
    font-weight: 500;
    line-height: 1.3;
    padding-right: 5%;
    padding-left: 5%;
  }
  .rte blockquote:before,
.rte .blockquote:before {
    font-size: 2.5em;
    line-height: 0.2;
    padding-right: 0.2em;
    vertical-align: bottom;
  }
  .rte blockquote:first-child,
.rte .blockquote:first-child {
    padding-left: 0;
    padding-right: 0;
  }
  .rte h3 {
    margin-bottom: 1.25em;
    margin-top: 2em;
  }
}
@media screen and (min-width: 62em) {
  .rte blockquote,
.rte .blockquote {
    margin-left: 0;
    margin-right: 0;
    padding-right: 13%;
    padding-left: 13%;
  }
}

.blue-overlay {
  position: relative;
  display: inline-block;
}
.blue-overlay:before {
  background-image: linear-gradient(to bottom, rgba(68, 96, 219, 0), rgb(35, 84, 232));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
  content: "";
}
.blue-overlay > img {
  max-width: 100%;
  display: block;
}

.read-more__button {
  margin: 1.375em auto 0;
  display: block;
}
.read-more .button-primary__label:before {
  content: "+";
  display: inline-block;
  width: 1.25em;
}
.read-more--is-expanded .button-primary__label:before {
  content: "–";
}
.read-more--not-expandable .read-more__window {
  height: auto;
}
.read-more--not-expandable .read-more__window:after {
  content: none;
}
.read-more--initial-not-expandable .read-more__window {
  height: auto;
  max-height: 18.75em;
}
.read-more--initial-not-expandable .read-more__window:after {
  content: none;
}
.overview-block.section--gray .read-more__window:after {
  background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0), rgb(227, 227, 227));
}
.read-more__window {
  transition: height 0.35s ease-out;
  overflow: hidden;
  height: 600px;
  position: relative;
}
.read-more__window:after {
  transition: top 0.35s ease-out;
  content: "";
  display: block;
  position: absolute;
  top: 18.75em;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
  z-index: 2;
  pointer-events: none;
}
.read-more--is-expanded .read-more__window:after {
  top: 100%;
}
.read-more--separate-intro .read-more__window {
  height: 0;
}
.read-more--separate-intro .read-more__window:before {
  content: "";
  display: block;
  height: 1.375em;
}
.read-more--separate-intro .read-more__window:after {
  content: none;
}
@media screen and (min-width: 48em) {
  .read-more__button {
    margin-top: 2em;
  }
  .read-more__window {
    height: 37.5em;
  }
  .section--gray .read-more__window:after {
    background-image: linear-gradient(to bottom, rgba(227, 227, 227, 0), rgb(227, 227, 227));
  }
  .read-more--separate-intro .read-more__window:before {
    height: 2em;
  }
}
@media screen and (min-width: 62em) {
  .read-more__button {
    margin-left: 0;
  }
}

.insight-card {
  display: block;
  width: 100%;
  background-color: #fff;
  border: 1px solid #2354E8;
  padding: 1.25em 1.5625em;
  color: #3F3F3F;
  min-height: 17.5em;
  height: 99%;
}
.insight-card_transition-in {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.insight-card:focus {
  box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.15);
  color: #2354E8;
  border-color: transparent;
}
.insight-card:focus .insight-card__meta {
  color: #3F3F3F;
}
.insight-card--has-tile-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: center;
  border-color: transparent;
  display: flex;
  flex-direction: column;
  transition: transform 0.35s ease-out;
}
.insight-card__meta {
  display: block;
  font-size: 0.875em;
  color: #2354E8;
}
.insight-card__meta:not(.insight-card__meta--placeholder):after {
  content: "";
  display: block;
  width: 1.0714285714em;
  height: 0.1428571429em;
  background-color: #2354E8;
  margin-top: 1.7142857143em;
  margin-bottom: 1.7142857143em;
}
.insight-card__date:after {
  content: "-";
  margin-left: 0.5em;
  margin-right: 0.25em;
}
.insight-card__date:last-child:after {
  content: none;
}
.insight-card__title {
  font-size: 1.125em;
  font-weight: 300;
  display: block;
  margin-bottom: auto;
}
.insight-card--has-tile-image .insight-card__title {
  font-size: 28px;
  color: #fff;
  text-align: center;
}
.insight-card__watch {
  padding-top: 1em;
  margin-top: auto;
  cursor: pointer;
}
.insight-card__watch .button-primary {
  padding: 0.75em 1.25em;
  text-align: left;
  min-width: 0;
}
.insight-card__watch .button-primary:hover .button-primary__label:after {
  border-left: 10px solid #fff;
}
.insight-card__watch .button-primary__label:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #2354E8;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
  transition: 0.3s ease-in-out all;
}
@media screen and (min-width: 48em) {
  .insight-card {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 21.875em;
    padding: 30px 35px;
    transition: color 0.35s ease-out, border-color 0.35s ease-out, box-shadow 0.35s ease-out;
  }
  .insight-card__meta {
    flex-shrink: 1;
    transition: color 0.35s ease-out, border-color 0.35s ease-out, box-shadow 0.35s ease-out;
  }
  .insight-card__title {
    font-size: 1.25em;
    margin-bottom: auto;
  }
  .insight-card:hover, .insight-card:focus {
    box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.15);
    color: #2354E8;
    border-color: transparent;
  }
  .insight-card:hover.insight-card--has-tile-image, .insight-card:focus.insight-card--has-tile-image {
    transform: scale(1.005);
  }
  .insight-card:hover .insight-card__meta, .insight-card:focus .insight-card__meta {
    color: #3F3F3F;
  }
}

.prominent-card {
  display: block;
  width: 100%;
  padding: 1.25em 1.5625em;
  color: #fff;
  background-color: #2354E8;
  overflow: hidden;
  position: relative;
  z-index: 1;
  min-height: 17.5em;
  height: 100%;
}
.prominent-card__meta {
  display: block;
  font-size: 0.875em;
  position: relative;
  z-index: 5;
}
.prominent-card__meta:after {
  content: "";
  display: block;
  width: 1.0714285714em;
  height: 0.1428571429em;
  background-color: #2354E8;
  margin-top: 1.7142857143em;
  margin-bottom: 1.7142857143em;
}
.prominent-card__date:after {
  content: "-";
  margin-left: 0.5em;
  margin-right: 0.25em;
}
.prominent-card__title {
  font-size: 1.25em;
  font-weight: 300;
  display: block;
  position: relative;
  z-index: 5;
}
.prominent-card__wave {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #2354E8;
}
.prominent-card__details {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media screen and (min-width: 62em) {
  .prominent-card__details {
    flex-wrap: nowrap;
  }
}
.prominent-card__button {
  flex: 0 0 auto;
  background-color: #fff;
  z-index: 1;
  padding: 0.75em 1.25em;
  text-align: left;
  min-width: 0;
  margin-top: 1em;
  margin-left: 0;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out all;
}
@media screen and (min-width: 62em) {
  .prominent-card__button {
    margin-left: 1em;
    margin-top: 0;
  }
}
.prominent-card__button:hover {
  border: 1px solid #fff;
}
.prominent-card__button:hover .button-primary__label:after {
  border-left: 10px solid #fff;
}
.prominent-card__button .button-primary__label:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #2354E8;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
  transition: 0.3s ease-in-out all;
}
@media screen and (min-width: 48em) {
  .prominent-card {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: 21.875em;
    padding: 30px 35px;
    transition: color 0.35s ease-out, border-color 0.35s ease-out, box-shadow 0.35s ease-out;
  }
  .prominent-card__meta {
    flex-shrink: 1;
    transition: color 0.35s ease-out, border-color 0.35s ease-out, box-shadow 0.35s ease-out;
  }
  .prominent-card__title {
    font-size: 1.75em;
  }
  .prominent-card:hover {
    box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.15);
  }
}

.team-card {
  display: block;
  margin: 0 1px;
}
.team-card__figure {
  max-width: 15.625em;
  position: relative;
}
.team-card__img-placeholder {
  width: 15.625em;
  height: 0;
  padding-top: 100%;
  background-color: #fff;
  border-right: 1px solid #E3E3E3;
  border-left: 1px solid #E3E3E3;
  border-bottom: 1px solid #E3E3E3;
}
.team-card__img {
  display: block;
  width: 100%;
  max-width: 100%;
}
.team-card__info {
  background-color: #fff;
  padding: 1em;
  border: 1px solid #E3E3E3;
  border-top: 0;
  max-width: 250px;
  height: 100%;
}
.team-card__name {
  font-size: 0.875em;
  font-weight: 700;
  color: #2354E8;
  transition: color 0.35s ease-out;
  word-wrap: break-word;
}
.team-card__service {
  font-size: 0.75em;
  display: block;
  transition: color 0.35s ease-out;
}
@media screen and (min-width: 34.375em) {
  .team-card + .team-card {
    border-right: 1px solid #E3E3E3;
  }
  .team-card + .team-card .team-card__name {
    border-left: 0 none;
  }
}
@media screen and (min-width: 48em) {
  .team-card__info {
    position: relative;
  }
  .team-card__info:after {
    transition: top 0.35s ease-out;
    content: "";
    display: block;
    background-color: #2354E8;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    bottom: 0;
  }
  .team-card__name, .team-card__service {
    position: relative;
    z-index: 1;
  }
  .team-card:hover .team-card__info {
    color: #fff;
  }
  .team-card:hover .team-card__info:after {
    top: 0;
  }
  .team-card:hover .team-card__name {
    color: #fff;
  }
}
@media screen and (min-width: 62em) {
  .team-card {
    border-right: 1px solid #E3E3E3;
  }
  .team-card__figure {
    max-width: 17.8125em;
  }
  .team-card__img-placeholder {
    width: 17.8125em;
  }
  .team-card__name {
    font-size: 1em;
  }
  .team-card__service {
    font-size: 0.875em;
  }
  .team-card__info {
    max-width: 285px;
  }
}

.accordion + .accordion {
  margin-top: 1px;
}
.accordion__heading {
  background-color: #1C1C1C;
  font-size: 1em;
  padding: 1em;
  padding-right: 3.3125em;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
}
.accordion__title {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  line-height: 1.2;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .accordion__title {
    font-size: 1.5em;
  }
}
.accordion__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  right: 1em;
  text-align: center;
  color: #fff;
  width: 1.5625em;
  height: 1.5625em;
  margin-top: 0.390625em;
}
.accordion__icon-diamond {
  position: absolute;
  top: -0.234375em;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #7C7C7C;
  transform: rotate(45deg);
  overflow: hidden;
}
.accordion .icon-close:before {
  transition: transform 0.35s ease-out;
  display: inline-block;
  transform: rotate(45deg);
  position: relative;
  z-index: 2;
}
.accordion__body {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0.35s ease-out 0.35s, opacity 0.35s ease-out;
  padding-left: 1em;
  padding-right: 1em;
}
.accordion__body .rte {
  padding-top: 1.375em;
  padding-bottom: 1.375em;
}
.accordion--is-expanded .accordion__body {
  opacity: 1;
  transition: height 0.35s ease-out, opacity 0.35s ease-out 0.35s;
}
.accordion--is-expanded .accordion__heading {
  background-color: #fff;
  border-color: #E3E3E3;
}
.accordion--is-expanded .accordion__title {
  color: #1C1C1C;
}
.accordion--is-expanded .accordion__icon {
  transition: color 0.35s ease-out;
  color: #1C1C1C;
}
.accordion--is-expanded .accordion__icon:before {
  transform: rotate(0deg);
}
@media screen and (min-width: 48em) {
  .accordion__heading {
    transition: background-color 0.35s ease-out;
    padding: 16px 32px;
    min-height: 6.25em;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
  }
  .accordion__heading:hover {
    background-color: #2354E8;
  }
  .accordion__heading:hover .accordion__icon-diamond {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
  .accordion__heading:hover .accordion__icon-diamond:after {
    top: -25%;
    left: -25%;
  }
  .accordion__heading:hover .accordion__icon {
    color: #1C1C1C;
  }
  .accordion__title {
    max-width: 11.25em;
  }
  .accordion__icon {
    transition: color 0.35s ease-out;
    right: 32px;
    width: 2.5em;
    height: 2.5em;
    padding-top: 0.3125em;
    transition-delay: 0.35s;
  }
  .accordion .icon-close:before {
    font-size: 1.375em;
  }
  .accordion__icon-diamond {
    transition: box-shadow 0.35s ease-out;
    transition-delay: 0.35s;
  }
  .accordion__icon-diamond:after {
    transition: top 0.35s ease-out, left 0.35s ease-out;
    content: "";
    display: block;
    position: absolute;
    width: 150%;
    height: 150%;
    transform: rotate(-45deg);
    background-color: #fff;
    top: 85%;
    left: 85%;
    z-index: -1;
  }
  .accordion__body {
    padding-left: 0;
    padding-right: 0;
  }
  .accordion__body .rte {
    padding-top: 3.25em;
    padding-bottom: 3.25em;
  }
  .accordion__read-more-wrapper {
    padding-top: 3.25em;
    padding-bottom: 3.25em;
  }
  .accordion__read-more-wrapper .rte {
    padding-top: 0;
    padding-bottom: 0;
  }
  .accordion--is-expanded .accordion__heading:hover {
    background-color: #E3E3E3;
  }
  .accordion--is-expanded .accordion__heading:hover .accordion__title {
    color: #1C1C1C;
  }
}
@media screen and (min-width: 62em) {
  .accordion__body {
    margin-left: 18.875em;
  }
}

.profile-bar {
  transition: transform 0.35s ease-out;
  background-color: #fff;
  min-height: 3.75em;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 85;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  will-change: transform;
  transform: translateY(-100%);
}
.profile-bar:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  backface-visibility: hidden;
  box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.2);
}
.profile-bar--sticky {
  transform: translateY(0);
}
.profile-bar__figure {
  width: 3.75em;
  position: relative;
  margin: 0 auto;
  flex-shrink: 0;
}
.profile-bar__img {
  width: 100%;
  display: block;
  backface-visibility: hidden;
}
.profile-bar__info {
  flex-grow: 1;
  padding: 0.5em 1em;
  line-height: 1.2;
}
.profile-bar__name {
  font-family: "Playfair Display", serif;
  font-size: 1.125em;
}
.profile-bar__email {
  display: none;
  font-size: 0.875em;
  color: #2354E8;
}
.profile-bar__jump-nav {
  transition: transform 0.35s ease-out;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-top: 1px solid #E3E3E3;
  transform: translateY(-100%);
  z-index: -2;
}
.profile-bar__jump-link {
  color: #2354E8;
  font-size: 0.875em;
  width: 100%;
  text-align: left;
  border: 1px solid #E3E3E3;
  border-width: 0 1px 1px;
  padding: 0.7142857143em 1.4285714286em;
}
.profile-bar__nav-toggle {
  margin-right: 1.25em;
  font-size: 0.875em;
  color: #2354E8;
  padding-right: 1.4285714286em;
  position: relative;
}
.profile-bar__nav-toggle:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.35s ease-out;
  font-size: 1.1428571429em;
  right: 0;
}
.profile-bar--show-dropdown .profile-bar__nav-toggle:after {
  transform: rotateX(180deg) translateY(50%);
}
.profile-bar--show-dropdown .profile-bar__jump-nav {
  transform: translateY(0);
}
@media screen and (min-width: 48em) {
  .profile-bar {
    min-height: 6.25em;
  }
  .profile-bar:before {
    box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.2);
  }
  .profile-bar__info {
    flex-grow: 1;
    padding: 1em 1.375em;
    line-height: inherit;
  }
  .profile-bar__figure {
    width: 6.25em;
  }
  .profile-bar__name {
    font-size: 1.5em;
  }
  .profile-bar__email {
    display: inline-block;
    transition: color 0.35s ease-out;
  }
  .profile-bar__email:hover {
    color: #505050;
  }
  .profile-bar__jump-nav {
    position: static;
    top: auto;
    left: auto;
    flex-shrink: 0;
    padding-right: 2em;
    transform: none;
    z-index: 1;
    border-top: 0 none;
  }
  .profile-bar__nav-toggle {
    display: none;
  }
  .profile-bar__jump-link {
    padding: 0 0 0 2.5em;
    position: relative;
    width: auto;
    border: 0 none;
    transition: color 0.35s ease-out;
  }
  .profile-bar__jump-link:hover {
    color: #7C7C7C;
  }
  .profile-bar__jump-link--active {
    color: #7C7C7C;
  }
  .profile-bar__jump-link:after {
    content: "";
    top: 49%;
    position: absolute;
    left: -0.2em;
    border-top: 1px solid #7C7C7C;
    width: 1.7857142857em;
    margin: 0 0.3571428571em;
  }
  .profile-bar__jump-link:first-child {
    padding-left: 0;
  }
  .profile-bar__jump-link:first-child:after {
    display: none;
  }
  .profile-bar__jump-link__is-active {
    color: #7C7C7C;
  }
}

.section-accordion {
  background-color: transparent;
  margin-bottom: 1px;
}
@media (max-width: 47.9375em) {
  .section-accordion--combined-on-mobile + .section-accordion--combined-on-mobile {
    padding-top: 0;
  }
  .section-accordion--combined-on-mobile:not(:last-child) {
    padding-bottom: 0;
  }
}
.section-accordion__toggle {
  background-color: #1C1C1C;
  border: 1px solid transparent;
}
.section-accordion__label {
  color: #fff;
  padding: 1em;
  padding-right: 2.75em;
  position: relative;
  width: 100%;
  text-align: left;
  line-height: 1.2;
}
.section-accordion__label br {
  display: none;
}
.section-accordion__content {
  height: 0;
  overflow: hidden;
}
.section-accordion__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.8em;
  display: inline-block;
  right: 1em;
  text-align: center;
  color: #fff;
  width: 1.5625em;
  height: 1.5625em;
  margin-top: 0.390625em;
}
.section-accordion__icon-diamond {
  position: absolute;
  top: -0.234375em;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #7C7C7C;
  transform: rotate(45deg);
  overflow: hidden;
}
.section-accordion .icon-close:before {
  transition: transform 0.35s ease-out;
  display: inline-block;
  transform: rotate(45deg);
  position: relative;
  z-index: 2;
}
.section-accordion .titles__subheading {
  padding-bottom: 0;
}
.section-accordion .titles__subheading:after {
  display: none;
}
.section-accordion__toggle--is-expanded {
  background-color: #fff;
  border-color: #E3E3E3;
}
.section-accordion__toggle--is-expanded .section-accordion__label {
  color: #1C1C1C;
}
.section-accordion__toggle--is-expanded .section-accordion__icon {
  transition: color 0.35s ease-out;
  color: #1C1C1C;
}
.section-accordion__toggle--is-expanded .section-accordion__icon:before {
  transform: rotate(0deg);
}
@media screen and (min-width: 48em) {
  .section-accordion {
    margin-bottom: 0;
  }
  .section-accordion__content {
    height: auto !important;
    overflow: visible;
  }
  .section-accordion__toggle {
    background-color: transparent;
    border: 0 none;
  }
  .section-accordion__label {
    color: inherit;
    padding: 0;
    padding-right: 0;
    position: static;
    cursor: text;
  }
  .section-accordion__label br {
    display: block;
  }
  .section-accordion__icon {
    display: none;
  }
  .section-accordion .titles__subheading:after {
    display: block;
  }
}

.listing-services {
  margin-bottom: 1.375em;
}
.listing-services + .listing-services {
  margin-top: 1.375em;
}
.listing-services__heading {
  font-family: "Rubik", sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.5em;
}
@media screen and (min-width: 48em) {
  .listing-services__heading {
    font-size: 1.125em;
  }
}
.listing-services__item {
  display: inline;
}
.listing-services__item:after {
  content: "/";
  margin-left: 0.5em;
  margin-right: 0.25em;
  color: #7C7C7C;
}
.listing-services__item:last-child:after {
  display: none;
}
@media screen and (min-width: 48em) {
  .listing-services {
    margin-bottom: 0;
  }
  .listing-services + .listing-services {
    margin-top: 2em;
  }
  .listing-services__link {
    font-size: 1.125em;
  }
}

.expandable-services {
  position: relative;
  margin-left: -1.25em;
  margin-right: -1.25em;
}
.expandable-services--is-expanded .expandable-services__toggle:before {
  content: "—";
}
.expandable-services .listing-services {
  opacity: 0;
}
.expandable-services--is-expanded .listing-services {
  opacity: 1;
  transition: opacity 0.35s ease-out;
  transition-delay: 0.3s;
}
.expandable-services__toggle {
  color: #2354E8;
  font-size: 0.875em;
  margin-top: 1.375em;
  position: relative;
  z-index: 2;
}
.expandable-services__toggle:before {
  content: "+";
  display: inline-block;
  width: 1em;
  text-align: left;
}
.expandable-services .listing-services {
  margin-top: 1.375em;
  padding-left: 1.25em;
  padding-right: 1.25em;
}
.expandable-services .listing-services__heading {
  color: #1C1C1C;
}
.expandable-services .listing-services__link {
  color: #2354E8;
  font-size: 0.875em;
}
.expandable-services__body {
  transition: height 0.35s ease-out;
  overflow: hidden;
  height: 0;
}
@media screen and (min-width: 48em) {
  .expandable-services__toggle {
    color: #7090F0;
    font-size: 1.125em;
    position: absolute;
    margin-top: -4em;
    transform: translateY(-100%);
  }
  .expandable-services .listing-services {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .expandable-services .listing-services__link {
    color: #7090F0;
  }
  .expandable-services .listing-services + .listing-services {
    margin-top: 22px;
  }
  .expandable-services .listing-services:last-child {
    padding-bottom: 65px;
  }
  .expandable-services .listing-services__heading {
    color: #fff;
  }
  .expandable-services .listing-services__link {
    font-size: 1.125em;
  }
}
@media screen and (min-width: 62em) {
  .expandable-services .listing-services__heading {
    padding-right: 1em;
    display: inline-block;
  }
  .expandable-services .listing-services__items {
    display: inline-block;
  }
}

.page-heading {
  position: relative;
  padding-top: 5.125em;
  background-color: #1C1C1C;
}
.page-heading__background {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
}
.page-heading__background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
.page-heading__body {
  padding-bottom: 4.0625em;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.page-heading__cta {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #7090F0;
  display: inline-block;
  margin-top: 1.375em;
}
@media screen and (min-width: 48em) {
  .page-heading__cta:hover {
    color: #fff;
  }
}
.page-heading__cta:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e906";
  transition: transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translateX(0.3125em);
  vertical-align: middle;
}
.page-heading__title {
  font-size: 2em;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .page-heading__title {
    font-size: 4em;
  }
}
.page-heading__options {
  margin-top: 2em;
}
.page-heading--search {
  padding-bottom: 2em;
}
.page-heading__summary {
  font-size: 0.875em;
  margin-top: 1.5714285714em;
}
.page-heading .jumpto-nav {
  margin: 2em auto 0;
  max-width: 18.75em;
}
.page-heading--summary-layout {
  z-index: 10;
}
@media screen and (min-width: 34.375em) {
  .page-heading__header {
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
    width: 100%;
  }
  .page-heading__title {
    max-width: 72%;
  }
  .page-heading__related-cta {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    padding-top: 0.5em;
  }
  .page-heading__related-cta:before {
    content: "";
    border-top: 1px solid #7C7C7C;
    display: block;
    width: 2em;
    flex-grow: 1;
    min-width: 6.25em;
    margin-left: 1em;
    margin-right: 1em;
  }
  .page-heading__cta {
    flex-shrink: 0;
    margin-top: 0;
  }
  .page-heading__cta:hover:after {
    transform: translateX(0.625em);
  }
  .page-heading__body {
    text-align: left;
  }
  .page-heading .jumpto-nav {
    margin-left: 0;
    margin-right: 0;
    max-width: 37.5em;
  }
}
@media screen and (min-width: 48em) {
  .page-heading__body {
    padding-bottom: 4.0625em;
    padding-top: 4.0625em;
    min-height: 21.875em;
    height: 100px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .page-heading__details {
    width: 100%;
  }
  .page-heading__summary {
    font-size: 1em;
    line-height: 1.75;
    margin-top: 2em;
    max-width: 40.625em;
  }
  .page-heading--summary-layout .page-heading__body {
    flex-wrap: wrap;
    align-content: center;
  }
}
@media screen and (min-width: 62em) {
  .page-heading--summary-layout .page-heading__header {
    width: auto;
  }
  .page-heading--summary-layout .page-heading__body {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .page-heading__utility {
    margin-top: 0;
    width: 12.5em;
    flex-shrink: 0;
  }
  .page-heading__options {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-top: 2em;
  }
  .page-heading__details {
    width: 50%;
    max-width: 40.625em;
  }
  .page-heading__summary {
    margin-top: 0;
  }
}

.page-heading-overview {
  position: relative;
  padding-top: 5.125em;
  background-color: #1C1C1C;
}
.page-heading-overview__background {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
}
.page-heading-overview__background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
.page-heading-overview__body {
  padding-bottom: 4.0625em;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.page-heading-overview__title {
  font-size: 2em;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .page-heading-overview__title {
    font-size: 4em;
  }
}
.page-heading-overview__copy {
  margin-top: 2em;
}
.page-heading-overview--theme-coral .page-heading-overview__title {
  color: #FF715B;
}
@media screen and (min-width: 34.375em) {
  .page-heading-overview__body {
    text-align: left;
  }
}
@media screen and (min-width: 48em) {
  .page-heading-overview__body {
    padding-bottom: 4.0625em;
    padding-top: 4.0625em;
  }
  .page-heading-overview .read-more__button {
    margin-top: 2.75em;
  }
}
@media screen and (min-width: 62em) {
  .page-heading-overview__body {
    padding-bottom: 6.25em;
    padding-top: 6.25em;
  }
}

.featured-banner {
  background-color: #1C1C1C;
  position: relative;
  overflow: hidden;
  padding-top: 40vh;
}
.featured-banner__date {
  color: #7C7C7C;
  display: block;
  margin-bottom: 0.625em;
}
.featured-banner__title {
  transition: color 0.35s ease-out;
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .featured-banner__title {
    font-size: 2.375em;
  }
}
.featured-banner__button {
  margin-top: 1.25em;
}
.featured-banner__button .button {
  padding: 1em;
}
.featured-banner__body {
  padding-top: 2em;
  padding-bottom: 4.0625em;
  text-align: center;
}
.featured-banner__figure {
  height: 40vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  overflow: hidden;
}
.featured-banner__figure:after {
  content: "";
  background-image: linear-gradient(to bottom, rgba(68, 96, 219, 0), rgb(35, 84, 232));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
}
.featured-banner__img {
  min-height: 100%;
  width: 100%;
  transition: transform 1.05s ease-out;
}
.featured-banner__img--has-mobile-image {
  display: none;
}
.featured-banner__mobile-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
}
.featured-banner__triangle {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: block;
  width: 100%;
}
.featured-banner__triangle:before, .featured-banner__triangle:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  backface-visibility: hidden;
  bottom: 0;
}
.featured-banner__triangle:before {
  border-right: 50vw solid transparent;
  border-bottom: 10vw solid #1C1C1C;
  left: 0;
}
.featured-banner__triangle:after {
  border-left: 50vw solid transparent;
  border-bottom: 10vw solid #1C1C1C;
  right: 0;
}
@media screen and (min-width: 48em) {
  .featured-banner {
    padding: 5.078125em 0;
  }
  .featured-banner__figure {
    transition: height 0.35s cubic-bezier(0.86, 0, 0.07, 1);
    width: 33vw;
    height: 100%;
    position: absolute;
    right: auto;
  }
  .featured-banner__img {
    width: auto;
    height: 115.5%;
  }
  .featured-banner__img--has-mobile-image {
    display: block;
  }
  .featured-banner__mobile-image {
    display: none;
  }
  .featured-banner__triangle {
    position: static;
  }
  .featured-banner__triangle:before, .featured-banner__triangle:after {
    z-index: 5;
  }
  .featured-banner__triangle:before {
    border-left: 7.8125em solid transparent;
    border-top: 37.5em solid #1C1C1C;
    border-right-width: 0;
    border-bottom-width: 0;
    bottom: 50%;
    left: auto;
    right: 0;
  }
  .featured-banner__triangle:after {
    top: 50%;
    border-left-width: 7.8125em;
    border-bottom-width: 37.5em;
  }
  .featured-banner__body {
    padding-bottom: 4.0625em;
    padding-top: 4.0625em;
    min-height: 21.875em;
    text-align: left;
  }
  .featured-banner__date, .featured-banner__title, .featured-banner__button {
    margin-left: 40%;
  }
  .featured-banner__title {
    display: inline-block;
  }
  .featured-banner__title:hover {
    color: #7090F0;
  }
  .featured-banner__title:hover + .featured-banner__figure:after {
    top: 0;
  }
  .featured-banner__title:hover + .featured-banner__figure .featured-banner__img {
    transform: scale(1.05);
  }
}
@media screen and (min-width: 62em) {
  .featured-banner__date, .featured-banner__title, .featured-banner__button {
    margin-left: 35%;
  }
}
@media screen and (min-width: 85.375em) {
  .featured-banner__img {
    width: 115.5%;
    height: auto;
  }
}

.infographic-card {
  padding: 2em 1.25em;
  border: 1px solid #979797;
  background-color: #fff;
  text-align: center;
  width: 100%;
  min-height: 17.5em;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
.infographic-card__heading {
  width: 100%;
}
.infographic-card__img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  margin-bottom: 1.25em;
}
.infographic-card__info {
  font-size: 1.125em;
  font-weight: 300;
  max-width: 19.4444444444em;
  margin: 0 auto;
}
a.infographic-card {
  border-color: #2354E8;
}

.infographic-card--intro {
  border: 0 none;
  text-align: left;
  min-height: 0;
}
.infographic-card--intro .infographic-card {
  border: 0 none;
  text-align: left;
}
.infographic-card--intro .infographic-card__info {
  max-width: none;
}
.infographic-card__heading {
  font-size: 1.5em;
  line-height: 1.2;
  color: #2354E8;
}
.infographic-card__heading--external-link:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90b";
  font-size: 1em;
  padding-left: 0.4em;
}
.infographic-card__heading--external-link::after {
  font-size: 0.5em;
}
.infographic-card__heading + .infographic-card__info {
  margin-top: 1.375em;
}
@media screen and (min-width: 48em) {
  .infographic-card {
    padding: 2.75em 1.25em;
    transition: color 0.35s ease-out, border-color 0.35s ease-out, box-shadow 0.35s ease-out;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    min-height: 21.875em;
  }
  .infographic-card__heading {
    font-size: 2em;
  }
  .infographic-card__figure {
    width: 100%;
  }
  .infographic-card__img {
    margin-bottom: 4.0625em;
  }
  .infographic-card__info {
    font-size: 1.25em;
    margin: 0 auto;
    width: 100%;
  }
  a.infographic-card:hover, a.infographic-card:focus {
    box-shadow: 0 10px 30px 5px rgba(0, 0, 0, 0.15);
    color: #2354E8;
    border-color: transparent;
  }
}

.dropdown-custom {
  position: relative;
}
.dropdown-custom__toggle {
  width: 100%;
}
.dropdown-custom .button-utility__label:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  transition: transform 0.35s ease-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.dropdown-custom__link .dropdown-custom__list-item {
  color: #fff;
}
.dropdown-custom__list {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  text-align: left;
  border: 1px solid #505050;
  border-top-width: 0;
}
.dropdown-custom__list-item {
  padding: 0.9375em 1.375em;
  background-color: #3F3F3F;
  cursor: pointer;
}
.dropdown-custom__list-item:hover {
  color: #3F3F3F;
  background-color: #fff;
}
.dropdown-custom__list-item + .dropdown-custom__list-item {
  border-top: 1px solid #505050;
}
.dropdown-custom--show-dropdown .dropdown-custom__list {
  visibility: visible;
  opacity: 1;
}
.dropdown-custom--show-dropdown .button-utility__label:after {
  transform: rotateX(180deg) translateY(50%);
}
@media screen and (min-width: 48em) {
  .dropdown-custom__list-item {
    padding: 0.9375em 1.375em;
  }
}

.cta-outro {
  text-align: center;
  background-position: center;
  background-size: cover;
}
.cta-outro__link--external:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90b";
  padding-left: 1em;
  transform: translateY(0%);
}
@media screen and (min-width: 48em) {
  .cta-outro__link {
    padding-left: 2em;
    padding-right: 2em;
  }
}

.language-dropdown--profile--mobile {
  margin: auto;
  margin-top: 1.375em;
}
@media screen and (min-width: 34.375em) {
  .language-dropdown--profile--mobile {
    width: 100%;
    max-width: 16.25em;
  }
}
@media screen and (min-width: 48em) {
  .language-dropdown--profile--mobile {
    margin-left: 0;
    width: auto;
  }
}
@media screen and (min-width: 62em) {
  .language-dropdown--profile--mobile {
    display: none;
  }
}
.language-dropdown--profile--desktop {
  display: none;
}
@media screen and (min-width: 62em) {
  .language-dropdown--profile--desktop {
    display: block;
    position: absolute;
    transform: translateY(-100%);
    margin-top: -4em;
    right: 0;
  }
}
.language-dropdown--profile--desktop.dropdown-custom--show-dropdown .dropdown-custom__list, .language-dropdown--profile--mobile.dropdown-custom--show-dropdown .dropdown-custom__list {
  z-index: 7;
}
.language-dropdown--services {
  margin-top: 1.375em;
}
@media screen and (min-width: 34.375em) {
  .language-dropdown--services {
    width: 100%;
    max-width: 16.25em;
    margin-left: auto;
  }
}

.general-listing + .general-listing {
  border-top: 1px solid #979797;
  padding-top: 2em;
  margin-top: 2em;
}
.general-listing__grid-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}
.general-listing__grid-item + .general-listing__grid-item {
  margin-top: 1.2em;
}
.general-listing__meta {
  font-size: 1.125em;
  font-weight: 300;
}
.general-listing__meta:not(:last-child):after {
  content: "/";
  margin: 0 0.5em;
}
.general-listing__details, .general-listing__name {
  flex-grow: 1;
}
.general-listing__name {
  font-family: "Rubik", sans-serif;
  font-size: 1.375em;
  font-weight: 300;
}
.general-listing__name-link {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .general-listing__name-link:hover {
    color: #505050;
  }
}
@media screen and (min-width: 34.375em) {
  .general-listing {
    columns: 2 auto;
    column-gap: 2em;
  }
  .general-listing__grid-item {
    position: relative;
    break-inside: avoid;
    overflow: hidden;
  }
  .general-listing__grid-item:first-child:last-child {
    width: 150%;
  }
}
@media screen and (min-width: 62em) {
  .general-listing + .general-listing {
    margin-top: 1em;
  }
  .general-listing__meta {
    font-size: 1.25em;
  }
}

.general-subnavigation.section__dark-bg {
  background-color: transparent;
  padding-top: 0;
}
.general-subnavigation.section__dark-bg .general-subnavigation__grid-item:last-child .button {
  border-bottom: 1px solid #fff;
}
.general-subnavigation.section__dark-bg .titles__subheading {
  color: #fff;
}
.general-subnavigation.section__dark-bg .titles__subheading:after {
  background-color: #fff;
}
.general-subnavigation.section__dark-bg .button {
  border-color: #fff;
  background-color: transparent;
}
.general-subnavigation.section__dark-bg .button:before {
  background-color: transparent;
}
.general-subnavigation.section__dark-bg .button .button-primary__label {
  color: #fff;
}
.general-subnavigation.section__dark-bg .button__active .button-primary__label {
  color: #7C7C7C;
}
.general-subnavigation.section__dark-bg .general-subnavigation__listing {
  background-color: #1C1C1C;
}
.general-subnavigation.section__dark-bg .general-subnavigation__nav-toggle {
  background-color: #fff;
  color: #1C1C1C;
}
.general-subnavigation.section__dark-bg .general-subnavigation__nav-link {
  color: #fff;
}
.general-subnavigation.section__dark-bg .general-subnavigation__nav-link--active {
  color: #7C7C7C;
}
.general-subnavigation__nav {
  position: relative;
}
.general-subnavigation__listing {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  width: 100%;
  z-index: 15;
}
.general-subnavigation__listing--no-dropdown {
  opacity: 1;
  visibility: visible;
  position: relative;
  top: 0;
  left: 0;
  background-color: transparent;
}
.general-subnavigation__secondary-listing {
  margin-top: 32px;
}
.general-subnavigation__secondary-grid-item {
  margin-bottom: 0.625em;
}
.general-subnavigation__grid-item {
  display: block;
}
.general-subnavigation__grid-item:last-child {
  margin-right: 0;
}
.general-subnavigation__grid-item:last-child .button {
  border-bottom: 1px solid #2354E8;
}
.general-subnavigation__grid-item .button {
  width: 100%;
  min-width: 100px;
  padding: 1.0714285714em 1.4285714286em;
  z-index: 1;
  display: block;
  border-bottom: none;
  text-align: left;
}
.general-subnavigation__grid-item .button:before {
  display: none;
}
.general-subnavigation__grid-item .button__active {
  box-shadow: none;
}
.general-subnavigation__grid-item .button__active .button-primary__label {
  color: #1C1C1C;
}
.general-subnavigation__grid-item--no-dropdown {
  margin-bottom: 0.625em;
}
.general-subnavigation__grid-item--no-dropdown .button {
  text-align: center;
  border-bottom: 1px solid #2354E8;
}
.general-subnavigation__nav-toggle {
  display: block;
  position: relative;
  background-color: #2354E8;
  color: #fff;
  width: 100%;
  text-align: left;
  padding: 1.0714285714em 1.4285714286em;
  padding-right: 1.4285714286em;
  z-index: 1;
}
.general-subnavigation__nav-toggle:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.35s ease-out;
  right: 1.0714285714em;
}
.general-subnavigation__nav--show-dropdown .general-subnavigation__listing {
  opacity: 1;
  visibility: visible;
}
.general-subnavigation__nav--show-dropdown .general-subnavigation__nav-toggle:after {
  transform: rotateX(180deg) translateY(50%);
}
.general-subnavigation__nav-link {
  font-size: 18px;
  text-transform: uppercase;
  color: #2354E8;
  margin-right: 2.5em;
}
.general-subnavigation__nav-link--active {
  color: #1C1C1C;
}
@media screen and (min-width: 34.375em) {
  .general-subnavigation.section__dark-bg .button {
    border-bottom: 1px solid #fff;
  }
  .general-subnavigation.section__dark-bg .button:before {
    background-color: #fff;
  }
  .general-subnavigation.section__dark-bg .button__active {
    background-color: #fff;
  }
  .general-subnavigation.section__dark-bg .button__active .button-primary__label {
    color: #1C1C1C;
  }
  .general-subnavigation__listing {
    display: block;
    position: static;
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    width: 100%;
  }
  .general-subnavigation__secondary-grid-item {
    display: inline-block;
  }
  .general-subnavigation__grid-item {
    display: inline-block;
    margin-right: 1.25em;
    margin-bottom: 0.625em;
  }
  .general-subnavigation__grid-item .button {
    width: auto;
    border-bottom: 1px solid #2354E8;
    text-align: center;
    padding: 0.9375em 1.625em;
  }
  .general-subnavigation__grid-item .button:before {
    display: block;
  }
  .general-subnavigation__grid-item .button__active {
    background-color: #2354E8;
  }
  .general-subnavigation__grid-item .button__active .button-primary__label {
    color: #fff;
  }
  .general-subnavigation__nav-toggle {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  .general-subnavigation.section__dark-bg .button:hover .button-primary__label {
    color: #1C1C1C;
  }
  .general-subnavigation.section__dark-bg .general-subnavigation__nav-link:hover {
    color: #7C7C7C;
  }
  .general-subnavigation__grid-item .button:hover .button-primary__label {
    color: #fff;
    transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .general-subnavigation__nav-link {
    transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .general-subnavigation__nav-link:hover {
    color: #1C1C1C;
  }
}

.normalized-rte-list {
  margin-bottom: 0.5em;
}
.normalized-rte-list:after {
  content: "";
  display: block;
  width: 1.875em;
  height: 1px;
  background-color: #7C7C7C;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.normalized-rte-list:last-child:after {
  display: none;
}
.normalized-rte-list__item:not(.normalized-rte-list__item--no-comma):not(:last-child):after {
  content: ", ";
}
.normalized-rte-list__item p {
  margin: 0;
}
.normalized-rte-list__item:not(.normalized-rte-list__item--edu-details) p {
  display: inline;
}

.button-listing {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.button-listing + .button-listing {
  border-top: 1px solid #979797;
  padding-top: 2em;
  margin-top: 2em;
}
.button-listing__grid-item {
  margin-right: 2em;
}
@media screen and (min-width: 34.375em) {
  .button-listing {
    columns: 2 auto;
    column-gap: 2em;
  }
}
.typeahead {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
}
.typeahead--show {
  display: block;
  z-index: 10;
}
.typeahead__list {
  border: 1px solid #505050;
  border-top: none;
}
.typeahead__list-item {
  padding: 0.9375em 1.375em;
  cursor: pointer;
}
.typeahead__list-item:hover {
  color: #2354E8;
}
.typeahead__name {
  word-break: break-word;
}
@media screen and (min-width: 48em) {
  .typeahead {
    padding-right: 10px;
  }
  .typeahead__list-item {
    padding-left: 30px;
  }
}

.media-module__lead-media {
  padding-bottom: 2em;
  border-bottom: 1px solid #E3E3E3;
}
.media-module__lead-media-wrapper {
  position: relative;
  display: inline-block;
  max-width: 100%;
}
.media-module__lead-media-wrapper > img, .media-module__lead-media-wrapper video, .media-module__lead-media-wrapper iframe, .media-module__lead-media-wrapper embed {
  max-width: 100%;
  display: block;
}
.media-module__lead-media-wrapper--video {
  display: block;
}
.media-module__lead-media-caption {
  font-weight: 300;
  font-style: italic;
  margin-top: 1.375em;
}
.media-module.section:nth-child(2n+1) .media-module__lead-media {
  border-bottom: 1px solid #979797;
}

.back-to-top__container {
  position: fixed;
  right: 0.8em;
  bottom: 1.5em;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.back-to-top__container--active {
  opacity: 100;
}
.back-to-top__label {
  text-transform: uppercase;
  font-size: 0.75em;
  color: #7C7C7C;
  display: none;
  margin-top: 0.4em;
}
.back-to-top__button {
  text-align: center;
}
.back-to-top__button .back-to-top__svg rect {
  transition: fill 0.35s ease-out, stroke 0.3s ease-in-out;
}
.back-to-top__button .back-to-top__svg path {
  transition: stroke 0.3s ease-in-out;
}
.back-to-top__svg {
  width: 2.5em;
  height: 2.5em;
  display: block;
}
.back-to-top__svg-background {
  fill: #1C1C1C;
  stroke: #fff;
}
.back-to-top__svg-arrow {
  stroke: white;
}
@media screen and (min-width: 48em) {
  .back-to-top__label {
    display: block;
  }
  .back-to-top__button:hover .back-to-top__svg-background {
    fill: #2354E8;
  }
}

.showcase {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
}
.showcase.hasVideoInHeader {
  overflow: visible;
}
.showcase--init {
  opacity: 1;
}
.showcase.showcase--marquee-story {
  display: none;
}
.showcase:before {
  content: "";
  direction: block;
  background-image: linear-gradient(to bottom, rgba(28, 28, 28, 0.8) 8%, rgba(28, 28, 28, 0));
  position: absolute;
  top: 0;
  left: 0;
  height: 25%;
  width: 100%;
  z-index: 10;
}
.showcase__nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5em;
  z-index: 5;
  width: 100%;
  text-align: center;
}
.showcase__nav-option {
  transition: color 0.35s ease-out, background-color 0.35s ease-out, opacity 0.35s ease-out;
  width: 20%;
  height: 0.375em;
  background-color: #E3E3E3;
  vertical-align: middle;
}
.showcase__nav-option + .showcase__nav-option {
  margin-left: 1%;
}
.showcase__nav-option--active {
  background-color: #505050;
}
.showcase__arrow {
  vertical-align: middle;
  color: #1AC6BA;
  font-size: 1.25em;
}
.showcase__design-wedge {
  position: absolute;
  top: 40%;
  bottom: 0;
  width: 100%;
  background-color: #1C1C1C;
  z-index: 4;
}
.showcase__design-wedge:before, .showcase__design-wedge:after {
  content: "";
  position: absolute;
}
.showcase__design-wedge:before {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10vw solid #1C1C1C;
  border-right: 50vw solid transparent;
  bottom: 100%;
  left: 0;
}
.showcase__design-wedge:after {
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 10vw solid #1C1C1C;
  border-left: 50vw solid transparent;
  bottom: 100%;
  right: 0;
}
.showcase__updates {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75em;
  text-align: center;
  text-transform: uppercase;
  color: #1AC6BA;
  bottom: 2.0833333333em;
  z-index: 5;
}
.showcase__updates:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 100%;
  margin-top: 0.4166666667em;
  height: 1.5625em;
  border-left: 2px solid #1AC6BA;
  z-index: 5;
}
.showcase__updates-default:before {
  content: "+";
}
.showcase__back-wrapper {
  display: none;
}
@media screen and (min-width: 43.75em) {
  .showcase__design-wedge {
    top: 55%;
  }
}
@media screen and (min-width: 62.5em) {
  .showcase {
    transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
    position: fixed;
    z-index: 18;
    overflow: hidden;
  }
  .showcase.showcase--marquee-story {
    display: block;
  }
  .showcase:before {
    background-image: none;
    background-color: rgba(28, 28, 28, 0.75);
    opacity: 0;
    visibility: hidden;
    height: 100%;
    transition: opacity 1s cubic-bezier(0.86, 0, 0.07, 1), visibility 1s cubic-bezier(0.86, 0, 0.07, 1) 1s;
  }
  .showcase-layout--show-performance .showcase:before {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .showcase__frame-offset {
    margin-left: 50%;
    padding-left: 2.5em;
  }
  .showcase__updates {
    font-size: 0.875em;
    bottom: 3.5714285714em;
    left: auto;
    transform: none;
    right: 0;
    z-index: 10;
    color: #1AC6BA;
    transition: right 1s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .showcase__updates:before {
    content: none;
  }
  .showcase__updates:after {
    content: "";
    display: inline-block;
    border-top: 2px solid #1AC6BA;
    width: 3.5714285714em;
    vertical-align: middle;
    margin-left: 0.5em;
    transition: border-color 1s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .showcase__updates .icon-close {
    font-size: 2.1875em;
    line-height: 1;
    vertical-align: middle;
  }
  .showcase__updates-count {
    color: #7C7C7C;
    font-weight: 300;
  }
  .showcase__nav {
    text-align: left;
    bottom: 3.125em;
  }
  .showcase__nav-option {
    width: 18%;
    height: 0.625em;
  }
  .showcase__nav-option:hover {
    background-color: #1AC6BA;
  }
  .showcase__nav-option + .showcase__nav-option {
    margin-left: 1%;
  }
  .showcase__nav-option--active:hover {
    background-color: #505050;
    cursor: default;
  }
  .showcase__arrow {
    font-size: 1.625em;
  }
  .showcase__design-wedge {
    right: 0em;
    top: 0;
    bottom: auto;
    width: 52vw;
    height: 100%;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), right 1s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .showcase--marquee-story .showcase__design-wedge {
    top: 0;
  }
  .showcase__design-wedge:before, .showcase__design-wedge:after {
    display: none;
  }
  .showcase__design-wedge-left-col {
    position: absolute;
    height: 100%;
    right: 100%;
    margin-right: -1px;
  }
  .showcase__design-wedge-left-col:before, .showcase__design-wedge-left-col:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    backface-visibility: hidden;
    right: 0;
  }
  .showcase__design-wedge-left-col:before {
    border-top: 51vh solid #1C1C1C;
    border-left: 4.6875em solid transparent;
    top: 0;
  }
  .showcase__design-wedge-left-col:after {
    border-bottom: 51vh solid #1C1C1C;
    border-left: 4.6875em solid transparent;
    bottom: 0;
  }
  .showcase__design-wedge-right-col {
    position: absolute;
    height: 100%;
    left: 100%;
    margin-left: -1px;
  }
  .showcase__design-wedge-right-col:before, .showcase__design-wedge-right-col:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    backface-visibility: hidden;
    left: 0;
  }
  .showcase__design-wedge-right-col:before {
    border-bottom: 51vh solid #1C1C1C;
    border-right: 4.70833125em solid transparent;
    bottom: 50%;
  }
  .showcase__design-wedge-right-col:after {
    border-top: 51vh solid #1C1C1C;
    border-right: 4.7083125em solid transparent;
    top: 50%;
  }
  .showcase__back-wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    z-index: 90;
    padding-left: 1.25em;
  }
  .showcase__back {
    font-size: 0.875em;
    color: #7C7C7C;
    text-transform: uppercase;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease-in, visibility 0.25s ease-in 0.35s;
    position: absolute;
    top: 2.8125em;
  }
  .showcase__back--show-detail {
    opacity: 1;
    overflow: visible;
    visibility: visible;
    transition: opacity 1s ease-in;
  }
  .showcase__back:hover .showcase__back-diamond:after {
    top: -25%;
    left: -25%;
  }
  .showcase__back-icon {
    font-size: 2em;
    position: relative;
    z-index: 1;
    width: 1.40625em;
    height: 1.40625em;
    padding-top: 0.234375em;
    display: block;
    margin-bottom: 0.75em;
    overflow: visible;
  }
  .showcase__back-icon:before {
    margin-left: -3px;
  }
  .showcase__back-diamond {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #7C7C7C;
    transform: rotate(45deg);
    overflow: hidden;
    z-index: -1;
  }
  .showcase__back-diamond:after {
    transition: top 0.35s ease-out, left 0.35s ease-out;
    content: "";
    display: block;
    position: absolute;
    width: 150%;
    height: 150%;
    transform: rotate(-45deg);
    background-color: #fff;
    top: 85%;
    left: 85%;
    z-index: -2;
  }
}
@media screen and (min-width: 75em) {
  .showcase__updates {
    font-size: 1em;
    bottom: 3.125em;
  }
  .showcase__updates:after {
    width: 3.125em;
  }
  .showcase__nav-option {
    width: 6.25em;
  }
  .showcase__design-wedge {
    width: 55vw;
  }
}

.showcase--init .showcase-panel {
  opacity: 1;
}
.showcase-panel__figures {
  height: 40%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  background-color: #1C1C1C;
}
.showcase-panel__figures:after {
  content: "";
  background-image: linear-gradient(to bottom, rgba(68, 96, 219, 0), rgb(35, 84, 232));
  position: absolute;
  bottom: 0;
  top: 40%;
  width: 100%;
  opacity: 0.6;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
}
.showcase-panel__img {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  width: 105%;
  height: 100%;
  backface-visibility: hidden;
  opacity: 0;
  transition: transform 7s ease-out, opacity 1s ease-out;
}
.showcase-panel__img--active {
  opacity: 1;
  transition: transform 7s ease-out, opacity 1s ease-out;
}
.showcase-panel__figures--pan-image .showcase-panel__img--active {
  transform: translateX(-4.8%);
}
.showcase-panel__img--desktop {
  display: none;
}
.showcase-panel__headlines {
  text-align: center;
}
.showcase-panel__headline-frame {
  position: relative;
  margin-top: 42vh;
  padding-bottom: 3.125em;
  z-index: 5;
}
.showcase-panel__headline {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-out;
  backface-visibility: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  width: 100%;
}
.showcase-panel__headline:focus {
  outline-style: none;
}
.showcase-panel__headline--active {
  opacity: 1;
  visibility: visible;
  overflow: visible;
  transition: opacity 1s ease-out;
}
.showcase-panel__frame--transition-out .showcase-panel__headline {
  transition: opacity 1s ease-out, visibility 0.1s linear 1s;
}
.showcase-panel__category {
  text-transform: uppercase;
  color: #7C7C7C;
  font-size: 0.75em;
  margin-bottom: 0.5em;
}
.showcase-panel__title {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  line-height: 1.4;
}
.showcase-panel__date {
  transition: opacity 0.35s ease-out;
  opacity: 0;
  font-size: 0.75em;
  color: #7C7C7C;
  margin-top: 1em;
  transition-duration: 1s;
  display: block;
}
.showcase-panel__video-button {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  padding: 0.5em 1.5em;
  margin-top: 1.5em;
  display: inline-block;
  position: relative;
  overflow: hidden;
  opacity: 1;
  transition: all 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.showcase-panel__video-button--hidden {
  opacity: 0;
  height: 0;
  padding: 0;
  margin: 0;
}
.showcase-panel__video-button:hover {
  color: #1C1C1C;
}
.showcase-panel__video-button:hover::before {
  top: 0;
}
.showcase-panel__video-button:hover::after {
  border-left-color: #1C1C1C;
}
.showcase-panel__video-button::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fff;
  margin-left: 1em;
  display: inline-block;
  vertical-align: middle;
  transition: border-left-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.showcase-panel__video-button::before {
  content: "";
  background-color: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: -1;
  transition: top 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.showcase-panel__shade {
  position: absolute;
  background-color: #1C1C1C;
  height: 100%;
  width: 55vw;
  right: 52%;
  transform: translateX(-4.375em);
  top: 100%;
  z-index: 0;
  display: none;
}
@media screen and (min-width: 43.75em) {
  .showcase-panel__figures {
    height: 55%;
  }
  .showcase-panel__figures:after {
    top: 55%;
  }
  .showcase-panel__headline-frame {
    margin-top: 57vh;
  }
}
@media screen and (min-width: 48em) {
  .showcase-panel__title {
    font-size: 1.875em;
    height: auto;
  }
}
@media screen and (min-width: 62.5em) {
  .showcase-panel__shade {
    display: block;
  }
  .showcase-panel + .showcase-panel {
    opacity: 0;
    visibility: hidden;
  }
  .showcase-panel__figures {
    position: absolute;
    height: 100%;
    width: 49%;
    top: 0;
    left: 0;
  }
  .showcase-panel__figures:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #E3E3E3;
    width: 10vw;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.1s linear 0.5s;
  }
  .showcase-panel__img {
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 115.5%;
    height: 100%;
  }
  .showcase-panel__figures--pan-image .showcase-panel__img--active {
    transform: translateX(-15%);
  }
  .showcase-panel__figures--hover-image .showcase-panel__img--active {
    transform: translateX(-15%) scale(1.25);
  }
  .showcase-layout--show-detail .showcase-panel__img--active {
    transition: opacity 0.1s linear 0.5s, transform 0.1s ease-out 0.5s;
    opacity: 0;
  }
  .showcase-panel__img--desktop {
    display: block;
  }
  .showcase-panel__img--mobile {
    display: none;
  }
  .showcase-panel__headlines {
    height: 100%;
    text-align: left;
  }
  .showcase-panel__headlines:before, .showcase-panel__headlines:after {
    display: none;
  }
  .showcase-panel__headline-frame {
    height: 100%;
    background-color: transparent;
    transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
    width: 50%;
    padding: 0;
    margin-top: 0;
    margin-left: 50%;
  }
  .showcase-panel--marquee-story .showcase-panel__headline-frame {
    margin-top: 0;
  }
  .showcase-panel__headline {
    transition: color 0.35s ease-out;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding-left: 2.5em;
  }
  .showcase-panel__headline:hover {
    color: #7090F0;
  }
  .showcase-panel__category {
    font-size: 0.875em;
    width: 100%;
  }
  .showcase-panel__title {
    max-width: 85%;
  }
}
@media screen and (min-width: 75em) {
  .showcase-panel__title {
    transition: color 0.35s ease-out;
    font-size: 2.375em;
    max-width: 80%;
  }
}

@keyframes slideinview {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideoutview {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes slideinviewMobile {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slideoutviewMobile {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
.showcase-layout {
  overflow: hidden;
  height: 100%;
}
.showcase-layout #homepage {
  height: 100%;
}
.showcase-layout--show-detail {
  height: auto;
  overflow: visible;
}
.showcase-layout--show-detail.showcase-layout--remove-detail-scroll {
  height: 100%;
  overflow: hidden;
}
.showcase-layout--show-detail .showcase {
  position: absolute;
}
.showcase-layout--show-detail .showcase__nav, .showcase-layout--show-detail .showcase__updates {
  opacity: 0;
}
.showcase-layout--show-detail .showcase-panel__headline-frame {
  margin-left: 0;
}
.showcase-layout--show-detail .showcase-panel__date {
  opacity: 1;
}
.showcase-layout--show-detail .showcase-panel__figures:before {
  opacity: 1;
}
.showcase-layout--force-no-scroll {
  height: 100%;
  overflow: hidden;
}
.showcase-layout--show-detail .showcase-layout__dark-column {
  opacity: 1;
  transition: opacity 0.5s ease-in 1.25s;
}
.showcase-layout--show-detail .showcase-layout__frame {
  opacity: 1;
  transition: none;
}
.showcase-layout--show-detail .showcase-layout__insight-detail {
  z-index: 20;
  transition: z-index 0.5s ease-out 1s;
  opacity: 1;
}
.showcase-layout--allow-scrolling {
  overflow: visible;
}
@media screen and (min-width: 62.5em) {
  .showcase-layout--show-performance .showcase {
    cursor: pointer;
  }
}
.showcase-layout--show-performance .showcase__updates {
  color: #fff;
}
.showcase-layout--show-performance .showcase__updates:after {
  border-color: #fff;
}
.showcase-layout--show-performance .showcase__updates .icon-close {
  color: #1AC6BA;
}
.showcase-layout--show-performance .site-footer {
  padding-bottom: 4.0625em;
}
.showcase-layout--show-performance .showcase-layout__frame {
  opacity: 1;
  background-color: #fff;
  z-index: 20;
  position: relative;
  transition: none;
  animation: slideinviewMobile 1s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}
.showcase-layout--show-performance .showcase-layout__performance {
  display: block;
  height: auto;
  visibility: visible;
  transition: none;
}
.showcase-layout--show-performance .showcase-layout__insight-detail {
  display: none;
}
.showcase-layout--show-performance .showcase-layout__close-performance {
  transition: opacity 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 1s;
  opacity: 1;
  visibility: visible;
}
.showcase-layout--hide-performance-state .showcase-layout__frame {
  opacity: 1;
  background-color: #fff;
  z-index: 20;
  position: relative;
  transition: none;
  animation: slideoutviewMobile 1s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}
.showcase-layout--hide-performance-state .showcase-layout__performance {
  display: block;
  height: auto;
  visibility: visible;
  transition: none;
}
.showcase-layout--hide-performance-state .showcase-layout__insight-detail {
  display: none;
}
.showcase-layout--hide-performance-state .showcase-layout__close-performance {
  transition: opacity 0.35s ease-out, visibility 0.1s linear 0.35s;
  opacity: 0;
  visibility: hidden;
}
.showcase-layout__frame {
  background-color: #E3E3E3;
  opacity: 0;
  transition: opacity 0.1s linear 1s;
}
.showcase-layout__insight-detail {
  position: relative;
  z-index: 10;
  transition: none;
}
.showcase-layout__performance {
  height: 0;
  visibility: hidden;
  overflow: hidden;
  transition: height 0.1s linear 1s, visibility 0.1s linear 1s;
}
.showcase-layout .showcase-insights,
.showcase-layout .performance-grid {
  position: relative;
  z-index: 5;
}
.showcase-layout .showcase-insights {
  padding: 6.25em 0 6.25em;
}
.showcase-layout .performance-grid {
  padding: 7.8125em 0 6.25em;
}
.showcase-layout .site-footer {
  position: relative;
}
.showcase-layout__dark-column {
  transition: opacity 0.5s ease-out;
  opacity: 0;
  position: relative;
  background-color: #E3E3E3;
}
.showcase-layout__dark-column:before {
  content: "";
  display: block;
  bottom: 0;
  position: absolute;
  top: 0;
  background-color: #1C1C1C;
  transform: translateZ(0);
}
.showcase-layout__white-section {
  background-color: #fff;
}
.showcase-layout__close-performance {
  position: fixed;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  background-color: #1C1C1C;
  border-top: 1px solid #E3E3E3;
  color: #1AC6BA;
  z-index: 25;
  padding: 15px;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
}
.showcase-layout__close-performance .icon-close {
  font-size: 1.5em;
  line-height: 1;
  vertical-align: middle;
  color: #fff;
  margin-right: 0.25em;
}
@media screen and (min-width: 48em) {
  .showcase-layout .performance-grid {
    padding: 9.375em 0 6.25em;
  }
}
@media screen and (min-width: 62.5em) {
  .showcase-layout__frame {
    opacity: 0;
    transition: opacity 0.1s linear 1s;
  }
  .showcase-layout--show-performance .site-footer {
    padding-bottom: 0;
  }
  .showcase-layout--show-performance .showcase-layout__frame {
    margin-left: 0;
    background-color: #fff;
    animation: slideinview 1s cubic-bezier(0.86, 0, 0.07, 1) forwards;
  }
  .showcase-layout--show-performance .showcase-layout__close-performance {
    opacity: 0;
    visibility: hidden;
  }
  .showcase-layout--hide-performance-state .showcase-layout__frame {
    animation: slideoutview 1s cubic-bezier(0.86, 0, 0.07, 1) forwards;
    margin-left: 12.5em;
  }
  .showcase-layout__dark-column:before {
    width: 52vw;
    right: calc(52% + 4.375em - 5px);
  }
  .showcase-layout__dark-column--bottom-padding {
    padding-bottom: 6.25em;
  }
}
@media screen and (min-width: 62.5em) {
  .showcase-layout__insight-detail .wrapper {
    padding-left: 6.5625em;
  }
  .showcase-layout--show-detail {
    height: auto;
    overflow: visible;
  }
  .showcase-layout--show-detail .showcase {
    position: absolute;
  }
  .showcase-layout--show-detail .showcase__design-wedge {
    right: 52%;
    transform: translateX(-4.375em);
  }
  .showcase-layout--show-detail .team-carousel,
.showcase-layout--show-detail .showcase-quote {
    opacity: 1;
    transition: opacity 0.5s ease-in 1.25s;
  }
  .showcase-layout--show-performance .showcase__updates {
    right: calc(100% - 200px);
  }
  .showcase-layout--show-performance .showcase-layout__frame {
    margin-left: 12.5em;
  }
}

.showcase-copy {
  height: calc(100vh - 2.5em);
}
.page__marquee-story-detail .showcase-copy {
  height: auto;
  padding-top: 80px;
  padding-bottom: 80px;
}
.showcase-copy__cta {
  margin-top: 1em;
}
.showcase-copy__cta a:hover {
  color: #3F3F3F;
}
.showcase-copy__body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  height: 100%;
}
.showcase-copy .rte {
  font-weight: 400;
}
@media screen and (min-width: 62.5em) {
  .showcase-copy {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .showcase-copy__body {
    padding-top: 1em;
  }
  .showcase-layout__insight-detail .showcase-copy {
    padding-left: 2.5em;
  }
  .showcase-layout .showcase-copy__body {
    padding-left: calc(50% + 2.5em );
  }
  .showcase-layout .showcase-copy {
    height: calc(100vh - 2.5em);
  }
  .showcase-copy .rte {
    font-size: 1.125em;
    max-height: 100%;
    overflow: hidden;
  }
}
@media screen and (min-width: 75em) {
  .showcase-copy .rte {
    font-size: 1.25em;
  }
}

.showcase-quote {
  padding-top: 6.25em;
  padding-bottom: 6.25em;
  position: relative;
  z-index: 20;
  margin-bottom: 0;
}
.showcase-quote--mobile .showcase-quote__body {
  flex-direction: column;
}
.showcase-quote--mobile .showcase-quote__cite {
  max-width: none;
  width: 100%;
  text-align: right;
}
.showcase-quote--mobile .showcase-quote__author {
  font-size: 30px;
}
.showcase-quote--mobile .showcase-quote__author::after {
  right: 0;
}
.showcase-quote--mobile .showcase-quote__blockquote {
  margin-bottom: 1.5em;
  max-width: none;
  width: 100%;
  background-color: transparent;
  color: #fff;
}
.showcase-quote--desktop {
  display: none;
}
.showcase-quote--dark {
  background-color: #1C1C1C;
}
.showcase-quote__body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  z-index: 1;
}
.showcase-quote__cite {
  color: #fff;
  font-style: normal;
  display: block;
  max-width: 50%;
  padding-right: 1.25em;
  max-width: 40%;
}
.showcase-quote__author {
  font-family: "Playfair Display", serif;
  font-size: 3em;
  line-height: 1.1;
  display: block;
  padding-bottom: 0.9166666667em;
}
.showcase-quote__author:after {
  content: "";
  display: block;
  width: 60%;
  height: 0.4583333333em;
  border-bottom: 1px solid #7C7C7C;
  position: absolute;
  z-index: -1;
}
.showcase-quote__blockquote {
  font-size: 1.375em;
  font-weight: 300;
  width: 40%;
  background-color: #E3E3E3;
  padding-left: 1.8181818182em;
}
.showcase-quote__blockquote:before {
  font-family: "Playfair Display", serif;
  font-size: 2.9545454545em;
  font-weight: 400;
  content: "“";
  line-height: 1em;
  position: absolute;
  margin-left: -0.5em;
  display: inline-block;
}
@media screen and (min-width: 62.5em) {
  .showcase-quote--mobile {
    display: none;
  }
  .showcase-quote--desktop {
    display: block;
  }
}

.showcase-insights {
  padding-bottom: 4.0625em;
}
.showcase-insights__grid-item {
  margin-bottom: 1em;
}
.showcase-insights__more {
  margin: 1.375em auto 1em;
  cursor: pointer;
}
.showcase-insights__more .button-primary__label:before {
  content: "+";
  display: inline-block;
  width: 1.25em;
}
.showcase-insights__watch {
  padding-top: 1em;
  margin-top: auto;
  cursor: pointer;
}
.showcase-insights__watch .button-primary {
  padding: 0.75em 1.25em;
  text-align: left;
  min-width: 0;
}
.showcase-insights__watch .button-primary:hover .button-primary__label:after {
  border-left: 10px solid #fff;
}
.showcase-insights__watch .button-primary__label:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #2354E8;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
  transition: 0.3s ease-in-out all;
}
@media screen and (min-width: 34.375em) {
  .showcase-insights {
    padding-bottom: 6.25em;
  }
  .showcase-insights__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;
  }
  .showcase-insights__grid-item {
    width: calc(50% - 1em);
    margin: 0 0.5em;
    margin-bottom: 1em;
    order: 10;
  }
  .showcase-insights__grid-item--prominent {
    width: calc(100% - 1em);
  }
  .showcase-insights__grid-item--1, .showcase-insights__grid-item--3 {
    order: 1;
  }
  .showcase-insights__grid-item--2 {
    order: 2;
  }
  .showcase-insights__grid-item--4, .showcase-insights__grid-item--5 {
    order: 3;
  }
}
@media screen and (min-width: 48em) {
  .showcase-insights__more {
    margin: 2em auto 0;
  }
}
@media screen and (min-width: 75em) {
  .showcase-insights__grid-item {
    width: calc(33.33333% - 1em);
  }
  .showcase-insights__grid-item--prominent {
    width: calc(66.66667% - 1em);
  }
  .showcase-insights__grid-item--1, .showcase-insights__grid-item--3, .showcase-insights__grid-item--4 {
    order: 1;
  }
  .showcase-insights__grid-item--2 {
    order: 2;
  }
  .showcase-insights__grid-item--5 {
    order: 3;
  }
}

.showcase-rte {
  padding-top: 6.25em;
  padding-bottom: 6.25em;
}

.showcase-marquee-story-mobile-headline {
  margin-top: 60px;
  background-color: #1C1C1C;
  color: #fff;
  padding: 100px 0;
}
.showcase-marquee-story-mobile-headline__frame {
  text-align: center;
}
.showcase-marquee-story-mobile-headline__category {
  text-transform: uppercase;
  color: #7C7C7C;
  font-size: 0.75em;
  margin-bottom: 0.5em;
}
.showcase-marquee-story-mobile-headline__title {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  line-height: 1.4;
}
.showcase-marquee-story-mobile-headline__date {
  font-size: 0.75em;
  color: #7C7C7C;
  margin-top: 1em;
}
@media screen and (min-width: 48em) {
  .showcase-marquee-story-mobile-headline {
    margin-top: 6.5625em;
  }
  .showcase-marquee-story-mobile-headline__title {
    font-size: 1.875em;
  }
}
@media screen and (min-width: 62.5em) {
  .showcase-marquee-story-mobile-headline {
    display: none;
  }
}

.performance-grid {
  background-color: #fff;
  padding-bottom: 4.0625em;
}
.performance-grid__item + .performance-grid__item {
  margin-top: 1em;
}
.performance-grid__item--ajax {
  order: 5 !important;
}
@media screen and (min-width: 62em) {
  .performance-grid {
    padding-bottom: 6.25em;
  }
  .performance-grid__insights {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1em;
  }
}
@media screen and (min-width: 62em) {
  .performance-grid__item {
    width: calc(50% - 1em);
    margin: 0 0.5em;
    margin-bottom: 1em;
    order: 1;
  }
  .performance-grid__item + .performance-grid__item {
    margin-top: 0;
  }
  .performance-grid__item--prominent {
    width: calc(100% - 1em);
  }
  .performance-grid__item--2, .performance-grid__item--3, .performance-grid__item--4 {
    order: 1;
  }
  .performance-grid__item--1 {
    order: 2;
  }
  .performance-grid__item--5 {
    order: 3;
  }
  .performance-grid__item--ajax {
    order: 10;
  }
}
@media screen and (min-width: 62em) {
  .performance-grid__item {
    width: calc(33.33333% - 1em);
  }
  .performance-grid__item--prominent {
    width: calc(66.66667% - 1em);
  }
}

.prominent-services {
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  color: #fff;
  display: none;
}
.prominent-services__list {
  padding-left: 0;
  padding-right: 0;
}
.prominent-services__option + .prominent-services__option {
  border-top: 1px solid #979797;
}
.prominent-services__link {
  padding: 1em 1.25em;
  display: block;
}
@media screen and (min-width: 48em) {
  .prominent-services {
    display: block;
    position: relative;
  }
  .prominent-services__list {
    display: flex;
    flex-wrap: nowrap;
  }
  .prominent-services__option {
    flex-basis: 20%;
    flex-grow: 1;
    position: relative;
  }
  .prominent-services__option + .prominent-services__option {
    border-top: 0;
    border-left: 1px solid #979797;
  }
  .prominent-services__link {
    transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
    text-align: center;
    padding: 1em;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }
  .prominent-services__link:after {
    transition: transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
    content: "";
    background-color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    transform: translateY(100%);
  }
  .prominent-services__link:hover {
    color: #1C1C1C;
  }
  .prominent-services__link:hover:after {
    transform: translateY(0);
  }
}
@media screen and (min-width: 62em) {
  .prominent-services__option + .prominent-services__option {
    border-top: 0;
  }
  .prominent-services__option:first-child .prominent-services__link {
    padding-left: 2.2222222222em;
  }
  .prominent-services__option:last-child .prominent-services__link {
    padding-right: 2.2222222222em;
  }
  .prominent-services__link {
    font-size: 1.125em;
  }
}
@media screen and (min-width: 62em) {
  .prominent-services__option:first-child {
    border-left: 1px solid #979797;
  }
  .prominent-services__option:last-child {
    border-right: 1px solid #979797;
  }
}

.profile-heading {
  position: relative;
  padding-top: 5.125em;
  background-color: #1C1C1C;
  color: #fff;
  text-align: center;
}
.profile-heading .wrapper {
  position: relative;
  z-index: 3;
}
.profile-heading__background {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
  background-size: auto 90vh;
}
.profile-heading__background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
@media screen and (min-width: 48em) {
  .profile-heading__background {
    background-size: auto;
    background-size: cover;
  }
}
.profile-heading__main {
  margin-bottom: 1.375em;
}
.profile-heading__experience {
  margin-top: 0.625em;
}
.profile-heading__firm {
  margin-top: 10px;
}
.profile-heading__specialty--has-secondary:after {
  content: "/";
  display: inline;
  margin-left: 0.5em;
  margin-right: 0.25em;
  color: #7C7C7C;
}
.profile-heading__position:after {
  content: "/";
  color: #7C7C7C;
  margin-left: 0.65em;
  margin-right: 0.25em;
}
.profile-heading__position--no-separator::after {
  display: none;
}
.profile-heading__figure {
  position: relative;
  margin: 0 auto;
  display: block;
  width: 16.875em;
  box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.2);
  z-index: 5;
}
.profile-heading__snapshot {
  width: 100%;
  display: block;
}
.profile-heading__body {
  position: relative;
}
.profile-heading__contact-details {
  margin-bottom: 1.375em;
}
.profile-heading a, .profile-heading .expandable-services__toggle {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #7090F0;
}
@media screen and (min-width: 48em) {
  .profile-heading a:hover, .profile-heading .expandable-services__toggle:hover {
    color: #fff;
  }
}
.profile-heading__name {
  font-size: 1.75em;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .profile-heading__name {
    font-size: 3em;
  }
}
.profile-heading__email, .profile-heading__location {
  font-size: 0.875em;
  line-height: 1.4;
}
.profile-heading__location {
  margin: 0.3125em 0 0;
}
.profile-heading__location-link {
  display: inline-block;
  padding-right: 0.5em;
}
.profile-heading__location-phone, .profile-heading__mobile-phone {
  color: #E3E3E3 !important;
}
.profile-heading__location-phone:hover, .profile-heading__mobile-phone:hover {
  color: #7090F0 !important;
}
.profile-heading__location-phone a, .profile-heading__mobile-phone a {
  color: #E3E3E3;
}
.profile-heading .social-links {
  margin: 2.75em 0 2.0625em;
}
.profile-heading .social-links__item {
  margin: 0 0.5em;
}
.profile-heading .social-links__icon {
  height: 1.625em;
  padding: 0 0.5em;
  background-color: #1C1C1C;
  color: #fff;
  border: 1px solid #fff;
  display: inline-flex;
  align-items: center;
}
.profile-heading .icon-pdf .social-links__label {
  font-size: 0.75em;
}
.profile-heading .expandable-services:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: -2em;
  background-color: #E3E3E3;
  z-index: -1;
}
.profile-heading__figure-placeholder + .expandable-services:after, .profile-heading__figure-placeholder + [inline-template] .expandable-services:after {
  top: 0;
}
@media screen and (min-width: 34.375em) {
  .profile-heading {
    text-align: left;
  }
  .profile-heading__body {
    position: static;
  }
  .profile-heading__body:after {
    content: " ";
    display: table;
  }
  .profile-heading__body:after {
    clear: both;
  }
  .profile-heading__contact-details {
    text-align: center;
  }
  .profile-heading__figure, .profile-heading--figure-placeholder {
    margin-top: 1.375em;
  }
  .profile-heading .social-links {
    position: absolute;
    top: 0;
    right: 1.25em;
    margin: 0.625em 0 0;
  }
  .profile-heading .social-links__item:last-child {
    margin-right: 0;
  }
  .profile-heading .expandable-services {
    text-align: center;
  }
}
@media screen and (min-width: 48em) {
  .profile-heading {
    padding-top: 9.375em;
    min-height: 37.5em;
  }
  .profile-heading:after {
    content: "";
    background-color: #E3E3E3;
    height: 45px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .profile-heading__background {
    background-size: cover;
  }
  .profile-heading__body {
    margin-top: 4.0625em;
  }
  .profile-heading__body:after {
    display: none;
  }
  .profile-heading .social-links {
    right: 2.5em;
    margin-top: 1.25em;
  }
  .profile-heading .social-links__icon:hover {
    color: #1C1C1C;
    background-color: #fff;
  }
  .profile-heading__contact-details {
    text-align: left;
    float: right;
    width: calc(100% - 18.90625em);
  }
  .profile-heading--no-image .profile-heading__contact-details {
    float: none;
    width: auto;
  }
  .profile-heading__figure {
    margin: 0;
    width: 16.875em;
  }
  .profile-heading .expandable-services {
    text-align: left;
    margin-left: 18.90625em;
    margin-right: 0;
  }
  .profile-heading .expandable-services:after {
    display: none;
  }
  .profile-heading__figure-placeholder {
    margin: 0;
    height: 16.875em;
  }
  .profile-heading__figure-placeholder + .expandable-services, .profile-heading__figure-placeholder + [inline-template] .expandable-services {
    margin-left: 0;
  }
  .profile-heading__locations {
    margin-top: 0.5em;
  }
  .profile-heading__location {
    display: inline-block;
    padding-right: 1.5em;
  }
  .profile-heading a {
    transition: color 0.35s ease-out;
    color: #7090F0;
  }
  .profile-heading__location-phone a {
    color: #E3E3E3;
  }
  .profile-heading__name {
    padding-right: 3.3333333333em;
    display: inline-flex;
    width: 100%;
  }
  .profile-heading__name:after {
    content: "";
    display: block;
    border-top: 1px solid #7C7C7C;
    flex-grow: 1;
    margin-left: 0.5em;
    margin-top: 0.6666666667em;
  }
  .profile-heading__name-label {
    max-width: 85%;
    display: inline-block;
  }
  .profile-heading__specialty, .profile-heading__position {
    font-size: 1.5em;
  }
  .profile-heading__specialty--no-separator::after, .profile-heading__position--no-separator::after {
    display: none;
  }
  .profile-heading__firm {
    font-size: 1.25em;
  }
  .profile-heading__email, .profile-heading__location {
    font-size: 1em;
    line-height: 1.4;
  }
}

.overview-block--bio .titles__subheading {
  display: none;
}
.overview-block__heading-overview.section {
  background-color: transparent;
  padding: 0;
}
.overview-block__heading-overview.section .wrapper {
  padding: 0;
}
.overview-block__heading-overview .read-more__window {
  height: 5.625em;
}
.overview-block__heading-overview .read-more__window:after {
  background: none;
}
.overview-block__heading-overview .read-more--not-expandable .overview-block__heading-overview .read-more__window {
  height: auto;
}
.overview-block__body--mobile-accordion {
  padding-left: 1em;
  padding-right: 1em;
}
.overview-block__body--mobile-accordion .rte {
  padding-top: 1.375em;
  padding-bottom: 1.375em;
}
@media screen and (min-width: 48em) {
  .overview-block--bio {
    padding-top: 3.125em;
  }
  .overview-block--bio .titles__subheading {
    display: block;
  }
  .overview-block__body--mobile-accordion {
    padding: 0;
  }
  .overview-block__body--mobile-accordion .rte {
    padding: 0;
  }
}

.related-insights {
  background-color: #fff;
  padding-bottom: 4.0625em;
}
.related-insights__grid-item {
  list-style: none;
  margin: 0 auto 1em;
}
.related-insights__grid-item:not(.related-insights__grid-item--prominent) {
  position: relative;
  z-index: 10;
}
.related-insights__body {
  text-align: center;
}
.related-insights__grid {
  text-align: left;
}
.section-accordion .related-insights__grid {
  padding: 1.375em 1em 0;
}
.related-insights__more {
  margin: 1.375em auto 1em;
}
.related-insights__more .button-primary__label:before {
  content: "+";
  display: inline-block;
  width: 1.25em;
}
.related-insights__disclaimer {
  font-size: 0.75em;
  font-weight: 300;
  margin-top: 2.6666666667em;
}
@media screen and (min-width: 34.375em) {
  .related-insights__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;
  }
  .section-accordion .related-insights__grid {
    padding-left: 0;
    padding-right: 0;
  }
  .related-insights__grid-item {
    width: calc(50% - 1em);
    margin: 0 0.5em;
    margin-bottom: 1em;
    min-height: 17.5em;
  }
  .related-insights__grid-item--prominent {
    width: calc(100% - 1em);
  }
  .related-insights__grid-item--2, .related-insights__grid-item--3 {
    order: 1;
  }
  .related-insights__grid-item--1 {
    order: 2;
  }
  .related-insights__grid-item--4, .related-insights__grid-item--5 {
    order: 3;
  }
  .related-insights__grid-item--ajax {
    order: 4;
  }
}
@media screen and (min-width: 48em) {
  .related-insights {
    padding-bottom: 6.25em;
  }
  .related-insights__body {
    text-align: left;
  }
  .section-accordion .related-insights__grid {
    padding: 0;
  }
  .related-insights__grid-item {
    min-height: 21.875em;
  }
  .related-insights__more {
    margin: 2em auto 0;
  }
  .related-insights__disclaimer {
    margin-top: 5.4166666667em;
  }
}
@media screen and (min-width: 75em) {
  .related-insights__grid-item {
    width: calc(33.33333% - 1em);
  }
  .related-insights__grid-item--2, .related-insights__grid-item--3, .related-insights__grid-item--4 {
    order: 1;
  }
  .related-insights__grid-item--1 {
    order: 2;
  }
  .related-insights__grid-item--5 {
    order: 3;
  }
  .related-insights__grid-item--ajax {
    order: 10;
  }
  .related-insights__grid-item--prominent {
    width: calc(66.66667% - 1em);
  }
}

.related-services .listing-services {
  margin-top: 1.375em;
}
.related-services .listing-services__heading {
  color: #1C1C1C;
}
.related-services .listing-services__link {
  color: #2354E8;
  font-size: 0.875em;
}
.section-accordion .related-services__body {
  padding-left: 1em;
  padding-right: 1em;
}
@media screen and (min-width: 48em) {
  .related-services:not(.section--gray) .wrapper {
    position: relative;
  }
  .related-services:not(.section--gray) .wrapper:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 2.5em;
    right: 2.5em;
    display: block;
    border-top: 1px solid #E3E3E3;
    margin-bottom: -6.3125em;
  }
  .related-services .listing-services {
    margin-top: 0;
  }
  .related-services .listing-services + .listing-services {
    margin-top: 22px;
  }
  .related-services .listing-services__link {
    transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
    font-size: 1.125em;
  }
  .related-services .listing-services__link:hover {
    color: #1C1C1C;
  }
}
@media screen and (min-width: 62em) {
  .section-accordion .related-services__body {
    padding-left: 0;
    padding-right: 0;
  }
  .related-services .listing-services__heading {
    width: 7.2222222222em;
    float: left;
  }
  .related-services .listing-services__items {
    display: table;
  }
}

.team-carousel {
  padding-bottom: 6.25em;
}
.team-carousel__desktop {
  display: none;
}
.team-carousel__body {
  width: 18.125em;
  position: relative;
}
.team-carousel__intro {
  background-color: #E3E3E3;
  padding: 1.25em;
}
.team-carousel__intro br {
  display: none;
}
.team-carousel__view-all {
  color: #2354E8;
}
.team-carousel__slides {
  overflow: hidden;
}
.team-carousel__belt {
  display: flex;
  flex-wrap: nowrap;
  backface-visibility: hidden;
  flex-basis: 15.625em;
  transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
}
.team-carousel__belt .team-card {
  flex-grow: 0;
  flex-shrink: 0;
}
.team-carousel__controls {
  padding-top: 36px;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.team-carousel__arrow {
  font-size: 1.25em;
  position: relative;
  z-index: 1;
  width: 1.8em;
  height: 1.8em;
  color: #fff;
  transition: opacity 0.25s ease-in;
  display: block;
  overflow: visible;
}
.team-carousel__arrow:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  display: block;
  background-color: #3F3F3F;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}
.team-carousel__arrow--disabled:after {
  opacity: 0.25;
}
@media screen and (min-width: 34.375em) {
  .team-carousel__body {
    width: 33.75em;
  }
}
@media screen and (min-width: 48em) {
  .team-carousel__body {
    width: 36.25em;
  }
}
@media screen and (min-width: 62.5em) {
  .team-carousel {
    padding-bottom: 0;
  }
  .team-carousel__body {
    width: auto;
    display: flex;
    flex-wrap: nowrap;
  }
  .team-carousel__intro {
    flex-grow: 0;
    width: 13.4375em;
  }
  .team-carousel__intro br {
    display: block;
  }
  .team-carousel__slides {
    flex-shrink: 0;
    flex-basis: 35.625em;
  }
  .team-carousel__controls {
    right: auto;
    top: auto;
    position: relative;
    left: auto;
    bottom: auto;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
    padding: 0;
    padding-right: px-ot-em(20px);
  }
  .team-carousel__arrow {
    margin-bottom: 1em;
  }
}
@media screen and (min-width: 75em) {
  .team-carousel__slides {
    flex-shrink: 0;
    flex-basis: 53.4375em;
  }
  .team-carousel__controls {
    padding-right: 0;
  }
}

.tabby-area__nav-item--active .tabby-area__nav-label {
  color: #3F3F3F;
  cursor: auto;
}
.tabby-area__nav-label {
  font-size: 0.875em;
  text-transform: uppercase;
  color: #2354E8;
  text-align: left;
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .tabby-area__nav-label:hover {
    color: #505050;
  }
}
.tabby-area__nav {
  position: relative;
  z-index: 10;
}
.tabby-area__nav-item {
  border: 1px solid #E3E3E3;
  border-width: 0 1px 1px;
  padding: 0.625em 1.25em;
}
.tabby-area__nav--show-dropdown .tabby-area__nav-options {
  opacity: 1;
  visibility: visible;
}
.tabby-area__nav--show-dropdown .tabby-area__nav-toggle:after {
  transform: rotateX(180deg) translateY(50%);
}
.tabby-area__nav-toggle {
  position: relative;
  background-color: #E3E3E3;
  color: #1C1C1C;
  text-transform: uppercase;
  display: block;
  width: 100%;
  text-align: left;
  padding: 1.0714285714em 1.4285714286em;
  padding-right: 1.4285714286em;
  font-size: 0.875em;
}
.tabby-area__nav-toggle:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.35s ease-out;
  right: 1.0714285714em;
}
.tabby-area__nav-options {
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: #fff;
  width: 100%;
}
.tabby-area__frame {
  transition: height 0.35s ease-out;
  position: relative;
  padding: 0 1em;
  margin: 1em 0;
}
@media screen and (min-width: 48em) {
  .tabby-area__nav-options {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.03125em;
    position: static;
    top: auto;
    left: auto;
    opacity: 1;
    visibility: visible;
    background-color: transparent;
    width: 100%;
  }
  .tabby-area__nav-item {
    flex-shrink: 0;
    margin-right: 8%;
    border: 0 none;
    padding: 0;
    margin-bottom: 2.03125em;
  }
  .tabby-area__nav-item:last-child {
    margin-right: 0;
  }
  .tabby-area__nav-toggle {
    display: none;
  }
  .tabby-area__frame {
    padding: 0;
    margin: 0;
  }
}

.tab-area-tile {
  transition: opacity 0.35s ease-out;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.tab-area-tile--active {
  visibility: visible;
  height: auto;
  opacity: 1;
  position: relative;
  z-index: 2;
}
.tabby-area__frame--transition .tab-area-tile--active {
  opacity: 0;
  transition-delay: 0s;
}

.infographics {
  background-color: #fff;
}
.section ~ .infographics {
  margin-bottom: 4.0625em;
}
.infographics__grid {
  padding-top: 4.0625em;
  padding-bottom: 4.0625em;
  border: 1px solid #E3E3E3;
  border-width: 1px 0;
}
@media screen and (min-width: 48em) {
  .infographics__grid {
    padding-top: 6.25em;
    padding-bottom: 6.25em;
  }
}
.infographics--no-vertical-spacing .infographics__grid {
  border: none;
  padding-bottom: 0;
  padding-top: 0;
}
.infographics .infographic-card {
  margin-top: 1em;
}
.infographics + .section-layout {
  padding-top: 0;
}
@media screen and (min-width: 48em) {
  .infographics--gray {
    background-color: #E3E3E3;
  }
  .section ~ .infographics {
    margin-top: 0;
    margin-bottom: 0;
  }
  .infographics__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -0.5em;
  }
  .infographics .infographic-card {
    width: calc(50% - 1em);
    margin: 0.5em;
  }
  .infographics .infographic-card--intro {
    width: calc(100% - 1em);
  }
}
@media screen and (min-width: 75em) {
  .infographics .infographic-card {
    width: calc(33.33333% - 1em);
  }
}

.carousel-highlights {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100%;
  height: auto;
  position: relative;
  background-position: center;
}
.carousel-highlights--captions-below-on-mobile .carousel-highlights__desktop-only {
  display: none;
}
.carousel-highlights--show-slides .carousel-highlights {
  padding-bottom: 42.8571428571%;
}
.carousel-highlights--show-slides .carousel-highlights__intro {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out 0.5s;
}
.carousel-highlights--show-slides .carousel-highlights__arrows {
  opacity: 1;
}
.carousel-highlights--show-slides .carousel-highlights__slides {
  opacity: 1;
  visibility: visible;
}
.carousel-highlights--show-slides .carousel-highlights__mobile-bottom {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}
.carousel-highlights--show-slides .carousel-highlights__slide--active .carousel-highlights__slide-title-wrapper,
.carousel-highlights--show-slides .carousel-highlights__slide--active .carousel-highlights__slide-image {
  opacity: 1;
}
.carousel-highlights__intro {
  transition: background-color 0.35s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
}
.carousel-highlights__intro--hovered {
  background-color: rgba(0, 0, 0, 0.25);
}
.carousel-highlights__title {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
  width: 100%;
  text-align: center;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .carousel-highlights__title {
    font-size: 2.375em;
  }
}
.carousel-highlights__title:after {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1.1666666667em;
  padding-bottom: 0.9166666667em;
  margin-bottom: 0.9166666667em;
  border-bottom: 1px solid #fff;
}
.carousel-highlights .button-utility__label {
  padding-right: 0;
}
.carousel-highlights__slides {
  transition: opacity 1s ease-out;
  opacity: 0;
  visibility: hidden;
}
.carousel-highlights__slide, .carousel-highlights__slide-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.carousel-highlights__slide-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.carousel-highlights__slide {
  z-index: 10;
}
.carousel-highlights__slide--active {
  visibility: visible;
}
.carousel-highlights__slide-title-wrapper, .carousel-highlights__slide-image {
  transition: opacity 0.35s ease-out;
  opacity: 0;
}
.carousel-highlights__slide-title-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  z-index: 5;
  padding: 1.375em;
}
.carousel-highlights__mobile-bottom {
  opacity: 0;
  position: relative;
  min-height: 5.75em;
}
.carousel-highlights__mobile-slide-title-wrapper {
  padding: 1.375em 4em;
  text-align: center;
}
.carousel-highlights__mobile-titles {
  padding-top: 0;
}
.carousel-highlights__slide-title {
  font-weight: 300;
  font-style: italic;
}
.carousel-highlights__arrows {
  transition: opacity 1s ease-out;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 4em;
  width: 100%;
  opacity: 0;
  z-index: 10;
}
.carousel-highlights__mobile-arrows {
  width: 100%;
  position: absolute;
  padding-top: 2em;
  top: 0;
}
.carousel-highlights__arrow {
  float: left;
  overflow: visible;
  color: #fff;
}
.carousel-highlights__arrow--right {
  float: right;
}
.carousel-highlights__arrow-icon {
  font-size: 1.25em;
  position: relative;
  z-index: 1;
  width: 1.6em;
  height: 1.6em;
  padding-top: 0.2666666667em;
  display: block;
}
.carousel-highlights__arrow-diamond {
  transition: box-shadow 0.35s ease-out;
  transition-delay: 0.35s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
  overflow: hidden;
  z-index: -1;
  background-color: #1C1C1C;
}
.carousel-highlights__arrow-diamond:after {
  transition: top 0.35s ease-out, left 0.35s ease-out;
  content: "";
  display: block;
  position: absolute;
  width: 150%;
  height: 150%;
  transform: rotate(-45deg);
  background-color: #fff;
  top: 85%;
  left: 85%;
  z-index: -2;
}
@media screen and (min-width: 34.375em) {
  .carousel-highlights {
    padding-bottom: 42.8571428571%;
  }
  .carousel-highlights__mobile-titles {
    padding-top: 1em;
  }
}
@media screen and (min-width: 48em) {
  .carousel-highlights .button-utility {
    padding: 1.375em 2em;
  }
  .carousel-highlights__title:after {
    width: 0.7368421053em;
    padding-bottom: 0.5789473684em;
    margin-bottom: 0.8421052632em;
  }
  .carousel-highlights__mobile-titles {
    padding-top: 1.5em;
  }
  .carousel-highlights__mobile-slide-title-wrapper {
    padding: 1.375em 7em;
  }
  .carousel-highlights__arrows {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .carousel-highlights__arrow-icon {
    font-size: 1.75em;
    width: 1.7142857143em;
    height: 1.7142857143em;
    padding-top: 0.2857142857em;
    display: block;
  }
  .carousel-highlights__arrow:hover {
    color: #1C1C1C;
  }
  .carousel-highlights__arrow:hover .carousel-highlights__arrow-diamond {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
  .carousel-highlights__arrow:hover .carousel-highlights__arrow-diamond:after {
    top: -25%;
    left: -25%;
  }
}
@media screen and (min-width: 62em) {
  .carousel-highlights--captions-below-on-mobile .carousel-highlights__mobile-only {
    display: none;
  }
  .carousel-highlights--captions-below-on-mobile .carousel-highlights__desktop-only {
    display: block;
  }
}

.photo-gallery {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 70%;
  position: relative;
  background-position: center;
}
.photo-gallery--show-slides .photo-gallery__intro {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out 0.5s;
}
.photo-gallery--show-slides .photo-gallery__arrows {
  opacity: 1;
}
.photo-gallery--show-slides .photo-gallery__slides {
  opacity: 1;
  visibility: visible;
}
.photo-gallery--show-slides .photo-gallery__slide--active {
  opacity: 1;
}
.photo-gallery__intro {
  transition: background-color 0.35s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 15;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.photo-gallery__intro--hovered {
  background-color: rgba(0, 0, 0, 0.25);
}
.photo-gallery__cta {
  min-width: 16.25em;
}
.photo-gallery__cta .button-utility__label {
  padding-right: 0;
}
.photo-gallery__slides {
  transition: opacity 1s ease-out;
  opacity: 0;
  visibility: hidden;
}
.photo-gallery__slides:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1C1C1C;
}
.photo-gallery__slide, .photo-gallery__slide-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.photo-gallery__slide-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}
.photo-gallery__slide {
  z-index: 5;
  opacity: 0;
}
.photo-gallery__slide--active {
  visibility: visible;
  z-index: 7;
}
.photo-gallery__copy-wrapper, .photo-gallery__slide-image {
  transition: opacity 0.35s ease-out;
  opacity: 0;
}
.photo-gallery__slide--active .photo-gallery__copy-wrapper, .photo-gallery__slide--active .photo-gallery__slide-image {
  opacity: 1;
}
.photo-gallery__copy {
  font-weight: 300;
  font-style: italic;
}
.photo-gallery__copy-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  z-index: 5;
  padding: 1.375em;
}
.photo-gallery__arrows {
  transition: opacity 1s ease-out;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 4em;
  width: 100%;
  opacity: 0;
  z-index: 10;
}
.photo-gallery__arrow {
  float: left;
  overflow: visible;
  color: #fff;
  width: 2em;
  height: 2em;
}
.photo-gallery__arrow--right {
  float: right;
}
.photo-gallery__arrow-icon {
  font-size: 1.25em;
  z-index: 1;
  display: inline-block;
}
.photo-gallery__arrow-diamond {
  transition: box-shadow 0.35s ease-out;
  transition-delay: 0.35s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
  overflow: hidden;
  z-index: -1;
  background-color: #1C1C1C;
}
.photo-gallery__arrow-diamond:after {
  transition: top 0.35s ease-out, left 0.35s ease-out;
  content: "";
  display: block;
  position: absolute;
  width: 150%;
  height: 150%;
  transform: rotate(-45deg);
  background-color: #fff;
  top: 85%;
  left: 85%;
  z-index: -2;
}
.photo-gallery--careers-theme .photo-gallery__intro {
  background-color: transparent;
}
.photo-gallery--careers-theme .photo-gallery__intro:before {
  transition: opacity 0.35s ease-out;
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgb(255, 113, 91));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
  content: "";
}
.photo-gallery--careers-theme .photo-gallery__intro--hovered:before {
  opacity: 0.7;
}
.photo-gallery--careers-theme .photo-gallery__copy a {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #FF715B;
}
@media screen and (min-width: 48em) {
  .photo-gallery--careers-theme .photo-gallery__copy a:hover {
    color: #fff;
  }
}
@media screen and (min-width: 34.375em) {
  .photo-gallery {
    padding-bottom: 60%;
    height: auto;
  }
}
@media screen and (min-width: 48em) {
  .photo-gallery__cta {
    min-width: 17.1875em;
    padding: 1.375em 2em;
  }
  .photo-gallery__arrows {
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .photo-gallery__arrow {
    width: 3em;
    height: 3em;
  }
  .photo-gallery__arrow-icon {
    font-size: 1.75em;
  }
  .photo-gallery__arrow:hover {
    color: #1C1C1C;
  }
  .photo-gallery__arrow:hover .photo-gallery__arrow-diamond {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  }
  .photo-gallery__arrow:hover .photo-gallery__arrow-diamond:after {
    top: -25%;
    left: -25%;
  }
}

.video-card {
  max-width: 1000px;
  margin: auto;
}
.video-card__eyebrow {
  display: block;
  color: #2354E8;
  font-size: 0.875em;
  line-height: 1.5;
}
.video-card__title {
  margin-bottom: 10px;
  cursor: pointer;
}
.video-card__video-container {
  position: relative;
}
.video-card__icon {
  position: absolute;
  z-index: 1;
  right: 16px;
  bottom: 16px;
}
.video-card__cover {
  width: 100%;
  display: block;
  position: absolute;
  padding-bottom: 56.25%;
  height: 0;
  background-color: transparent;
  z-index: 10;
}
.video-card__iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.video-card__iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-card__details {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  flex-wrap: wrap;
  border-bottom: 1px solid #979797;
}
.video-card__share {
  flex: 0 1 auto;
  position: relative;
}
.video-card__share-btn {
  color: #2354E8;
  font-size: 0.875em;
}
.video-card__share-content {
  opacity: 0;
  pointer-events: none;
  z-index: 0;
  max-width: 220px;
  width: 100%;
  padding: 0.875em;
  background-color: #fff;
  position: relative;
  margin-bottom: 24px;
  position: absolute;
  width: 220px;
  bottom: 100%;
  left: 1em;
  transition: 0.3s ease all;
}
.video-card__share-content.active {
  z-index: 1;
  pointer-events: auto;
  opacity: 1;
}
.video-card__share-content::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 24px solid #fff;
  border-right: 24px solid transparent;
  position: absolute;
  left: 0;
  bottom: -24px;
  z-index: 1;
}
.video-card__share-input {
  border: 0;
  border-bottom: 1px solid #979797;
  padding-bottom: 5px;
  width: 100%;
}
.video-card__share-copy {
  color: #2354E8;
  font-size: 0.875em;
}
.video-card__time-container {
  flex: 0 1 auto;
}
.video-card__time {
  font-size: 0.875em;
}
.video-card__time-icon {
  margin-right: 0.25em;
}
.video-card__caption {
  font-style: italic;
  color: #3F3F3F;
  flex: 1 1 100%;
  margin-top: 0.5em;
  border-bottom: 1px solid #979797;
  padding-bottom: 1em;
  margin-bottom: 1em;
}
.video-card__desc {
  padding: 1.5em 0;
}
.video-card__cta {
  font-weight: 500;
  color: #2354E8;
  margin-top: 1em;
  display: block;
}
.video-card__accordion {
  border-top: 1px solid #fff;
  padding-top: 27px;
  margin-top: 2em;
  transition: 0.3s ease all;
  border-bottom: 1px solid #fff;
}
.video-card__accordion.open {
  padding-bottom: 27px;
  transition: 0.3s ease all;
}
.video-card__accordion-title-area {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
}
.video-card__title-icon {
  height: 42px;
  width: 42px;
}
.video-card__title-icon svg {
  width: 100%;
  height: 100%;
}
.video-card__accordion-content {
  padding: 0;
  padding-right: 50px;
  transition: 0.3s ease all;
}

#homepage .video-card__share {
  display: none;
}
#homepage .video-card__time-container {
  margin-left: auto;
}
#homepage .video-card__accordion {
  display: none;
}

.video-grid__grid {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 -1em;
}
@media screen and (min-width: 48em) {
  .video-grid__grid {
    margin: -1em;
  }
}
.video-grid__grid-item {
  display: none;
  margin: 1em;
  flex: 1 1 100%;
}
.video-grid__grid-item.active {
  display: block;
}
@media screen and (min-width: 48em) {
  .video-grid__grid-item {
    flex: 0 1 calc(50% - 2em);
  }
}
.video-grid__load-more {
  margin-top: 1em;
  margin-bottom: 1em;
}
@media screen and (min-width: 62em) {
  .video-grid__load-more {
    margin-top: 3em;
    margin-bottom: 0;
  }
}
.video-grid__load-more-button .button-primary__label::before {
  content: "+";
  display: inline-block;
  width: 1.25em;
}
.video-grid .video-card__accordion {
  display: none;
}
.video-grid .video-card__details {
  border: 0;
  padding-bottom: 0;
}
.video-grid .video-card__desc {
  padding-top: 0.5em;
  padding-bottom: 0;
}
.video-grid--single .video-card__cta,
.video-grid--single .video-card__caption {
  display: block;
}
.video-grid--single .video-grid__grid-item {
  flex: 1 1 100%;
}
@media screen and (min-width: 48em) {
  .video-grid--single .video-grid__grid-item {
    flex: 1 1 100%;
  }
}
.video-grid .titles__subheading-title button {
  text-align: left;
}

.hasVideoInHeader .showcase-copy.hasVideoInHeader {
  height: auto;
}
.hasVideoInHeader .video-grid.section {
  background-color: transparent;
  padding: 0;
  min-height: 75vh;
  display: flex;
  align-items: center;
}
.hasVideoInHeader .video-grid .titles__subheading {
  display: none;
}
.hasVideoInHeader .video-grid .wrapper {
  padding: 0;
  flex: 1 1 100%;
}

.articles-body .video-grid header.titles__subheading {
  display: none;
}
.articles-body .video-grid .wrapper {
  padding: 0;
}
.articles-body .video-grid.section {
  background-color: #fff;
}

.video-modal .modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(28, 28, 28, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 9999;
}
.video-modal .modal__container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  margin: auto;
  padding: 30px;
  max-width: 870px;
  box-sizing: border-box;
}
.video-modal .modal__close {
  background: transparent;
  border: 0;
  position: relative;
  right: 0;
  left: 100%;
  display: block;
}
.video-modal .modal__close svg {
  display: block;
  height: 30px;
  width: 30px;
}
.video-modal .modal__content .video-card__title {
  cursor: auto;
}
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
.video-modal .micromodal-slide {
  display: none;
}
.video-modal .micromodal-slide.is-open {
  display: block;
}
.video-modal .micromodal-slide[aria-hidden=false] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.video-modal .micromodal-slide[aria-hidden=false] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.video-modal .micromodal-slide[aria-hidden=true] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.video-modal .micromodal-slide[aria-hidden=true] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.video-modal .micromodal-slide .modal__container,
.video-modal .micromodal-slide .modal__overlay {
  will-change: transform;
}
.video-modal .video-card,
.video-modal .video-card__details,
.video-modal .video-card__eyebrow,
.video-modal .video-card__title,
.video-modal .video-card__share-btn,
.video-modal .video-card__caption,
.video-modal .video-card__desc,
.video-modal .video-card__cta {
  color: #fff;
}
.video-modal .video-card__cover,
.video-modal .video-card__icon {
  display: none !important;
}
.video-modal .video-card__accordion,
.video-modal .video-card__iframe-container,
.video-modal .video-card__desc {
  display: block !important;
}

.featured-spotlight.section-accordion .video-card {
  margin-top: 1em;
}
@media screen and (min-width: 62em) {
  .featured-spotlight.section-accordion .video-card {
    margin-top: 0;
  }
}
.featured-spotlight.section-accordion .video-card__accordion {
  display: none;
}
.featured-spotlight.section-accordion .video-card__iframe-container {
  display: none;
}

.services-areas {
  min-height: 400px;
}
.services-areas__nav {
  margin-bottom: 2em;
  text-align: center;
}
.services-areas__nav-button {
  font-size: 1.125em;
  font-weight: 700;
  color: #2354E8;
  text-transform: uppercase;
}
.services-areas__nav-button--selected, .services-areas__nav-button.initial-active {
  color: #1C1C1C;
  cursor: auto;
}
.services-areas__nav-button.initial-inactive {
  color: #2354E8;
}
.services-areas__nav-button--has-divider {
  border-right: 1px solid #7C7C7C;
  padding-right: 1em;
  margin-right: 0.75em;
}
.services-areas__frame {
  position: relative;
}
.services-areas__panel {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.services-areas__panel--active, .services-areas__panel.initial-active {
  opacity: 1;
  visibility: visible;
  position: relative;
}
.services-areas__panel.initial-inactive {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.services-areas__listing {
  top: 0;
  left: 0;
}
.services-areas__listing--active {
  opacity: 1;
  visibility: visible;
  position: relative;
}
.services-areas__listing--inactive {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.services-areas__listing-parent {
  font-size: 1em;
  font-family: "Rubik", sans-serif;
}
.services-areas__listing-parent .services-areas__listing-link {
  font-weight: 700;
}
.services-areas__listing-link {
  font-size: 1em;
  font-weight: 300;
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  line-height: 1.3;
  color: #2354E8;
  display: inline-block;
  padding-bottom: 0.625em;
}
@media screen and (min-width: 48em) {
  .services-areas__nav-button {
    font-size: 1.25em;
  }
  .services-areas__nav-button:hover:not(.services-areas__nav-button--selected) {
    color: #3F3F3F;
  }
  .services-areas__nav-button--selected {
    color: #1C1C1C;
  }
  .services-areas__listing {
    columns: 2 auto;
    column-gap: 1.375em;
  }
  .services-areas__listing-option {
    break-inside: avoid;
    overflow: hidden;
    width: 100%;
    display: inline-block;
  }
  .services-areas__listing-link {
    font-size: 1.125em;
    padding-bottom: 1em;
  }
  .services-areas__listing-link:hover {
    color: #3F3F3F;
  }
}
@media screen and (min-width: 62em) {
  .services-areas__nav {
    margin-bottom: 2em;
    text-align: left;
    float: right;
    width: calc(100% - 29.6875em);
  }
  .services-areas .practice-area-filters {
    float: left;
    width: 25.625em;
  }
  .services-areas__frame {
    clear: right;
    margin-left: 29.6875em;
  }
}

.practice-area-filters {
  transition: max-height 0.35s ease-out;
  overflow: hidden;
  max-height: 20em;
}
.practice-area-filters--disabled {
  max-height: 0;
  position: relative;
}
.practice-area-filters--disabled:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.practice-area-filters--disabled .practice-area-filters__nav {
  opacity: 0.2;
}
.practice-area-filters__heading {
  font-family: "Rubik", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.3;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 2em;
}
.practice-area-filters__heading:after {
  content: "";
  display: block;
  border-top: 1px solid #979797;
  flex-grow: 1;
  margin-left: 1em;
}
.practice-area-filters__heading-label {
  display: block;
  flex-shrink: 0;
}
.practice-area-filters__nav {
  transition: opacity 0.35s ease-out;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding-bottom: 2em;
}
.practice-area-filters__filter {
  line-height: 1.35;
  width: calc(50% - 10px);
  margin: 0 5px;
  margin-bottom: 10px;
  height: 3.75em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.625em 0.4166666667em;
  border-color: transparent;
}
.practice-area-filters__filter:first-child {
  width: calc(100% - 10px);
}
.practice-area-filters__filter--selected {
  background-color: #fff;
  color: #1C1C1C;
  border-color: transparent;
  box-shadow: none;
}
.practice-area-filters__filter--selected:hover {
  cursor: default;
  box-shadow: none;
}
@media screen and (min-width: 48em) {
  .practice-area-filters__heading {
    font-size: 1.125em;
    line-height: 1.3;
  }
}
@media screen and (min-width: 48em) {
  .practice-area-filters {
    max-height: none;
  }
  .practice-area-filters--disabled {
    max-height: none;
  }
}

.service-heading {
  position: relative;
  padding-top: 5.125em;
  background-color: #1C1C1C;
}
.service-heading__background {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
}
.service-heading__background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
.service-heading__body {
  padding-bottom: 4.0625em;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.service-heading__header {
  margin-bottom: 1.375em;
}
.service-heading__cta {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #7090F0;
  display: inline-block;
  margin-top: 1.375em;
}
@media screen and (min-width: 48em) {
  .service-heading__cta:hover {
    color: #fff;
  }
}
.service-heading__cta:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e906";
  transition: transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translateX(0.3125em);
  vertical-align: middle;
}
.service-heading__title {
  font-size: 2em;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .service-heading__title {
    font-size: 4em;
  }
}
@media screen and (min-width: 34.375em) {
  .service-heading__header {
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
    width: 100%;
  }
  .service-heading__utility {
    margin-left: auto;
  }
  .service-heading__title {
    max-width: 72%;
  }
  .service-heading__related-cta {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    padding-top: 0.5em;
  }
  .service-heading__related-cta:before {
    content: "";
    border-top: 1px solid #7C7C7C;
    display: block;
    width: 2em;
    flex-grow: 1;
    min-width: 6.25em;
    margin-left: 1em;
    margin-right: 1em;
  }
  .service-heading__cta {
    flex-shrink: 0;
    margin-top: 0;
  }
  .service-heading__cta:hover:after {
    transform: translateX(0.625em);
  }
  .service-heading__body {
    text-align: left;
  }
}
@media screen and (min-width: 48em) {
  .service-heading__body {
    padding-bottom: 4.0625em;
    padding-top: 4.0625em;
    min-height: 21.875em;
    height: 100px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.search-controls {
  padding-top: 4.0625em;
  padding-bottom: 4.0625em;
  background-color: #fff;
  padding-top: 0;
}
@media screen and (min-width: 48em) {
  .search-controls {
    padding-top: 6.25em;
    padding-bottom: 6.25em;
  }
}
.search-controls .keyword-search .searchbox {
  transform: translateY(-50%);
  z-index: 3;
}
.search-controls--insights {
  padding-bottom: 0;
}
.search-controls--insights .wrapper:after {
  content: "";
  display: block;
  border-bottom: 1px solid #E3E3E3;
  padding-bottom: 4.0625em;
}
.search-controls__no-results {
  padding-top: 4.0625em;
  font-size: 1.25em;
}
@media screen and (min-width: 48em) {
  .search-controls {
    padding-top: 0;
  }
  .search-controls--insights .keyword-search .searchbox {
    transform: none;
  }
  .search-controls--insights .advanced-search {
    margin-top: 0;
  }
  .search-controls--insights .keyword-search--grid {
    margin-top: -2.24em;
    margin-bottom: 2.24em;
  }
  .search-controls--insights .wrapper:after {
    padding-bottom: 6.25em;
  }
}
@media screen and (min-width: 62em) {
  .search-controls .keyword-search--grid {
    margin-top: -2.42em;
    margin-bottom: 2.42em;
  }
}

.keyword-search--typeahead {
  position: relative;
  z-index: 3;
}
.keyword-search--site-search-landing {
  position: relative;
}
.keyword-search .searchbox__input {
  transition: border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  border: 1px solid #2354E8;
}
.keyword-search .searchbox__input:focus {
  border-color: #505050;
  box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.15);
}
.keyword-search .searchbox__button {
  color: #2354E8;
  padding-right: 0.75em;
}
.keyword-search .searchbox + .searchbox {
  margin-top: 1.375em;
}
@media screen and (min-width: 48em) {
  .keyword-search .searchbox__input {
    font-size: 1.5em;
    padding: 1.0416666667em 1.25em;
    padding-right: 2.5em;
  }
  .keyword-search .searchbox__button {
    font-size: 1.625em;
    padding-right: 1.1538461538em;
  }
  .keyword-search .searchbox__button:hover, .keyword-search .searchbox__button:focus {
    color: #505050;
  }
  .keyword-search .searchbox + .searchbox {
    margin-top: 0;
  }
  .keyword-search--grid {
    margin: 0 -0.3125em;
  }
  .keyword-search--grid:after {
    content: " ";
    display: table;
  }
  .keyword-search--grid:after {
    clear: both;
  }
  .keyword-search--grid .searchbox {
    float: left;
    width: 50%;
    padding: 0 0.3125em;
  }
  .keyword-search--grid .searchbox__input {
    font-size: 1.375em;
  }
}
@media screen and (min-width: 62em) {
  .keyword-search--grid .searchbox__input {
    font-size: 1.5em;
  }
}

.alphabetical-filters:after {
  content: " ";
  display: table;
}
.alphabetical-filters:after {
  clear: both;
}
.alphabetical-filters__letter {
  float: left;
  width: 15.3846153846%;
  text-align: center;
  margin-bottom: 1.375em;
}
.alphabetical-filters__filter {
  font-size: 1.25em;
  text-transform: uppercase;
  padding-left: 0;
  padding-right: 0;
  color: #2354E8;
}
.alphabetical-filters__filter--disabled {
  color: #979797;
  cursor: initial;
}
.alphabetical-filters__filter--active {
  color: #1C1C1C;
  cursor: initial;
}
@media screen and (min-width: 34.375em) {
  .alphabetical-filters__letter {
    width: 10.9615384615%;
  }
}
@media screen and (min-width: 48em) {
  .alphabetical-filters__letter {
    width: 7.6923076923%;
  }
  .alphabetical-filters__filter {
    font-size: 1.5em;
  }
  .alphabetical-filters__filter:hover {
    color: #505050;
  }
  .alphabetical-filters__filter--disabled:hover {
    color: #979797;
  }
  .alphabetical-filters__filter--active:hover {
    color: #1C1C1C;
  }
}
@media screen and (min-width: 62em) {
  .alphabetical-filters {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .alphabetical-filters__letter {
    width: auto;
    flex-grow: 1;
  }
}

.select-dropdown {
  margin-bottom: 1.375em;
  position: relative;
}
.select-dropdown__select {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background: transparent;
  border: 0;
  font-family: "Rubik", sans-serif;
}
.select-dropdown__label {
  font-size: 0.875em;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
  position: relative;
  border: 1px solid #2354E8;
  padding: 1.0714285714em;
  padding-right: 1.875em;
  position: relative;
  text-transform: uppercase;
  color: #2354E8;
}
.select-dropdown__label:before {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e908";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.0714285714em;
}
.select-dropdown--large .select-dropdown__label {
  font-size: 1em;
  padding: 1.125em;
  padding-right: 1.6071428571em;
}
.select-dropdown--large .select-dropdown__label:before {
  font-size: 0.875em;
}
@media screen and (min-width: 48em) {
  .select-dropdown__select:focus + .select-dropdown__label, .select-dropdown__select:hover + .select-dropdown__label {
    color: #505050;
    border-color: #505050;
    box-shadow: 0 6px 20px 5px rgba(0, 0, 0, 0.15);
  }
  .select-dropdown__label {
    transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1), border-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
    font-size: 1em;
    padding-left: 1.375em;
    padding-right: 2.40625em;
  }
  .select-dropdown__label:before {
    right: 1.375em;
  }
  .select-dropdown--large .select-dropdown__label {
    font-size: 20px;
    padding: 0.96em 1.2em;
  }
  .select-dropdown--large .select-dropdown__label:before {
    font-size: 0.875em;
  }
}

.advanced-search__filters--collapsed {
  transition: max-height 0.35s ease-out;
  max-height: 0;
  overflow: hidden;
}
.advanced-search__filters--expanded, .advanced-search__filters--initial-expanded {
  max-height: 14.0625em;
}
.advanced-search .button-primary__label:before {
  content: "+";
  width: 1.5em;
  display: inline-block;
}
.advanced-search__toggle {
  display: block;
  text-transform: uppercase;
  margin: 0.625em auto 0;
}
.advanced-search__toggle--expanded .button-primary__label:before {
  content: "—";
}
@media screen and (min-width: 34.375em) {
  .advanced-search__filters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;
  }
  .advanced-search .select-dropdown {
    width: calc(50% - 1em);
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .advanced-search .select-dropdown--large {
    width: calc(100% - 1em);
  }
  .advanced-search .select-dropdown--centered {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 48em) {
  .advanced-search {
    margin-top: 1.375em;
  }
}
@media screen and (min-width: 62em) {
  .advanced-search__filters--expanded {
    max-height: 5.625em;
  }
  .advanced-search .select-dropdown {
    width: calc(33.33333% - 1em);
  }
  .advanced-search .select-dropdown--large {
    width: calc(100% - 1em);
  }
  .advanced-search .select-dropdown--medium {
    width: calc(50% - 1em);
  }
  .advanced-search__filters--has-2 .select-dropdown {
    width: calc(50% - 1em);
  }
}

.search-criteria {
  background-color: #fff;
}
.search-criteria--insights {
  padding-top: 4.0625em;
}
.search-criteria__filters-list {
  display: inline;
}
.search-criteria__applied-filter {
  display: inline-block;
  border: 1px solid #2354E8;
  font-size: 0.875em;
  padding: 0.625em 0.75em;
  color: #2354E8;
  margin-bottom: 0.3125em;
  margin-right: 0.3125em;
}
.search-criteria__remove-filter {
  display: inline-block;
  vertical-align: middle;
}
.search-criteria__clear {
  color: #2354E8;
  text-transform: uppercase;
}
.search-criteria__matches {
  font-family: "Playfair Display", serif;
  font-style: italic;
  margin-bottom: 1.375em;
  text-align: center;
}
.search-criteria__clear {
  display: block;
  text-align: left;
  margin: 1.375em auto 0;
}
.search-criteria__filters-label {
  display: inline-block;
  padding-right: 0.5em;
  max-width: 100%;
  word-break: break-word;
}
@media screen and (min-width: 34.375em) {
  .search-criteria__filters-applied {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    padding-right: 1em;
  }
  .search-criteria__applied-filter {
    margin-right: 0.5em;
    margin-bottom: 0.75em;
  }
  .search-criteria__filters-label, .search-criteria__clear {
    flex-shrink: 0;
  }
  .search-criteria__clear {
    margin-left: 1.375em;
  }
}
@media screen and (min-width: 48em) {
  .search-criteria--insights {
    padding-top: 4.0625em;
  }
}
@media screen and (min-width: 62em) {
  .search-criteria__matches {
    float: right;
    margin-top: 1.09375em;
    margin-bottom: 0;
  }
  .search-criteria__matches--site-search {
    margin-top: 0;
  }
  .search-criteria__subtype-wrapper {
    float: right;
    font-size: 0.875em;
    margin-top: 0.5em;
    min-width: 11em;
  }
  .search-criteria__subtype-wrapper + .search-criteria__matches {
    clear: right;
  }
}

.search-results__load-more {
  display: block;
  margin: 2em auto 0;
}
.search-results__load-more .button-primary__label:before {
  content: "+";
  width: 1.1em;
  display: inline-block;
  text-align: left;
  margin-left: -5px;
}
.search-results__expanded-search-result-list .person-result:last-child {
  margin-bottom: 0;
}
.search-results__grid {
  width: 17.8125em;
  margin: 0 auto 1em;
}
.search-results .insight-card + .insight-card {
  margin-top: 1em;
}
@media screen and (min-width: 34.375em) {
  .search-results__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;
    width: 100%;
  }
  .search-results .insight-card {
    width: calc(50% - 1em);
    margin: 0 0.5em 1em;
  }
  .search-results .insight-card + .insight-card {
    margin-top: 0;
  }
}
@media screen and (min-width: 75em) {
  .search-results .insight-card {
    width: calc(33.33333% - 1em);
  }
}
.search-results__list-custom {
  max-width: 1003px;
}
@media screen and (min-width: 48em) {
  .search-results__list-custom {
    max-width: 62.6875em;
  }
}

.person-result {
  border-bottom: 1px solid #E3E3E3;
  margin-bottom: 1.5625em;
  padding-bottom: 1.5625em;
}
.person-result_transition-in {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
.person-result__name {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
}
@media screen and (min-width: 48em) {
  .person-result__name {
    font-size: 2.375em;
  }
}
.person-result__name > a {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .person-result__name > a:hover {
    color: #505050;
  }
}
.person-result__level {
  font-size: 1.125em;
  font-weight: 300;
  white-space: pre-line;
  margin-top: 0.5555555556em;
}
.person-result__email {
  display: inline-block;
  margin-top: 0.5555555556em;
}
.person-result__office, .person-result__email {
  font-size: 1.125em;
  font-weight: 300;
  margin-top: 0.5555555556em;
}
.person-result__office-title {
  font-family: "Rubik", sans-serif;
  font-weight: inherit;
}
.person-result__office-title, .person-result__email {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .person-result__office-title:hover, .person-result__email:hover {
    color: #505050;
  }
}
.person-result__level, .person-result__email, .person-result__office {
  font-size: 0.75em;
  line-height: 16px;
}
.person-result__level {
  line-height: 100%;
}
@media screen and (min-width: 48em) {
  .person-result {
    margin-bottom: 2.34375em;
    padding-bottom: 2.34375em;
    display: flex;
    justify-content: space-between;
  }
  .person-result__level, .person-result__office, .person-result__email {
    font-size: 1.25em;
    line-height: 30px;
  }
  .person-result__email {
    line-height: 30px;
    padding-bottom: 1.25em;
  }
  .person-result__main {
    flex-grow: 1;
    padding-right: 1.375em;
  }
  .person-result__offices {
    flex-shrink: 0;
    max-width: 35%;
    padding-top: 6px;
  }
  .person-result__office {
    margin-top: 0;
  }
  .person-result__office + .person-result__office {
    margin-top: 1.6em;
  }
}
@media screen and (min-width: 62em) {
  .person-result:after {
    content: " ";
    display: table;
  }
  .person-result:after {
    clear: both;
  }
  .person-result__offices {
    width: 28%;
  }
}

.site-search-result {
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #E3E3E3;
}
.site-search-result__name {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
}
@media screen and (min-width: 48em) {
  .site-search-result__name {
    font-size: 2.375em;
  }
}
.site-search-result__name > a {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .site-search-result__name > a:hover {
    color: #505050;
  }
}
.site-search-result__date {
  margin-top: 1em;
}
.site-search-result__context {
  margin-top: 1em;
}
.site-search-result__context highlight {
  font-weight: bold;
}
.site-search-result_transition-in {
  animation: 2s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}
@media screen and (min-width: 48em) {
  .site-search-result {
    margin-bottom: 3em;
    padding-bottom: 3em;
    display: flex;
    justify-content: space-between;
  }
  .site-search-result__main {
    flex-grow: 1;
    padding-right: 1.375em;
  }
}
@media screen and (min-width: 62em) {
  .site-search-result:after {
    content: " ";
    display: table;
  }
  .site-search-result:after {
    clear: both;
  }
}

.site-search-segments__item {
  font-size: 1.25em;
  text-transform: uppercase;
  color: #2354E8;
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
  margin-right: 1em;
  display: block;
  margin-top: 10px;
}
@media screen and (min-width: 48em) {
  .site-search-segments__item:hover {
    color: #505050;
  }
}
.site-search-segments__item:last-child {
  margin-right: 0;
}
.site-search-segments__item--active {
  color: #3F3F3F;
  cursor: auto;
}
@media screen and (min-width: 34.375em) {
  .site-search-segments {
    display: flex;
    justify-content: space-between;
  }
  .site-search-segments__item {
    display: inline;
  }
}

.testimonial-result {
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #E3E3E3;
}
.testimonial-result__grid-item {
  display: block;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
}
.testimonial-result__grid-item + .testimonial-result__grid-item {
  margin-top: 2em;
}
.testimonial-result__grid-item:last-child {
  margin-bottom: 0;
}
.testimonial-result__name {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
  font-size: 1.5em;
}
@media screen and (min-width: 48em) {
  .testimonial-result__name {
    font-size: 2.375em;
  }
}
.testimonial-result__quote {
  margin-top: 0.9375em;
}
.testimonial-result__more-detail {
  margin-top: 1.5625em;
}
.testimonial-result__more-detail .button-primary {
  min-width: 6.25em;
  padding: 0.625em 1.625em;
}
.testimonial-result__figure {
  position: relative;
  width: 12.5em;
  flex-shrink: 0;
  margin-right: 1em;
  margin-bottom: 0.625em;
  margin-top: 0;
}
.testimonial-result__figure > img {
  max-width: 100%;
  display: block;
}
.testimonial-result__figure:after {
  transition: opacity 0.35s ease-out;
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgb(255, 113, 91));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
  content: "";
}
.testimonial-result__experience {
  font-size: 1.125em;
  font-weight: 300;
  font-style: italic;
  margin-top: 0.5em;
}
.testimonial-result__experience:before {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
}
.testimonial-result__meta:not(:last-child):after {
  content: "/";
  margin-right: 0.2em;
  padding-left: 0.5em;
}
.testimonial-result .rte blockquote {
  font-size: 16px;
  padding-left: 0;
}
@media screen and (min-width: 34.375em) {
  .testimonial-result {
    columns: 2 auto;
    column-gap: 2em;
  }
  .testimonial-result__grid-item {
    display: flex;
  }
  .testimonial-result__figure {
    margin-bottom: 0;
    margin-top: 10px;
  }
}
@media screen and (min-width: 48em) {
  .testimonial-result {
    margin-bottom: 3em;
    padding-bottom: 3em;
  }
}
@media screen and (min-width: 62em) {
  .testimonial-result:after {
    content: " ";
    display: table;
  }
  .testimonial-result:after {
    clear: both;
  }
  .testimonial-result__name {
    font-size: 32px;
  }
  .testimonial-result__figure {
    margin-right: 2em;
  }
  .testimonial-result .rte blockquote {
    font-size: 18px;
  }
}

.articles-heading {
  position: relative;
  padding-top: 5.125em;
  background-color: #1C1C1C;
}
.articles-heading__background {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
}
.articles-heading__background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
.articles-heading__body {
  padding-bottom: 4.0625em;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.articles-heading__title {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
  margin-bottom: 0.9166666667em;
}
@media screen and (min-width: 48em) {
  .articles-heading__title {
    font-size: 2.375em;
  }
}
.articles-heading__meta {
  color: #7C7C7C;
  padding-bottom: 1.375em;
}
.articles-heading__meta-item {
  font-size: 1em;
  text-transform: uppercase;
}
.articles-heading__meta-item + .articles-heading__meta-item:before {
  content: "—";
  margin-left: 0.5em;
  margin-right: 0.75em;
}
.articles-heading__action-link {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #7090F0;
  display: block;
}
@media screen and (min-width: 48em) {
  .articles-heading__action-link:hover {
    color: #fff;
  }
}
.articles-heading__action-link i {
  margin-left: 0.5em;
}
.articles-heading__utility {
  margin-top: 2em;
}
.articles-heading .social-links {
  margin-top: 2em;
}
.articles-heading .social-links .icon-envelope:before {
  font-size: 1.3em;
}
.articles-heading .social-links__item {
  margin: 0 0.5em;
}
.articles-heading .social-links__icon {
  height: 2em;
  min-width: 2em;
  padding: 0 0.5em;
  background-color: #1C1C1C;
  color: #fff;
  border: 1px solid #fff;
  display: inline-flex;
  align-items: center;
}
@media screen and (min-width: 34.375em) {
  .articles-heading__options {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 2em;
  }
  .articles-heading .social-links {
    margin-top: 0;
    flex-shrink: 0;
  }
  .articles-heading__utility {
    margin-left: auto;
    width: 12.5em;
    padding-right: 0.5em;
  }
  .articles-heading__actions-wrapper {
    display: block;
  }
  .articles-heading__actions {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    text-align: left;
    position: relative;
    align-items: center;
  }
  .articles-heading__actions:after {
    content: "";
    border-top: 1px solid #7C7C7C;
    display: inline-block;
    width: 2em;
    flex-grow: 1;
    min-width: 6.25em;
    margin-right: 1em;
  }
  .articles-heading__action-link {
    margin-right: 1.5em;
  }
  .articles-heading__action-link + .articles-heading__action-link {
    margin-left: 0;
  }
}
@media screen and (min-width: 48em) {
  .articles-heading__body {
    padding-bottom: 4.0625em;
    padding-top: 4.0625em;
    min-height: 21.875em;
    text-align: left;
  }
  .articles-heading__action-link {
    display: inline-block;
  }
  .articles-heading__action-link + .articles-heading__action-link {
    margin-left: 1.5em;
  }
  .articles-heading__title {
    max-width: 20.3947368421em;
  }
  .articles-heading__options {
    margin-top: 4.0625em;
  }
  .articles-heading .social-links__icon:hover {
    color: #1C1C1C;
    background-color: #fff;
  }
}

.articles-body .podcast {
  margin-bottom: 2em;
}
.articles-body__lead-image {
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom: 1px solid #E3E3E3;
}
.articles-body__lead-image-wrapper {
  position: relative;
  display: block;
}
.articles-body__lead-image-wrapper > img {
  max-width: 100%;
  display: block;
}
.articles-body__lead-image-caption {
  font-weight: 300;
  font-style: italic;
  margin-top: 1.8125em;
}
@media screen and (min-width: 48em) {
  .articles-body .podcast {
    margin-bottom: 4.0625em;
  }
  .articles-body__lead-image {
    padding-bottom: 4.0625em;
    margin-bottom: 4.0625em;
  }
}

.podcast {
  border: 1px solid #E3E3E3;
  border-width: 1px 0;
  padding-top: 2em;
  padding-bottom: 2em;
}
.podcast__play {
  display: inline-flex;
  align-items: center;
  text-align: left;
}
.podcast__play-icon {
  transition: color 0.35s ease-out, border-color 0.35s ease-out;
  flex-shrink: 0;
  display: inline-block;
  vertical-align: middle;
  width: 3.4375em;
  height: 3.4375em;
  border: 1px solid #2354E8;
  border-radius: 50%;
  position: relative;
  text-align: center;
  color: #2354E8;
}
.podcast__play-icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.625em;
}
.podcast__title {
  display: inline-block;
  flex-grow: 1;
  padding-left: 1em;
}
.podcast__duration:before {
  content: "—";
  display: inline-block;
  padding-left: 0.5em;
  padding-right: 0.5em;
  font-weight: 300;
}
.podcast__audio {
  display: none;
  width: 100%;
  margin-top: 10px;
}
.podcast--show-audio-element .podcast__audio {
  display: block;
}
@media screen and (min-width: 48em) {
  .podcast__play:hover .podcast__play-icon {
    border-color: #7090F0;
    color: #7090F0;
  }
  .podcast__play-icon {
    width: 4.6875em;
    height: 4.6875em;
  }
  .podcast__play-icon:before {
    font-size: 2.625em;
  }
  .podcast__title {
    font-size: 1.5em;
    font-weight: 300;
    padding-left: 1.5em;
  }
}

.lawyers-listing + .lawyers-listing {
  border-top: 1px solid #979797;
  padding-top: 2em;
  margin-top: 2em;
}
.lawyers-listing__flex-sizer {
  padding-bottom: 2em;
}
.lawyers-listing__flex-sizer:last-child {
  padding-bottom: 0;
}
.lawyers-listing__grid-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}
.lawyers-listing__grid-item--has-description .lawyers-listing__meta:nth-last-child(2):after {
  display: none;
}
.lawyers-listing__description {
  margin-top: 0.5em;
}
.lawyers-listing__meta {
  font-size: 1.125em;
  font-weight: 300;
}
.lawyers-listing__meta--link {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .lawyers-listing__meta--link:hover {
    color: #505050;
  }
}
.lawyers-listing__meta:not(:last-child):after {
  content: "/";
  margin: 0 0.5em;
}
.lawyers-listing__figure {
  position: relative;
  width: 5.3125em;
  flex-shrink: 0;
  margin-right: 1em;
  align-self: flex-start;
}
.lawyers-listing__figure > img {
  max-width: 100%;
  display: block;
}
.lawyers-listing__details, .lawyers-listing__name {
  flex-grow: 1;
}
.lawyers-listing__name {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
}
.lawyers-listing__name-link {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
  word-break: break-word;
}
@media screen and (min-width: 48em) {
  .lawyers-listing__name-link:hover {
    color: #505050;
  }
}
@media screen and (min-width: 48em) {
  .lawyers-listing {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .lawyers-listing__flex-sizer {
    width: 48%;
  }
  .lawyers-listing__flex-sizer:first-child:last-child {
    width: 100%;
  }
  .lawyers-listing__grid-item {
    position: relative;
    break-inside: avoid;
    overflow: hidden;
  }
  .lawyers-listing__figure-placeholder {
    height: 5.3125em;
  }
}
@media screen and (min-width: 62em) {
  .lawyers-listing + .lawyers-listing {
    padding-top: 4.0625em;
    margin-top: 4.0625em;
  }
  .lawyers-listing__meta {
    font-size: 1.25em;
  }
  .lawyers-listing__figure {
    width: 9.375em;
    margin-right: 2em;
  }
  .lawyers-listing__figure-placeholder {
    height: 9.375em;
  }
}

.listing-insights__meta {
  font-size: 0.875em;
  display: block;
  margin-bottom: 1em;
}
.listing-insights__date:after {
  content: "-";
  margin-left: 0.5em;
  margin-right: 0.25em;
}
.listing-insights__date:last-child:after {
  content: none;
}
.listing-insights__link {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  font-weight: 300;
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
  display: block;
  line-height: 1.2;
}
@media screen and (min-width: 48em) {
  .listing-insights__link:hover {
    color: #505050;
  }
}
.listing-insights__link--external:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90b";
  font-size: 1em;
  padding-left: 0.4em;
}
.listing-insights__link--external::after {
  font-size: 0.5em;
  display: inline;
}
.listing-insights__item--prominent .listing-insights__link {
  font-size: 1.5em;
}
.listing-insights__item + .listing-insights__item {
  border-top: 2px solid #E3E3E3;
  padding-top: 2em;
  margin-top: 2em;
}
.listing-insights__figure {
  position: relative;
  display: inline-block;
  width: 70%;
  margin-right: 1em;
  margin-bottom: 1em;
  flex-shrink: 0;
}
.listing-insights__img {
  max-width: 100%;
  display: block;
}
@media screen and (min-width: 34.375em) {
  .listing-insights__meta {
    margin-top: -0.25em;
  }
  .listing-insights__link--top-align {
    margin-top: -0.25em;
  }
  .listing-insights__item {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .listing-insights__figure {
    width: 35%;
    margin-bottom: 0;
    max-width: 14.6875em;
  }
  .listing-insights__item--prominent .listing-insights__figure {
    width: 40%;
    max-width: 25em;
  }
}
@media screen and (min-width: 48em) {
  .listing-insights__link {
    font-size: 1.75em;
  }
  .listing-insights__item + .listing-insights__item {
    padding-top: 4.0625em;
    margin-top: 4.0625em;
  }
  .listing-insights__figure {
    margin-right: 2em;
  }
  .listing-insights__item--prominent .listing-insights__link {
    font-size: 2.375em;
  }
}

.listing-links__link {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  font-weight: 300;
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
  display: block;
}
@media screen and (min-width: 48em) {
  .listing-links__link:hover {
    color: #505050;
  }
}
.listing-links__item--prominent .listing-links__link {
  font-size: 1.5em;
}
.listing-links__item + .listing-links__item {
  border-top: 2px solid #E3E3E3;
  padding-top: 2em;
  margin-top: 2em;
}
.listing-links__figure {
  position: relative;
  display: inline-block;
  width: 70%;
  margin-right: 1em;
  margin-bottom: 1em;
  flex-shrink: 0;
}
.listing-links__img {
  max-width: 100%;
  display: block;
}
.listing-links .button-primary__label:before {
  content: "+";
  display: inline-block;
  width: 1.25em;
}
.listing-links--is-expanded .button-primary__label:before {
  content: "–";
}
.listing-links__toggle {
  margin: 2em auto 0;
  display: block;
}
@media screen and (min-width: 34.375em) {
  .listing-links__item {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  .listing-links__figure {
    width: 35%;
    margin-bottom: 0;
    max-width: 14.6875em;
  }
  .listing-links__item--prominent .listing-links__figure {
    width: 40%;
    max-width: 25em;
  }
}
@media screen and (min-width: 48em) {
  .listing-links__link {
    font-size: 1.75em;
  }
  .listing-links__item + .listing-links__item {
    padding-top: 4.0625em;
    margin-top: 4.0625em;
  }
  .listing-links__figure {
    margin-right: 2em;
  }
  .listing-links__item--prominent .listing-links__link {
    font-size: 2.375em;
  }
  .listing-links__toggle {
    margin: 4.0625em 0 0;
  }
}

.topic-landing__listing {
  background-color: #fff;
  padding-bottom: 4.0625em;
  padding-top: 0;
}
.topic-landing__grid-item {
  width: 17.8125em;
  margin: 0 auto 1em;
  position: relative;
  z-index: 10;
}
.topic-landing__listing-body {
  text-align: center;
}
.topic-landing__grid {
  text-align: left;
}
.section-accordion .topic-landing__grid {
  padding: 1.375em 1em 0;
}
.topic-landing__more {
  margin: 1.375em auto 1em;
}
.topic-landing__more .button-primary__label:before {
  content: "+";
  display: inline-block;
  width: 1.25em;
}
@media screen and (min-width: 34.375em) {
  .topic-landing__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.5em;
  }
  .topic-landing__grid-item {
    width: calc(50% - 1em);
    margin: 0 0.5em;
    margin-bottom: 1em;
    min-height: 16.25em;
  }
}
@media screen and (min-width: 48em) {
  .topic-landing {
    padding-bottom: 6.25em;
  }
  .section-accordion .topic-landing__grid {
    padding: 0;
  }
  .topic-landing__grid-item {
    min-height: 20.3125em;
  }
  .topic-landing__more {
    margin: 2em auto 0;
  }
}
@media screen and (min-width: 75em) {
  .topic-landing__grid-item {
    width: calc(33.33333% - 1em);
  }
}
.topic-landing .insight-card {
  min-height: 20.3125em;
}

.locations-heading {
  position: relative;
  padding-top: 5.125em;
  background-color: #1C1C1C;
}
.locations-heading__background {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
}
.locations-heading__background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
.locations-heading__body {
  padding-bottom: 4.0625em;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.locations-heading__header {
  margin-bottom: 1.375em;
}
.locations-heading__title {
  font-size: 2em;
  line-height: 1.1;
}
@media screen and (min-width: 48em) {
  .locations-heading__title {
    font-size: 4em;
  }
}
.locations-heading__cta {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #7090F0;
  display: inline-block;
  margin-top: 1.375em;
  word-wrap: break-word;
}
@media screen and (min-width: 48em) {
  .locations-heading__cta:hover {
    color: #fff;
  }
}
.locations-heading__cta:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e906";
  transition: transform 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  transform: translateX(0.3125em);
  vertical-align: middle;
}
.locations-heading__phone-entry, .locations-heading__address {
  font-size: 1.125em;
}
.locations-heading__address {
  font-style: normal;
  margin-bottom: 1.7777777778em;
}
.locations-heading .address,
.locations-heading .firm,
.locations-heading .country {
  display: block;
}
.locations-heading__phone-label {
  display: inline-block;
}
.locations-heading__utility {
  margin-top: 2em;
}
.locations-heading__utility-link {
  display: block;
  width: 100%;
  max-width: 16.25em;
  margin-left: auto;
  margin-right: auto;
}
.locations-heading__utility-link + .locations-heading__utility-link {
  margin-top: 1.375em;
}
.locations-heading .language-dropdown {
  margin-top: 1.375em;
  width: 100%;
  max-width: 16.25em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 34.375em) {
  .locations-heading__options {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
  }
  .locations-heading__body {
    text-align: left;
  }
  .locations-heading__contact {
    flex-grow: 1;
  }
  .locations-heading__utility {
    margin-top: 0;
    width: 12.5em;
    flex-shrink: 0;
  }
  .locations-heading__utility-link + .locations-heading__utility-link {
    margin-top: 0.625em;
  }
  .locations-heading .language-dropdown {
    margin-top: 0.625em;
  }
}
@media screen and (min-width: 48em) {
  .locations-heading__body {
    padding-bottom: 4.0625em;
    padding-top: 4.0625em;
    min-height: 21.875em;
  }
  .locations-heading__header {
    align-items: flex-start;
  }
  .locations-heading__title {
    max-width: 20.3947368421em;
  }
  .locations-heading__phone-entry, .locations-heading__address {
    font-size: 1.25em;
  }
  .locations-heading__utility {
    width: 16.875em;
  }
}
@media screen and (min-width: 62em) {
  .locations-heading__header {
    display: flex;
    flex-wrap: nowrap;
    text-align: left;
  }
  .locations-heading__related-cta {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    padding-top: 0.5em;
    padding-top: 2em;
  }
  .locations-heading__related-cta:before {
    content: "";
    border-top: 1px solid #7C7C7C;
    display: block;
    width: 2em;
    flex-grow: 1;
    min-width: 6.25em;
    margin-left: 1em;
    margin-right: 1em;
  }
  .locations-heading__cta {
    flex-shrink: 0;
    margin-top: 0;
  }
  .locations-heading__cta:hover:after {
    transform: translateX(0.625em);
  }
}

.location-card {
  border-top: 1px solid #E3E3E3;
  padding: 2em 1.5625em;
  min-height: 21.875em;
  position: relative;
  background-color: #1C1C1C;
}
.location-card:after {
  background-image: linear-gradient(to bottom, rgba(68, 96, 219, 0), rgb(35, 84, 232));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
  transition: opacity 0.35s ease-out;
  content: "";
}
.location-card__figure {
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.35s ease-out;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.location-card__info {
  margin-bottom: 2em;
  margin-left: 4em;
  margin-right: 4em;
  position: relative;
  z-index: 5;
  color: #fff;
  text-align: center;
}
.location-card__title {
  font-size: 2em;
  line-height: 1.1;
  display: block;
}
@media screen and (min-width: 48em) {
  .location-card__title {
    font-size: 4em;
  }
}
.location-card___header {
  display: block;
}
.location-card__cta {
  margin-top: 2em;
}
.location-card__address {
  font-size: 1.125em;
  font-style: normal;
  padding-top: 1.7777777778em;
  margin-bottom: 0;
}
.location-card__office-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (min-width: 48em) {
  .location-card__office-container {
    flex-wrap: nowrap;
  }
}
.location-card__phone {
  font-size: 1.125em;
}
.location-card .address,
.location-card .firm,
.location-card .country, .location-card__sub-title {
  display: block;
  font-size: 2.5em;
}
@media screen and (min-width: 48em) {
  .location-card {
    padding: 30px 35px;
    height: 37.5em;
    min-height: 37.5em;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .location-card__figure {
    background-position: left bottom;
  }
  .location-card__address, .location-card__phone {
    font-size: 1.25em;
  }
  .location-card__address {
    padding-top: 1em;
  }
}
@media screen and (min-width: 62em) {
  .location-card {
    height: 33vh;
  }
}
@media screen and (min-width: 62em) and (min-height: 769px) {
  .location-card__figure {
    background-attachment: fixed;
  }
}
@media screen and (min-width: 62em) {
  .location-card__details {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out 0.25s, opacity 0.5s ease-out;
  }
}
@media screen and (min-width: 62em) {
  .location-card--is-hovered:after {
    opacity: 0;
  }
}
@media screen and (min-width: 62em) {
  .location-card--is-hovered .location-card__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 25em;
    opacity: 1;
    transition: max-height 0.75s ease-in, opacity 0.5s ease-out 0.25s;
  }
  .location-card--is-hovered .location-card__figure {
    opacity: 0.15;
  }
}

@media screen and (min-width: 62em) {
  .locations-prominent-listing--is-hovered:after {
    opacity: 0;
  }
  .locations-prominent-listing--is-hovered .locations-prominent-listing__details {
    max-height: 25em;
    opacity: 1;
    transition: max-height 0.75s ease-in, opacity 0.5s ease-out 0.25s;
  }
  .locations-prominent-listing--is-hovered .locations-prominent-listing__figure {
    opacity: 0.15;
  }
}

.location-alert {
  background-color: #2354E8;
  padding-top: 22px;
  padding-bottom: 22px;
}
.location-alert__content {
  color: #fff;
}
.location-alert__content.rte {
  font-size: 1.125em;
}

@keyframes fadeInContent {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.landing-showcase {
  min-height: 25em;
  opacity: 0;
  transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
}
.landing-showcase--init {
  opacity: 1;
}
.landing-showcase__app {
  background-color: #1C1C1C;
  height: auto;
  position: relative;
}
.landing-showcase__snapshot {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 38.5%;
}
.landing-showcase__snapshot:after {
  transition: opacity 0.35s ease-out;
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgb(255, 113, 91));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
  content: "";
  display: block;
}
.landing-showcase__header {
  height: 35vh;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 3.75em 0 2em;
  position: relative;
  z-index: 5;
  margin-bottom: 2em;
}
.landing-showcase__title {
  font-size: 2em;
  line-height: 1.1;
  width: 100%;
  text-align: center;
}
.landing-showcase__tagline {
  font-size: 1em;
  margin-top: 0.5em;
  max-height: 100px;
  overflow: hidden;
}
.landing-showcase__design-wedge {
  position: absolute;
  top: 35vh;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1C1C1C;
}
.landing-showcase__design-wedge-left-col, .landing-showcase__design-wedge-right-col {
  position: absolute;
  width: 0;
  height: 0;
  backface-visibility: hidden;
  bottom: 100%;
}
.landing-showcase__design-wedge-left-col {
  border-right: 50vw solid transparent;
  border-bottom: 10vw solid #1C1C1C;
  left: 0;
}
.landing-showcase__design-wedge-right-col {
  border-left: 50vw solid transparent;
  border-bottom: 10vw solid #1C1C1C;
  right: 0;
}
.landing-showcase__briefs-action {
  margin: 20px 0;
}
.landing-showcase__briefs-toggle {
  position: relative !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  font-size: 0.75em;
  text-align: center;
  text-transform: uppercase;
  color: #1AC6BA;
  bottom: 2.5em;
  z-index: 5;
  font-weight: 700;
}
.landing-showcase__briefs-toggle-label:before {
  content: "+";
}
@media screen and (max-width: 700px) {
  .landing-showcase__briefs-toggle-label:after {
    content: "";
    position: absolute;
    top: 100%;
    width: 1px;
    left: 50%;
    margin-top: 0.4166666667em;
    height: 2.1875em;
    border-left: 1px solid #1AC6BA;
    backface-visibility: hidden;
  }
}
.landing-showcase__briefs-toggle-label--close {
  display: inline;
  position: relative;
}
@media screen and (max-width: 1000px) {
  .landing-showcase__briefs-toggle-label--close {
    display: none;
  }
  .landing-showcase__briefs-toggle-label--close:after {
    display: none;
  }
}
.landing-showcase__briefs-toggle-label--close:after {
  content: "";
  position: absolute;
  margin-top: 0;
  top: 48%;
  border-top: 1px solid #1AC6BA;
  border-left: 0 none;
  height: 0;
  width: 50vw;
  left: 100%;
  margin-left: 1em;
  display: block;
}
.landing-showcase__briefs-toggle-label--show-desktop {
  display: none;
}
@media screen and (min-width: 48em) {
  .landing-showcase__snapshot {
    height: 44%;
  }
  .landing-showcase__header {
    height: 40vh;
  }
  .landing-showcase__design-wedge {
    top: 40vh;
  }
}
@media screen and (max-height: 25em) {
  .landing-showcase {
    height: auto;
    position: relative;
  }
  .landing-showcase__tagline {
    max-height: 75px;
  }
  .landing-showcase__snapshot {
    width: 100%;
    padding-bottom: 38.5%;
  }
  .landing-showcase__header {
    height: 13.125em;
  }
  .landing-showcase__design-wedge {
    top: 13.125em;
  }
}
@media screen and (min-width: 62.5em) {
  .landing-showcase {
    height: 100%;
  }
  .landing-showcase__app {
    overflow: hidden;
    position: relative;
    min-height: 100%;
  }
  .landing-showcase__title {
    font-size: 3em;
  }
  .landing-showcase__tagline {
    margin-top: 0.5em;
    display: inline-block;
    max-height: none;
  }
  .landing-showcase__content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 100vh;
  }
  .landing-showcase__header {
    height: auto;
    display: block;
    padding: 0;
    margin-bottom: 0;
    margin-right: 6.25em;
  }
  .landing-showcase__header,
.landing-showcase .landing-showcase-nav {
    width: 50%;
    text-align: center;
    margin-top: 6.25em;
    margin-bottom: 6.25em;
  }
  .landing-showcase__snapshot {
    height: 100%;
    right: 40%;
  }
  .landing-showcase__design-wedge {
    position: absolute;
    left: 52%;
    top: 0;
  }
  .landing-showcase__design-wedge-left-col, .landing-showcase__design-wedge-right-col {
    bottom: 100%;
    margin-right: -1px;
  }
  .landing-showcase__design-wedge-left-col {
    border-bottom: 30em solid #1C1C1C;
    border-left: 6.25em solid transparent;
    border-right: 0 none;
    top: 49%;
    left: auto;
    right: 100%;
    margin-right: -1px;
  }
  .landing-showcase__design-wedge-right-col {
    border-top: 30em solid #1C1C1C;
    border-left: 6.25em solid transparent;
    border-bottom: 0 none;
    right: 100%;
    bottom: 49%;
  }
  .landing-showcase__briefs-action {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 2em;
    z-index: 10;
    width: 100%;
    margin: 0;
  }
  .landing-showcase__briefs-toggle {
    position: relative;
    font-size: 0.875em;
    margin-left: 50%;
    width: 50%;
    text-align: center;
    bottom: auto;
    left: auto;
    transform: none;
    overflow: visible;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), color 0.5s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .landing-showcase__briefs-toggle:after {
    content: none;
  }
  .landing-showcase__briefs-toggle-label {
    position: relative;
  }
  .landing-showcase__briefs-toggle-label--show-desktop {
    display: inline;
  }
  .landing-showcase__briefs-toggle-label:after {
    content: "";
    position: absolute;
    top: 48%;
    border-top: 1px solid #1AC6BA;
    border-left: 0 none;
    height: 0;
    width: 50vw;
    left: 100%;
    margin-left: 1em;
    display: block;
  }
  .landing-showcase__briefs-toggle:hover {
    color: #fff;
  }
  .landing-showcase__briefs-toggle .icon-close {
    font-size: 2.1875em;
    line-height: 1;
    vertical-align: middle;
    color: #1AC6BA;
  }
}
.landing-showcase__opaque-briefs-toggle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s cubic-bezier(0.86, 0, 0.07, 1), visibility 0.1s cubic-bezier(0.86, 0, 0.07, 1) 1s;
}
@media screen and (min-height: 940px) and (min-width: 1000px) {
  .landing-showcase__design-wedge-left-col {
    border-bottom: 51vh solid #1C1C1C;
  }
  .landing-showcase__design-wedge-right-col {
    border-top: 51vh solid #1C1C1C;
  }
}
@media screen and (min-width: 62.5em) {
  .landing-showcase {
    min-height: 100vh;
    height: auto;
  }
  .landing-showcase--show-career-briefs .landing-showcase__briefs-toggle {
    transform: translateX(-100%);
    color: #fff;
  }
  .landing-showcase--show-career-briefs .landing-showcase__briefs-toggle-label:before {
    content: "";
  }
  .landing-showcase--show-career-briefs .landing-showcase__opaque-briefs-toggle {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .landing-showcase__title {
    font-size: 5.3125em;
  }
  .landing-showcase__tagline {
    font-size: 1.25em;
    margin-top: 0.5em;
    display: inline-block;
  }
  .landing-showcase__design-wedge {
    left: 48%;
  }
}

.landing-showcase-nav {
  position: relative;
  z-index: 5;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .landing-showcase-nav {
    margin-bottom: 4.5rem;
  }
}
.landing-showcase-nav__link {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #fff;
  color: #fff;
  cursor: pointer;
}
@media screen and (min-width: 48em) {
  .landing-showcase-nav__link:hover {
    color: #2354E8;
  }
}
.landing-showcase-nav__link--external:after {
  display: inline-block;
  font-family: "kirkland";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90b";
  vertical-align: middle;
  color: #7C7C7C;
  font-size: 0.5em;
  padding-left: 0.5em;
}
.landing-showcase-nav__sublist-item {
  margin-top: 0.625em;
}
.landing-showcase-nav__sublist .landing-showcase-nav__link {
  font-size: 1.25em;
  font-weight: 300;
}
.landing-showcase-nav__list-item {
  position: relative;
}
.landing-showcase-nav__list-item + .landing-showcase-nav__list-item:before {
  transition: opacity 0.35s ease-out;
  content: "";
  display: inline-block;
  width: 1.75em;
  border-top: 1px solid #7C7C7C;
  padding-top: 0.625em;
  margin-top: 1.25em;
}
.landing-showcase-nav__list-item--active {
  z-index: 20 !important;
}
.landing-showcase-nav__list-item--active:before {
  opacity: 0;
}
.landing-showcase-nav__title {
  font-size: 1.3125em;
  line-height: 1.3;
  font-family: "Playfair Display", serif;
}
.landing-showcase-nav__group {
  position: relative;
  width: 100%;
}
.landing-showcase-nav__children {
  transition: opacity 0.35s ease-out;
  height: 0;
  overflow: hidden;
  opacity: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
}
.landing-showcase-nav__list-item--active .landing-showcase-nav__children {
  pointer-events: all;
}
.landing-showcase-nav__close-sublist {
  transition: color 0.35s ease-out;
  color: #fff;
  border: 1px solid #7C7C7C;
  width: 1.875em;
  height: 1.875em;
  margin-top: 1em;
}
.landing-showcase-nav__close-sublist:before {
  transition: top 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: -1;
}
.landing-showcase-nav--show-children .landing-showcase-nav__children {
  opacity: 1;
  height: auto;
  transition-delay: 1s;
}
.landing-showcase-nav--show-children .landing-showcase-nav__list-item {
  visibility: hidden;
}
.landing-showcase-nav--show-children .landing-showcase-nav__list-item--active {
  visibility: visible;
}
.landing-showcase-nav--show-children .landing-showcase-nav__list-item:first-child .landing-showcase-nav__children {
  transition-delay: 0s;
}
@media screen and (min-width: 48em) {
  .landing-showcase-nav__list-item + .landing-showcase-nav__list-item:before {
    margin-top: 1.375em;
    padding-top: 0.625em;
  }
  .landing-showcase-nav__sublist-item {
    margin-top: 1.375em;
  }
  .landing-showcase-nav__sublist .landing-showcase-nav__link {
    font-size: 1.75em;
  }
  .landing-showcase-nav__close-sublist {
    width: 2.5em;
    height: 2.5em;
    margin-top: 1.5em;
  }
  .landing-showcase-nav__close-sublist:hover {
    color: #1C1C1C;
  }
  .landing-showcase-nav__close-sublist:hover:before {
    top: 0;
  }
}
@media screen and (min-width: 62.5em) {
  .landing-showcase-nav__title {
    font-size: 3em;
  }
  .landing-showcase-nav__link--external:after {
    font-size: 1rem;
  }
}
@media screen and (min-width: 62.5em) {
  .landing-showcase-nav {
    margin-right: 1.25em;
  }
}

.career-briefs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 100;
  transform: translateY(0%);
  visibility: hidden;
  transition: height 1s cubic-bezier(0.86, 0, 0.07, 1), visibility 1s cubic-bezier(0.86, 0, 0.07, 1);
  overflow: hidden;
}
.career-briefs__card {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 18.75em;
  color: #fff;
  position: relative;
  overflow: hidden;
}
.career-briefs__card + .career-briefs__card {
  border-top: 1px solid #fff;
}
.career-briefs__card:before, .career-briefs__card:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.career-briefs__card:before {
  background-color: rgba(0, 0, 0, 0.3);
}
.career-briefs__card:after {
  transition: opacity 0.35s ease-out;
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgb(255, 113, 91));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
}
.career-briefs__card-title {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
  position: relative;
  z-index: 5;
}
@media screen and (min-width: 48em) {
  .career-briefs__card-title {
    font-size: 2.375em;
  }
}
.career-briefs__card--has-overview .career-briefs__card-title {
  padding-bottom: 20px;
}
.career-briefs__card--has-overview .career-briefs__card-title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  left: calc(50% - 0.7em);
  width: 0.7em;
  border-top: 1px solid #7C7C7C;
}
.career-briefs__card-overview {
  font-family: "Rubik", sans-serif;
  font-size: 1.125em;
  font-weight: 300;
  width: 100%;
  z-index: 5;
  margin-top: 20px;
  padding: 0 15%;
  text-align: center;
}
@media screen and (min-width: 62em) {
  .career-briefs__card-overview {
    font-size: 20px;
  }
}
.career-briefs__card-figure {
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.35s ease-out;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  backface-visibility: hidden;
}
.career-briefs--show-desktop {
  transform: translateY(0%);
  height: 100%;
  visibility: visible;
}
.career-briefs__card {
  height: 33.33%;
}
.career-briefs__card--panel-count-1 {
  height: 100%;
}
.career-briefs__card--panel-count-2 {
  height: 50%;
}
.career-briefs__panel-container {
  height: 92%;
}
.career-briefs__mobile-toggle {
  height: 8%;
  background-color: #1C1C1C;
  color: #1AC6BA;
  text-transform: uppercase;
  width: 100%;
}
.career-briefs__mobile-toggle .icon-close {
  font-size: 1.5em;
  line-height: 1;
  vertical-align: middle;
  color: #fff;
  margin-right: 0.25em;
}
@media screen and (max-height: 25em) {
  .career-briefs__card {
    height: 50%;
  }
  .career-briefs__card--panel-count-1 {
    height: 100%;
  }
  .career-briefs__card--panel-count-2 {
    height: 50%;
  }
  .career-briefs__panel-container {
    overflow-y: auto;
  }
}
@media screen and (min-width: 62.5em) {
  .career-briefs {
    border-top-width: 0;
    border-left: 1px solid #fff;
    transform: translateX(100%);
    height: 100%;
    right: 0;
    top: 0;
    left: auto;
    width: 58%;
    transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), visibility 1s cubic-bezier(0.86, 0, 0.07, 1);
  }
  .career-briefs--show-desktop {
    transform: translateX(0%);
  }
  .career-briefs__panel-container {
    height: 100%;
  }
  .career-briefs__card {
    height: 33.33%;
  }
  .career-briefs__card--panel-count-1 {
    height: 100vh;
  }
  .career-briefs__card--panel-count-2 {
    height: 50vh;
  }
  .career-briefs__card:hover:after {
    opacity: 0.7;
  }
  .career-briefs__card:hover .career-briefs__card-figure {
    transform: scale(1.05);
  }
  .career-briefs__mobile-toggle {
    display: none;
  }
}

.lawyer-stories {
  background-color: #fff;
  padding-bottom: 4.0625em;
  background-color: #fff;
}
.lawyer-stories__figures {
  width: 7.5em;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.lawyer-stories__figures:after {
  transition: opacity 0.35s ease-out;
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgb(255, 113, 91));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
  content: "";
  z-index: 10;
}
.lawyer-stories__snapshot {
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.35s ease-out;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  transition-duration: 1s;
}
.lawyer-stories__snapshot--active {
  opacity: 1;
  z-index: 5;
  transition: opacity 1s ease-out, z-index 0.1s linear 1s;
}
.lawyer-stories__main {
  padding: 1em 0.625em 1em 8.75em;
  position: relative;
  background-color: #E3E3E3;
  height: 14.6875em;
}
.lawyer-stories__tagline {
  font-family: "Playfair Display", serif;
  font-size: 1.25em;
  line-height: 1.3;
  margin-bottom: 1.1em;
}
@media screen and (min-width: 48em) {
  .lawyer-stories__tagline {
    font-size: 1.5em;
  }
}
.lawyer-stories__name {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  line-height: 1.3;
  line-height: 1.4;
  color: #1C1C1C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 48em) {
  .lawyer-stories__name {
    font-size: 2.375em;
  }
}
.lawyer-stories__name-link {
  transition: color 0.35s ease-out, background-color 0.35s ease-out;
  color: #2354E8;
}
@media screen and (min-width: 48em) {
  .lawyer-stories__name-link:hover {
    color: #505050;
  }
}
.lawyer-stories__meta {
  font-size: 1.125em;
  font-weight: 300;
  font-style: italic;
  margin-top: 0.2em;
}
.lawyer-stories__meta:before {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
}
.lawyer-stories__meta-item:not(:last-child):after {
  content: ",";
  margin-right: 0.2em;
}
.lawyer-stories__qa {
  font-weight: 300;
  display: flex;
  flex-wrap: nowrap;
  text-align: left;
}
.lawyer-stories__qa + .lawyer-stories__qa {
  margin-top: 0.625em;
}
.lawyer-stories__qa-prefix {
  width: 1.5em;
  flex-shrink: 0;
}
.lawyer-stories__qa-prefix:after {
  content: ":";
}
.lawyer-stories__count {
  padding: 2em 0;
  display: inline-block;
  position: relative;
  text-align: center;
  padding-left: 2em;
  padding-right: 2em;
}
.lawyer-stories__count-info {
  font-size: 0.875em;
  font-style: italic;
}
.lawyer-stories__details {
  text-align: center;
}
.lawyer-stories__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #FF715B;
}
.lawyer-stories__arrow--left {
  left: 0;
}
.lawyer-stories__arrow--right {
  right: 0;
}
.lawyer-stories__closing {
  margin-top: 2em;
}
.lawyer-stories__name-wrapper {
  flex: 1 1 auto;
}
.lawyer-stories__cta-wrapper {
  flex: 0 0 auto;
}
.lawyer-stories__video-cta {
  position: relative;
}
.lawyer-stories__video-cta:hover::after {
  border-left-color: #fff;
}
.lawyer-stories__video-cta span::after {
  content: "";
  top: 0.15em;
  position: relative;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 15px solid #fff;
  margin-left: 1em;
  display: inline-block;
  transition: border-left-color 0.35s cubic-bezier(0.86, 0, 0.07, 1), box-shadow 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
.lawyer-stories__video-cta:hover span::after {
  border-left-color: #FF715B;
}
@media screen and (min-width: 34.375em) {
  .lawyer-stories__main {
    padding-left: 14.0625em;
    padding-right: 2.5em;
    min-width: 11.5625em;
    height: 10.75em;
  }
  .lawyer-stories__figures {
    width: 11.5625em;
  }
  .lawyer-stories__closing {
    margin-top: 5em;
  }
}
@media screen and (min-width: 48em) {
  .lawyer-stories {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
    padding-left: 50%;
    position: relative;
    transition: all 0.5s;
  }
  .lawyer-stories__main {
    padding-left: 2.5em;
    padding-top: 0;
    padding-bottom: 0;
    min-width: inherit;
    position: static;
    background-color: #fff;
  }
  .lawyer-stories__figures {
    width: 50%;
  }
  .lawyer-stories__closing {
    text-align: left;
    margin-top: 5em;
  }
  .lawyer-stories__name {
    font-size: 1.875em;
  }
  .lawyer-stories__meta {
    margin-top: 0.2em;
  }
  .lawyer-stories__details {
    text-align: left;
  }
  .lawyer-stories__experience {
    height: 10em;
  }
  .lawyer-stories__count-info {
    font-size: 1em;
    font-weight: 300;
  }
  .lawyer-stories__arrow .icon {
    font-size: 1.125em;
  }
}
@media screen and (min-width: 62em) {
  .lawyer-stories {
    padding-top: 6.25em;
    padding-bottom: 6.25em;
  }
  .lawyer-stories__main {
    padding-left: 10%;
    padding-right: 10%;
  }
  .lawyer-stories .wrapper {
    padding-left: 10%;
    padding-right: 10%;
  }
  .lawyer-stories__name {
    font-size: 2.375em;
  }
  .lawyer-stories__qa, .lawyer-stories__meta {
    font-size: 1.25em;
  }
  .lawyer-stories__answer {
    min-height: 7.5em;
  }
  .lawyer-stories__closing {
    margin-top: 4em;
  }
  .lawyer-stories__experience {
    height: 13em;
  }
}
@media screen and (min-width: 85.375em) {
  .lawyer-stories {
    padding-left: 42.1875em;
  }
  .lawyer-stories__figures {
    width: 42.1875em;
  }
  .lawyer-stories__experience {
    max-width: 43.75em;
    height: auto;
  }
}

.story-heading {
  position: relative;
  padding-top: 5.125em;
  background-color: #1C1C1C;
}
.story-heading__background {
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.15;
}
.story-heading__background:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35));
}
.story-heading__tagline {
  margin-bottom: 0.5em;
}
.story-heading__body {
  padding-top: 2em;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.story-heading__figure {
  position: relative;
  margin: 2em auto 0;
  display: block;
  width: 16.875em;
  backface-visibility: hidden;
  box-shadow: 0 10px 40px 10px rgba(0, 0, 0, 0.2);
}
.story-heading__figure:after {
  transition: opacity 0.35s ease-out;
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgb(255, 113, 91));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
  content: "";
}
.story-heading__snapshot {
  display: block;
  max-width: 100%;
}
.story-heading--theme-coral .story-heading__title {
  color: #FF715B;
}
.story-heading__experience {
  font-size: 1.125em;
  font-weight: 300;
  font-style: italic;
  margin-top: 0.5em;
}
.story-heading__experience:before {
  content: "-";
  display: inline-block;
  margin-right: 0.25em;
}
.story-heading__meta:not(:last-child):after {
  content: "/";
  margin-right: 0.2em;
  padding-left: 0.5em;
}
.story-heading__quote {
  margin-top: 2em;
}
.story-heading__quote--mobile {
  padding-bottom: 2em;
}
.story-heading__quote .story-heading__blockquote {
  padding-left: 1em;
  padding-right: 1em;
}
.story-heading__blockquote p:before {
  color: #FF715B;
}
.story-heading__lawyer {
  margin-top: 12px;
}
@media screen and (min-width: 48em) {
  .story-heading:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E3E3E3;
    height: 3.75em;
  }
  .story-heading__body {
    text-align: left;
  }
  .story-heading__lawyer {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    aling-items: stretch;
    width: 100%;
    margin-top: 4.0625em;
  }
  .story-heading__figure {
    flex-shrink: 0;
    margin-right: 1.25em;
    margin-top: 0;
    margin-bottom: 2em;
    overflow: hidden;
  }
  .story-heading__snapshot {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-height: 100%;
    height: 100%;
    max-width: none;
  }
  .story-heading__details {
    flex-grow: 1;
    padding-bottom: 5.75em;
  }
  .story-heading__quote .story-heading__blockquote {
    padding-left: 0;
    padding-right: 0;
  }
  .story-heading__tagline {
    margin-bottom: 0.75em;
  }
  .story-heading__quote {
    font-size: 1.1em;
  }
  .story-heading__quote--desktop {
    display: block !important;
  }
  .story-heading__quote--mobile {
    display: none;
  }
}
@media screen and (min-width: 62em) {
  .story-heading__body {
    padding-top: 6.25em;
  }
  .story-heading__quote {
    font-size: 1.25em;
  }
  .story-heading__figure {
    width: 30em;
    height: 31.5625em;
    margin-right: 4.0625em;
  }
  .story-heading__snapshot {
    position: relative;
  }
  .story-heading__details {
    padding-bottom: 7.8125em;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

[v-cloak] {
  display: none;
}

.page--no-scroll {
  overflow: hidden;
}
.page--no-scroll .profile-bar {
  display: none;
}
.page__home {
  height: 100%;
}
.page__home .site-nav {
  max-width: 92.5em;
}
.page__careers-landing {
  height: 100%;
}
.page__careers-detail .listing-links__figure:before {
  transition: opacity 0.35s ease-out;
  background-image: linear-gradient(to bottom, rgba(255, 113, 91, 0), rgb(255, 113, 91));
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.4;
  transition: opacity 0.35s ease-out, top 0.35s ease-out;
}
.page__people-detail .section.ancillary-info + .section.related-insights {
  margin-top: 1px;
}

.hide-elements-initial--trigger .hide-elements-initial {
  visibility: hidden;
}