.site-search-result {
  margin-bottom: px-to-em($spacing__gutter);
  padding-bottom: px-to-em($spacing__gutter);
  border-bottom: 1px solid $color__brand-gray;
  

  &__name {
    @include titles-banner;

    > a {
      @include links__blue-on-light;
    }
  }

  &__date{
      margin-top: 1em;
  }

  &__context {
    margin-top: 1em;
    
    highlight {
      font-weight: bold;
    }
  }  
  
  &_transition-in { 
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    
    visibility: hidden;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }


  @include respond-to('between') {
    margin-bottom: px-to-em($spacing__gutter * 1.5);
    padding-bottom: px-to-em($spacing__gutter * 1.5);
    display: flex;
    justify-content: space-between;
    
    &__main {
      flex-grow: 1;
      padding-right: px-to-em($spacing__sm);
    }

  }

  @include respond-to('desktop') {
    @include clearfix;


  }
}