.cta-outro { 
  text-align: center;
  background-position: center;
  background-size: cover;

  &__link {
      &--external {
          @include ico-icon-after('external-link') {
            padding-left: 1em;
            transform: translateY(0%);
          }
    }
  }
	@include respond-to('between') {
    &__link {
      padding-left: px-to-em($spacing__gutter);
      padding-right: px-to-em($spacing__gutter);
    }
  }
}