.lawyers-listing {
  $img-width: 85px;
  $img-width--desktop: 150px;

  & + & {
    border-top: 1px solid $color__gray-shady-lady;
    padding-top: px-to-em($spacing__gutter);
    margin-top: px-to-em($spacing__gutter);
  }

  //removed css column layout and changed to flex row. 
  //this fixed incorrect staggering of different sized results 
  //and css column problems on mobile safari
  &__flex-sizer {
    // & + & {
    //   margin-top: px-to-em($spacing__gutter);
    // }

    padding-bottom: px-to-em($spacing__gutter);
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__grid-item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    //used for external professionals on insights 
    &--has-description {

      //all images are top aligned now, this is no longer a special case
      // .lawyers-listing__figure {
        // align-self: flex-start;
      // }

      //hide the "/"
      .lawyers-listing__meta:nth-last-child(2):after{
        display: none;
      }
    }
  }

  &__description {
    margin-top: 0.5em;
  }

  &__meta {
    @include font($size: $font-size__h3, $weight: 'light');

    &--link {
      @include links__blue-on-light;
    }

    &:not(:last-child) {
      &:after {
        content: '/';
        margin: 0 0.5em;
      }
    }
  }

  &__figure {
    position: relative;
    width: px-to-em($img-width);
    flex-shrink: 0;
    margin-right: 1em;

    //while some long bois may hang past the bottom of their images, 
    //this aligns images in 2-column view properly
    //arguably more important
    align-self: flex-start;

    > img {
      max-width: 100%;
      display: block;
    }
  }

  &__details,
  &__name {
    flex-grow: 1;
  }

  &__name {
    @include font($font-family__titles, $font-size__banner)
  }

  &__name-link {
    @include links__blue-on-light;
    word-break: break-word;
  }

  @include respond-to('between') {
    $credentials-grid-spacing: px-to-em($spacing__gutter);
    //see above note- //removed css column layout and changed to flex row. 
    // columns: 2 auto;
    // column-gap: $credentials-grid-spacing;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &__flex-sizer {
      width: 48%; //make up for lack of column-gap

      //testimonial detail, or other one person only
      &:first-child:last-child {
        width: 100%;
      }
    }

    &__grid-item {
      position: relative;
      break-inside: avoid;
      overflow: hidden;

      // &:first-child:last-child {
      //   width: 150%;
      // }

      // & + & {
      //   margin-top: 0;
      // }
    }

    &__figure-placeholder {
      //if the assumption that images are square breaks then this needs to be revisited
      height: px-to-em($img-width)
    }

    // &__details {
      // padding-bottom: px-to-em($spacing__gutter);
    // }
  }
  
	@include respond-to('desktop') {

    & + & {
      padding-top: px-to-em($spacing__md);
      margin-top: px-to-em($spacing__md);
    }
    &__meta {
      font-size: px-to-em($font-size__h3--tablet);
    }

    &__figure {
      width: px-to-em($img-width--desktop);
      margin-right: px-to-em($spacing__gutter);
    }

    &__figure-placeholder {
      height: px-to-em($img-width--desktop);
    }
	}
}