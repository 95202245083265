.advanced-search {
  $grid-spacing: 1em;

  &__filters {

    &--collapsed {
      @include transition(max-height);

      max-height: 0;
      overflow: hidden;
    }

    &--expanded, &--initial-expanded {
      max-height: px-to-em(225px);
    }
  }

  .button-primary__label {
    &:before {
      content: '+';
      width: 1.5em;
      display: inline-block;
    }
  }


  &__toggle {
    display: block;
    text-transform: uppercase;
    margin: px-to-em($spacing__unit) auto 0;

    &--expanded {
      .button-primary__label {
        &:before {
          content: '—';
        }
      }
    }
  }

  
  @include respond-to('landscape') {

    &__filters {
      display: flex;
      flex-wrap: wrap;
      margin: 0 $grid-spacing / -2;
    }
    
    .select-dropdown {
      width: calc(50% - #{$grid-spacing});
      margin-left: $grid-spacing/2;
      margin-right: $grid-spacing/2;

      &--large {
        width: calc(100% - #{$grid-spacing});
      }

      &--centered {
        margin-left: auto ;
        margin-right: auto ;
      }

    }
  }

  @include respond-to('between') {
    margin-top: px-to-em($spacing__sm);
  }

  @include respond-to('desktop') {
    &__filters {
      &--expanded {
        max-height: px-to-em(90px);
      }
    } 
    .select-dropdown {
      width: calc(33.33333% - #{$grid-spacing});

      &--large {
        width: calc(100% - #{$grid-spacing});
      }
      &--medium{
        width: calc(50% - #{$grid-spacing});
      }
    }

    //viewModel adds the count to the CSS class
    &__filters--has-2{
      .select-dropdown {
        width: calc(50% - #{$grid-spacing});
        
      }
    }
  }
}