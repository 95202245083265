.page-heading-overview {
  position: relative;
  padding-top: px-to-em($site-header-height + $spacing__sm);
  background-color: $color__black;

  &__background {
    @include bg-cover;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: -2;
    opacity: 0.15;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35) );
    }
  }

  &__body {
    padding-bottom: px-to-em($spacing__md);
    text-align: center;
    color: $color__white;
    position: relative;
    z-index: 2;
  }


  &__title {
    @include titles-page-title();
  }

  &__copy {
    margin-top: px-to-em($spacing__gutter);
  }

  &--theme-coral & {
    &__title {
      color: $color__brand-coral;
    }
  }

  @include respond-to('landscape') {
    &__body {
      text-align: left;
    }
  }

  @include respond-to('between') {
    &__body {
      padding-bottom:px-to-em($spacing__md) ;
      padding-top:px-to-em($spacing__md);
    }

    //they liked the background zoom in effect
    // if this changes again, you need to fix the 600px absolute image overlapping
    // when the first component is not position:relative
    
    // &__background {
    //     &--careers-detail {
    //         bottom: auto;
    //         height: px-to-em(600px);
    //     }
    // }


    .read-more{
      &__button {
        margin-top: px-to-em($spacing__sm * 2);
      }
    }
  }

  @include respond-to('desktop') {
    &__body {
      padding-bottom:px-to-em($spacing__lg) ;
      padding-top:px-to-em($spacing__lg);
    }
  }
}