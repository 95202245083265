.button-listing {
  
  & + & {
    border-top: 1px solid $color__gray-shady-lady;
    padding-top: px-to-em($spacing__gutter);
    margin-top: px-to-em($spacing__gutter);
  }

  display: flex;
  align-items: center;
  flex-direction: row;
  // justify-content: center;
  // flex-wrap: nowrap;

  &__grid-item {
    // display: flex;
    // align-items: center;
    // flex-direction: row;
    // flex-wrap: nowrap;
    // width: 100%;

    // & + & {
    //   margin-top: px-to-em($spacing__gutter);
    // }
    margin-right: px-to-em($spacing__gutter);
  }


  @include respond-to('landscape') {
    $grid-spacing: px-to-em($spacing__gutter);

    columns: 2 auto;
    column-gap: $grid-spacing;

  }


  @include respond-to('desktop') {

    // & + & {
    //   padding-top: px-to-em($spacing__md);
    //   margin-top: px-to-em($spacing__md);
    // }


  }
  


}