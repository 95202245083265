.podcast {
  $icon-dimension: 55px;

  border: 1px solid $color__border-gray;
  border-width: 1px 0;
  padding-top: px-to-em($spacing__gutter);
  padding-bottom: px-to-em($spacing__gutter);
  
  
  &__play {
    display: inline-flex;
    align-items: center;
    text-align: left;
  }
  
  &__play-icon {
    @include transition(color, border-color);

    flex-shrink: 0;
    display: inline-block;
    vertical-align: middle;
    width: px-to-em($icon-dimension);
    height: px-to-em($icon-dimension);
    border: 1px solid $color__brand-blue;
    border-radius: 50%;
    position: relative;
    text-align: center;
    color: $color__brand-blue;

    &:before {
      @include center-align(both);

      font-size: px-to-em(26px);
    }
  }
  &__title {
    display: inline-block;
    flex-grow: 1;
    padding-left: 1em;
  }

  &__duration {
    &:before {
      content: '—';
      display: inline-block;
      padding-left: 0.5em;
      padding-right: 0.5em;
      font-weight: map-get($font-weights, 'light');
    }
  }

  &__audio {
    display: none;
    width: 100%;
    margin-top: 10px;
  }

  &--show-audio-element{
     .podcast__audio {
      display: block;
     }
  }

	@include respond-to('between') {
    $icon-dimension: 75px;

    &__play:hover & {
      &__play-icon {
        // border-color: $color__brand-coral;
        // color: $color__brand-coral;
        border-color: $color__blue-light;
        color: $color__blue-light;
      }
    }

    &__play-icon {
      width: px-to-em($icon-dimension);
      height: px-to-em($icon-dimension);

      &:before {
        font-size: px-to-em(42px);
      }
    }

    &__title {
      @include font($size: $font-size__subheading--tablet, $weight: 'light' );

      padding-left: 1.5em;
    }
	}
}