.infographics {
  $grid-spacing: 1em;

  background-color: $color__white;

  .section ~ & {
    // margin-top: px-to-em($spacing__md);
	  margin-bottom: px-to-em($spacing__md);
  }

  &__grid {
    @include section--padded;

    border: 1px solid $color__border-gray;
    border-width: 1px 0;
  }

  &--no-vertical-spacing {
      
      .infographics__grid {
          border: none;
          padding-bottom: 0;
          padding-top: 0;
      }
  }

  .infographic-card {
    margin-top: $grid-spacing;
  }

  & + .section-layout {
    padding-top: 0;
  }

  @include respond-to('between') {
    &--gray {
      background-color: $color__brand-gray;
    }

    .section ~ & {
      margin-top: 0;
      margin-bottom: 0;
    }
    
    &__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 $grid-spacing / -2;
    }

    .infographic-card {
      width: calc(50% - #{$grid-spacing});
      margin: $grid-spacing/2;
    }

    .infographic-card--intro {
      width: calc(100% - #{$grid-spacing});
    }
  }

  @include respond-to('desktop-large') {
    .infographic-card {
      width: calc(33.33333% - #{$grid-spacing});
    }
  }
}
