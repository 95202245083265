.landing-showcase-nav {
  position: relative;
  z-index: 5;
  text-align: center;

  @media screen and (max-width: 800px){
    margin-bottom: 4.5rem;
  }
  &__link {
    @include links__white-on-black();
    color: $color__white;
    cursor: pointer;

    &--external {
      @include ico-icon-after('external-link') {
        vertical-align: middle;
        color: $color__grey;
        font-size: px-to-em($font-size__small, $font-size__h1);
        padding-left: 0.5em;
      }
    }
  }

  &__sublist-item {
    margin-top:  px-to-em($spacing__unit);    
  }

  &__sublist & {
    &__link {
      @include font($size: $font-size__prominent-card, $weight: 'light');
    }
  }

  &__list-item {
    position: relative;

    & + & {
      &:before {
        @include transition(opacity);

        content: '';
        display: inline-block;
        width:px-to-em(28px);
        border-top: 1px solid $color__grey;
        padding-top: px-to-em($spacing__unit);
        margin-top: px-to-em($spacing__unit * 2); 
      }
    }

    &--active {
      z-index: 20 !important; // for safari mobile

      &:before {
        opacity: 0;
      }
    }
  }

  &__title {
    font-size: px-to-em($font-size__h1 * 0.75);
    line-height: 1.3;
    font-family: $font-family__titles;
  }

  &__group {
    position: relative;
    width: 100%;
  }

  &__children {
    @include transition(opacity);

    height: 0;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    width: 100%;

    //on tablet views, the submenus were still clickable with opacity 0
    pointer-events: none;
    .landing-showcase-nav__list-item--active & {
      pointer-events: all;
    }

  }

  &__close-sublist {
    $dimension: 30px;
    @include transition(color);
    color: $color__white;
    border: 1px solid $color__grey;
    width: px-to-em($dimension);
    height: px-to-em($dimension);
    margin-top: 1em;

    &:before {
      @include transition-bezier(top);

      content: '';
      display: block;
      position: absolute;
      top: 100%;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $color__white;
      z-index: -1;
    }
  }

  &--show-children & {
    &__children {
      opacity: 1;
      height: auto;
      transition-delay: 1s
    }
    &__list-item {
      visibility: hidden;

      &--active {
        visibility: visible;
      }
    }

    &__list-item:first-child {
      .landing-showcase-nav__children {
        transition-delay: 0s
      }
    }
  }

  @include respond-to('between') {
    &__list-item {
      & + & {
        &:before {
          margin-top: px-to-em($spacing__sm);
          padding-top: px-to-em($spacing__unit);
        }
      }
    }

    &__sublist-item {
      margin-top: px-to-em($spacing__sm);
    }

    &__sublist & {    
      &__link {
        font-size: px-to-em($font-size__prominent-card--tablet);
      }
    }

    &__close-sublist {
      $dimension: 40px;
      width: px-to-em($dimension);
      height: px-to-em($dimension);
      margin-top: 1.5em;

      &:hover {
        color: $color__black;

        &:before {
          top: 0;
        }
      }
    }
  }

  @include respond-to('showcase-desktop') {
    &__title {
      font-size: px-to-em($font-size__h1--tablet);
    }

    &__link {
      &--external {
        &:after {
          font-size: rem(16px);
        }
      }
    }
  }

  @include respond-to('showcase-desktop--careers') {
      margin-right: px-to-em(20px);
  }
}
