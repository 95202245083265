.showcase-panel {
  $font-size__headline:   20px;
  $font-size__headline--tablet:   30px;
  $font-size__headline--desktop:   38px;

  .showcase--init & {
    opacity: 1;
  }

  &__figures {
    height: $showcase__img-frame-height; 
    // .showcase-panel--marquee-story & {
    //   height: $showcase__img-frame-height--marquee-story;
    // }

    width: 100%;
    overflow: hidden;
    position: absolute;
    z-index: $showcase__zindex-photo;
    top: 0;
    background-color: $color__black;
    
    &:after {
      content: '';
      background-image: linear-gradient(to bottom, $color__gradient--start, $color__gradient--end );
      position: absolute;
      bottom: 0;
      top: $showcase__img-frame-height;
      width: 100%;
      opacity: 0.6;
      transition: $transition__card-gradient; 
    }
  }

  &__img {
    @include bg-cover(top center);

    position: absolute;
    width: 105%;
    height: 100%;
    backface-visibility: hidden;
    opacity: 0;
    transition: transform 7s ease-out,
      opacity 1s ease-out; 

    &--active {
      opacity: 1;
      transition: transform 7s ease-out,
          opacity 1s ease-out; 
  
      .showcase-panel__figures--pan-image & {
        transform: translateX(-4.8%);
      }
    }

    &--desktop {
      display: none;
    }
  }

  &__headlines {
    $border-height: 20px;
    text-align: center;
  }

  &__headline-frame {
    position: relative;
    margin-top: $showcase__headline-height;

    // .showcase-panel--marquee-story & {
    //   margin-top: $showcase__headline-height--marquee-story;
    // }

    padding-bottom: px-to-em(50px);
    z-index: $showcase__zindex-headline;
  }

  &__headline {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease-out;
    backface-visibility: hidden;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    color: $color__white;
    width: 100%;
    //TODO: change width back on higher breakpoints?

    //hide ugly focus indicator
    //the discussion decided it was worth the slight accessibility compromise
    &:focus {
      outline-style: none;
    }
    
    &--active {
      opacity: 1;
      visibility: visible;
      overflow: visible;
      transition: opacity 1s ease-out;
    }

    .showcase-panel__frame--transition-out & {
      transition: opacity 1s ease-out,
        visibility 0.1s linear 1s;
    }
  }

  &__category {
    text-transform: uppercase;
    color: $color__gray-text;
    font-size: px-to-em($font-size__smallest);
    margin-bottom: 0.5em;
  }

  &__title {
    @include font($font-family__titles, $font-size__headline);

    line-height: 1.4;

    //removed height restriction + scrollability because it breaks swipe handling on this element.
    //this was originally here to allow the title to scroll if it got too long

    // max-height: 200px;
    // height: auto;
    //overflow-y: auto; 
    // &--carousel-title {
    //     height: 250px;
    // }
    
  }

  &__date {
    @include transition(opacity);
    opacity: 0;
    font-size: px-to-em($font-size__smallest);
    color: $color__gray-text;
    margin-top: 1em;
    transition-duration: $animation__overlay-expanded;
    display: block;
  }

  &__video-button {
    font-family: $font-family__copy;
    font-size: $font-size-base;
    color: $color__white;
    border: 1px solid $color__white;
    padding: .5em 1.5em;
    margin-top: 1.5em;
    display: inline-block;
    position: relative;
    overflow: hidden;
    opacity: 1;
    transition: all .35s cubic-bezier(.86,0,.07,1),box-shadow .35s cubic-bezier(.86,0,.07,1);

    &--hidden {
      opacity: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }

    &:hover {
      color: $color__black;
    }

    &:hover::before {
      top: 0;
    }

    &:hover::after {
      border-left-color: $color__black;
    }

    &::after {
      content: '';
      width: 0; 
      height: 0; 
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid $color__white;
      margin-left: 1em;
      display: inline-block;
      vertical-align: middle;
      transition: border-left-color .35s cubic-bezier(.86,0,.07,1),box-shadow .35s cubic-bezier(.86,0,.07,1);
    }
    
    &::before {
      content: '';
      background-color: $color__white;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: -1;
      transition: top .35s cubic-bezier(.86,0,.07,1),box-shadow .35s cubic-bezier(.86,0,.07,1);
    }
  }

  &__shade {
    position: absolute;
    background-color: $color__black;
    height: 100%;
    width: 55vw;
    right: 52%;
    transform: translateX(-4.375em);
    top: 100%;
    z-index: 0;
    display: none;
  }


  @include respond-to($showcase-tablet-fix) {
    &__figures {
      height: $showcase__img-frame-height--tablet; 
      
      &:after {
        top: $showcase__img-frame-height--tablet;
      }
    }

    &__headline-frame {
      margin-top: $showcase__headline-height--tablet;
    }
  }

  @include respond-to('height-landscape'){
      // &__title {
      //     max-height: 90px;
      //     height: auto;
      //     &--carousel-title {
      //       height: 85px;
      //     }
      // }
  }
  @include respond-to('between') {

    &__title {
      font-size: px-to-em($font-size__headline--tablet);
      height: auto;
    }
  }
  

  @include respond-to('showcase-desktop') {

    &__shade {
      display: block;
    }
    
    & + & {
      opacity: 0;
      visibility: hidden;
    }
    
    &__figures {
      position: absolute;
      height: 100%;
      width: 49%;  /// 49 instead of 50 helps the line that shows up in IE. can change to 50 if you make the line go away otherwise
      // width: 50%;
      top: 0;
      left: 0;

      &:before {
        //gray bar to hide image once detail is expanded;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $color__brand-gray;
        // width: px-to-em(70px);
        width: 10vw; //used to be separate width just for between 1000 and 1200 px
        z-index: 2;
        opacity: 0;
        transition: opacity 0.1s linear 0.5s;
      }
    }
  
    &__img {
      @include bg-cover(top center);
  
      position: absolute;
      width: 115.5%;
      height: 100%;

      &--active {
    
        .showcase-panel__figures--pan-image & {
          transform: translateX(-15%);
        }

        .showcase-panel__figures--hover-image & {
          transform: translateX(-15%) scale(1.25);
        }

        //IE was showing a weird line in the active / show detail state
        //speed up the transition to make it go away as fast as possible
        .showcase-layout--show-detail & {
          transition: opacity 0.1s linear 0.5s, transform 0.1s ease-out 0.5s;
          opacity: 0;
        }
        
      }

      &--desktop {
        display: block;
      }

      &--mobile {
        display: none;
      }
    }

    &__headlines {
      height: 100%;
      text-align: left;

      &:before,
      &:after {
        display: none;
      }
    }

    &__headline-frame {
      height: 100%;
      background-color: transparent;
      transition: all 1s cubic-bezier(0.86, 0, 0.07, 1);
      width: 50%;
      padding: 0;
      margin-top: 0;
      margin-left: 50%;

      .showcase-panel--marquee-story & {
        margin-top: 0;
      }
    }

    &__headline {
      @include transition(color);
      @include center-align(vertical);
      width: 100%;
      
      padding-left: px-to-em($wrapper-padding--tablet);

      &:hover {
        color: $color__blue-light;
      }
    }

    &__category {
      font-size: px-to-em($font-size__small);
      width: 100%;
    }

    &__title {
      max-width: 85%;
      // height: auto;
      // overflow-y: visible;
    }
  }

  //1200px (showcase-desktop is 1000px)
  @include respond-to('desktop-large') {
    &__title {
      @include transition(color);
      font-size: px-to-em(38px);
      max-width: 80%;
    }

  }
}
