.showcase-insights {
  $grid-spacing: 1em;

  padding-bottom: px-to-em($spacing__md);  

  &__grid-item {
    margin-bottom: $grid-spacing;
  }

  &__more {
    margin: px-to-em($spacing__sm) auto 1em;
    cursor: pointer;
    
    .button-primary__label {

      &:before {
        content: '+';
        display: inline-block;
        width: px-to-em(20px);
      }
    }
  }

  &__watch {
    padding-top: 1em;
    margin-top: auto;
    cursor: pointer;

    .button-primary {
      padding: .75em 1.25em;
      text-align: left;
      min-width: 0;

      &:hover {
        .button-primary__label:after {
          border-left: 10px solid $color__white;
        }
      }
    }
    
    .button-primary__label {

      &:after {
        content: '';
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 10px solid $color__brand-blue;
        display: inline-block;
        vertical-align: middle;
        margin-left: .5em;
        transition: .3s ease-in-out all;
      }
    }
  }
  
  //two across 
  @include respond-to('landscape') {
    padding-bottom: px-to-em($spacing__lg);

    &__grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0 $grid-spacing / -2;
    }

    &__grid-item {
      width: calc(50% - #{$grid-spacing});
      margin: 0 $grid-spacing/2;
      margin-bottom: $grid-spacing;
      // max-width: px-to-em($insight-card-width-ideal);

      &--prominent {
        width: calc(100% - #{$grid-spacing});
      }

      //default: at the end
      order: 10;

      //in this view, show the prominent card third - aka the second row
      &--1, &--3 {
        order: 1;
      }

      &--2 {
        order: 2;
      }

      &--4, &--5 {
        order: 3;
      }
    }
  }

  @include respond-to('between') {
    &__more {
      margin: px-to-em($spacing__gutter) auto 0;
    }
  }

  //three across
  //1200px
  @include respond-to('desktop-large') {
    &__grid-item {
      width: calc(33.33333% - #{$grid-spacing});

      &--prominent {
        width: calc(66.66667% - #{$grid-spacing});
        // order: 0;
      }


      //first, third, fourth go before the prominent card
      &--1, &--3, &--4{
        order: 1
      }

      //prominent position on desktop - move to 4th
      //aka first on left on the second line
      &--2 {
        order: 2;
      }

      &--5 {
        order: 3;
      }
    }
  }
}
