
.search-criteria {
  background-color: $color__white;

  &--insights {
    padding-top: px-to-em($spacing__md);
  }

  &__filters-list {
    display: inline;
  }

  &__applied-filter {
    display: inline-block;
    border:1px solid $color__brand-blue;
    font-size: px-to-em($font-size__small);
    padding: px-to-em($spacing__unit) px-to-em(12px);
    color: $color__brand-blue;
    margin-bottom: px-to-em($spacing__unit/2);
    margin-right: px-to-em($spacing__unit/2);
  }
  &__remove-filter {
    display: inline-block;
    vertical-align: middle;
  }

  &__clear {
    color: $color__brand-blue;
    text-transform: uppercase;
  }

  &__matches {
    @include font($font-family__titles);

    font-style: italic;
    margin-bottom: px-to-em($spacing__sm);
    text-align: center;
  }

  &__clear {
    display: block;
    
    text-align: left;
    margin: px-to-em($spacing__sm) auto 0;
  }
  &__filters-label {
    display: inline-block;
    padding-right: 0.5em;
    max-width: 100%;
    word-break: break-word;
  }

  @include respond-to('landscape') {
    
    &__filters-applied {
      display: flex;
      flex-wrap: nowrap;
      align-items: baseline;
      padding-right: 1em;
    }

    &__applied-filter {
      margin-right: 0.5em;
      margin-bottom: 0.75em;
    }

    &__filters-label,
    &__clear {
      flex-shrink: 0;
    }

    &__clear {
      margin-left: px-to-em($spacing__sm);
    }

  }
  @include respond-to('between') {
    &--insights {
      padding-top: px-to-em($spacing__md);
    }
  }
  
  @include respond-to('desktop') {
    &__matches {
      float: right;
      margin-top: px-to-em($spacing__unit * 1.75);
      margin-bottom: 0;
      
      &--site-search {
        //no clearable filters, is shorter
        margin-top: 0;
      }
    }

    &__subtype-wrapper {
      float: right;
      font-size: .875em;
      margin-top: (px-to-em(8px));
      min-width: 11em;
    }

    &__subtype-wrapper + &__matches {
      clear: right;
    }
  }

}
