.language-dropdown {

    &--profile {
        &--mobile {

            margin:auto;
            margin-top: px-to-em($spacing__sm);
            
            @include respond-to('landscape') {
                width: 100%;
                max-width: px-to-em(260px);
            }
            @include respond-to('between') {
                margin-left: 0;
                width: auto;
            }
            @include respond-to('desktop'){
                display: none;
            }
        }






        &--desktop {
            display: none;
            @include respond-to('desktop') {
                display: block;
                position: absolute;
                // right: px-to-em($wrapper-padding);
                // bottom: $spacing__gutter * 2;
                transform: translateY(-100%);
                margin-top: px-to-em($spacing__gutter * -2);
                // right: px-to-em($wrapper-padding--tablet);
                right: 0;
                
            }
        }


        &--desktop, &--mobile {
            &.dropdown-custom--show-dropdown .dropdown-custom__list {
                z-index: 7;
            }
        }
    }








    &--services {
        margin-top: px-to-em($spacing__sm);
        

        @include respond-to('landscape') {
            // margin-top: px-to-em($spacing__sm);
            width: 100%;
            max-width: px-to-em(260px);
            margin-left: auto;
        }
        

    }


}

