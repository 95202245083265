//on locations detail, we want to keep the arrows over the image on mobile, rather than dropping them down below like on general content pages

.carousel-highlights {
  @include bg-cover;
  $slide-width: 1400;
  $slide-height: 600;
  $slide-ratio: $slide-height / $slide-width;

  $arrow-size: 20px;
  $arrow-diamond-size: 32px;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100%;
  // padding-bottom: $slide-ratio * 100%;
  height: auto;

  position: relative;
  background-position: center;

  &--captions-below-on-mobile {
    .carousel-highlights__desktop-only {
      display: none;
    }
  }

  &--show-slides &  {

    padding-bottom: $slide-ratio * 100%;
    // transition: padding-bottom 0.1s $transition__ease;
    // transition-delay: .3s;

    &__intro {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s $transition__ease,
                  visibility 0.5s ease-out 0.5s;
    }
    &__arrows {
      opacity: 1;
    }

    &__slides {
      opacity: 1;
      visibility: visible;
    }

    &__mobile-bottom {
      opacity: 1;
      transition: opacity 0.5s $transition__ease;
    }

  }

  //IE doesn't inherit opacity like other browsers do
  &--show-slides {
    .carousel-highlights__slide--active {
      .carousel-highlights__slide-title-wrapper,
      .carousel-highlights__slide-image {
        opacity: 1;
      }
    }
  }

  &__intro {
    @include transition(background-color);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 15;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    padding-left: $wrapper-padding;
    padding-right: $wrapper-padding;
    align-items: center;

    &--hovered {
      background-color: rgba(0,0,0,0.25);
    }
  }

  &__title {
    @include titles-banner();

    width: 100%;
    text-align: center;
    color: $color__white;

    &:after {
      content: '';
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: px-to-em(28px, $font-size__banner);
      padding-bottom: px-to-em($spacing__sm, $font-size__banner);
      margin-bottom: px-to-em($spacing__sm, $font-size__banner);
      border-bottom: 1px solid $color__white;
    }
  }

  .button-utility__label {
    padding-right: 0;
  }

  &__slides {
    @include transition(opacity 1s);

    opacity: 0;
    visibility: hidden;
  }



  &__slide,
  &__slide-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__slide-image {
    @include bg-cover;

    background-position: 50%;
  }

  &__slide {
    z-index: 10;
    &--active {
      visibility: visible;
    }
  }

  &__slide-title-wrapper,
  &__slide-image  {
    @include transition(opacity);

    opacity: 0;
  }


  &__slide-title-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.65);
    color: $color__white;
    z-index: 5;
    padding: px-to-em($spacing__sm);
  }

  &__mobile-bottom {
    opacity: 0;
    position: relative;
    min-height: px-to-em(92px); //show proper height even when no images have captions
  }

  &__mobile-slide-title-wrapper {
    padding: px-to-em($spacing__sm) px-to-em($arrow-diamond-size + $spacing__gutter);
    text-align: center;
  }

  &__mobile-titles {
    // padding-top: px-to-em($spacing__gutter);
    padding-top: 0; // lots of room because captions will likely be multi-line on mobile 
    //for larger, will vertical align the first line of text with the arrows
  }

  &__slide-title {
    font-weight: map-get($map: $font-weights, $key: light);
    font-style: italic;
  }

  &__arrows {
    @include transition(opacity 1s);

    @include center-align(horizontal);

    top: px-to-em($spacing__gutter * 2);
    width: 100%;
    opacity: 0;
    z-index: 10;
  }

  &__mobile-arrows{
    width: 100%;
    position: absolute;
    padding-top: px-to-em($spacing__gutter);
    top:0;
  }

  &__arrow {
    float: left;
    overflow: visible;
    color: $color__white;

    &--right {
      float: right;
    }
  }

  &__arrow-icon {
    font-size: px-to-em($arrow-size);
    position: relative;
    z-index: 1;
    width: px-to-em($arrow-diamond-size, $arrow-size);
    height: px-to-em($arrow-diamond-size, $arrow-size);
    padding-top: px-to-em($arrow-diamond-size/6, $arrow-size);
    display: block;
  }

  &__arrow-diamond {
    @include transition(box-shadow);

    transition-delay: $transition__timing;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
    overflow: hidden;
    z-index: -1;
    background-color: $color__black;

    &:after {
      @include transition(top,left);
      content: '';
      display: block;
      position: absolute;
      width: 150%;
      height: 150%;
      transform: rotate(-45deg);
      background-color: $color__white;
      top: 85%;
      left: 85%;
      z-index: -2;
    }
  }

  @include respond-to('landscape') {
    padding-bottom: $slide-ratio * 100%;

    &__mobile-titles {
      padding-top: 1em; //vertical align the first line of text with the arrows
    }
  }

	@include respond-to('between') {
    $arrow-size: 28px;
    $arrow-diamond-size: 48px;

    .button-utility {
      padding: px-to-em($spacing__sm) px-to-em($spacing__gutter);
    }
    &__title {
      &:after {
        width: px-to-em(28px, $font-size__banner--tablet);
        padding-bottom: px-to-em($spacing__sm, $font-size__banner--tablet);
        margin-bottom: px-to-em($spacing__gutter, $font-size__banner--tablet);
      }
    }

    &__mobile-titles {
      padding-top: 1.5em; //vertical align the first line of text with the arrows
    }

    &__mobile-slide-title-wrapper {
      padding: px-to-em($spacing__sm) px-to-em($arrow-diamond-size + $spacing__gutter * 2);
    }

    &__arrows {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__arrow-icon {
      font-size: px-to-em($arrow-size);
      width: px-to-em($arrow-diamond-size, $arrow-size);
      height: px-to-em($arrow-diamond-size, $arrow-size);
      padding-top: px-to-em($arrow-diamond-size/6, $arrow-size);
      display: block;
    }

    &__arrow:hover {
      color: $color__black;
    }

    &__arrow:hover & {
      
      &__arrow-diamond {
        box-shadow: $color__box-shadow; //$button__box-shadow;
        &:after {
          top: -25%;
          left: -25%;
        }
      }
    }
	}


  //only hide when we are showing mobile controls/caption underneath
  @include respond-to('desktop') {
    &--captions-below-on-mobile {
      .carousel-highlights__mobile-only {
        display: none;
      }

      .carousel-highlights__desktop-only {
        display: block;
      }
    }
  }
}