.prominent-card {
  $padding-sides: px-to-em(25px);
  $padding-vertical: px-to-em(20px);

  display: block;
  width: 100%;
  padding: $padding-vertical $padding-sides;
  color: $color__white;
  background-color: $color__brand-blue;
  overflow: hidden;
  position: relative;
  z-index: 1;
  min-height: px-to-em($insight-card-height/1.25);
  height: 100%;

  &__meta {
    display: block;
    font-size: px-to-em($font-size__small);
    position: relative;
    z-index: 5;

    &:after {
      content: '';
      display: block;
      width: px-to-em(15px, $font-size__small);
      height: px-to-em(2px, $font-size__small);
      background-color: $color__brand-blue;
      margin-top: px-to-em(24px, $font-size__small);
      margin-bottom: px-to-em(24px, $font-size__small);
    }
  }

  &__date {
    &:after {
      content: '-';
      margin-left: 0.5em;
      margin-right: 0.25em;
    }
  }

  &__title {
    @include font($size: $font-size__prominent-card, $weight: 'light');
    display: block;
    position: relative;
    z-index: 5;
  }
  
  &__wave {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $color__brand-blue;
  }

  // &__dummy {
  //   position: absolute;
  //   background-color: $color__brand-blue;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 5;
  // }

  &__details {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    @include respond-to('desktop') {
      flex-wrap: nowrap;
    }
  }

  &__button {
    flex: 0 0 auto;
    background-color: $color__white;
    z-index: 1;
    padding: .75em 1.25em;
    text-align: left;
    min-width: 0;
    margin-top: 1em;
    margin-left: 0;
    border: 1px solid transparent;
    transition: .3s ease-in-out all;

    @include respond-to('desktop') {
      margin-left: 1em;
      margin-top: 0;
    }

      &:hover {
        border: 1px solid $color__white;
        .button-primary__label:after {
          border-left: 10px solid $color__white;
        }
      }

      .button-primary__label {

        &:after {
          content: '';
          width: 0; 
          height: 0; 
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 10px solid $color__brand-blue;
          display: inline-block;
          vertical-align: middle;
          margin-left: .5em;
          transition: .3s ease-in-out all;
        }
      }
  }
  
  @include respond-to('between') {
    $padding-sides: 35px;
    $padding-vertical: 30px;

    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: px-to-em($insight-card-height);
    padding: $padding-vertical $padding-sides;
    transition: $transition__card;

    &__meta {
      flex-shrink: 1;

      transition: $transition__card;
    }

    &__title {
      font-size: px-to-em($font-size__prominent-card--tablet);
    }

    &:hover {
      box-shadow: 0 10px 30px 5px rgba(0,0,0,0.15);
  
    }
  }
}