.locations-heading {
  @include heading--base();

  &__background {
    @include heading__background();
  }

  &__body {
    @include heading__body();
  }

  &__header {
    margin-bottom: px-to-em($spacing__sm);
  }

  &__title {
    @include titles-page-title();
  }

  &__cta {
    @include heading__cta();
    word-wrap: break-word;
  }

  &__phone-entry,
  &__address {
    font-size: px-to-em($font-size__h3);
  }

  &__address {
    font-style: normal;
    margin-bottom: px-to-em($spacing__gutter, $font-size__h3);
  }

  .address,
  .firm,
  .country {
    display: block;
  }

  &__phone-label {
    display: inline-block;
  }

  &__utility {
    margin-top: px-to-em($spacing__gutter);
  }


  &__utility-link {
    display: block;
    width: 100%;
    max-width: px-to-em(260px);
    margin-left: auto;
    margin-right: auto;

    & + & {
      margin-top: px-to-em($spacing__sm);
    }
  }

  .language-dropdown {
    margin-top: px-to-em($spacing__sm);
    width: 100%;
    max-width: px-to-em(260px);
    margin-left: auto;
    margin-right: auto;
  }



  @include respond-to('landscape') {
    &__options {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      margin-top: px-to-em($spacing__gutter);
    }

    &__body {
      text-align: left;
    }

    &__contact {
      flex-grow: 1;
    }

    &__utility {
      margin-top: 0;
      width: px-to-em(200px);
      flex-shrink: 0;
    }

    &__utility-link {
      & + & {
        margin-top: px-to-em($spacing__unit);
      }
    }

    .language-dropdown {
      margin-top: px-to-em($spacing__unit);
    }
  }

  @include respond-to('between') {
    &__body {
      padding-bottom:px-to-em($spacing__md) ;
      padding-top:px-to-em($spacing__md);
      min-height: px-to-em(350px);
    }

    &__header {
      align-items: flex-start;
    }

    &__title {
      max-width: px-to-em(775px, $font-size__banner--tablet)
    }

    &__phone-entry,
    &__address {
      font-size: px-to-em($font-size__h3--tablet);
    }

    &__utility {
      width: px-to-em(270px);
    }
  }

  @include respond-to('desktop') {
 &__header {
      display: flex;
      flex-wrap: nowrap;
      text-align: left;
    }

    &__related-cta {
     @include heading__related-cta();
     padding-top: px-to-em($spacing__gutter);
    }

    &__cta {
      flex-shrink: 0;
      margin-top: 0;

      &:hover {
        &:after {
          transform: translateX( px-to-em($spacing__unit));
        }
      }
    }
  }
}