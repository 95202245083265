.section-accordion {
  $diamond-size: 25px;
  $padding-sides: 1em;

  background-color: transparent;

  @media (max-width: px-to-em($screen-sm - 1 )){
    //no padding for mobile because the accordions all stack closely together
    //use max-width breakpoint so we don't have to know what the padding on desktop is to reset it

    //no padding top for 2nd, 3rd 4th accordions on mobile...
    &--combined-on-mobile + &--combined-on-mobile {
      padding-top: 0;
    }

    //no padding bottom for every accordion except for the last one
    &--combined-on-mobile:not(:last-child){
      padding-bottom: 0;
    }

    // &:not(.section-accordion--keep-bottom-padding):not(:last-child){
    //   padding-bottom: 0 !important;
    // }
  }

  margin-bottom: 1px;

  &__toggle {
    background-color: $color__black;
    border: 1px solid transparent;
  }

  &__label {
    color: $color__white;
    padding: $padding-sides;
    padding-right: ($padding-sides*1.5) + px-to-em($diamond-size, $font-size__h2);
    position: relative;
    width: 100%;
    text-align: left;
    line-height: 1.2;

    br {
      display: none;
    }
  }

  &__content {
    height: 0;
    overflow: hidden;
  }

  &__icon {
    @include center-align(vertical);

    font-size: px-to-em(16px, $font-size__h2);
    display: inline-block;
    right: $padding-sides;
    text-align: center;
    color: $color__white;
    width: px-to-em($diamond-size);
    height: px-to-em($diamond-size);
    margin-top: px-to-em($diamond-size * 0.25);
  }

  &__icon-diamond {
    position: absolute;
    top: px-to-em($diamond-size * -0.15);
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $color__gray-text;
    transform: rotate(45deg);
    overflow: hidden;
  }
  
  //plus icon
  .icon-close {
    
    &:before {
      @include transition(transform);
      
      display: inline-block;
      transform: rotate(45deg);
      position: relative;
      z-index: 2;
    }
  }
  //overrides
  .titles__subheading {
      padding-bottom: 0;
    &:after {
      display: none;
    }
  }
  &__toggle {
    &--is-expanded {
      background-color: $color__white;
      border-color: $color__brand-gray;
    }
  }

  &__toggle--is-expanded & {

    // &__content {
    //   height: auto;
    //   overflow: visible;
    // }

    &__label {
      color: $color__black;
    }

    &__icon {
      @include transition(color);

      color: $color__black;

      &:before {
        transform: rotate(0deg);
      }
    }
  }

  @include respond-to('between') {    
    margin-bottom: 0;

    &__content {
      height: auto !important;
      overflow: visible;
    } 

    &__toggle {
      background-color: transparent;
      border: 0 none;
    }

    &__label {
      color: inherit;
      padding: 0;
      padding-right: 0;
      position: static;
      cursor: text;

      br {
        display: block;
      }
    }

    &__icon {
      display: none;
    }

    //overrides
    .titles__subheading {
      &:after {
        display: block;
      }
    }
  }
}
