

.profile-bar {
  $profile-img: 60px;
  $profile-img--tablet: 100px;

  $name-size: 18px;
  $name-size--tablet: 24px;

  @include transition(transform);

  background-color: $color__white;
  min-height: px-to-em($profile-img);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex__site-header - 5;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  will-change: transform;
  transform: translateY(-100%);

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $color__white;
    backface-visibility: hidden;
    box-shadow: 1px 3px 8px 1px rgba(0,0,0,0.2);
  }

 
  &--sticky {
    transform: translateY(0);
  }

  &__figure {
    width: px-to-em($profile-img);
    position: relative;
    margin: 0 auto;
    flex-shrink: 0;

    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   bottom: 0;
    //   right: 0;
    //   opacity: 0.2;
    //   background-image: linear-gradient(to bottom, $color__gradient--start, $color__gradient--end );
    // }
  }

  &__img {
    width: 100%;
    display: block;
    backface-visibility: hidden;
  }

  &__info {
    flex-grow: 1;
    padding: 0.5em 1em;
    line-height: 1.2; // Allow 2 lines for lawyer names before causing spacing issues
  }

  &__name {
    @include font($font-family__titles, $name-size);

    // &--last {
    //   display: none;
    // }
  }

  &__email {
    display: none;
    font-size: px-to-em($font-size__small);
    color: $color__brand-blue;
  }

  
  &__jump-nav {
    @include transition(transform);

    position: absolute;
    top: 100%;
    left: 0;
    background-color: $color__white;
    border-top: 1px solid $color__brand-gray;
    transform: translateY(-100%);
    z-index: -2;
  }

  &__jump-link {
    $line-width: 25px;
    $line-width-spacing: 10px;
    color: $color__brand-blue;
    font-size: px-to-em($font-size__small);
    width: 100%;
    text-align: left;

    border: 1px solid $color__brand-gray;
    border-width: 0 1px 1px;
    padding: px-to-em($spacing__unit, $font-size__small) px-to-em($wrapper-padding, $font-size__small);

  }

  &__nav-toggle {
    $icon-size: 16px;
    margin-right: px-to-em($wrapper-padding);
    font-size: px-to-em($font-size__small);
    color: $color__brand-blue;
    padding-right: px-to-em($icon-size * 1.25, $font-size__small);
    position: relative;
    
    @include ico-icon-after('chevron-thin-down') {
      @include center-align(vertical);
      @include transition(transform);

      font-size: px-to-em($icon-size, $font-size__small);
      right: 0;
    }
  }

  &--show-dropdown &  {
    &__nav-toggle {
      &:after {
        transform: rotateX(180deg) translateY(50%);
      }
    }
    &__jump-nav {
      transform: translateY(0);
    }
  }

  @include respond-to('between') {
    min-height:  px-to-em($profile-img--tablet);

    &:before {
      box-shadow: 0 10px 40px 10px rgba(0,0,0,0.2);
    }

    &__info {
      flex-grow: 1;
      padding: 1em px-to-em($spacing__sm);
      line-height: inherit;
    }

    &__figure {
      width: px-to-em($profile-img--tablet);
    }

    &__name {
      font-size: px-to-em($name-size--tablet);

      // &--last {
      //   display: inline;
      // }
    }

    &__email {
      display: inline-block;
      @include transition(color);
      &:hover {
        // color: $color__gray-text;
        color: $color__gray-charcoal-alt;
      }
    }

    &__jump-nav {
      position: static;
      top: auto;
      left: auto;
      flex-shrink: 0;
      padding-right: px-to-em($spacing__gutter);
      transform: none;
      z-index: 1;
      border-top: 0 none;
    }

    &__nav-toggle {
      display: none;
    }

    &__jump-link {
      $line-width: 25px;
      $line-width-spacing: 10px;

      padding: 0 0 0 px-to-em($line-width + $line-width-spacing, $font-size__small);
      position: relative;
      width: auto;
      border: 0 none;

      @include transition(color);

      &:hover {
        color: $color__gray-text;
      }

      &--active {
        color: $color__gray-text;
      }

      &:after {

        content: '';
        top: 49%;
        position: absolute;
        left: -0.2em;
        border-top: 1px solid $color__gray-text;
        width: px-to-em($line-width, $font-size__small);
        margin:0 px-to-em($line-width-spacing/2, $font-size__small);
      }

      &:first-child {
        padding-left: 0;

        &:after {
          display: none;
        }
      }

      &__is-active {
        color: $color__gray-text;
      }
    }
  }
}