.video-card {
    max-width: $wrapper-max-width--narrow;
    margin: auto;

    &__eyebrow {
        display: block;
        color: $color__brand-blue;
        font-size: .875em;
        line-height: 1.5;
    }   

    &__title {
        margin-bottom: 10px;
        cursor: pointer;
    }

    &__video-container {
        position: relative;
    }

    &__icon {
        position: absolute;
        z-index: 1;
        right: 16px;
        bottom: 16px;
    }

    &__cover {
        width: 100%;
        display: block;
        position: absolute;
        padding-bottom: 56.25%;
        height: 0;
        background-color: transparent;
        z-index: 10;
    }

    &__iframe-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__details {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        flex-wrap: wrap;
        border-bottom: 1px solid $color__gray-shady-lady;
    }

    &__share {
        flex: 0 1 auto;
        position: relative;
    }

    &__share-btn {
        color: $color__brand-blue;
        font-size: .875em;
    }

    &__share-content {
        // display: none;
        opacity: 0;
        pointer-events: none;
        z-index: 0;
        max-width: 220px;
        width: 100%;
        padding: .875em;
        background-color: $color__white;
        position: relative;
        margin-bottom: 24px;
        position: absolute;
        width: 220px;
        bottom: 100%;
        left: 1em;
        transition: .3s ease all;

        &.active {
            z-index: 1;
            pointer-events: auto;
            opacity: 1;
        }

        &::after {
            content: '';
            width: 0;
            height: 0;
            border-top: 24px solid $color__white;
            border-right: 24px solid transparent;
            position: absolute;
            left: 0;
            bottom: -24px;
            z-index: 1;
        }
    }

    &__share-input {
        border: 0;
        border-bottom: 1px solid $color__gray-shady-lady;
        padding-bottom: 5px;
        width: 100%;
    }

    &__share-copy {
        color: $color__brand-blue;
        font-size: .875em;
    }

    &__time-container {
        flex: 0 1 auto;
    }

    &__time {
        font-size: .875em;
    }

    &__time-icon {
        margin-right: .25em;
    }

    &__caption {
        font-style: italic;
        color: $color__gray-charcoal;
        flex: 1 1 100%;
        margin-top: .5em;
        border-bottom: 1px solid $color__gray-shady-lady;
        padding-bottom: 1em;
        margin-bottom: 1em;
    }

    &__desc {
        padding: 1.5em 0;
    }

    &__cta {
        font-weight: 500;
        color: $color__brand-blue;
        margin-top: 1em;
        display: block;
    }

    &__accordion {
        border-top: 1px solid $color__white;
        padding-top: 27px;
        margin-top: 2em;
        transition: .3s ease all;
        border-bottom: 1px solid $color__white;

        &.open {
            padding-bottom: 27px;
            transition: .3s ease all;
        }
    }

    &__accordion-title-area {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__title-icon {
        height: 42px;
        width: 42px;
        svg {
            width: 100%;
            height: 100%;
        }
    }

    &__accordion-content {
        padding: 0;
        padding-right: 50px;
        transition: .3s ease all;
    }
}

// video card styles on home page only
// hide share feature
#homepage {
    .video-card {
        &__share {
            display: none;
        }

        &__time-container {
            margin-left:auto;
        }

        // &__caption {
        //     border-bottom: 0;
        //     padding-bottom: 0;
        //     margin-bottom: 0;
        // }

        &__accordion {
            display: none;
        }

        // &__iframe-container {
        //     display: none;
        // }
    }
}


// video card styles on insight detail page only
// .page__articles-detail {
//     .video-card {
//         &__desc {
//             display: none;
//         }
//         &__cta {
//             display: none!important;
//         }

//         &__title {
//             display: none;
//         }

//         &__eyebrow {
//             display: none;
//         }

//         &__caption {
//             display: block!important;
//         }
//     }
// }