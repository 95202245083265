$site-header-height: 60px;
$site-header-height--tablet: 150px;
$site-header-height--headroom-tablet: 100px;
$site-header-padding-sides: 6px;

@keyframes headroom-fadein { 
  0% {opacity: 0}
  100% { opacity: 1}
}

@keyframes headroom-fadein-reverse { 
  0% {opacity: 1}
  50% {opacity: 0}
  100% { opacity: 1}
}

.site-header {
  height:  px-to-em($site-header-height);
  position: absolute;
  top: 0;
  width: 100%;
  z-index: $zindex__site-header;
  padding: px-to-em(20px) 0;

  &__h1-tag{
      font-size: 1em;
  }

  &__hidden-label{
    position: absolute;
    pointer-events: none;
    opacity: 0;
    right: 100vw;
  }

  //headroom specific
  &.headroom--not-top {
    position: fixed;
    transform: translateY(-100%);
    background-color: $color__black;

    animation: headroom-fadein 0.5s ease-in forwards;
  }

  &.headroom--not-top &{
    &__body {
      max-width: none;
    }
  }

  &.headroom--unpinned {
    transform: translateY(-100%);
    transition: transform 0.2s $transition__ease,
                background-color 0.2s $transition__ease;
  }

  &.headroom--pinned {
    transform: translateY(0);
    transition: transform 0.2s $transition__ease,
                background-color 0.2s $transition__ease;
  }

  &.headroom--top {
    transition: none;

    // animation: headroom-fadein-reverse 0.5s ease-in forwards;
  }
  
  &__body {
    @include clearfix;

    text-align: right;
    position: relative
  }

  &__brand {
    display: block;
    width: px-to-em(190px);
    float: left;
    position: relative;
  }

  &__logo {
    display: block;
    width: 100%;
    transition: opacity 0.35s ease-in;
  }

  &__logo--black {
    opacity: 0;
    position: absolute;
    top: 0; 
    left: 0;
  }

  &.site-header--overlay-expanded {
    background-color: transparent;
  }

  .site-header--overlay-expanded & {
    &__logo {
      opacity: 0;
    }
    &__logo--black {
      opacity: 1;
      transition-delay: 0.5s
    }
  }
  
  &__search {
    color: $color__white;
    font-size: px-to-em(22px);
    margin-right: 0.5em;
    vertical-align: top;
    line-height: 1;
    overflow: visible;

    @include ico-icon-after('close') {
      @include transition(opacity);

      position: absolute;
      top: 0;
      left: 0;
      font-size: px-to-em(26px, 20px);
      line-height: 0.75;
      opacity: 0;
    }

    //magnifiying glass icon
    &:before {
      @include transition(opacity);
    }

    &--expanded {
      &:before {
        opacity: 0
      }
      &:after {
        opacity: 1;
      }
    }
  }

  &--showcase {
    transition: .2s opacity $transition__ease;
  }

  &--showcase.site-header--modal-open {
    // bulletproof no iteraction
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    display: none;
    height: 0;
    overflow: hidden;
  }

  &--showcase-expanded-logo & {
    &__logo {
      opacity: 0;
      transition-delay: 0s;
    }
    &__logo--black {
      opacity: 1;
      transition-delay: 0.5s
    }

    &__search {
      color: $color__gray-text;
    }
  }

  //just for the marquee story detail
  &--showcase-marquee-story.site-header--overlay-expanded & {
    &__logo {
      opacity: 0 !important;
    }
    &__logo--black {
      opacity: 1 !important;
    }
  }

  //breadcrumb invisibility was still messing up space for marquee detail page
  &--showcase-marquee-story:not(.headroom--pinned) {
    .breadcrumbs {
      display: none;
    }
  }

  @include respond-to('between') {
    padding: px-to-em(45px) 0;
    height:  px-to-em($site-header-height--headroom-tablet);

    //do not apply these styles on the marquee detail page when headroom is active, 
    //which is the only case where it looks like the showcase, but has the regular header
    &--showcase:not(.headroom--pinned) &{
      &__body {
        display: block;
        text-align: right;
      }
      &__brand {
        float: left;
      }
    }

    &--showcase-expanded & {
      &__logo {
        opacity: 0;
      }
      &__logo--black {
        opacity: 1;
        transition-delay: 0.5s
      }
    }

    &.headroom--not-top {
      height: px-to-em($site-header-height--headroom-tablet);
      padding-top: px-to-em(35px);

      .site-header__brand {
        width: px-to-em(220px);
      }

      &:not(.site-header--overlay-expanded) {
        .breadcrumbs {
          visibility: visible;
        }
      }
    }

    &__body {
      text-align: left;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
    }

    .breadcrumbs {
      flex-grow: 1;
      padding-left: 5%;
      visibility: hidden;
    }

    &__brand {
      // @include transition(width);
      width: px-to-em(270px);
      flex-shrink: 0;
    }

    &__search {
      font-size: px-to-em(28px);
      margin-right: 0.75em;
      color: $color__gray-text;

      &:hover {
        color: $color__white;
      }
    }

    $arrow-size: 40px;
    $arrow-diamond-size: 65px;
    
    &--auxillary {
      opacity: 1;

      .site-header__body {
        padding-left: calc(50% + #{$wrapper-padding--tablet});
      }
    }    
  }

  @include respond-to('desktop') {
    &__brand {
      width: px-to-em(286px);
    }
  }

  @include respond-to('showcase-desktop') {
    &--showcase:not(.headroom--pinned):not(&--showcase-marquee-story) & {
      &__brand{
        margin-left: calc(50% + #{px-to-em($wrapper-padding--tablet)});
      }
    }
  }
}

