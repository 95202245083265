.team-carousel {
  $arrow-size: 20px;
  $arrow-diamond-size: 36px;
  padding-bottom: px-to-em($spacing__lg);

  &__desktop {
    display: none;
  }
  
  &__body {
    width: px-to-em( $wrapper-padding *2 + $team-card-width);
    position: relative;
  }

  &__intro {
    background-color: $color__brand-gray;
    padding: px-to-em($wrapper-padding);

    //don't do the fancy line break thing on mobile view 
    br {
      display: none;
    }
  }

  &__view-all {
    color: $color__brand-blue;
  }

  &__slides {
    overflow: hidden;
  }

  &__belt {
    display: flex;
    flex-wrap: nowrap;
    backface-visibility: hidden;
    flex-basis: px-to-em($team-card-width);
    transition: all 1s cubic-bezier(0.86, 0, 0.07, 1) ;

    .team-card {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &__controls {
    padding-top: 36px;
    //arrows overflow their size 
    padding-left: 5px;
    padding-right: 5px;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  &__arrow {
    font-size: px-to-em($arrow-size);
    position: relative;
    z-index: 1;
    width: px-to-em($arrow-diamond-size, $arrow-size);
    height: px-to-em($arrow-diamond-size, $arrow-size);
    color: $color__white;
    transition: opacity 0.25s ease-in;
    display: block;
    overflow: visible;

    &:after {
      @include center-align(both);

      content: '';
      display: block;
      background-color: $color__gray-charcoal;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%) rotate(45deg);
      z-index: -1;
    }

    &--disabled {
      &:after {
        opacity: 0.25;
      }
    }
  }
  
  @include respond-to('landscape') {    
    &__body {
      width: px-to-em( $wrapper-padding *2 + $team-card-width *2 );
    }
  }

  @include respond-to('between') {    
    &__body {
      // width: auto;
      width: px-to-em( $wrapper-padding--tablet *2 + $team-card-width *2 );
      
    }
  }

  @include respond-to('showcase-desktop') {
    padding-bottom: 0;

   
    &__body {
      width: auto;
      display: flex;
      flex-wrap: nowrap;
      
    }


    &__intro {
      // flex-grow: 1;
      flex-grow: 0;
      width: px-to-em(215px);

      //bring back the line breaks
      br {
        display: block;
      }
    }

    &__slides {
      flex-shrink: 0;
      // flex-basis: px-to-em($team-card-width * 2);
      flex-basis: px-to-em($team-card-width--medium * 2);

      //removed box shadow because it was weird with carousel with only 2 items
      // box-shadow: 15px 16px 30px -4px rgba(0,0,0,.15);
    }

    &__controls {
      right: auto;
      top: auto;
      position: relative;
      left:auto;
      bottom: auto;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      flex-grow: 1;
      
      padding:0;
      padding-right: px-ot-em($wrapper-padding);
    }

    &__arrow {
      margin-bottom: 1em;
    }

  }


  //3 slides at 1200px
  @include respond-to('desktop-large') {
    &__slides {
      flex-shrink: 0;
      flex-basis: px-to-em($team-card-width--medium * 3);
    }
    &__controls {
      padding-right: 0;
    }
  }
}
