.mobile-toggle {
  $bun-width: px-to-em(30px);
  $bun-height: 3px;
  $bun-spacing-between: 5px;

  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: $bun-width;
  height: $bun-width; //some mobile browsers don't apply the height to click it
  padding-top: px-to-em($bun-spacing-between + $bun-height);
  color: $color__gray-text;
  vertical-align: top;
  overflow: visible;

  &__label {
    display: none;
  }

  &__bars {
    @include transition(background-color);

    width: 100%;
    display: inline-block;
    height: $bun-height;
    background-color: $color__brand-coral;
    position: absolute;
    left: 50%;
    top: px-to-em($bun-spacing-between + $bun-height + 3px);
    transform: translate(-50%, -50%);

    &:before,
    &:after {
      @include transition(background-color);

      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: -1 * px-to-em($bun-spacing-between + $bun-height);
      background-color: $color__brand-coral;
      display: block;
      transition: all 0.25s;
    }

    &:after {
      top: auto;
      bottom: -1 * px-to-em($bun-spacing-between + $bun-height);
    }
  }

  .mobile-toggle--expanded &{
    &__bars {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }

      &:before {
        transform: rotate(-45deg);
        top: 0;
      }

      &:after {
        transform: rotate(45deg);
        bottom: 0;
      }
    }
  }

  @include respond-to('between') {

    $bun-width: px-to-em(30px);
    $bun-spacing-between: 7px;

    padding-top: px-to-em($bun-spacing-between*3 + $bun-height) ;
    width: auto;

    &:hover &{
      &__bars {
        background-color: $color__white;

        &:before,
        &:after {
          background-color: $color__white;
        }
      }
    }

    .mobile-toggle--expanded:hover &{
      &__bars {
        transition: none;
        background-color: transparent;
      }
    }

    &__bars {
      position: absolute;
      top: px-to-em($bun-spacing-between + $bun-height);
      width: 100%;

      &:before {
        top: -1 * px-to-em($bun-spacing-between + $bun-height);
      }

      &:after {
        bottom: -1 * px-to-em($bun-spacing-between + $bun-height);
      }
    }

    &__label {
      display: inline-block;
      text-transform: uppercase;
      text-align: left;
      font-size: px-to-em(12px);

    }
  }
}
