.read-more {
	//reducing this to be a bit lower than the trigger height to try and eliminate read mores reveal next to nothing
	$viewable-height: 600px;
	$viewable-height--desktop: 600px; 

	&__button {
		margin: px-to-em($spacing__sm) auto 0;
		display: block;
	}

	.button-primary__label {
		&:before {
			content: '+';
			display: inline-block;
			width: px-to-em(20px);
		}
	}

	&--is-expanded {
		.button-primary__label {
			&:before {
				content: '–'
			}
		}
	}

	&--not-expandable &{
		&__window {
			height: auto;

			&:after {
				content: none;
			}
		}
	}
    &--initial-not-expandable &{
        &__window {
			height: auto;
            max-height: px-to-em(300px);
			
            &:after {
				content: none;
			}
		}
    }

	.overview-block.section--gray & {
	// .overview-block--bio & {
		&__window {
			&:after {
				background-image: linear-gradient(to bottom, $color__gradient-gray--start, $color__gradient-gray--end );
			}
		}
	}

	&__window {
		@include transition(height);
		overflow: hidden;
		height: $viewable-height;
		position: relative;

		&:after {
			@include transition(top);

			content: '';

			display: block;
			position: absolute;
			top: px-to-em($viewable-height / 2);
			bottom: 0;
			left: 0;
			right: 0;
			background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 100) );
			z-index: 2;
			pointer-events: none;
		}
	}

	&--is-expanded & {
		&__window {
			&:after {
				top: 100%;
			}
		}
	}

	&--separate-intro & {

		&__window {
			height: 0;
			&:before {
				content: '';
				display:block;
				height: px-to-em($spacing__sm)
			}
			&:after {
				content: none;
			}
		}
	}

	@include respond-to('between') {

		&__button {
			margin-top: px-to-em($spacing__gutter);
		}

		&__window {
			height: px-to-em($viewable-height--desktop);
		}

		.section--gray &{
			&__window {
				&:after {
					background-image: linear-gradient(to bottom, $color__gradient-gray--start, $color__gradient-gray--end );
				}
			}
		}

		&--separate-intro & {
			&__window {
				&:before {
					height: px-to-em($spacing__gutter)
				}
			}
		}
	}

	@include respond-to('desktop') {
		&__button {
			margin-left: 0;

		}
	}
}