@keyframes fadeInContent {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.landing-showcase {
    $window-image-view: 35%;
    $window-image-view--tablet: 40%;

    min-height: px-to-em(400px);

    $showcase__animation: all 1s $transition__bezier;
    opacity: 0;
    transition: $showcase__animation;

    &--init {
        opacity: 1;
    }

    &__app {
        background-color: $color__black;
        height: auto;
        position: relative;
    }

    &__snapshot {
        @include bg-cover;
        background-position: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: $window-image-view * 1.1;

        &:after {
            @include overlay-gradient__coral();
            content: '';
            display: block;
        }
    }

    &__content {
   
    }

    &__header {
        height: 35vh;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        justify-content: center;
        color: $color__white;
        padding: px-to-em($site-header-height) 0 px-to-em($spacing__gutter);
        position: relative;
        z-index: 5;
        margin-bottom: px-to-em($spacing__gutter);
    }

    &__title {
        font-size: px-to-em($font-size__hero);
        line-height: 1.1;
        width: 100%;
        text-align: center;
    }

    &__tagline {
        font-size: px-to-em($font-size__h4);
        margin-top: 0.5em;
        max-height: 100px;
        overflow: hidden;
    }

    &__design-wedge {
        position: absolute;
        top: 35vh;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $color__black;

        $wedge-angle: 10vw;
        $wedge-width: 50vw;

        &-left-col,
        &-right-col {
            position: absolute;
            width: 0;
            height: 0;
            backface-visibility: hidden;
            bottom: 100%;
        }

        &-left-col {
            border-right: $wedge-width solid transparent;
            border-bottom: $wedge-angle solid $color__black;
            left: 0;
        }

        &-right-col {
            border-left: $wedge-width solid transparent;
            border-bottom: $wedge-angle solid $color__black;
            right: 0;
        }
    }
    &__briefs-action{
        margin: 20px 0;
    }

    &__briefs-toggle {
        $border-height: 30px;
        position: relative !important;

        @include center-align(horizontal);
        top: 0;
        font-size: px-to-em($font-size__smallest);
        text-align: center;
        text-transform: uppercase;
        color: $color__brand-teal;
        bottom: px-to-em($border-height, $font-size__smallest);
        z-index: 5;
        font-weight: map-get($map: $font-weights, $key: 'bold');

        &-label {
            &:before {
                content: "+";
            }
            @media screen and (max-width: 700px) {
            &:after {
                content: '';
                position: absolute;
                top: 100%;
                width: 1px;
                left: 50%;
                margin-top: px-to-em($spacing__unit/2, $font-size__smallest);
                height: px-to-em($border-height + $spacing__unit/2);
                border-left: 1px solid $color__brand-teal;
                backface-visibility: hidden;
            }
        }

            &--close{
           
                @media screen and (max-width:  $showcase-desktop--careers) {
                    display: none; 
                    &:after{
                        display: none;
                    }   
                }
                
                display:inline;
                position:relative;
                &:after {
                    content: '';
                    position: absolute;
                    margin-top: 0;
                    top: 48%;
                    border-top: 1px solid $color__brand-teal;
                    border-left: 0 none;
                    height: 0;
                    width: 50vw;
                    left: 100%;
                    margin-left: 1em;
                    display: block;
                }
            }
     
            &--show-desktop {
                display: none;
            }
        }
 
    }

    @include respond-to('between') {
        &__snapshot {
            height: $window-image-view--tablet * 1.1;
        }

        &__header {
            height: 40vh;
        }

        &__design-wedge {
            top: 40vh;
        }
    }

    @include respond-to-max-height('height-landscape') {
        height: auto;
        position: relative;

        &__tagline {
            max-height: 75px;
        }
        &__snapshot {
            width: 100%;
            padding-bottom: $window-image-view * 1.1;
        }
        &__header{
            height: 13.125em;
        }

        &__design-wedge {
            $offset: 600 * 0.35px;

            top: px-to-em($offset);
        }
    }

    
    // @include respond-to('between') {
    @include respond-to('showcase-desktop--careers') {
        height: 100%;

        &__app {
            overflow: hidden;
            position: relative;
            min-height: 100%;
        }

        &__title {
            font-size: px-to-em($font-size__hero * 1.5);
        }

        &__tagline {
            margin-top: 0.5em;
            display: inline-block;
            max-height: none;
        }

        &__content {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            min-height:  100vh;
        }

        &__header {
            height: auto;
            display: block;
            padding: 0;
            margin-bottom: 0;
            margin-right: px-to-em(100px);
        }

        &__header,
        .landing-showcase-nav {
           
            width: 50%;
            text-align: center;
            margin-top: px-to-em(100px);
            margin-bottom: px-to-em(100px);
        }

        &__snapshot {
            height: 100%;
            right: 40%;
        }

        &__design-wedge {
            position: absolute;
            left: 52%;
            top: 0;

            $wedge-angle: 10vw;
            $wedge-width: 50vw;

            &-left-col,
            &-right-col {
                bottom: 100%;
                margin-right: -1px;
            }

            &-left-col {
                border-bottom: 30em solid $color__black; //50.1 fixes rounding calculation issue in safari
                border-left: px-to-em(100px) solid transparent;
                border-right: 0 none;
                top: 49%;
                left: auto;
                right: 100%;
                margin-right: -1px;
            }

            &-right-col {
                border-top: 30em solid $color__black;
                border-left: px-to-em(100px) solid transparent;
                border-bottom: 0 none;
                right: 100%;
                bottom: 49%;
            }
        }

        &__briefs-action {
            @include center-align(horizontal);
            position: absolute;
            bottom: px-to-em($spacing__gutter);
            z-index: 10;
            width: 100%;
            margin: 0;
        }

        &__briefs-toggle {
            position: relative;
            font-size: px-to-em($font-size__small);
            margin-left: 50%;
            width: 50%;
            text-align: center;
            bottom: auto;
            left: auto;
            transform: none;
            overflow: visible;
            transition: transform 1s $transition__bezier, color 0.5s $transition__bezier;


            &:after {
                content: none;
            }

            &-label {
                position: relative;
                
                &--show-desktop {
                    display: inline;
                }
                &:after {
                    content: '';
                    position: absolute;
                    top: 48%;
                    border-top: 1px solid $color__brand-teal;
                    border-left: 0 none;
                    height: 0;
                    width: 50vw;
                    left: 100%;
                    margin-left: 1em;
                    display: block;
                }
         
            }
            &:hover {
                color: $color__white;
            }

            .icon-close {
                font-size: px-to-em(35px);
                line-height: 1;
                vertical-align: middle;
                color: $color__brand-teal;
            }

        }
    }

    &__opaque-briefs-toggle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        display: block;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        transition: opacity 1s $transition__bezier, visibility 0.1s $transition__bezier 1s;
    }

    @media screen and (min-height: 940px) and (min-width: $showcase-desktop--careers) {
        &__design-wedge {
            &-left-col {
                border-bottom: 51vh solid $color__black; //50.1 fixes rounding calculation issue in safari
            }

            &-right-col {
                border-top: 51vh solid $color__black;
            }
        }
    }

    @include respond-to('showcase-desktop--careers') {
        min-height:100vh;
        height: auto;

        &--show-career-briefs & {
            &__briefs-toggle {
                transform: translateX(-100%);
                color: $color__white;

                &-label {
                    &:before {
                        content: '';
                    }
                }
            }

            &__opaque-briefs-toggle {
                opacity: 1;
                visibility: visible;
                transition: opacity 1s $transition__bezier;
            }
        }

        &__title {
            font-size: px-to-em($font-size__hero--tablet);
        }

        &__tagline {
            font-size: px-to-em($font-size__h3--tablet);
            margin-top: 0.5em;
            display: inline-block;
        }

        &__design-wedge {
            left: 48%;
        }
    }
}
