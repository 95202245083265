//standard
$spacing-base: $font-size-base;

//variation
$spacing__sm: 22px;
$spacing__md: 65px;
$spacing__lg: 100px; //150px //

$spacing__gutter: 32px;
$spacing__unit: 10px;
$spacing__gutter-custom: 25px;
$spacing__gutter-custom-level: 42px;
$spacing__gutter-custom-tablet: 23px;
$spacing__gutter-professional: 10px;
$spacing__normalize: $spacing-base * $font__line-height;

//homepage
$showcase__img-frame-height: 40%;
$showcase__headline-height: 42vh;

$showcase__img-frame-height--tablet: 55%;
$showcase__headline-height--tablet: 57vh;

$spacing__custom-wrapper: 1003px;
$spacing__custom-wrapper-tablet: 334px;

//marquee story detail
//on mobile view, there are no < - - - > indicator things on the bottom so we make the image height a little heigher
// $showcase__img-frame-height--marquee-story: 60%;
// $showcase__headline-height--marquee-story: 63vh;

//nvm we're removing this 