.site-nav {
  $font-size__primary: 22px;
  $font-size__secondary: 18px;
  $font-size__primary--tablet: 40px;
  $font-size__secondary--tablet: 32px;
  $border: 1px solid $color__gray-shady-lady;

  $transition-timing: 0.4s;

  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  &--expanded {
    z-index: 4;
  }

  // Disable nav links when search is open
  &:not(&--expanded) {
    .site-nav__link,
    .site-nav__auxillary-link,
    .social-links__item a {
      pointer-events: none;
    }
    .site-nav__link,
    .site-nav__auxillary-link {
      // fix "pointer-events: none" not working in IE11 on elements with "display: inline"
      display: inline-block;
    }
  }

  &__primary {
    width: 100%;
  }

  &__auxillary {
    align-items: flex-end;
    flex-shrink: 0;
  }

  &__item {
    margin-bottom: 2vh;
  }

  &__link {
    font-size: px-to-em($font-size__secondary);
  }

  &__list--primary & {
    &__link {
      font-size: px-to-em($font-size__primary);
      color: $color__brand-blue;
      @include transition(color);
      &:hover {
        color: $color__gray-hover--for-gray-background;
      }
    }
  }

  &__list--secondary & {
    &__link {
      @include transition(color);
      //"should be a lighter grey"
      &:hover {
        color: $color__gray-text;
      }
    }
  }

  .social-links {
    $icon-size: 24px;
    $icon-box-size: 40px;
    overflow: hidden;
    margin-bottom: 1em;
    padding-right: 1px;

    &__item {
      float: left;
      margin-right: -1px;
    }

    &__icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: px-to-em($icon-size);
      width: px-to-em($icon-box-size,$icon-size);
      height: px-to-em($icon-box-size,$icon-size);
      text-align: center;
      border: $border;
    }
  }
  &__auxillary-info {
    font-size: px-to-em($font-size__smallest);
    line-height: 1.6;
  }

  &__auxillary-option {
    margin-bottom: 1em;
  }

  &__auxillary-link {
    @include transition(color);
    color: $color__brand-blue;
    &:hover {
      color: $color__gray-hover--for-gray-background;
    }
  }

  @include respond-to('between') {

    &__item {
      margin-bottom: 2.5vh;
      text-align: right;
    }
  
    &__link {
      font-size: px-to-em($font-size__secondary--tablet);  
    }

    &__list--primary {
      .site-nav__link {
        font-size: px-to-em($font-size__primary--tablet);
      }
    }

    &__auxillary {
      padding-bottom: px-to-em($spacing__sm * 2 );
      text-align: right;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
    }
    &__auxillary-info {
      padding-left: 2em;
      flex-grow:1;
    }
    &__auxillary-nav {
      display: inline;
      margin-right: 1em;
    }
    &__auxillary-option {
      $slash-spacing: 0.4em;

      display: inline;
      margin-bottom: 0;

      &:after {
        content: "/";
        
        margin:0 $slash-spacing;
      }

      &:last-child  {
        &:after {
          content: close-quote;
          margin-right: 0;
        }
      }
    }

    .social-links {
      $icon-size: 30px;
      $icon-box-size: 60px;

      flex-shrink: 0;
      margin-bottom: 0;
      overflow: visible;

      &__icon {
        font-size: px-to-em($icon-size);
        width: px-to-em($icon-box-size,$icon-size);
        height: px-to-em($icon-box-size,$icon-size);

        &:hover {
          background-color: $color__white;
          color: $color__black;
        }
      }
    }
  }
}
