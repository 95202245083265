$wrapper-padding: 20px;
$wrapper-padding--tablet: 40px;
$wrapper-padding--desktop: 50px;

$wrapper-max-width: 1200px;
$wrapper-max-width--wide: 1400px;
$wrapper-max-width--narrow: 1000px;
$wrapper-max-width--custom: 1003px;
$wrapper-max-width--custom-tablet: 334px;

.wrapper {  
  padding-left: px-to-em($wrapper-padding);
  padding-right: px-to-em($wrapper-padding);
  margin-left: auto;
  margin-right: auto;

    @include respond-to('between') {
	    max-width: px-to-em($wrapper-max-width) + px-to-em($wrapper-padding--tablet * 2);
	    padding-left: px-to-em($wrapper-padding--tablet);
	    padding-right: px-to-em($wrapper-padding--tablet);
		
	    &--wide {
		    max-width: px-to-em($wrapper-max-width--wide) +  px-to-em($wrapper-padding--tablet * 2);
	    }

	    &--narrow {
		    max-width: px-to-em($wrapper-max-width--narrow) +  px-to-em($wrapper-padding--tablet * 2);
	    }
    }
}