.expandable-services {
  position: relative;
  margin-left: px-to-em($wrapper-padding * -1);
  margin-right: px-to-em($wrapper-padding * -1);
  
  &--is-expanded & {
    &__toggle {
      &:before {
        content: '—'
      }
    }
  }

  .listing-services {
    opacity: 0;
  }

  //fade in or it appears over the other background and looks bad
  &--is-expanded {
    .listing-services {
      opacity: 1;
      @include transition(opacity);
      transition-delay: 0.3s;
    }
  }

  //could extract out
  &__toggle {
    color: $color__brand-blue;
    font-size: px-to-em($font-size__small);
    margin-top: px-to-em($spacing__sm);
    position: relative;
    z-index: 2;

    &:before {
      content: '+';
      display: inline-block;
      width: px-to-em(16px);
      text-align: left;
    }
  }

  .listing-services {
    margin-top: px-to-em($spacing__sm);
    padding-left: px-to-em($wrapper-padding);
    padding-right: px-to-em($wrapper-padding);

    &__heading {
      color: $color__black; 
    }

    &__link {
      color: $color__brand-blue;
      font-size: px-to-em($font-size__small);
    }
  }

  &__body {
    @include transition(height);
    
    overflow: hidden;
		height: 0;
  }
  
  @include respond-to('between') {

    &__toggle {
      $font-size: 18px;
      color: $color__blue-light;
      font-size: px-to-em($font-size);
      position: absolute;
      margin-top: px-to-em($spacing__gutter * -2);
      transform: translateY(-100%);
    }

    .listing-services {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;

      &__link {
        color: $color__blue-light;
      }

      & + .listing-services {
        margin-top: $spacing__sm;
      }

      &:last-child {
        padding-bottom: $spacing__md;
      }

      &__heading {
        color: $color__white;
      }

      &__link {
        font-size: px-to-em($font-size__h4--tablet);
      }
    }
  }
  @include respond-to('desktop') {

    .listing-services {
      //the old styles did not allow for the label length to change
      &__heading {
        // width: px-to-em(130px, $font-size__h4--tablet);
        // float: left;
        padding-right: 1em;
        display: inline-block;
      }

      &__items {
        display: inline-block;
      }
    }

  }
}
