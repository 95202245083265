$insight-card-height: 350px;
$insight-card-width-ideal: 285px;

.insight-card {
  $padding-sides: px-to-em(25px);
  $padding-vertical: px-to-em(20px);

  display: block;
  width: 100%;
  background-color: $color__white;
  border:1px solid $color__brand-blue;
  padding: $padding-vertical $padding-sides;
  color: $color__gray-charcoal;
  min-height: px-to-em($insight-card-height/1.25);
  height: 99%;
  &_transition-in{
    animation: 2s fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
  }  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      visibility: visible;
      opacity: 1;
    }
  }
  &:focus {
    box-shadow: 0 10px 30px 5px rgba(0,0,0,0.15);
    color: $color__brand-blue;
    border-color: transparent;

    .insight-card__meta{
      color: $color__gray-charcoal;
    }
  }

  &--has-tile-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: center;
    border-color: transparent;
    display: flex;
    flex-direction: column;
    @include transition(transform);
  }

  &__meta {
    display: block;
    font-size: px-to-em($font-size__small);
    color: $color__brand-blue;

    &:not(&--placeholder):after {
      content: '';
      display: block;
      width: px-to-em(15px, $font-size__small);
      height: px-to-em(2px, $font-size__small);
      background-color: $color__brand-blue;
      margin-top: px-to-em(24px, $font-size__small);
      margin-bottom: px-to-em(24px, $font-size__small);
    }
  }

  &__date {
    &:after {
      content: '-';
      margin-left: 0.5em;
      margin-right: 0.25em;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }

  &__title {
    @include font($size: $font-size__h3, $weight: 'light' );

    display: block;
    margin-bottom: auto;

    .insight-card--has-tile-image & {
      font-size: 28px;
      color: $color__white;
      text-align: center;
    }
  }

  &__watch {
    padding-top: 1em;
    margin-top: auto;
    cursor: pointer;

    .button-primary {
      padding: .75em 1.25em;
      text-align: left;
      min-width: 0;

      &:hover {
        .button-primary__label:after {
          border-left: 10px solid $color__white;
        }
      }
    }
    
    .button-primary__label {

      &:after {
        content: '';
        width: 0; 
        height: 0; 
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 10px solid $color__brand-blue;
        display: inline-block;
        vertical-align: middle;
        margin-left: .5em;
        transition: .3s ease-in-out all;
      }
    }
  }
  
  @include respond-to('between') {
    $padding-sides: 35px;
    $padding-vertical: 30px;

    display: inline-flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    min-height: px-to-em($insight-card-height);
    padding: $padding-vertical $padding-sides;
    

    transition: $transition__card;

    &__meta {
      flex-shrink: 1;

      transition: $transition__card;
    }

    &__title {
      font-size: px-to-em($font-size__h3--tablet);
      margin-bottom: auto;
    }

    &:hover,
    &:focus {
      box-shadow: 0 10px 30px 5px rgba(0,0,0,0.15);

      &.insight-card--has-tile-image {
        transform: scale(1.005);
        
      }
      color: $color__brand-blue;
      border-color: transparent;

      .insight-card__meta{
        color: $color__gray-charcoal;
      }
    }
  }
}