.rte {
  line-height: 1.5;
  font-weight: map-get($map: $font-weights, $key: 'light' );

  &.rte--intro-paragraph {
    > p {
      &:first-child {
        font-size: 1.32em;
      }
    }
  }

  /* Class added within rich text content by editors*/
  .rte--intro-paragraph-custom{
      font-size: 1.32em;
  }

  &--disclaimer {
    font-size: px-to-em($font-size__small);
    font-style: italic;
  }

  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  
    &:first-child {
      margin-top: 0;
    }
  }

  a {
    @include links__blue-on-light;
    word-wrap: break-word;
  }

  ul {
    list-style-type: disc;

    ul {
      // list-style-type: decimal;
      list-style-type: circle;
      margin-top: px-to-em($spacing__normalize/2);
      
      //make consistent with paragraphs (request)
      // margin-top: 1.5em;
    }

  }

  li {
    //make consistent with paragraphs 
    // margin-bottom: 1.5em;

    margin-bottom: px-to-em($spacing__normalize/2);
  }

  ol {
    list-style-type: decimal;

    ol {
      margin-top: px-to-em($spacing__normalize/2);
      list-style-type: lower-alpha;

      ol {
        list-style-type: lower-roman;
      }
    }
  }

  ul,ol {
    padding-left: 1em;
    margin-left: 1em;
  }

  //highlight text in rte dropdown is like a pull quote styling without the quote
  .blockquote--no-quote {
    &:before{ 
      content: "" !important;
      display: none !important;
    }
  }

  blockquote,
  .blockquote,
  .blockquote--no-quote {
    @include font($size: $font-size__copy-blockquote, $weight: 'bold');

    border: 1px solid $color__border-gray;
    border-width: 1px 0;
    margin:  px-to-em($spacing__gutter, $font-size__copy-blockquote) 0;
    padding: px-to-em($spacing__gutter, $font-size__copy-blockquote)  2em;
    
    &:before {
        content: "“";
        display: inline-block;
        @include font($family: $font-family__titles, $weight: 'regular' );
        padding-right: 0.25em;
        font-size: 1.5em;
        line-height: 0.5;
        vertical-align: middle;
      }

    p {
        &:first-child {
            display: inline;
        }
    }
    

    &:first-child {
      margin-top: 0;
      border: 0 none;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  cite,
  .cite {
    font-weight: map-get($map: $font-weights, $key: 'light');
  }

  //make all images in RTEs responsive
  img {
    max-width: 100%;
    height: auto !important; //some images were ending up with inline style not just height/width
    //also mirror this in print styling
  }

  //some paragraphs end up in list items, don't give them margins 
  ul li p {
    margin-bottom: 0;
  }

  //give the end of lists the same (?) spacing as paragraphs 
  ul {
    margin-bottom: 1.5em;
  }

  //headings
  h3,
  .h3-size {
    margin-bottom: px-to-em($spacing__normalize, $font-size__h2);
  }

  h4,
  .h4-size {
    @include font($family: $font-family__copy, $weight: 'bold' );

    font-size: 1em;
    margin-bottom: 0;
    margin-top:  0;
  }


  @include respond-to('between') {
    font-size: px-to-em($font-size__copy--tablet);

    &--disclaimer {
      font-size: px-to-em($font-size__small);
    }

    a {
      color: $color__brand-blue;

      &:hover {
        color: $color__brand-teal;
      }
    }

    blockquote,
    .blockquote {
      font-size: px-to-em($font-size__copy-blockquote--tablet, $font-size__copy--tablet);
      font-weight: map-get($font-weights, 'medium');
      line-height: 1.3;
      padding-right: 5%;
      padding-left: 5%;
      
      &:before {
          font-size: 2.5em;
          line-height: 0.2;
          padding-right: 0.2em;
          vertical-align: bottom;
        }

      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }
    }

    //headings
    h3 {
      margin-bottom: px-to-em($spacing__normalize * 1.25, $font-size__h2--tablet);
      margin-top: px-to-em($spacing__normalize * 2, $font-size__h2--tablet);
    }
  }

  @include respond-to('desktop') {
    blockquote,
    .blockquote {
      margin-left: 0;
      margin-right: 0;
      padding-right: 13%;
      padding-left: 13%;
      
    }
  }
}

.blue-overlay {
  position: relative;
  display: inline-block;

  &:before {
    @include overlay-gradient__blue;

    content: '';
  }

  > img {
    max-width: 100%;
    display: block;
  }
}