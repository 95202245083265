.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to {
// .component-fade-leave-active below version 2.1.8 */
  opacity: 0;
}


[v-cloak] {
  display: none;
}