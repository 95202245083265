.career-briefs {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: $zindex__site-header + 10;
    transform: translateY(0%);
    visibility: hidden;
    transition: height 1s $transition__bezier,
                visibility 1s $transition__bezier;
    overflow: hidden;

    &__card {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: px-to-em(300px);
        color: $color__white;
        position: relative;
        overflow: hidden;

        & + & {
            border-top: 1px solid $color__white;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }

        &:before {
            background-color: rgba(0,0,0,0.3);
        }

        &:after {
            // @include overlay-gradient__blue();
            @include overlay-gradient__coral();
        }
    }


    $title-underline-spacing: 20px;

    &__card-title {
        @include titles-banner();
        position: relative;
        z-index: 5;

        .career-briefs__card--has-overview & {
            padding-bottom: $title-underline-spacing;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                left: calc(50% - .7em);
                width: .7em;
                border-top: 1px solid $color__grey;
            }
        }
    }

    &__card-overview {

        @include font($font-family__rubik, $font-size__h3, $weight:'light');

        @include respond-to('desktop') {
            font-size: $font-size__h2;
        }

        width: 100%;
        z-index: 5;
        margin-top: $title-underline-spacing;
        padding: 0 15%;
        text-align: center;
    }

    &__card-figure {
        @include bg-cover;
        @include transition(transform);
        background-position: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        backface-visibility: hidden;
    }

    &--show-desktop {
        transform: translateY(0%);
        height: 100%;
        visibility: visible;
    }

    &__card {
        height: 33.33%;

        &--panel-count-1 {
            height: 100%;
        }

        &--panel-count-2 {
            height: 50%;
        }
    }

    &__panel-container {
        height: 92%;
    }

    &__mobile-toggle {
        height: 8%;
        background-color: $color__black;
        color: $color__brand-teal;
        text-transform: uppercase;
        width: 100%;

        .icon-close {
            font-size: px-to-em(24px);
            line-height: 1;
            vertical-align: middle;
            color: $color__white;
            margin-right: 0.25em;
        }
    }

    @include respond-to-max-height('height-landscape') {
        &__card {
            height: 50%;

            &--panel-count-1 {
                height: 100%;
            }

            &--panel-count-2 {
                height: 50%;
            }
        }

        &__panel-container {
            overflow-y: auto;
        }
    }
    // @include respond-to('between') {
    @include respond-to('showcase-desktop--careers') {
        border-top-width: 0;
        border-left: 1px solid $color__white;
        transform: translateX(100%);
        height: 100%;
        right: 0;
        top: 0;
        left: auto;
        width: 58%;
        transition: transform 1s $transition__bezier,
                    visibility 1s $transition__bezier;

        &--show-desktop {
            transform: translateX(0%);
        }

        &__panel-container {
            height: 100%;
        }

        &__card {
            height: 33.33%;

            &--panel-count-1 {
                height: 100vh;
            }

            &--panel-count-2 {
                height: 50vh;
            }

            &:hover {
                &:after {
                    opacity: 0.7;
                }
            }
        }

        &__card:hover & {
            &__card-figure {
                transform: scale(1.05);
            }
        }

        &__mobile-toggle {
            display: none;
        }
    }
}
