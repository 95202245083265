
$easeInOutQuint: 				cubic-bezier(0.86, 0, 0.07, 1);

$transition__timing: 0.35s;
$transition__ease: ease-out;

$transition__bezier: $easeInOutQuint;

$transition-timing__site-nav: 0.5s;

$transition-timing__reveal: 0.5s;

$transition__card:  color $transition__timing $transition__ease,
                    border-color $transition__timing $transition__ease,
                    box-shadow $transition__timing $transition__ease;


$transition__link: color $transition__timing $transition__ease;




$transition__card-gradient:  opacity $transition__timing $transition__ease,
                            top $transition__timing $transition__ease;
$transition__card-text: color $transition__timing $transition__ease;


//Heading animation
$animation__overlay-expanded: 1s;

