$button__min-width: 125px;
$button__min-width--tablet: 175px;
$button__padding: 15px;
$button__padding-sides: 26px;

$button__padding--small: 8px;
$button__padding-sides--small: 16px;

$button__box-shadow: 0 6px 20px 5px rgba(0,0,0,0.15);

button {
  @include transition(color);
  
  line-height: 1.1;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
  transition: color 0.4s;
  position: relative;
  border-radius: 0;
  padding: 0;
  border-width: 1px;

  // &:focus {
  //   outline: 1px dotted;
  // }
}

//default styles
.button {
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  border-radius: 0;
  border-width: 1px;
  text-align: center;
  padding: px-to-em($button__padding) px-to-em($button__padding-sides);
  min-width: px-to-em($button__min-width);
  
  @include respond-to('between') {
    min-width: px-to-em($button__min-width--tablet);
    padding: px-to-em($button__padding* 1.5) px-to-em($button__padding-sides * 1.2);
  }

  &--disabled {
    background-color: $color__white !important;
    &::before {
      display: none;
    }
    &:hover {
      box-shadow: none !important;
      color: inherit !important;
    }
  }
}

.button-primary {
  @include transition-bezier(color, box-shadow);

  background-color: $color__white;
  border: 1px solid $color__brand-blue;
  color: $color__brand-blue;
  overflow: hidden;


  &__label {
    position: relative;
  }

  &:before {
    @include transition-bezier(transform);

    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color__brand-blue;
    transform: translateY(100%);
  }

  &--play {
    &:hover::after {
      transition: border-left-color .35s cubic-bezier(.86,0,.07,1),box-shadow .35s cubic-bezier(.86,0,.07,1);
    }

    &::after {
      content: '';
      width: 0; 
      height: 0; 
      border-top: 6px solid transparent;
      border-bottom: 6px solid transparent;
      border-left: 10px solid $color__white;
      margin-left: 1em;
      display: inline-block;
      transition: border-left-color .35s cubic-bezier(.86,0,.07,1),box-shadow .35s cubic-bezier(.86,0,.07,1);
      position: relative;
      z-index: 1;
    }
  }

  &--dark {
    border: 0 none;
    background-color: $color__black;
    color: $color__white;

    &:before {
      background-color: $color__white;
    }

    &:hover::after {
      border-left-color: $color__brand-blue;
    }
  }

  &--coral {
    border-color: $color__brand-coral;
    background-color: $color__brand-coral;
    color: $color__white;

    &:before {
      background-color: $color__white;
    }
    &:hover::after {
      border-left-color: $color__brand-coral;
    }
  }

  &--blue {
    background-color: $color__brand-blue;
    color: $color__white;

    &:before {
      background-color: $color__white;
    }
    &:hover::after {
      border-left-color: $color__brand-blue;
    }
  }

  &--ghost {
    border-color: $color__grey;
    background-color: transparent;
    color: $color__white;

    &:before {
      background-color: $color__white;
    }

    &:hover::after {
      border-left-color: $color__grey
    }
  }

  &--centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  @include respond-to('between') {
    &:hover {
    // &:focus {
      color: $color__white;
      box-shadow: $button__box-shadow;

      &:before {
        transform: translateY(0);
      }
    }

    //keep indication for accessibility 
    //without leaving full color hanging around until the next click
    &:focus {
      outline: none;
      box-shadow: $button__box-shadow;
    }
    
    &--dark {
      &:hover {
      // &:focus {
        color: $color__black; 
      }
    }
    &--blue {
      &:hover {
      // &:focus {
        color: $color__brand-blue;
      }
    }
    &--ghost {
      &:hover {
      // &:focus {
        color: $color__black;
      }
    }
    &--coral {
      &:hover {
      // &:focus {
        color: $color__brand-coral;
      }
    }
  }
}

.button-utility {
  $icon-padding: 24px;
  @include transition-bezier(color, box-shadow);

  border: 1px solid $color__white;
  background-color: $color__black;
  color: $color__white;
  text-align: left;
  text-transform: uppercase;
  overflow: hidden;
  padding: px-to-em($button__padding) px-to-em($spacing__sm);
  
  &__label {
    position: relative;
    width: 100%;
    line-height: 1.5;
    display: inline-block;
    padding-right: px-to-em($icon-padding);

    [class^="icon-"] {
      font-size: 1.2em;
      padding-right: 0.5em;
      vertical-align: text-bottom;
    }
  }

  &--cta {
    max-width: px-to-em(260px);
    width: 100%;
  }

  &--cta & {
    &__label {
      padding-right: 0;
    }
  }
  

  &--external & {
    &__label {
      @include ico-icon-after('chevron-thin-right') {
        @include center-align(vertical);

        right: 0;
      }
    }
  }

  &--centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &--smaller {
    padding: px-to-em($button__padding--small) px-to-em($button__padding-sides--small);
    
    @include respond-to('between') {
      padding: px-to-em($button__padding--small* 1.5) px-to-em($button__padding-sides--small * 1.2);
    }
  }

  &:before {
    @include transition-bezier(top);

    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color__white;
  }

  @include respond-to('between') {
    &:hover,
    &:focus {
      color: $color__black; 
      box-shadow: $button__box-shadow;
      

      &:before {
        top: 0;
      }
    }
  }
}