.story-heading {
  $snapshot-width-px: 270px;
  $snapshot-width: px-to-em($snapshot-width-px);

  $snapshot-width-px--landscape: 230px;
  $snapshot-width--lanscape: px-to-em($snapshot-width-px--landscape);

  $gray-bar-height: 60px;

  position: relative;
  padding-top: px-to-em($site-header-height + $spacing__sm);
  background-color: $color__black;

  // Gray Bar at bottom
  // removed from mobile because we are switching the quote and the image
  // &:after {
  //   content: '';
  //   display: block;
  //   position: absolute;
  //   left:  0;
  //   right:  0;
  //   bottom:0;
  //   background-color: $color__brand-gray;
  //   height: px-to-em($gray-bar-height);
  // }

  &__background {
    @include bg-cover;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: -2;
    opacity: 0.15;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.35) );
    }
  }

  &__tagline {
    margin-bottom: 0.5em;
  }

  &__body {
    padding-top: px-to-em($spacing__gutter);
    text-align: center;
    color: $color__white;
    position: relative;
    z-index: 2;
  }

  &__figure {
    position: relative;
    margin: px-to-em($spacing__gutter) auto 0;
    display: block;
    width: $snapshot-width;
    backface-visibility: hidden;
    box-shadow: 0 10px 40px 10px rgba(0,0,0,0.2);

    &:after {
      @include overlay-gradient__coral();

      content: '';
    }
  }

  &__snapshot {
    display:block;
    max-width: 100%;
  }

  &--theme-coral & {
    &__title {
      color: $color__brand-coral;
    }
  }

  &__experience {
    @include font($size: $font-size__h3, $weight: 'light');

    font-style: italic;
    margin-top: 0.5em;
    
    &:before {
      content: '-';
      display: inline-block;
      margin-right: 0.25em;
    }
  }

  &__meta {
    &:not(:last-child) {
      &:after {
        content: '/';
        margin-right: 0.2em;
        padding-left: 0.5em;
      }
    }
  }

  &__quote {
    margin-top: px-to-em($spacing__gutter);

    &--mobile {
      padding-bottom: px-to-em($spacing__gutter);
    }

    .story-heading__blockquote {
      //override default rte styles
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  &__blockquote {
    p {

      &:before {
        color: $color__brand-coral;
      }
    }
  }

  &__lawyer {
    // margin-top: px-to-em($spacing__gutter);
    //we want it to be the same as between the top text and the title
    //but the spacing underneath has to be higher because the letters go past the bottom
    margin-top: 12px;
  }

  @include respond-to('landscape') {
    //what was here was moved to 'between' 
    //and the mobile view was kept for here. 
    //the content was getting really cramped

  }

  @include respond-to('between') {

    // Gray Bar at bottom
    // removed from mobile because we are switching the quote and the image
    &:after {
      content: '';
      display: block;
      position: absolute;
      left:  0;
      right:  0;
      bottom:0;
      background-color: $color__brand-gray;
      height: px-to-em($gray-bar-height);
    }

    &__body {
      text-align: left;
    }

    &__lawyer {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row-reverse;
      aling-items: stretch;
      width: 100%;
      // margin-top: px-to-em($spacing__gutter);
      margin-top: px-to-em($spacing__md);
    }

    &__figure {
      flex-shrink: 0;
      margin-right: px-to-em($wrapper-padding);
      margin-top: 0;
      margin-bottom: px-to-em($spacing__gutter);
      overflow: hidden;
    }

    &__snapshot {
      @include center-align(horizontal);

      min-height: 100%;
      //the max width was causing some image skewing in rare cases
      // max-width: 130%;
      height: 100%;
      max-width: none;
    }

    &__details {
      flex-grow: 1;
      padding-bottom: px-to-em($gray-bar-height + $spacing__gutter);
    }

    &__quote {
      .story-heading__blockquote {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__tagline {
      margin-bottom: 0.75em;
    }

    &__quote {
      font-size: 1.1em;

      &--desktop {
        //override inline style (to prevent two quotes flashing)
        display: block !important;
      }

      &--mobile {
        display: none;
      }
    }


  }

  @include respond-to('desktop') {
    &__body {
      padding-top:px-to-em($spacing__lg);
    }

    &__quote{
      font-size: px-to-em($font-size__copy--tablet);
    }

    &__figure {
      width: px-to-em(480px);
      height: px-to-em(505px);
      margin-right: px-to-em($spacing__md);
    }

    &__snapshot {
      position: relative;
    }

    &__details {
      padding-bottom:px-to-em($gray-bar-height + $spacing__md);
    }
  }
}