.tabby-area {
  $padding-sides: 1em;
  $padding-sides--tablet: $spacing__gutter;

  &__nav-item--active & {

    &__nav-label {
      color: $color__gray-charcoal;
      cursor: auto;
    }
  }

  &__nav-label {
    font-size: px-to-em($font-size__small);
    text-transform: uppercase;
    color: $color__brand-blue;
    text-align: left; 
    @include links__blue-on-light();
  }


  &__nav {
    position: relative;
    z-index: 10
  }

  &__nav-item {
    border: 1px solid $color__brand-gray;
    border-width: 0 1px 1px;
    padding: px-to-em($spacing__unit) px-to-em($spacing__unit * 2);

  }

  &__nav--show-dropdown &{
    &__nav-options {
      opacity: 1;
      visibility: visible;
    }

    &__nav-toggle {
      &:after {
        transform: rotateX(180deg)  translateY(50%) ;
      }
    }
  }

  &__nav-toggle {
    position: relative;
    background-color: $color__brand-gray;
    color: $color__black;
    text-transform: uppercase;
    display: block;
    width: 100%;
    text-align: left;
    padding: px-to-em($spacing__unit * 1.5, $font-size__small) px-to-em($spacing__unit * 2, $font-size__small) ;
    padding-right: px-to-em($spacing__unit* 2, $font-size__small);
    font-size: px-to-em($font-size__small);

    @include ico-icon-after('chevron-thin-down') {
      @include center-align(vertical);
      @include transition(transform);

      right: px-to-em($spacing__unit * 1.5, $font-size__small);
    }
    
  }

  &__nav-options {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: $color__white;
    width: 100%;
  }




  &__frame {
    @include transition(height);
    position: relative;
    
    padding:0  $padding-sides;
    margin: $padding-sides 0;
  }

  @include respond-to('between') {
    &__nav-options {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: px-to-em($spacing__md / 2);
      //overwrite mobile
      position: static;
      top: auto;
      left: auto;
      opacity: 1;
      visibility: visible;
      background-color: transparent;
      width: 100%;
    }

    &__nav-item {
      flex-shrink: 0;
      margin-right: 8%;
      border: 0 none;
      padding: 0;
      margin-bottom: px-to-em($spacing__md / 2);

      &:last-child {
        margin-right: 0;
      }
    }

    &__nav-toggle {
      display: none;
    }

    &__frame {
      padding: 0;
      margin: 0;
    }
  }
} 