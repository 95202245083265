.service-heading {
  @include heading--base();

  &__background {
   @include heading__background();
  }

  &__body {
    @include heading__body();
  }

  &__header {
    margin-bottom: px-to-em($spacing__sm);
  }

  &__cta {
    @include heading__cta();
  }

  &__title {
    @include titles-page-title();
  }



  @include respond-to('landscape') {
    &__header {
      display: flex;
      flex-wrap: nowrap;
      text-align: left;
      width: 100%;
    }

    &__utility {
        margin-left: auto;
    }

    &__title {
      max-width: 72%;
    }

    &__related-cta {
      @include heading__related-cta();
    }

    &__cta {
      flex-shrink: 0;
      margin-top: 0;

      &:hover {
        &:after {
          transform: translateX( px-to-em($spacing__unit));
        }
      }
    }

    &__body {
      text-align: left;
    }

  }

  @include respond-to('between') {

    &__body {
      padding-bottom:px-to-em($spacing__md) ;
      padding-top:px-to-em($spacing__md);
      min-height: px-to-em(350px);
      height: 100px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      flex-direction: column;
    }
  }
}