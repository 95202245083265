
.accordion {
  $viewable-height: 150px;
  $diamond-size: 25px;
  $diamond-size--tablet: 40px;
  $padding-sides: 1em;
  $padding-sides--tablet: $spacing__gutter;

  & + & {
    margin-top: 1px;
  }
  
  &__heading {
    background-color: $color__black;
    font-size: 1em;
    padding: $padding-sides;
    padding-right: ($padding-sides*1.75) + px-to-em($diamond-size);
    position: relative;
    cursor: pointer;
    border: 1px solid transparent;
  }


  &__title {
    @include titles-tertiary-heading;

    color: $color__white;
  }


  &__icon {
    @include center-align(vertical);

    display: inline-block;
    right: $padding-sides;
    text-align: center;
    color: $color__white;
    width: px-to-em($diamond-size);
    height: px-to-em($diamond-size);
    margin-top: px-to-em($diamond-size * 0.25);
  }

  &__icon-diamond {
    position: absolute;
    top: px-to-em($diamond-size * -0.15);
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid $color__gray-text;
    transform: rotate(45deg);
    overflow: hidden;
  }
  
  //plus icon
  .icon-close {
    
    &:before {
      @include transition(transform);

      display: inline-block;
      transform: rotate(45deg);
      position: relative;
      z-index: 2;
      
    }
  }

  &__body {
		overflow: hidden;
    height: 0;
    opacity: 0;
    transition: height $transition__timing $transition__ease $transition__timing,
                opacity $transition__timing $transition__ease;
    padding-left: $padding-sides;
    padding-right: $padding-sides;

    .rte {
      padding-top: px-to-em($spacing__sm);
      padding-bottom: px-to-em($spacing__sm);
    }
	}


	&--is-expanded & {
    &__body {
      opacity: 1;
      transition: height $transition__timing $transition__ease,
      opacity $transition__timing $transition__ease $transition__timing;
    }

    &__heading {
      background-color: $color__white;
      border-color: $color__brand-gray;
      
    }

    &__title {
      color: $color__black;
    }

    &__icon {
      @include transition(color);

      color: $color__black;

      &:before {
        transform: rotate(0deg);
      }
    }
	}
	
	

	@include respond-to('between') {

		&__heading {
      @include transition(background-color);

      padding: $padding-sides--tablet/2 $padding-sides--tablet;
      min-height: px-to-em(100px);
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      
      &:hover {
        background-color: $color__brand-blue;

        .accordion__icon-diamond {
          box-shadow: $color__box-shadow; //$button__box-shadow;

          &:after {
            top: -25%;
            left: -25%;
          }
        }

        .accordion__icon {
          color: $color__black;
        }
      }
    }
    
    &__title {
      max-width: px-to-em($section-title-width, $font-size__h2--tablet); 
    }

    &__icon {
      @include transition(color);

      right: $padding-sides--tablet;
      width: px-to-em($diamond-size--tablet);
      height: px-to-em($diamond-size--tablet);
      padding-top: px-to-em($diamond-size * 0.2);
      transition-delay: $transition__timing;
    }

    .icon-close {

      &:before {
        $size: 22px;

        font-size: px-to-em($size);
      }
    }

    &__icon-diamond {
      @include transition(box-shadow);

      transition-delay: $transition__timing;

      &:after {
        @include transition(top,left);
        content: '';
        display: block;
        position: absolute;
        width: 150%;
        height: 150%;
        transform: rotate(-45deg);
        background-color: $color__white;
        top: 85%;
        left: 85%;
        z-index: -1;
      }
    }
    
    &__body {
      padding-left: 0;
      padding-right: 0;

      .rte {
        padding-top: px-to-em($spacing__md, $font-size__copy--tablet);
        padding-bottom: px-to-em($spacing__md, $font-size__copy--tablet);
      }
    }

    //for read more inside accordion, unlike regular RTE
    //we need the padding to be outside of the 'more' button
    &__read-more-wrapper {
      padding-top: px-to-em($spacing__md, $font-size__copy--tablet);
      padding-bottom: px-to-em($spacing__md, $font-size__copy--tablet);

      .rte {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

		&--is-expanded &{
      
      &__heading {
        
        &:hover {
          background-color: $color__brand-gray;


          .accordion__title {
            color: $color__black;
          }
        }
      }
		}
  }
  
  @include respond-to('desktop') {
    &__body {
      margin-left: px-to-em( $section-title-width + $spacing__gutter);
    }
  }
}