.location-card {
  $padding-sides: px-to-em(25px);

  border-top:1px solid $color__border-gray;
  padding: px-to-em($spacing__gutter) $padding-sides;
  min-height: px-to-em($insight-card-height);
  position: relative;
  background-color: $color__black;

  &:after {
    @include overlay-gradient__blue;
    @include transition(opacity);

    content: '';
  }

  &__figure {
    @include bg-cover;
    @include transition(opacity);

    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;    
  }

  &__info {
    margin-bottom:2em;
    margin-left: 4em;
    margin-right: 4em;
    position: relative;
    z-index: 5;
    color: $color__white;
    text-align: center;
  }

  &__title {
    @include titles-page-title();

    display: block;
  }
  &___header{
    display: block;
  }

  &__cta {
    margin-top: px-to-em($spacing__gutter);
  }

  &__address {
    font-size: px-to-em($font-size__h3);
    font-style: normal;
    padding-top: px-to-em($spacing__gutter, $font-size__h3);
    margin-bottom: 0;
  }
  &__office-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    @include respond-to('between') {
      flex-wrap: nowrap;
    }
  }
  &__phone {
    font-size: px-to-em($font-size__h3);
  }

  .address,
  .firm,
  .country,
  &__sub-title {
    display: block;
    font-size:2.5em;
  }
  
  @include respond-to('between') {
    $padding-sides: 35px;
    $padding-vertical: 30px;

    padding: $padding-vertical $padding-sides;
    height: px-to-em(600px);
    min-height: px-to-em(600px);
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    &__figure {
      //iOS hates fixed
      // background-attachment: fixed;
      background-position: left bottom
    }

    &__address,
    &__phone {
      font-size: px-to-em($font-size__h3--tablet);
    }

    &__address {
      padding-top: 1em;
    }
  }

  
  @include respond-to('desktop') {
    height: 33vh;


    //only do fixed on real desktop devices. ipads mess it up
      @media(min-height: $screen-height-landscape--tablet) {
      &__figure {
        background-attachment: fixed;
      }
    }

    &__details {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.5s ease-out 0.25s,
        opacity 0.5s ease-out;
    }
    &--is-hovered {
      // hide blue gradient
      &:after {
        opacity: 0;
      }
    }

    &--is-hovered &{
      &__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        max-height: px-to-em(400px);
        opacity: 1;
        transition: max-height 0.75s ease-in,
          opacity 0.5s ease-out 0.25s
      }

      &__figure {
        opacity: 0.15;
      }
    }
  }
}

.locations-prominent-listing {
    @include respond-to('desktop') {
 
    &--is-hovered {
      // hide blue gradient
      &:after {
        opacity: 0;
      }
    }

    &--is-hovered &{
      &__details {
        max-height: px-to-em(400px);
        opacity: 1;
        transition: max-height 0.75s ease-in,
          opacity 0.5s ease-out 0.25s
      }

      &__figure {
        opacity: 0.15;
      }
    }
  }
}
