.services-areas {
  //make sure the filter container doesn't shrink under the filters
  min-height: 400px;

  &__nav {
    margin-bottom: px-to-em($spacing__gutter);
    text-align: center;
  }

  &__nav-button {
    @include font($size: $font-size__h3, $weight: 'bold');

    color: $color__brand-blue;
    text-transform: uppercase;

    &--selected,
    &.initial-active {
      color: $color__black;
      cursor: auto;
    }

    &.initial-inactive {
      color: $color__brand-blue;
    }

    &--has-divider {
      border-right: 1px solid $color__gray-text;
      padding-right: 1em;
      margin-right: 0.75em;
    }
  }

  &__frame {
    position: relative;
  }

  &__panel {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &--active,
    &.initial-active {
      opacity: 1;
      visibility: visible;
      position: relative;
    }

    &.initial-inactive {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
  }

  &__listing {
    top: 0;
    left: 0;  

    &--active {
      opacity: 1;
      visibility: visible;
      position: relative;
    }

    &--inactive {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
  }

  &__listing-parent {
    font-size: 1em;
    font-family: $font-family__copy;

    .services-areas__listing-link {
      font-weight: map-get($font-weights, 'bold');
    }
  }

  &__listing-link {
    @include font($size: $font-size__h4, $weight: 'light');
    @include transition-bezier(color);

    line-height: 1.3;
    color: $color__brand-blue;
    display: inline-block;
    padding-bottom: px-to-em($spacing__unit);
  }
  
  @include respond-to('between') {
    $grid-spacing: px-to-em($spacing__sm);

    &__nav-button {
      font-size: px-to-em($font-size__h3--tablet);

      &:hover:not(&--selected) {
        color: $color__gray-hover--for-gray-background;
      }

      &--selected {
        color: $color__black;
      }
    }
    
    &__listing {
      columns: 2 auto;
      column-gap: $grid-spacing;
    }

    &__listing-option {
      break-inside: avoid;
      overflow: hidden;
      width: 100%;
      //with the default display, the right column was taller than the left half the time
      //and they didn't like that
      //not totally sure why inline block fixes it (in chrome at least)
      display: inline-block; 
    }

    &__listing-link {
      font-size: px-to-em($font-size__h4--tablet);
      // padding-bottom: px-to-em($spacing__sm, $font-size__h4--tablet);
      //also not sure why but inline-block increases the space between the elements 
      //reduce to compensate 
      padding-bottom: 1em;

      &:hover {
        color: $color__gray-hover--for-gray-background;
      }
    }
  }
  @include respond-to('desktop') {
    $practices-filters-width: 410px;
    $offset-width:  px-to-em($practices-filters-width) + px-to-em($spacing__md);

    &__nav {
      margin-bottom: px-to-em($spacing__gutter);
      text-align: left;
      float: right;
      width: calc(100% - #{$offset-width});
    }

    .practice-area-filters {
      float: left;
      width: px-to-em($practices-filters-width);
    }

    &__frame {
      clear: right;
      margin-left: $offset-width;
    }

  }
}
