
.select-dropdown {
  $padding: px-to-em(15px, $font-size__small);

  margin-bottom: px-to-em($spacing__sm);
  position: relative;

  &__select {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background: transparent;
    border: 0;
    font-family: $font-family__copy;
  }

  &__label {
    font-size: px-to-em($font-size__small);
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1;
    position: relative;
    border: 1px solid $color__brand-blue;
    padding: $padding;
    padding-right: $padding * 1.75;
    position: relative;
    text-transform: uppercase;
    color: $color__brand-blue;
    

    
    @include ico-icon-before('chevron-thin-down') {
      @include center-align(vertical);
      
      right: $padding;
    }
  }

  &--large &{
    &__label {
      font-size: 1em;
      padding: $searchbox__padding;
      padding-right: $padding * 1.5;

      &:before {
        font-size: px-to-em($font-size__small);
      }
    }
  }

  @include respond-to('between') {

    &__select {
      
      &:focus,
      &:hover {
        & + .select-dropdown__label {
          color: $color__gray-hover--for-white-background;
          border-color: $color__gray-hover--for-white-background;
          box-shadow: $button__box-shadow;
        }
      }
    }
   
    &__label {
      @include transition-bezier(color, border-color, box-shadow);
      
      font-size: 1em;
      padding-left: px-to-em($spacing__sm);
      padding-right: px-to-em($spacing__sm * 1.75);

      &:before {
        right: px-to-em($spacing__sm);
      } 
    }

    &--large &{
      &__label {
        font-size: $searchbox__fontsize--tablet;
        padding: $searchbox__padding--tablet/1.25 $searchbox__padding--tablet;
        
  
        &:before {
          font-size: px-to-em($font-size__small);
        }
      }
    }
  }
}
