.page {
  $wrapper-max-width--custom: 1003px;
  $wrapper-max-width--custom-tablet: 334px;
  $wrapper-padding--tablet: 40px;
  $wrapper-padding--desktop: 50px;

  &--no-scroll {
    overflow:hidden;

    .profile-bar {
        display: none;
    }
  }
  
  &__home {
    height: 100%;
    .site-nav {
      max-width: px-to-em($wrapper-max-width--wide) +  px-to-em($wrapper-padding--tablet * 2);
    }
  }
  &__careers-landing {
    height: 100%;
  }

  &__careers-detail {
    .listing-links {
      &__figure {
        &:before {
          @include overlay-gradient__coral;
        }
      }
    }
  }


  //related insights was moved to after the big accordion section on professional heavy detail, 
  //breaking the assumptions made for the alternating section and mobile-only accordion sections
  //so fixing it here without risking breaking every other page
  &__people-detail {
    .section.ancillary-info + .section.related-insights {
      margin-top: 1px;
    }
  }
}

.hide-elements-initial--trigger {
  //use root element to trigger hide because v-if was causing the removed element to come back
  .hide-elements-initial {
    visibility: hidden;
  }
}
