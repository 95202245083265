.profile-heading {
  @include heading--base();
  $snapshot-width-px: 270px;
  $snapshot-width: px-to-em($snapshot-width-px);

  $snapshot-width-px--landscape: 230px;
  $snapshot-width--lanscape: px-to-em($snapshot-width-px--landscape);
  
  $content-snapshot-gutter-px: $spacing__md/2;
  $content-snapshot-gutter: px-to-em($content-snapshot-gutter-px);

  color: $color__white;
  text-align: center;

  .wrapper {
    position: relative;
    //higher than 2 so the language dropdown isn't blocked by the read more fade-out thing
    z-index: 3;
  }

  &__background {
    @include heading__background();

    //stop background from adjusting when container resizes from expand/collapse
    background-size: auto 90vh;

    @include respond-to('between') {
        background-size: auto;
        background-size: cover;
        
    }

  }

  &__main {
    margin-bottom: px-to-em($spacing__sm);
  }

  &__experience {
    margin-top: px-to-em($spacing__unit);
  }

  &__firm {
    margin-top: 10px;
    // margin-top: px-to-em($spacing__unit);
  }

  &__specialty--has-secondary {
    &:after {
      content: '/';
      display: inline;
      margin-left: 0.5em;
      margin-right: 0.25em;
      color: $color__gray-text;
    }
  }

  &__position {
    &:after {
      content: '/';
      color: $color__gray-text;
      margin-left: 0.65em;
      margin-right: 0.25em;
    }
    &--no-separator::after {
      display: none;
    }
  }

  &__figure {
    position: relative;
    margin: 0 auto;
    display: block;
    width: $snapshot-width;
    box-shadow: 0 10px 40px 10px rgba(0,0,0,0.2);
    z-index: 5;
  }

  &__snapshot {
    width: 100%;
    display: block;
  }

  &__body {
    position: relative;
  }

  &__contact-details {
    margin-bottom: px-to-em($spacing__sm);
  }

  a, .expandable-services__toggle {
    @include links__blue-on-black;
  }

  //typography
  &__name {
    @include titles-page-heading;
  }

  &__email,
  &__location {
    font-size: px-to-em($font-size__small);
    line-height: 1.4;
  }

  &__location {
    margin: px-to-em($spacing__unit/2) 0 0;
  }

  &__location-link {
    display: inline-block;
    padding-right: 0.5em;
  }

  &__location-phone, &__mobile-phone {
    color: $color__brand-gray !important;
    &:hover {
      color: $color__blue-light !important;
    }
    a {
      color: $color__brand-gray;
    }
  }

  .social-links {
    margin: px-to-em($spacing__sm * 2) 0 px-to-em($spacing__sm * 1.5);

    &__item {
      margin:0 0.5em;
    }

    $icon-height: 26px;
    &__icon {
      height: px-to-em(26px);
      padding: 0 px-to-em(8px);
      background-color: $color__black;
      color: $color__white;
      border: 1px solid $color__white;
      display: inline-flex;
      align-items: center;
    }
  }

  .icon-pdf {
    .social-links__label {
      font-size: px-to-em($font-size__smallest);
    }
  }

  .expandable-services {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: px-to-em($spacing__gutter * -1);
      background-color: $color__brand-gray;
      z-index: -1;
    }
  }

  &__figure-placeholder {
    // Undo negative top when there is no profile image
    & + .expandable-services,
    & + [inline-template] .expandable-services {
      &:after {
        top: 0;
      }
    }
  }

  @include respond-to('landscape') {
    text-align: left;

    &__body {
      @include clearfix;

      position: static;
    }

    &__contact-details { 
      text-align: center;
    }

    &__figure, &--figure-placeholder {
      margin-top: px-to-em($spacing__sm);
    }

    .social-links {
      position: absolute;
      top: 0;
      right: px-to-em($wrapper-padding);
      margin: px-to-em($spacing__unit) 0 0;

      &__item {
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .expandable-services {
      text-align: center;
    }
  }

  @include respond-to('between') {
    $gray-bar-height: 45px;
    
    padding-top: px-to-em($site-header-height--tablet);
    //match height to background height or it all gets wacky
    min-height: px-to-em(600px);


    //gray bar that appears below image
    &:after {
      content: '';
      background-color: $color__brand-gray;
      height: $gray-bar-height;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      // z-index: -1;
    }


    //set fixed background per nate
    &__background {
      background-size: cover;
    }
    
    &__body {
      margin-top: px-to-em($spacing__md);

      &:after {
        display: none;
      }
    }
    
    .social-links {
      right: px-to-em($wrapper-padding--tablet);
      margin-top: px-to-em($spacing__unit * 2);

      &__icon {
        &:hover {
          color: $color__black;
          background-color: $color__white;
        }
      }
    }

    &__contact-details {
      text-align: left;
      float: right;
      width: calc(100% - #{$content-snapshot-gutter + $snapshot-width});
    }

    &--no-image &__contact-details {
      float: none;
      width: auto;
    }

    &__figure {
      margin: 0;
      width: $snapshot-width;
    }

    .expandable-services {
      text-align: left;
      margin-left: px-to-em($content-snapshot-gutter-px + $snapshot-width-px);
      margin-right: 0;
      
      &:after {
       display: none;
      }
    }

    &__figure-placeholder {
      margin: 0;
      height: $snapshot-width;

      // Align expandable services left when there is no profile image
      & + .expandable-services,
      & + [inline-template] .expandable-services {
        margin-left: 0;
      }
    }

    &__locations {
      margin-top: 0.5em;
    }

    &__location {
      display: inline-block;
      padding-right: 1.5em;
    }

    // typography
    a {
      @include transition(color);
      
      color: $color__blue-light;
    }

    &__location-phone a {
      color: $color__brand-gray;
    }
    
    &__name {
      padding-right: px-to-em(160px, $font-size__h1--tablet);
      display: inline-flex;
      width: 100%;

      &:after {
        content: '';
        display: block;
        border-top: 1px solid $color__gray-text;
        flex-grow: 1;
        margin-left: 0.5em;
        margin-top: px-to-em(32px, $font-size__h1--tablet); //fixed value = icon size /2;
      }
    }

    &__name-label {
      max-width: 85%;
      display: inline-block;
    }

    &__specialty,
    &__position {
      font-size: px-to-em($font-size__h2--tablet);
      
      &--no-separator::after {
        display: none;
      }
    }

    &__firm {
      font-size: px-to-em($font-size__h2) ;
    }

    &__email,
    &__location {
      font-size: px-to-em($font-size__h4);
      line-height: 1.4;
    }  
  }
}
