.alphabetical-filters {
  @include clearfix;

  &__letter {
    float: left;
    width: 100%/(26 / 4);
    text-align: center;
    margin-bottom:  px-to-em($spacing__sm);
  }

  &__filter {
    font-size: px-to-em($font-size__h2);
    text-transform: uppercase;
    padding-left: 0;
    padding-right: 0;
    color: $color__brand-blue;

    &--disabled {
      color: $color__gray-shady-lady;
      cursor: initial;
    }

    &--active {
      color: $color__black;
      cursor: initial;
    }
  }


  @include respond-to('landscape') {
    &__letter {
      width: 100%/(26 / 2.85);
    }
  }

  @include respond-to('between') {

    &__letter {
      width: 100%/(26 / 2);
    }
    &__filter {
      font-size: px-to-em($font-size__h2--tablet);

      &:hover {
        color: $color__gray-hover--for-white-background;
      }

      &--disabled {
        &:hover {
          color: $color__gray-shady-lady;
        }
      }

      &--active {
        &:hover {
          color: $color__black;
        }
      }
    }
  }


  @include respond-to('desktop') {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &__letter {
      width: auto;
      flex-grow: 1;
    }
  }
}
