.related-insights {
  $grid-spacing: 1em;

  background-color: $color__white;
  padding-bottom: px-to-em($spacing__md);
  
  //mobile (single column) follows the HTML layout, prominent card is 2nd
  &__grid-item {
    list-style: none;
    margin: 0 auto $grid-spacing;

    &:not(.related-insights__grid-item--prominent) {
      position: relative;
      z-index: 10;
    }
  }

  &__body {
    text-align: center;
  }

  &__grid {
    text-align: left;

    .section-accordion &{
      padding: px-to-em($spacing__sm) 1em 0;
    }
  }

  &__more {
    margin: px-to-em($spacing__sm) auto 1em;

    .button-primary__label {

      &:before {
        content: '+';
        display: inline-block;
        width: px-to-em(20px);
      }
    }
  }

  &__disclaimer {
    @include font($size: $font-size__smallest, $weight: 'light');

    margin-top: px-to-em($spacing__gutter, $font-size__smallest);
  }


  //GRID OF 2
  @include respond-to('landscape') {
    //moved up because the accordions were uneven
    // padding-bottom: px-to-em($spacing__lg);

    &__grid {
      display: flex;
      flex-wrap: wrap;
      margin: 0 $grid-spacing / -2;

      .section-accordion &{
        padding-left: 0;
        padding-right: 0;
      }
    }

    &__grid-item {
      width: calc(50% - #{$grid-spacing});
      margin: 0 $grid-spacing/2;
      margin-bottom: $grid-spacing;
      min-height: px-to-em($insight-card-height/1.25);

      &--prominent {
        width: calc(100% - #{$grid-spacing});
      }

      //in this view, show the prominent card third - aka the second row
      &--2, &--3 {
        order: 1;
      }

      &--1 {
        order: 2;
      }

      &--4, &--5 {
        order: 3;
      }

      &--ajax {
        order: 4;
      }

    }
  }

  @include respond-to('between') {
    padding-bottom: px-to-em($spacing__lg);
    
    &__body {
      text-align: left;
    }

    &__grid {
      .section-accordion &{
        padding: 0;
      }
    }

    &__grid-item {
      min-height: px-to-em($insight-card-height);
    }

    &__more {
      margin: px-to-em($spacing__gutter) auto 0;
    }

    &__disclaimer {
      margin-top: px-to-em($spacing__md, $font-size__smallest);
    }
  }


  //GRID OF 3
  @include respond-to('desktop-large') {
    &__grid-item {
      width: calc(33.33333% - #{$grid-spacing});
      
      //first, third, fourth go before the prominent card
      &--2, &--3, &--4{
        order: 1
      }

      //prominent position on desktop - move to 4th
      //aka first on left on the second line
      &--1 {
        order: 2;
      }

      &--5 {
        order: 3;
      }

      &--ajax {
        order: 10
      }

      &--prominent {
        width: calc(66.66667% - #{$grid-spacing});
      }
    }
  }
}
