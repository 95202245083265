.topic-landing {
    $topic-insight-card-height: 325px;
    $grid-spacing: 1em;
    &__listing {
        background-color: $color__white;
        padding-bottom: px-to-em($spacing__md);
        padding-top: 0;
    }
    //mobile (single column) follows the HTML layout, prominent card is 2nd
    &__grid-item {
        width: px-to-em($insight-card-width-ideal);
        margin: 0 auto $grid-spacing;
        position: relative;
        z-index: 10;
    }

    &__listing-body {
        text-align: center;
    }

    &__grid {
        text-align: left;

        .section-accordion & {
            padding: px-to-em($spacing__sm) 1em 0;
        }
    }

    &__more {
        margin: px-to-em($spacing__sm) auto 1em;

        .button-primary__label {

            &:before {
                content: '+';
                display: inline-block;
                width: px-to-em(20px);
            }
        }
    }
    //GRID OF 2
    @include respond-to('landscape') {
        //moved up because the accordions were uneven
        // padding-bottom: px-to-em($spacing__lg);
        &__grid {
            display: flex;
            flex-wrap: wrap;
            margin: 0 $grid-spacing / -2;
        }

        &__grid-item {
            width: calc(50% - #{$grid-spacing});
            margin: 0 $grid-spacing/2;
            margin-bottom: $grid-spacing;
            min-height: px-to-em($topic-insight-card-height/1.25);
        }
    }

    @include respond-to('between') {
        padding-bottom: px-to-em($spacing__lg);

        &__grid {
            .section-accordion & {
                padding: 0;
            }
        }

        &__grid-item {
            min-height: px-to-em($topic-insight-card-height);
        }

        &__more {
            margin: px-to-em($spacing__gutter) auto 0;
        }
    }
    //GRID OF 3
    @include respond-to('desktop-large') {
        &__grid-item {
            width: calc(33.33333% - #{$grid-spacing});
        }
    }

    .insight-card {
        min-height: px-to-em($topic-insight-card-height);
    }
}
