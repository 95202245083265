.location-alert {
    background-color: $color__brand-blue;
    padding-top: $spacing__sm;
    padding-bottom: $spacing__sm;
    &__content {
        color: $color__white;

        &.rte {
            font-size: px-to-em($font-size__office-alert);
        }
    }
}