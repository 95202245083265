$breadcrumbs-height: 40px;
$breadcrumbs-height--mobile: 32px;

.breadcrumbs {
  display: none;
  
  &__options {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
  }

  &__item {
    color: $color__gray-text;
    display: inline;

    &:after {
      content: '-';

      margin-left: 0.45em;
      margin-right: 0.15em;
    }

    &:last-child {
      &:after {
        content: '';
        margin: 0;
      }
    }
  }

  &__title {
    font-size: px-to-em($font-size__small);

    @at-root a#{&} { //a.breacrumbs__title
      color: $color__white;
      @include transition(color);
    }
  }

  @include respond-to('between') {
    display: block;
    overflow: hidden;

    a.breadcrumbs__title:hover {
        color: $color__gray-text;
    }
  }
}
