.lawyer-stories {
  $snapshot-width: 120px;
  background-color: $color__white;
  padding-bottom: px-to-em($spacing__md);
  background-color: $color__white; //stop background image from sneaking over 

  &__figures {
    width: px-to-em($snapshot-width);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    &:after {
      @include overlay-gradient__coral;

      content: '';
      z-index: 10;
    }
  }

  &__snapshot {
    @include bg-cover;
    @include transition(opacity);

    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition-duration: 1s;
    

    &--active {
      opacity: 1;
      z-index: 5;
      transition: opacity 1s $transition__ease,
        z-index 0.1s linear 1s;
    }
  }

  &__main {
    padding:px-to-em($spacing__gutter/2) px-to-em($spacing__unit) px-to-em($spacing__gutter/2) px-to-em($snapshot-width + $wrapper-padding);
    position: relative;
    background-color: $color__brand-gray;
    height: px-to-em(235px);
  }


  &__tagline {
    @include titles-subheading();

    margin-bottom: px-to-em($spacing__sm, $font-size__subheading);
  }

  &__name {
    @include titles-banner;

    line-height: 1.4;
    color: $color__black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__name-link {
    @include links__blue-on-light;
  }

  &__meta {
    @include font($size: $font-size__h3, $weight: 'light');

    font-style: italic;
    margin-top: 0.2em;
    
    &:before {
      content: '-';
      display: inline-block;
      margin-right: 0.25em;
    }
  }

  &__meta-item {
    &:not(:last-child) {
      &:after {
        content: ',';
        margin-right: 0.2em;
      }
    }
  }

  &__qa {
    // @include font($size: $font-size__h3, $weight: 'light');
    font-weight: map-get($map: $font-weights, $key: 'light');
    display: flex;
    flex-wrap: nowrap;
    text-align: left;

    & + & {
      margin-top: px-to-em($spacing__unit);
    }

    &-prefix {
      width: 1.5em;
      flex-shrink: 0;

      &:after {
        content: ':';
      }
    }
  }


  &__count {
    padding: px-to-em($spacing__gutter) 0;
    display: inline-block;
    position: relative;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;

    &-info {
      font-size: px-to-em($font-size__small);
      font-style: italic;
    }
  }

  &__details {
    text-align: center;
  }

  &__arrow {
    @include center-align(vertical);

    color: $color__brand-coral;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

  }

  &__closing {
    margin-top: px-to-em($spacing__gutter);
  }

  &__name-wrapper {
    flex: 1 1 auto;
  }

  &__cta-wrapper {
    flex: 0 0 auto;
  }

  &__video-cta {
    position: relative;

    &:hover::after {
      border-left-color: $color__white;
    }

    span::after {
      content: '';
      top: .15em;
      position: relative;
      width: 0; 
      height: 0; 
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 15px solid $color__white;
      margin-left: 1em;
      display: inline-block;
      transition: border-left-color .35s cubic-bezier(.86,0,.07,1),box-shadow .35s cubic-bezier(.86,0,.07,1);
    }

    &:hover {
      span::after {
        border-left-color: $color__brand-coral;
      }
    }

  }

  @include respond-to('landscape') {
    $snapshot-width: 185px;

    &__main {
      padding-left: px-to-em($snapshot-width + $wrapper-padding--tablet);
      padding-right: px-to-em($wrapper-padding--tablet);
      min-width: px-to-em($snapshot-width);
      height: px-to-em(172px);
    }

    &__figures {
      width: px-to-em($snapshot-width);
    }

    &__closing{
        margin-top: 5em;
    }
  }

  @include respond-to('between') {
    $snapshot-width: 50%;
    padding-top: px-to-em($wrapper-padding--tablet);
    padding-bottom: px-to-em($wrapper-padding--tablet);
    padding-left: $snapshot-width;
    position: relative;
    transition: all 0.5s;


    &__main {
      padding-left: px-to-em($wrapper-padding--tablet);
      padding-top: 0;
      padding-bottom: 0;
      min-width: inherit;
      position: static;
      background-color: $color__white;
    }

    &__figures {
      width: $snapshot-width;
    }

    &__closing {
      text-align: left; 
      margin-top: 5em;
    }

    &__name {
      font-size: px-to-em(30px);
    }

    &__meta {
      margin-top: 0.2em;
    }

    &__details {
      text-align: left;
    }

      //without fixed height, the wordier testimonials glitch on resize
    &__experience {
      height: 10em;
    }

    &__count-info {
      font-size: 1em;
      font-weight: map-get($map: $font-weights, $key: 'light');
    }

    &__arrow {
      .icon {
        font-size: px-to-em(18px);
      }
    }
  }

  @include respond-to('desktop') {
    $padding-sides: 10%;
    padding-top: px-to-em($spacing__lg);
    padding-bottom: px-to-em($spacing__lg);

    &__main {
      padding-left: $padding-sides;
      padding-right: $padding-sides;
    }

    .wrapper {
      padding-left: $padding-sides;
      padding-right: $padding-sides;
    }

    &__name {
      font-size: px-to-em($font-size__banner--tablet);
    }

    &__qa,
    &__meta {
      font-size: px-to-em($font-size__h3--tablet)
    }

    &__answer {
      min-height: px-to-em(150px, $font-size__h3--tablet);
    }

    &__closing {
      margin-top: px-to-em($spacing__gutter * 2);
    }

      //without fixed height, the wordier testimonials glitch on resize
    &__experience {
      height: 13em;
    }

  }
  @include respond-to('desktop-largest') {
    $snapshot-width: px-to-em(675px);

    padding-left: $snapshot-width;

    &__figures {
      width: $snapshot-width;
    }

    &__experience {
      max-width: px-to-em(700px);
      height: auto;
    }
  }
}