.general-subnavigation {
    &.section {
        &__dark-bg {
            background-color: transparent;
            padding-top: 0;

            .general-subnavigation__grid-item {
                &:last-child {
                    .button {
                        border-bottom: 1px solid $color__white;
                    }
                }
            }

            .titles__subheading {
                color: $color__white;

                &:after {
                    background-color: $color__white;
                }
            }

            .button {
                border-color: $color__white;
                background-color: transparent;

                &:before {
                    background-color: transparent;
                }
                .button-primary__label {
                    color: $color__white;
                }

                &__active {
                    .button-primary__label {
                        color: $color__grey;
                    }
                }
            }
            .general-subnavigation__listing {
                background-color: $color__black;
            }
            .general-subnavigation__nav-toggle{
                background-color: $color__white;
                color: $color__black;
            }

            .general-subnavigation__nav-link {
                color: $color__white;
                &--active {
                    color: $color__grey;
                }

            }
        }
    }
    &__nav{
        position: relative;
    }
    &__listing{
        position: absolute;
        top: 100%;
        left: 0;
        opacity: 0;
        visibility: hidden;
        background-color: $color__white;
        width: 100%;
        z-index: $zindex__back-to-top - 5; // 

        &--no-dropdown{
            opacity: 1;
            visibility: visible;
            position: relative;
            top: 0;
            left: 0;
            background-color: transparent;
        }
    }
    &__secondary-listing{
        margin-top: $spacing__gutter;
    }
    &__secondary-grid-item{
        margin-bottom: px-to-em(10px);
    }
    &__grid-item {
        display: block;

        &:last-child {
            margin-right: 0;

            .button {
                border-bottom: 1px solid $color__brand-blue;
            }
        }

        .button {
            width: 100%;
            min-width: 100px;
            padding: px-to-em($spacing__unit * 1.5, $font-size__small) px-to-em($spacing__unit * 2, $font-size__small);
            z-index: 1;
            display: block;
            border-bottom: none;
            text-align: left;

            &:before {
                    display: none;
                }

            &__active {
                box-shadow: none;
                .button-primary__label {
                    color: $color__black;
                }

                
            }
        }

        &--no-dropdown {
            margin-bottom: px-to-em(10px);

            .button {
                text-align: center;
                border-bottom: 1px solid $color__brand-blue;
            }
        }
    }

    &__nav-toggle{
        display: block;
        position: relative;
        background-color: $color__brand-blue;
        color: $color__white;
        width: 100%;
        text-align: left;
        padding: px-to-em($spacing__unit * 1.5, $font-size__small) px-to-em($spacing__unit * 2, $font-size__small) ;
        padding-right: px-to-em($spacing__unit* 2, $font-size__small);
        z-index: 1;
        @include ico-icon-after('chevron-thin-down') {
          @include center-align(vertical);
          @include transition(transform);

          right: px-to-em($spacing__unit * 1.5, $font-size__small);
        }
    }

    &__nav--show-dropdown {
        .general-subnavigation__listing {
          opacity: 1;
          visibility: visible;
        }

        .general-subnavigation__nav-toggle {
          &:after {
            transform: rotateX(180deg)  translateY(50%) ;
          }
        }
      }

    

    &__nav-link {
        font-size: $font-size__h3;
        text-transform: uppercase;
        color: $color__brand-blue;
        margin-right: px-to-em(40px);

        &--active {
            color: $color__black;
        }

    }

    @include respond-to('landscape') {
        &.section {
            &__dark-bg {
                .button{
                    border-bottom: 1px solid $color__white;
                     &:before {
                            background-color: $color__white;
                        }
                        &__active {
                            background-color: $color__white;
                    
                            .button-primary__label {
                                color: $color__black;
                            }
                        }
                    }

            }
        }
        &__listing {
          display: block;
          position: static;
          top: auto;
          left: auto;
          opacity: 1;
          visibility: visible;
          background-color: transparent;
          width: 100%;
        }
        &__secondary-grid-item{
            display: inline-block;
        }
        &__grid-item {
            display: inline-block;
            margin-right: px-to-em(20px);
            margin-bottom: px-to-em(10px);
            .button {
                width: auto;
                border-bottom: 1px solid $color__brand-blue;
                text-align: center;
                padding: px-to-em($button__padding) px-to-em($button__padding-sides);
                
                &:before{
                        display: block;
                    }
                &__active {
                    background-color: $color__brand-blue;
                    
                    .button-primary__label {
                        color: $color__white;
                    }
                }
            }
        }

        &__nav-toggle{
            display: none;
        }
    }

    @include respond-to('between') {
        &.section {
            &__dark-bg {
                .button {
                    &:hover {
                        .button-primary__label {
                            color: $color__black;
                        }
                    }
                }

                .general-subnavigation__nav-link {
                    &:hover{
                        color: $color__grey
                    }
                }
            }
        }

        &__grid-item {
            .button {
                &:hover {
                    .button-primary__label {
                        color: $color__white;
                         @include transition-bezier(color);
                    }
                }
            }
        }

        &__nav-link {
            @include transition-bezier(color);
            &:hover{
                color: $color__black
            }
        }
    }
}
