$offset-sliver: px-to-em(40px);

.showcase-copy {
  //this is different than the RTE - this is what appears on the right with the wedge 
  
  height: calc(100vh - #{$offset-sliver});

  //mobile is only visible on marquee story detail page
  .page__marquee-story-detail & {
    height: auto;
    padding-top: $wrapper-padding--tablet * 2;
    padding-bottom: $wrapper-padding--tablet * 2;
  }

  &__cta {
    margin-top: px-to-em($spacing-base);

    & a:hover {
      color: $color__gray-hover--for-gray-background;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    // align-items:center; 
    height: 100%;
  }

  .rte {
    font-weight: map-get($map: $font-weights, $key: 'regular' );
  }


  @include respond-to('showcase-desktop') {

    //both marquee story detail and homepage view need padding now
    padding-top: $wrapper-padding--tablet * 2;
    padding-bottom: $wrapper-padding--tablet * 2;
    
    &__body {
      padding-top: 1em;
    }

    .showcase-layout__insight-detail & {
      padding-left: px-to-em($wrapper-padding--tablet);
    }

    .showcase-layout &__body {
      padding-left: calc(50% + #{px-to-em($wrapper-padding--tablet)} );
    }

    .showcase-layout & {
       height: calc(100vh - #{$offset-sliver});
    }

    .rte {
      font-size: px-to-em(18px);
      //the desktop design doesn't really handle huge amounts of text, so cut it off if it happens, or it looks really broken
      max-height: 100%;
      overflow: hidden;
    }
  }

  @include respond-to('desktop-large') {

    .rte {
      font-size: px-to-em($font-size__copy--tablet);
    }
  }
}
