.tab-area-tile {
  @include transition(opacity);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;

  //without this, large tabs make the bio detail page too long
  visibility: hidden;
  height: 0;

  &--active {
    visibility:visible;
    height: auto;
    opacity: 1;
    position: relative;
    z-index: 2;
  }

  .tabby-area__frame--transition & {
    &--active {
      opacity: 0;
      transition-delay: 0s;
    }
  }
}


