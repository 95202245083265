.overview-block {

  &--bio {
    .titles__subheading {
      display: none;
    }
  }

  &__heading-overview {
        &.section {
            background-color: transparent;
            padding: 0;

            .wrapper {
                padding: 0;
            }
        }
        .read-more {
            &__window {
			    height: px-to-em(90px);

                &:after {
                    background: none;
                }
		    }

            &--not-expandable &{
		        &__window {
			        height: auto;
		        }
	        }

        }
        
    }

  &__body {
      &--mobile-accordion {
        padding-left: 1em;
        padding-right: 1em;

        .rte {
          padding-top: px-to-em($spacing__sm);
          padding-bottom: px-to-em($spacing__sm);
        }
      }
  }

  @include respond-to('between') {
    &--bio {
      padding-top: px-to-em($spacing__lg/2);

      .titles__subheading {
        display: block;
      }
    }

    &__body{
      &--mobile-accordion {
        padding: 0;

        .rte {
          padding: 0;
        }
      }
    }

  }
}
